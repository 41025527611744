import { RecruitersActionsConstants, RecruitersActionTypes } from './types';

import { apiFetch } from '../util/apiFetch';
import { Action } from 'redux';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { showAlert } from './alertActions';
import { AlertType } from '../typings/enums';

export const getRecruiters = (
  positionId: string
): ThunkAction<
  void,
  RootState,
  RecruitersActionTypes,
  Action<string>
> => async (dispatch) => {
  try {
    dispatch({
      type: RecruitersActionsConstants.PENDING_RECRUITERS,
      payload: positionId,
    });
    const res = await apiFetch('company', `/position/${positionId}/recruiter/`);

    if (res.error) throw new Error(res.error);

    dispatch({
      type: RecruitersActionsConstants.GET_RECRUITERS,
      payload: { recruiters: res.data, positionId },
    });
  } catch (err) {
    dispatch({
      type: RecruitersActionsConstants.ERROR_RECRUITERS,
      payload: err.message,
    });
  }
};
export const deleteRecruiterByPosition = (
  positionId: string,
  recruiterId: string
): ThunkAction<
  void,
  RootState,
  RecruitersActionTypes,
  Action<string>
> => async (dispatch) => {
  try {
    const res = await apiFetch(
      'company',
      `/position/${positionId}/recruiter/${recruiterId}/`,
      'DELETE'
    );
    if (res.error) throw new Error(res.error);
    dispatch({
      type: RecruitersActionsConstants.DELETE_RECRUITER_BY_POSITION,
      payload: { recruiterId, positionId },
    });
    dispatch(
      showAlert({
        message: 'Recruiter successfully deleted',
        type: AlertType.success,
      })
    );
  } catch (err) {
    dispatch(showAlert({ message: err.message, type: AlertType.fail }));
  }
};
