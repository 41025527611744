import React from 'react';
import { FormatedNotification } from '../../../typings/interfaces';
import { NameWithShortId } from '../NameWithShortId';
import { Stages, UserRoles } from '../../../typings/enums';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { userRoleSelector } from '../../../selectors';
import { filterSolutionByStage } from '../../../util/filterSolutionByStage';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../../util/DateFormat';

interface Props {
  notification: FormatedNotification;
}

export const KeyActivityRow: React.FC<Props> = ({ notification }) => {
  const userRole = useShallowEqualSelector(userRoleSelector);
  const isReviewer = userRole === UserRoles.REVIEWER;

  if (!notification.event) return null;

  let link = notification.event.link;
  if (
    !link &&
    notification.candidate &&
    filterSolutionByStage(notification.candidate, userRole, [
      Stages.SUBMITTED,
      Stages.ACCEPTED,
      Stages.REJECTED,
      Stages.PROCESSED,
      Stages.ARCHIVED,
    ])
  ) {
    link = `/position/${notification.candidate.position.uuid}/candidate/${
      notification.candidate.candidate.uuid
    }${isReviewer ? '/overview' : ''}`;
  }

  const content = (
    <>
      <div className='border-top-0'>
        {notification.candidate && (
          <NameWithShortId
            firstName={notification.candidate.candidate.first_name}
            lastName={notification.candidate.candidate.last_name}
            uuid={notification.candidate.candidate.uuid}
          />
        )}
      </div>
      <div className='border-top-0'>{notification.event.content}</div>
      <div className='border-top-0'>{notification.position.title}</div>
      <div className='border-top-0'>
        {dateFormat(notification.date_created).dateFormatKeyActExpand()}
      </div>
    </>
  );

  if (!link) {
    return (
      <div className='grid-cols-4 grid py-2 text-black px-2'>{content}</div>
    );
  }
  return (
    <Link
      to={link}
      className='grid-cols-4 grid hover:bg-blue-100 py-2 text-black hover:text-black px-2'
    >
      {content}
    </Link>
  );
};
