import React, { FC } from 'react';
import { InstanceTicketTypes } from '../../../actions/types';
import { ITicket } from '../../../typings/interfaces';
import { TicketSelf } from './TicketSelf';

interface TicketDashboardProps {
  ticketsEntity: ITicket[];
}

export const TicketDashboard: FC<TicketDashboardProps> = ({
  ticketsEntity,
}) => (
  <div className='ticket-dashboard'>
    <h2>Selected tickets</h2>
    <div className='ticket-table'>
      <div className='ticket-table-wrap'>
        <div className='ticket-table-header'>
          <span></span>
          <span>Ticket</span>
          <span>Title</span>
          <span>Type</span>
          <span>Skils</span>
          <span>Story Points</span>
          <span>Mandatory</span>
        </div>
        {ticketsEntity.map((item: ITicket) => (
          <TicketSelf ticketInfo={item} key={item.id} />
        ))}
      </div>
    </div>
  </div>
);
