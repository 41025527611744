export function nGram(n: number) {
  if (typeof n !== 'number' || isNaN(n) || n < 1 || n === Infinity) {
    throw new Error('`' + n + '` is not a valid argument for n-gram');
  }

  return grams;

  // Create n-grams from a given value.
  function grams(value: string) {
    const nGrams: string[] = [];
    let index;

    if (value === null || value === undefined) {
      return nGrams;
    }

    index = value.length - n + 1;

    if (index < 1) {
      return nGrams;
    }

    while (index--) {
      nGrams[index] = value.slice(index, index + n);
    }

    return nGrams;
  }
}
