import React, { useEffect } from 'react';
import { ReactComponent as LikeIcon } from '../../../assets/like.svg';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { postLike, getLikes } from '../../../actions/likesActions';
import { LikesContentTypeEnum, UserRoles } from '../../../typings/enums';
import { useDispatch } from 'react-redux';
import { ILike } from '../../../typings/interfaces';
import { LikesBlock } from '../../../shared/components/LikesBlock';
import { Loader } from '../Loader';
import cn from 'classnames';
import './LikeBtn.scss';
import {
  likesByCandidatePositionSelector,
  pendingLikesSelector,
  userSelector,
  errorLikesSelector,
} from '../../../selectors';

interface Props {
  solutionId: string;
}

export const VoteLike: React.FC<Props> = ({ solutionId }) => {
  const dispatch = useDispatch();

  const pendingLikes = useShallowEqualSelector(pendingLikesSelector);
  const errorLikes = useShallowEqualSelector(errorLikesSelector);
  const likesByCandidatePosition = useShallowEqualSelector(
    likesByCandidatePositionSelector
  );

  const user = useShallowEqualSelector(userSelector);

  let currentLike: ILike | undefined;
  if (likesByCandidatePosition && user) {
    currentLike = likesByCandidatePosition.find(
      (like) =>
        like.content_type_id === LikesContentTypeEnum.CANDIDATE_POSITION &&
        like.created_by.uuid === user?.identity &&
        like.object_id === solutionId
    );
  }

  useEffect(() => {
    dispatch(getLikes(LikesContentTypeEnum.CANDIDATE_POSITION, solutionId));
  }, [dispatch, solutionId]);

  const handleClick = (negate: boolean) => {
    if (likesByCandidatePosition) {
      dispatch(
        postLike(
          LikesContentTypeEnum.CANDIDATE_POSITION,
          solutionId,
          !!currentLike,
          likesByCandidatePosition,
          negate
        )
      );
    }
  };

  if (pendingLikes) {
    return <Loader />;
  }

  if (errorLikes) {
    return <div className='mx-1'>error: {errorLikes}</div>;
  }

  if (!likesByCandidatePosition) return null;

  const DeslikeBtn = (
    <button
      className={classes}
      disabled={currentLike && currentLike.negate}
      onClick={() => handleClick(true)}
    >
      <LikeIcon style={{ transform: 'rotate(180deg)' }} />
    </button>
  );
  const LikeBtn = (
    <button
      className={classes}
      onClick={() => handleClick(false)}
      disabled={currentLike && !currentLike.negate}
    >
      <LikeIcon />
    </button>
  );
  return (
    <div className='d-flex align-items-center'>
      {user?.user_claims.roles[0] === UserRoles.REVIEWER && (
        <div className='d-flex align-items-center mr-4'>
          <span className='mr-2'>Do you like this solution</span>
          {LikeBtn}
          {DeslikeBtn}
        </div>
      )}
      <div
        className={cn({
          'mr-6': likesByCandidatePosition.length > 0,
        })}
      >
        <LikesBlock votes={likesByCandidatePosition} />
      </div>
    </div>
  );
};
const classes = 'border-0 p-0 focus:outline-none like-btn bg-transparent mx-1';
