import moment from 'moment';

class DateFormat {
  private readonly dateTime: string;
  constructor(dateTime: string) {
    this.dateTime = dateTime;
  }
  getMonth(month: number) {
    return [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ].filter((item, index) => (month === index ? item : null));
  }

  dateFormatKeyActExpand() {
    return `${new Date(this.dateTime).getDate()} ${this.getMonth(
      new Date(this.dateTime).getMonth()
    )}${
      new Date(this.dateTime).getFullYear() ===
      new Date(Date.now()).getFullYear()
        ? ','
        : ` ${new Date(this.dateTime).getFullYear()},`
    } ${new Date(this.dateTime).getHours()}:${
      new Date(this.dateTime).getMinutes() < 10
        ? `0${new Date(this.dateTime).getMinutes()}`
        : new Date(this.dateTime).getMinutes()
    }`;
  }

  dateFromNow() {
    const lessThenMinute =
      Math.ceil(Date.now() / 1000) -
      Math.ceil(Date.parse(this.dateTime) / 1000);
    if (lessThenMinute < 10850) {
      return 'Just now';
    }
    return moment.utc(this.dateTime).fromNow();
  }
}

export const dateFormat = (data: string) => new DateFormat(data);
