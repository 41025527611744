import React from 'react';
import { INotification, ISolution } from '../../typings/interfaces';
import { generateKeyActivityEvent } from '../../util/generateKeyActivityEvent';
import { filterSolutionByStage } from '../../util/filterSolutionByStage';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { userRoleSelector } from '../../selectors';
import { Stages, UserRoles } from '../../typings/enums';
import { NameWithShortId } from '../../shared/components/NameWithShortId';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../util/DateFormat'
import './KeyActivity.scss';

interface Props {
  notification: INotification;
  candidate: ISolution | undefined;
}

export const KeyActivity: React.FC<Props> = ({ notification, candidate }) => {
  const userRole = useShallowEqualSelector(userRoleSelector);
  const event = generateKeyActivityEvent(notification, candidate);
  const isReviewer = userRole === UserRoles.REVIEWER;

  if (!event?.content) return null;

  let link = event.link;
  if (
    !link &&
    candidate &&
    filterSolutionByStage(candidate, userRole, [
      Stages.SUBMITTED,
      Stages.ACCEPTED,
      Stages.REJECTED,
      Stages.PROCESSED,
      Stages.ARCHIVED
    ])
  ) {
    link = `/position/${candidate.position.uuid}/candidate/${
      candidate.candidate.uuid
    }${isReviewer ? '/overview' : ''}`;
  }

  const content = (
    <>
      <div className='text-black'>
        <span>
          {candidate ? (
            <NameWithShortId
              firstName={candidate.candidate.first_name}
              lastName={candidate.candidate.last_name}
              uuid={candidate.candidate.uuid}
            />
          ) : (
            'N/A'
          )}
        </span>
        <span> {event.content}</span>
        <span> &#9679; {candidate?.position.title} </span>
        <span className='text-gray-600'>
          &#9679; {dateFormat(notification.date_created).dateFromNow()}
        </span>
      </div>
    </>
  );

  if (!link) {
    return (
      <div className='text-sm notification d-flex lh-24 mb-2 px-3'>
        {content}
      </div>
    );
  }

  return (
    <Link
      className='text-sm notification d-flex lh-24 mb-2 px-3 hover:bg-blue-100'
      to={link}
    >
      {content}
    </Link>
  );
};
