import React from 'react';
import cn from 'classnames';

interface Props {
  isDropdownOpen: {
    isOpen: boolean;
    type: string;
  };
  setIsDropdownOpen: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      type: string;
    }>
  >;
  curRef: React.MutableRefObject<any>;
  icon: JSX.Element;
  dropdown: string;
  children: React.ReactNode;
  className?: string;
  testId?: string | undefined;
}

export const HeaderDropdown: React.FC<Props> = ({
  isDropdownOpen,
  setIsDropdownOpen,
  curRef,
  icon: Icon,
  dropdown,
  children,
  className,
}) => {
  return (
    <div
      className={cn('btn-group', {
        show:
          isDropdownOpen.isOpen === true && isDropdownOpen.type === dropdown,
      })}
      ref={curRef}
    >
      <button
        data-testid='profile_details_button'
        type='button'
        className={cn(
          'border-0',
          'bg-transparent',
          'dropdown-toggle',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'position-relative',
          'outline-none',
          className,
          {
            show:
              isDropdownOpen.isOpen === true &&
              isDropdownOpen.type === dropdown,
          }
        )}
        style={{ height: '40px', width: '40px', outline: 'none' }}
        onClick={() => {
          setIsDropdownOpen({
            isOpen:
              isDropdownOpen.isOpen && isDropdownOpen.type === dropdown
                ? false
                : true,
            type: dropdown,
          });
        }}
      >
        {Icon}
      </button>
      <div
        style={{ minWidth: '170px' }}
        className={cn(
          'dropdown-menu',
          'dropdown-menu-right',
          'dropdown-animated',
          {
            show:
              isDropdownOpen.isOpen === true &&
              isDropdownOpen.type === dropdown,
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};
