import React from 'react';
import { CandidateLink } from '../../shared/components/CandidateLink';
import { ISolution, ILike, INote } from '../../typings/interfaces';
import { InformCandidateBlock } from '../pages/CandidatesToInform/InformCandidateBlock';
import { FeedbackNote } from './FeedbackNote';
import { CandidateCardFooter } from './CandidateCardFooter';
import { Interview } from './Interview';
import cn from 'classnames';
import './candidateCard.scss';

interface Props {
  candidate: ISolution;
  votes: ILike[] | undefined;
  curCandidateComments: INote[] | undefined;
}

export const CandidateCard: React.SFC<Props> = ({
  candidate,
  votes,
  curCandidateComments,
}) => {
  return (
    <div
    className='candidate-card-wrap'
      key={candidate.candidate.uuid}
    >
      <div className='candidate-card-flex'>
        <div className='candidate-card-flex-position'>
          <div className='mb-3 d-flex align-items-center'>
            <CandidateLink
              candidate={candidate}
              className='font-weight-bold mr-2 font-weight-bold text-black hover:text-primary text-lg lh-24'
            />
          </div>
          <FeedbackNote candidate={candidate} />
          <CandidateCardFooter
            candidate={candidate}
            votes={votes}
            curCandidateComments={curCandidateComments}
          />
        </div>

        <div
          className={cn('border-left pl-6 flex-shrink-0', {
            'pt-4': !candidate.feedback?.is_sent,
          })}
        >
          {!candidate.feedback?.is_sent ? (
            <InformCandidateBlock candidate={candidate} />
          ) : (
            <Interview />
          )}
        </div>
      </div>
    </div>
  );
};
