import React from 'react';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import { ISlideBtn } from './ImgRightSlider';

interface Props {
  btn: ISlideBtn;
  index: number;
  handleSelect: (selectedIndex: number, e: object | null) => void;
  btnIndex: number;
  minHeight?: string;
}

export const SliderBtn: React.FC<Props> = ({
  btn,
  index,
  handleSelect,
  btnIndex,
  minHeight,
}) => {
  const isActive = btnIndex === index;
  return (
    <div
      className={cn('mt-2 mt-lg-0 p-lg-2 px-lg-4 mb-lg-4 cursor-pointer', {
        'lg:bg-blue-100': isActive,
      })}
      onClick={(e) => handleSelect(btnIndex, e)}
      key={uuid()}
      style={{ minHeight }}
    >
      <div
        className={cn('text-xl lg:text-22 font-weight-bold', {
          'lg:text-blue-500': isActive,
        })}
      >
        {btn.title}
      </div>
      <div>{btn.text}</div>
    </div>
  );
};
