import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { showModal } from '../../actions/modalActions';
import { Actions } from '../../typings/enums';

export const DecisionBtnBlock: React.FC = () => {
  const dispatch = useDispatch();

  const handleReview = (type: string) => {
    dispatch(showModal({ type, title: type[0].toUpperCase() }));
  };

  return (
    <div className='d-flex align-items-center' data-testid='accept-reject-btn'>
      <Button
        variant='outline-dark'
        className='mr-3 px-8 rounded-lg'
        onClick={() => handleReview(Actions.reject)}
      >
        Reject
      </Button>
      <Button
        className='px-8 rounded-lg'
        variant='warning'
        onClick={() => handleReview(Actions.accept)}
      >
        Accept
      </Button>
    </div>
  );
};
