import React from 'react';
import { NavBar } from '../../components/NavBar';
import { BookADemoBtn } from '../../components/BookADemoBtn';
import heroImg from '../../../assets/newLanding/hero.png';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import cn from 'classnames';

interface Props {
  handleLinkClick?: (
    e: React.MouseEvent<Element, MouseEvent>
  ) => void | undefined;
}

export const Header: React.FC<Props> = ({ handleLinkClick }) => {
  const [isTablet] = useMatchMedia(769);
  const [isLandscape] = useMatchMedia(667);

  return (
    <div className='pt-6 pb-8 pb-md-12'>
      <div className='container'>
        <NavBar handleLinkClick={handleLinkClick} links={LINKS} />
      </div>
      <div
        className={cn('d-md-flex justify-content-between', {
          container: isTablet,
        })}
      >
        <div className='text-blue-100 pb-8 container pl-md-0 mx-md-0 md:w-6/12 pr-md-6'>
          <h1 className='text-4-7xl' style={{ lineHeight: '56px' }}>
            Shortlist promising tech candidates in minutes
          </h1>
          <p className='text-2xl lh-32' style={{ maxWidth: '540px' }}>
            Sfeeda helps you to evaluate coding ability with ready-made real
            world challenges
          </p>
          <BookADemoBtn
            className='mr-lg-4 mb-4 mb-lg-0 rounded-lg font-weight-bold d-flex align-items-center justify-content-center'
            style={{ maxWidth: '188px', height: '3rem' }}
          />
        </div>
        <div
          className={cn('md:w-6/12 mx-sm-auto', {
            container: isLandscape && !isTablet,
          })}
        >
          <img
            src={heroImg}
            alt='Shortlist promising tech candidates in minutes'
            className='img-fluid w-100'
          />
        </div>
      </div>
    </div>
  );
};
const LINKS = [
  {
    titleDesktop: 'Hiring teams',
    titleMob: 'Teams',
    href: '/hiring_teams',
  },
  {
    titleDesktop: 'Recruiters',
    titleMob: 'Recruiters',
    href: '/recruiters',
  },

  {
    titleDesktop: 'Pricing',
    titleMob: 'Pricing',
    href: 'pricing',
  },
  {
    titleDesktop: 'Contact',
    titleMob: 'Contact',
    href: '/contact',
  },
];
