import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import { Invite } from '../../../typings/interfaces';
import { dateFormat } from '../../../util/DateFormat';
import { useDispatch } from 'react-redux';
import { AlertType } from '../../../typings/enums';
import { showAlert } from '../../../actions/alertActions';

interface InvitesTableProps {
  invitesData: Invite[];
}

export const InvitesTable: FC<InvitesTableProps> = ({ invitesData }) => {
  const dispatch = useDispatch();
  const tableHeader = [
    { id: 1, title: 'Invite ID/Email' },
    { id: 2, title: 'Send by' },
    { id: 3, title: 'Comment' },
    { id: 4, title: 'Sent' },
  ];

  const copyLinkUrl = (id: string) => {
    window.navigator.clipboard.writeText(
      `${window.OPTIONS.CANDIDATE_APP_URL}/invitation/${id}`
    );
    dispatch(showAlert({ message: 'Link copied', type: AlertType.success }));
  };
  return (
    <div className='invites-table'>
      <div className='invites-table-header'>
        {tableHeader.map(({ id, title }) => (
          <div className='header-self-title' key={id}>
            <span>
              {title}
              <FontAwesomeIcon
                icon={faAngleDown}
                size='sm'
                color='#656565'
                className='ml-2'
              />
            </span>
          </div>
        ))}
        <div className='header-self-title-empty' />
      </div>
      <div className='header-content'>
        {Object.keys(invitesData).length > 0
          ? invitesData.map((item) => (
              <div
                className='header-self-content'
                key={item.uuid}
                onClick={() => copyLinkUrl(item.uuid)}
              >
                <span>{item.email || item.uuid.split('-')[0]}</span>
                <span>{item.created_by.email}</span>
                <span>{item.note}</span>
                <span>{dateFormat(item.date_created).dateFromNow()}</span>
                <span className='content-copy-link'>Copy link</span>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};
