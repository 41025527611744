import React from 'react';
import { ReactComponent as CompleteIcon } from '../../../assets/completed.svg';
import { ReactComponent as AlertIcon } from '../../../assets/alert.svg';
import { MiniLoader } from '../../../shared/components/MiniLoader';
import { ICheck } from '../../../typings/interfaces';

interface Props {
  check: ICheck;
  isHidden?: boolean;
}

export const LintInfo: React.FC<Props> = ({ check, isHidden }) => {
  const { status, commit } = check;
  if (
    status !== 'RUNNING' &&
    check.hasOwnProperty('lintData') &&
    check.lintData
  ) {
    const countedLintData = countLint(check.lintData);

    if (
      status !== 'RUNNING' &&
      countedLintData &&
      countedLintData.lintWarnings === 0 &&
      countedLintData.lintErrors === 0
    ) {
      return (
        <div className='d-flex align-items-center'>
          <CompleteIcon className='icon--16 mr-2' />
          Ok
        </div>
      );
    }

    if (
      status !== 'RUNNING' &&
      countedLintData &&
      (countedLintData.lintWarnings !== 0 || countedLintData.lintErrors !== 0)
    ) {
      return (
        <div className='d-flex align-items-top'>
          <div>
            <AlertIcon className='icon--16 mr-2 mt-n1' />
          </div>
          <div className='d-flex flex-wrap'>
            <div>
              <span data-testid={`lint-warnings-${commit}`}>
                {isHidden ? 'X' : countedLintData.lintWarnings}
              </span>{' '}
              warnings,
            </div>
            <div>
              <span data-testid={`lint-errors-${commit}`}>
                {isHidden ? 'X' : countedLintData.lintErrors}
              </span>{' '}
              errors
            </div>
          </div>
        </div>
      );
    }
  }
  return <MiniLoader />;
};

const countLint = (lintData: any) => {
  let lintWarnings = 0;
  let lintErrors = 0;
  lintData.forEach((lint: any) => {
    lintWarnings += lint.warningCount;
    lintErrors += lint.errorCount;
  });
  return {
    lintWarnings,
    lintErrors,
  };
};
