import React from 'react';
import { ILike } from '../../../typings/interfaces';
import { MultipleLike } from './MultipleLike';
import { LikesRenderType } from '../../../typings/enums';
import { MultipleLikeWithCounter } from './MultipleLikeWithCounter';
import cn from 'classnames';

interface Props {
  votes: ILike[];
  type?: LikesRenderType;
  className?: string;
}

export const Likes: React.FC<Props> = ({ votes, type, className }) => {
  if (!votes.length) {
    return null;
  }

  if (type === LikesRenderType.count && votes.length > 1) {
    return (
      <div className={cn([className])}>
        <MultipleLikeWithCounter votes={votes} />
      </div>
    );
  }

  return (
    <div className={cn([className])}>
      <MultipleLike votes={votes} />
    </div>
  );
};
