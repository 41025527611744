import React from 'react';
import cn from 'classnames';
import { Field, FieldArray } from 'formik';

interface Props {
  values: string[];
  isError: string | string[] | undefined;
  isTouched: boolean | undefined;
}

const SKILLS = ['Front-end', 'Back-End', 'Full-Stack', 'DevOps', 'QA'];

export const Skills: React.FC<Props> = ({ values, isError, isTouched }) => {
  return (
    <div className='mb-4'>
      <div className='font-weight-bold text-xl leading-thin mb-3'>
        Assess skills for
      </div>
      <FieldArray
        name='skills'
        render={(arrayHelpers) => (
          <>
            {SKILLS.map((skill: any, index) => (
              <label
                className={cn(
                  'inline',
                  'border',
                  'mr-2',
                  'rounded-pill',
                  'py-1',
                  'px-4',
                  'my-1',
                  'cursor-pointer',
                  'position-relative',
                  { 'border-blue-800': !values.includes(skill) },
                  { 'bg-blue-500': values.includes(skill) },
                  { 'border-blue-400': values.includes(skill) },
                  { 'text-white': values.includes(skill) }
                )}
                key={skill}
                htmlFor={skill}
              >
                <Field
                  type='radio'
                  name={`skills.${skill}`}
                  value={skill}
                  id={skill}
                  className='position-absolute inset-0 w-100 h-100 opacity-0 cursor-pointer'
                  style={{ zIndex: 100 }}
                  onChange={(e: any) => {
                    const curIndex = values.findIndex(
                      (curSkill) => curSkill === e.target.value
                    );
                    if (curIndex !== -1) {
                      return arrayHelpers.remove(curIndex);
                    }
                    arrayHelpers.push(e.target.value);
                  }}
                />
                {skill}
              </label>
            ))}
          </>
        )}
      />
      <div
        className={cn({
          'text-danger my-1': isError && isTouched && isError,
        })}
      >
        {isError && isTouched && isError}
      </div>
    </div>
  );
};
