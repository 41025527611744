import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';

export let store: any = null;

export const configureStore = (preloadedState: any) => {
  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  return createStore(
    rootReducer,
    preloadedState,
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools(middlewareEnhancer)
      : middlewareEnhancer
  );
};

store = configureStore({});
