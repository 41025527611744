import React from 'react';

import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '../../../shared/components/TextField';
import { ToggleYesNo } from './ToggleYesNo';
import { Participants } from './Participants';

import { v4 as uuid } from 'uuid';

interface Props {
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  values: FormikValues;
  participants: any;
  setParticipants: React.Dispatch<any>;
}

export const AddTeamFormFields: React.SFC<Props> = ({
  errors,
  touched,
  values,
  participants,
  setParticipants,
}) => {
  return (
    <div className='pr-5'>
      <Form.Group className='mb-0 flex-grow-1'>
        <Form.Label className='text-sm mb-1'>Company</Form.Label>
        <TextField
          name='company_name'
          isError={errors.company_name}
          isTouched={touched.company_nameisError}
        />
      </Form.Group>
      <Form.Group className='mb-0 flex-grow-1'>
        <Form.Label className='text-sm mb-1'>Team name</Form.Label>
        <TextField
          name='team_title'
          isError={errors.team_title}
          isTouched={touched.team_title}
        />
      </Form.Group>
      <div className='d-flex justify-content-between align-items-center mt-3'>
        <div className='text-sm'>Team can see candidates` names</div>
        <div>
          <ToggleYesNo values={values} valueName='reveal' />
        </div>
      </div>
      <Form.Group className='mb-0 flex-grow-1'>
        <Form.Label className='text-sm mb-1'>Hiring Manager</Form.Label>
        <TextField
          name='hiring_manager'
          isError={errors.hiring_manager}
          isTouched={touched.hiring_manager}
        />
      </Form.Group>
      <Form.Group className='mb-0 flex-grow-1'>
        <Form.Label className='text-sm mb-1'>Aditional Participants</Form.Label>
        <TextField
          name='participant_email'
          isError={errors.participant_email}
          isTouched={touched.participant_email}
        />
      </Form.Group>
      <Participants
        participants={participants}
        setParticipants={setParticipants}
      />

      <button
        className='bg-transparent border-0 px-0 mb-4'
        style={{ color: '#3D5470' }}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          if (values.participant_email && !errors.participant_email) {
            setParticipants((prevParticipants: any) => {
              return [
                ...prevParticipants,
                {
                  uuid: uuid(),
                  first_name: '',
                  last_name: '',
                  email: values.participant_email,
                },
              ];
            });
            values.participant_email = '';
          }
        }}
      >
        <FontAwesomeIcon
          icon={faPlusCircle}
          size='lg'
          color='#3D5470'
          className='mr-3'
        />
        Add Participant
      </button>
    </div>
  );
};
