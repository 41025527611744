import React from 'react';
import { capitalize } from '../../../util/capitalize';
import { Invite } from '../../../typings/interfaces';
import { ReactComponent as AvatarIcon } from '../../../assets/user.svg';
import { CopyLinkBtn } from '../../../shared/components/CopyLinkBtn';
import { generateInviteLink } from '../../../util/invitesHelpers';

interface Props {
  invite: Invite;
}

export const FreshInvite: React.FC<Props> = ({ invite }) => {
  return (
    <div className='d-flex align-items-center justify-content-between mb-2'>
      <div className='d-flex align-items-center'>
        <div className='rounded-circle icon--32 mr-2 bg-gray-400 d-flex align-items-end justify-content-center'>
          <AvatarIcon className='rounded-circle icon--24' />
        </div>
        <div>{invite.email}</div>
      </div>
      <div className='d-flex align-items-center'>
        <CopyLinkBtn link={generateInviteLink(invite.uuid, invite.role.name)} />
        <div className='ml-2'>{capitalize(invite.role.name)}</div>
      </div>
    </div>
  );
};
