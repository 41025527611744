import {
  CandidatesState,
  candidatesActionTypesConstants,
  CandidatesActionsTypes,
} from '../actions/types';

export const initialState: CandidatesState = {
  errorFeedback: [],
  teamNote: '',
  pendingCandidates: [],
  errorCandidates: [],
  currentCandidate: undefined,
  attachedFeedbacks: [],
  candidates: undefined,
  pendingCandidate: false,
  errorCandidate: '',
  candidateNote: undefined,
  errorCandidateNote: '',
  pendingCandidateNote: false,
};

export const candidatesReducer = (
  state = initialState,
  action: CandidatesActionsTypes
): CandidatesState => {
  switch (action.type) {
    case candidatesActionTypesConstants.GET_POSITION_CANDIDATES:
      return {
        ...state,
        candidates: {
          ...state.candidates,
          [action.payload.positionId]: action.payload.candidates,
        },
        pendingCandidates: [...state.pendingCandidates].filter(
          (id) => id !== action.payload.positionId
        ),
        errorCandidates: [...state.errorCandidates].filter(
          (id) => id !== action.payload.positionId
        ),
      };
    case candidatesActionTypesConstants.CLEAR_CANDIDATES:
      return {
        ...state,
        candidates: undefined,
        pendingCandidates: [],
        errorCandidates: [],
      };
    case candidatesActionTypesConstants.PENDING_CANDIDATES:
      return {
        ...state,
        pendingCandidates: [...state.pendingCandidates, action.payload],
      };
    case candidatesActionTypesConstants.ERROR_CANDIDATES:
      return {
        ...state,
        errorCandidates: [...state.errorCandidates, action.payload],
        pendingCandidates: [...state.pendingCandidates].filter(
          (id) => id !== action.payload
        ),
      };
    case candidatesActionTypesConstants.SET_CURRENT_CANDIDATE:
      return {
        ...state,
        currentCandidate: action.payload,
        errorCandidate: '',
        pendingCandidate: false,
      };
    case candidatesActionTypesConstants.PENDING_CANDIDATE:
      return { ...state, pendingCandidate: true };
    case candidatesActionTypesConstants.ERROR_CANDIDATE:
      return {
        ...state,
        errorCandidate: action.payload,
        pendingCandidate: false,
      };
    case candidatesActionTypesConstants.ERROR_SEND_FEEDBACK:
      return {
        ...state,
        errorFeedback: [...state.errorFeedback, action.payload],
      };
    case candidatesActionTypesConstants.CLEAR_ERROR_SEND_FEEDBACK:
      return {
        ...state,
        errorFeedback: state.errorFeedback.filter(
          (id) => id !== action.payload
        ),
      };
    case candidatesActionTypesConstants.GET_CANDIDATE_NOTE:
      return {
        ...state,
        candidateNote: action.payload,
        errorCandidateNote: '',
        pendingCandidateNote: false,
      };
    case candidatesActionTypesConstants.ERROR_CANDIDATE_NOTE:
      return {
        ...state,
        errorCandidateNote: action.payload,
        pendingCandidateNote: false,
      };
    case candidatesActionTypesConstants.PENDING_CANDIDATE_NOTE:
      return {
        ...state,
        pendingCandidateNote: true,
      };
    case candidatesActionTypesConstants.CLEAR_CANDIDATE_NOTE:
      return {
        ...state,
        candidateNote: undefined,
        errorCandidateNote: '',
        pendingCandidateNote: false,
      };
    case candidatesActionTypesConstants.ATTACH_FEEDBACK:
      return {
        ...state,
        attachedFeedbacks: [...state.attachedFeedbacks, action.payload],
      };
    case candidatesActionTypesConstants.DETACH_FEEDBACK:
      return {
        ...state,
        attachedFeedbacks: [...action.payload],
      };
    case candidatesActionTypesConstants.CLEAR_CANDIDATE:
      return {
        ...state,
        currentCandidate: undefined,
      };
    default:
      return state;
  }
};
