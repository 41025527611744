import React from 'react';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { Header } from '../Layout/Header';
import { Sidebar } from './Sidebar';
import { Footer } from '../../shared/components/Footer';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { NavBar } from '../../shared/components/NavBar';
import { userSelector } from '../../selectors';

interface Props {
  children: React.ReactNode;
}

const LINKS = [
  {
    titleDesktop: 'Hiring teams',
    titleMob: 'Teams',
    href: '/hiring_teams',
  },
  {
    titleDesktop: 'Recruiters',
    titleMob: 'Recruiters',
    href: '/recruiters',
  },
  {
    titleDesktop: 'Pricing',
    titleMob: 'Pricing',
    href: '/#pricing',
  },
  {
    titleDesktop: 'Contact',
    titleMob: 'Contact',
    href: '/contact',
  },
];

export const PoliciesContainer: React.FC<Props> = ({ children }) => {
  const user = useShallowEqualSelector(userSelector);
  const [isMatchMediaQuery] = useMatchMedia(992);

  return (
    <div className='bg-white'>
      {user ? <Header withLogo /> : null}
      <div className='container pt-6'>
        {!user ? <NavBar darkText links={LINKS} /> : null}

        <div className='d-lg-flex'>
          {isMatchMediaQuery ? <Sidebar /> : null}
          <div
            className='flex-grow-1'
            style={{ paddingTop: user ? '50px' : undefined }}
          >
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
