import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { signin } from '../../../actions/userActions';
import { LoaderMini } from '../../components/LoaderMini';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { FormContainer } from '../../components/SubscribeForms/FormContainer';
import * as Yup from 'yup';
import { IFields } from '../../../typings/interfaces';
import { FormFields } from '../../components/FormFields';
import { Button } from 'react-bootstrap';
import { CustomCheckbox } from './CustomCheckbox';
import { useParams, useHistory } from 'react-router-dom';
import { userSelector, userFetchErrorSelector } from '../../../selectors';

const initialValues: FormValues = {
  email: '',
  password: '',
  'keep-signed': false,
};

interface FormValues {
  email: string;
  password: string;
  'keep-signed': boolean;
}

export const Singin: React.SFC = () => {
  const { inviteId } = useParams();

  const dispatch = useDispatch();
  let history = useHistory();

  const user = useShallowEqualSelector(userSelector);

  const userFetchError = useShallowEqualSelector(userFetchErrorSelector);

  const fields: IFields = {
    email: {
      label: 'Email',
      id: 'field_username',
      autoComplete: 'username',
      tabIndex: 1,
    },
    password: {
      label: 'Password',
      id: 'field_password',
      tabIndex: 2,
    },
  };

  useEffect(() => {
    if (user && !inviteId) history.push('/dashboard');
  }, [history, inviteId, user]);

  if (user && !inviteId) return null;

  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            signin(
              {
                username: values.email,
                password: values.password,
                invite: inviteId,
              },
              values['keep-signed'],
              () => {
                setSubmitting(false);
                if (inviteId) history.push('/dashboard');
              }
            )
          );
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          password: Yup.string()
            .required('Required')
            .min(2, 'Must be 2 characters or more'),
        })}
      >
        {({ errors, touched, isSubmitting }) => (
          <div style={{ maxWidth: '430px' }} className='mx-auto'>
            <h1 className='text-4-5xl mb-6 text-center font-weight-bold'>
              Sign in to your account
            </h1>
            {userFetchError && (
              <div className='text-danger mb-3' id='login_form_error'>
                {userFetchError.split('\n').map((str: string, i: number) => (
                  <div key={`str-${i}`}>{str}</div>
                ))}
              </div>
            )}
            <Form className='items-center d-flex flex-column w-100'>
              <FormFields fields={fields} errors={errors} touched={touched} />
              <Button
                variant='warning'
                disabled={isSubmitting}
                id='sign_in'
                type='submit'
                className='md:text-xl mt-3 mb-4 rounded-lg d-flex align-items-center justify-content-center h-10 md:h-16 font-weight-bold'
                tabIndex={3}
              >
                {isSubmitting ? <LoaderMini /> : 'Sign in'}
              </Button>
              <CustomCheckbox
                name='keep-signed'
                id='keep-signed-checkbox'
                title='Keep me signed in'
                tabIndex={4}
              />
            </Form>
          </div>
        )}
      </Formik>
    </FormContainer>
  );
};
