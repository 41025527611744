import React from 'react';
import {
  IUser,
  ISolution,
  ITracker,
  ICheck,
} from '../../../typings/interfaces';
import { StepKeys } from '../../../typings/enums';
import { Btns } from './Btns';
import { Step } from './Step';
import { Expires } from './Expires';

interface Props {
  candidate: ISolution;
  user: IUser | null;
  checks?: ICheck[];
  date_sent: string | undefined;
}

interface StepType {
  stepKey: StepKeys;
  title: string;
  timestamp: string | undefined | null;
}

const countStoryPoints = (completedTrackers: ITracker[]) => {
  return completedTrackers.reduce(
    (acc, obj) => acc + obj.ticket.story_points,
    0
  );
};

export const InProgressCandidate: React.FC<Props> = ({
  candidate,
  checks,
  date_sent,
}) => {
  const completedTrackers = candidate.candidate.trackers.filter(
    (tracker: ITracker) => tracker.status === 'COMPLETED'
  );

  let lastCompletedTicketTimeStamp: string | undefined;
  if (completedTrackers?.length) {
    const sortedCompletedTickets = completedTrackers?.sort(
      (a, b) => Date.parse(b.date_ready) - Date.parse(a.date_ready)
    );
    lastCompletedTicketTimeStamp = sortedCompletedTickets[0].date_ready;
  }

  let codePushed: ICheck | undefined;

  if (checks && checks.length > 1) {
    codePushed = checks[0];
  }

  const STEPS: StepType[] = [
    {
      stepKey: StepKeys.sent,
      title: 'Task sent',
      timestamp: date_sent,
    },
    {
      stepKey: StepKeys.started,
      title: 'Task started',
      timestamp: candidate.date_created,
    },
    {
      stepKey: StepKeys.commit,
      title: 'Code pushed',
      timestamp: codePushed?.date_created,
    },
    {
      stepKey: StepKeys.tickets,
      title: 'Tickets completed',
      timestamp: lastCompletedTicketTimeStamp,
    },
  ];

  return (
    <div className='p-3 border mb-4 rounded-lg' style={{ maxWidth: '1020px' }}>
      <div className='d-flex justify-content-between w-100'>
        <div className='flex-grow-1 lg:w-6/12'>
          <div className='font-weight-bold text-2xl mb-1'>
            {candidate.candidate.first_name} {candidate.candidate.last_name}{' '}
            <span className='text-black font-weight-normal'>
              {candidate.candidate.uuid.slice(0, 6).toUpperCase()}
            </span>
          </div>
          <Expires candidate={candidate} />
          <Btns candidate={candidate} />
        </div>
        <div className='flex-grow-1 lg:w-6/12'>
          <ul className='step-progressbar pb-4 w-100'>
            {STEPS.map((STEP) => (
              <Step
                key={`${STEP.stepKey}-${candidate.candidate.uuid}`}
                title={STEP.title}
                timestamp={STEP.timestamp}
                stepKey={STEP.stepKey}
                storyPoints={countStoryPoints(completedTrackers)}
                candidateId={candidate.candidate.uuid}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
