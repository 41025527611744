import { RootState } from '../reducers';

export const ticketsSelector = (state: RootState) =>
  state.ticketsReducer.tickets;
export const activeTicketsSelector = (state: RootState) =>
  state.ticketsReducer.activeTickets;
export const errorTicketsSelector = (state: RootState) =>
  state.ticketsReducer.errorTickets;
export const pendingTicketsSelector = (state: RootState) =>
  state.ticketsReducer.pendingTickets;
