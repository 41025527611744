export const initializeChat = async (w: Window, d: Document) => {
  w.CollectId = id;
  const body = d.body || d.getElementsByTagName('body')[0];
  const script = d.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', url);
  body.appendChild(script);
};

export const setEmail = async (w: Window, email: string) => {
  if (email) {
    const collectchat = w.collectchat || {};
    collectchat.ready = function () {
      collectchat.set('email', email);
    };
  }
};

const url = 'https://collectcdn.com/launcher.js';
const id = '5ede4d65f906363bd69e796d';
