import React, { useState, useEffect, useCallback } from 'react';
import { CheckItem } from './CheckItem';
import { ICheck } from '../../../typings/interfaces';
import { UnitTestInfo } from './UnitTestInfo';
import { LintInfo } from './LintInfo';

interface Props {
  checks: ICheck[] | undefined;
  isHidden?: boolean;
}

export const LatestCheck: React.FC<Props> = ({ checks, isHidden }) => {
  const [lastCheck, setLastCheck] = useState<null | ICheck>(null);
  const setLastCheckState = useCallback(() => {
    if (checks) {
      const againstMasterChecks: ICheck[] = checks
        .filter((check) => {
          if (!check.data.hasOwnProperty('reference')) return false;
          return (
            check.data.reference === 'master' && check.status !== 'RUNNING'
          );
        })
        .sort(
          (a, b) => Date.parse(b.date_created) - Date.parse(a.date_created)
        );
      setLastCheck(againstMasterChecks[0]);
    }
  }, [checks]);

  useEffect(() => {
    setLastCheckState();
  }, [checks, setLastCheckState]);

  return (
    <>
      {lastCheck ? (
        <div className='mb-4 d-flex flex-wrap'>
          <div className='flex-grow-1'>
            <div className='d-flex'>
              <CheckItem
                title='Lint Check'
                link={
                  lastCheck.htmlLintLink ? lastCheck.htmlLintLink : undefined
                }
                isHidden={isHidden}
                testId='latest-lint'
              >
                <LintInfo check={lastCheck} isHidden={isHidden} />
              </CheckItem>
              <CheckItem
                title='Unit Test'
                link={
                  lastCheck.htmlTestLink ? lastCheck.htmlTestLink : undefined
                }
                isHidden={isHidden}
                testId='latest-check'
              >
                <UnitTestInfo check={lastCheck} isHidden={isHidden} />
              </CheckItem>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
