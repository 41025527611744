import React, { FC, useState, useEffect } from 'react';
import { formatComplexity } from '../../../util/ticketHelpers';
import { ITicket } from '../../../typings/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { setInstanceSetup } from '../../../actions/ticketsActions';
import { Link, useParams } from 'react-router-dom';

interface TicketSelfProps {
  ticketInfo: ITicket;
}

export const TicketSelf: FC<TicketSelfProps> = ({ ticketInfo }) => {
  const [selectedTicket, setSelectedTicket] = useState(
    ticketInfo.selectedTicket
  );
  const [mandatoryTicket, setMandatoryTicket] = useState(
    ticketInfo.mandatoryTicket
  );
  const { positionId } = useParams();
  const dispatch = useDispatch();

  const { instanceTicket } = useSelector(
    (state: RootState) => state.ticketsReducer
  );

  const setInstanceTicketFromChallengeSetup = (
    setTickets?: number[],
    setMandatory?: number[]
  ) => {
    if (instanceTicket === undefined) {
      return null;
    }
    const payload = {
      duration: instanceTicket.duration,
      estimate: instanceTicket.estimate,
      story_points: instanceTicket.story_points,
      tickets_mandatory: setMandatory || instanceTicket.tickets_mandatory,
      tickets: setTickets || instanceTicket.tickets,
    };
    dispatch(setInstanceSetup(payload));
  };

  const selectedTicketHandleChange = (id: number) => {
    if (instanceTicket === undefined) {
      return null;
    }
    if (selectedTicket) {
      const index = instanceTicket?.tickets.indexOf(id);
      if (index > -1) {
        instanceTicket?.tickets.splice(index, 1);
      }
      setInstanceTicketFromChallengeSetup(instanceTicket?.tickets);
      return setSelectedTicket(!selectedTicket);
    }

    instanceTicket?.tickets.push(id);
    setInstanceTicketFromChallengeSetup(instanceTicket?.tickets);

    return setSelectedTicket(!selectedTicket);
  };

  const mandatoryTicketHandleChange = (id: number) => {
    if (instanceTicket === undefined) {
      return null;
    }
    if (mandatoryTicket) {
      const index = instanceTicket?.tickets_mandatory.indexOf(id);
      if (index > -1) {
        instanceTicket?.tickets_mandatory.splice(index, 1);
      }
      setInstanceTicketFromChallengeSetup(
        undefined,
        instanceTicket?.tickets_mandatory
      );
      return setMandatoryTicket(!mandatoryTicket);
    }

    instanceTicket?.tickets_mandatory.push(id);
    setInstanceTicketFromChallengeSetup(
      undefined,
      instanceTicket?.tickets_mandatory
    );

    return setMandatoryTicket(!mandatoryTicket);
  };

  return (
    <div className='ticket-table-content' key={ticketInfo.id}>
      <span>
        <input
          type='checkbox'
          checked={selectedTicket}
          onChange={() => selectedTicketHandleChange(ticketInfo.id)}
        />
      </span>
      <span>
        <Link to={`/position/${positionId}/task/ticket/${ticketInfo.uid}`}>
          {ticketInfo.uid}
        </Link>
      </span>
      <span>{ticketInfo.title}</span>
      <span>{ticketInfo.kind.toLocaleLowerCase()}</span>
      <span>
        {ticketInfo.skills_meta.map((meta: any) => (
          <div key={meta.skill_id}>
            {meta.title}:
            <span className='complexity-format'>
              {formatComplexity(meta.complexity)}
            </span>
          </div>
        ))}
      </span>
      <span>{ticketInfo.story_points}</span>
      <span>
        <input
          type='checkbox'
          id={ticketInfo.id.toString()}
          className='checkbox'
          checked={mandatoryTicket}
          onChange={() => mandatoryTicketHandleChange(ticketInfo.id)}
        />
        <label htmlFor={ticketInfo.id.toString()} className='switch'></label>
      </span>
    </div>
  );
};
