import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTeamEmployees } from '../../../actions/teamsActions';
import { getRecruiters } from '../../../actions/recruitersActions';
import { IPosition } from '../../../typings/interfaces';
import { Avatar } from '../Avatar';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import cn from 'classnames';
import { useMembers } from '../../../hooks/useMembers';

interface Props {
  position: IPosition;
  className?: string;
}

export const Participants: React.FC<Props> = ({ position, className }) => {
  const dispatch = useDispatch();
  const { members } = useMembers(position);

  useEffect(() => {
    position && dispatch(getRecruiters(position.uuid));
  }, [dispatch, position]);

  useEffect(() => {
    position && dispatch(getTeamEmployees(position.team.uuid));
  }, [dispatch, position]);

  return (
    <div
      className={cn('d-flex position-relative participant-length', [className])}
      style={{
        width: `${members.length * 27 + 5}px`,
        height: '33px',
      }}
      data-testid='participants'
    >
      {members.map((member, i: number) => (
        <div
          key={`member-${member.data.uuid}-${position.team.uuid}`}
          className='position-absolute'
          style={{
            zIndex: members.length - i,
            left: `${i * 27}px`,
          }}
        >
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip
                id={`tooltip-member-${member.data.uuid}-${position.team.uuid}`}
              >
                <div>
                  {member.data.first_name} {member.data.last_name}
                </div>
              </Tooltip>
            }
          >
            <div>
              <Avatar data={member.data} />
            </div>
          </OverlayTrigger>
        </div>
      ))}
    </div>
  );
};
