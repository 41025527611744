import { combineReducers } from 'redux';
import { candidatesReducer } from './candidatesReducer';
import { challengesReducer } from './challengesReducer';
import { userReducer } from './userReducer';
import { alertReducer } from './alertReducer';
import { gitReducer } from './gitReducer';
import { subscribesReducer } from './subscribesReducer';
import { slideMenuReducer } from './slideMenuReducer';
import { positionsReducer } from './positionsReducer';
import { teamsReducer } from './teamsReducer';
import { modalReducer } from './modalReducer';
import { checksReducer } from './checksReducer';
import { invitesReducer } from './invitesReducer';
import { taskReducer } from './taskReducer';
import { ticketsReducer } from './ticketsReducer';
import { notesReducer } from './notesReducer';
import { likesReducer } from './likesReducer';
import { recruitersReducer } from './recruitersReducer';
import { policiesReducer } from './policiesReducer';
import { acceptanceReducer } from './acceptanceReducer';
import { notificationsReducer } from './notificationsReducer';
import {socialReducer} from './socialReducer'
import { positionReducerV2 } from './positionsReducerV2';

export const rootReducer = combineReducers({
  candidatesReducer,
  challengesReducer,
  userReducer,
  alertReducer,
  gitReducer,
  subscribesReducer,
  slideMenuReducer,
  positionsReducer,
  teamsReducer,
  modalReducer,
  checksReducer,
  taskReducer,
  invitesReducer,
  ticketsReducer,
  likesReducer,
  notesReducer,
  policiesReducer,
  recruitersReducer,
  acceptanceReducer,
  notificationsReducer,
  socialReducer,
  positionReducerV2,
});

export type RootState = ReturnType<typeof rootReducer>;
