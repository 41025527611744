import { IPosition } from '../../typings/interfaces';

export enum PositionsActionsTypesConstants {
  GET_POSITIONS = 'GET_POSITIONS',
  CLEAR_POSITIONS = 'CLEAR_POSITIONS',
  PENDING_POSITIONS = 'PENDING_POSITIONS',
  ERROR_POSITIONS = 'ERROR_POSITIONS',
  CREATE_POSITION = 'CREATE_POSITION',
  SET_CURRENT_POSITION = 'SET_CURRENT_POSITION',
  UPDATE_POSITIONS = 'UPDATE_POSITIONS',
  TOGGLE_ADD_POSITION = 'TOGGLE_ADD_POSITION',
  GET_SUMMARY_PIPELINE = 'GET_SUMMARY_PIPELINE',
  PENDING_SUMMARY_PIPELINE = 'PENDING_SUMMARY_PIPELINE',
  PENDING_POSITION = 'PENDING_POSITION',
  GET_POSITION = 'GET_POSITION',
  ERROR_POSITION = 'ERROR_POSITION',
}

export interface PositionsState {
  positions: undefined | IPosition[];
  pending: boolean;
  error: string | undefined;
  curPosition: IPosition | undefined;
  isActiveAddPosition: boolean;
  summaryPipeline: any;
  errorSummaryPipeline: string | undefined;
  pendingSummaryPipeline: boolean;
  position: any;
  pendingPosition: boolean;
  errorPosition: string;
}

interface GetPositionsAction {
  type: typeof PositionsActionsTypesConstants.GET_POSITIONS;
  payload: IPosition[];
}
interface GetPositionAction {
  type: typeof PositionsActionsTypesConstants.GET_POSITION;
  payload: any;
}
interface UpdatePositionsAction {
  type: typeof PositionsActionsTypesConstants.UPDATE_POSITIONS;
  payload: IPosition[];
}
interface ClearPositionsAction {
  type: typeof PositionsActionsTypesConstants.CLEAR_POSITIONS;
}
interface ToggleAddPositionAction {
  type: typeof PositionsActionsTypesConstants.TOGGLE_ADD_POSITION;
  payload: boolean;
}
interface PendingPositionsAction {
  type: typeof PositionsActionsTypesConstants.PENDING_POSITIONS;
}
interface PendingPositionAction {
  type: typeof PositionsActionsTypesConstants.PENDING_POSITION;
}
interface ErrorPositionsAction {
  type: typeof PositionsActionsTypesConstants.ERROR_POSITIONS;
  payload: string;
}
interface ErrorPositionAction {
  type: typeof PositionsActionsTypesConstants.ERROR_POSITION;
  payload: string;
}
interface CreatePositionAction {
  type: typeof PositionsActionsTypesConstants.CREATE_POSITION;
  payload: IPosition;
}
interface SetCurPositionAction {
  type: typeof PositionsActionsTypesConstants.SET_CURRENT_POSITION;
  payload: IPosition;
}
interface PendingSummaryPipeline {
  type: typeof PositionsActionsTypesConstants.PENDING_SUMMARY_PIPELINE;
}

interface GetSummaryPipelineAction {
  type: typeof PositionsActionsTypesConstants.GET_SUMMARY_PIPELINE;
  payload: any;
}

export type PositionsActionTypes =
  | GetSummaryPipelineAction
  | ToggleAddPositionAction
  | GetPositionsAction
  | PendingPositionsAction
  | UpdatePositionsAction
  | ErrorPositionsAction
  | CreatePositionAction
  | PendingSummaryPipeline
  | SetCurPositionAction
  | GetPositionAction
  | PendingPositionAction
  | ErrorPositionAction
  | ClearPositionsAction;
