export const detectLang = (file: any) => {
  let fileExt = file.match(/\.(.+)/g);

  if (fileExt) {
    fileExt[0] = fileExt[0].replace('.min', '');
    fileExt[0] = fileExt[0].replace('.gz', '');
    fileExt[0] = fileExt[0].replace('.', '');
    return fileExt[0];
  } else {
    return 'txt';
  }
};
