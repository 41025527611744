import React from 'react';
import { IComment } from '../../../typings/interfaces';
import { CommentInfo } from 'react-diff-viewer';
import { v4 as uuid } from 'uuid';
import { CommentBlock } from '../CommentBlock';
import { Button } from 'react-bootstrap';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import cn from 'classnames';
import { userSelector } from '../../../selectors';

interface Props {
  commentsForCurLine: IComment[];
  newComment: IComment | undefined;
  createNewComment: (commentInfo: CommentInfo) => void;
  clearNewComment: () => void;
  commentInfo: CommentInfo;
  commentsIds: number[];
}

export const CommentsChain: React.FC<Props> = ({
  commentsForCurLine,
  newComment,
  createNewComment,
  clearNewComment,
  commentInfo,
  commentsIds,
}) => {
  const user = useShallowEqualSelector(userSelector);

  return (
    <>
      {commentsForCurLine
        .sort(
          (a, b) =>
            Date.parse(a.created_by?.date_created) -
            Date.parse(b.created_by?.date_created)
        )
        .map((currComment, i: number) => {
          const isLastOtherUerComment =
            i === commentsForCurLine.length - 1 &&
            user?.identity !== currComment.created_by?.uuid;

          return (
            <div key={uuid()}>
              <CommentBlock
                comment={currComment}
                clearNewComment={clearNewComment}
                commentInfo={commentInfo}
                className={cn({
                  'rounded-top': i === 0,
                  'border-top-0': commentsForCurLine.length > 1 && i !== 0,
                  'rounded-bottom':
                    (i === commentsForCurLine.length - 1 &&
                      !isLastOtherUerComment) ||
                    (i === commentsForCurLine.length - 1 && newComment),
                  'border-bottom-0': isLastOtherUerComment && !newComment,
                })}
                commentsIds={commentsIds}
              />
              {isLastOtherUerComment && !newComment ? (
                <div className='border pt-0 pb-2 px-2 border-top-0 rounded-bottom'>
                  <Button
                    variant='warning'
                    className='text-xs'
                    onClick={() => createNewComment(commentInfo)}
                  >
                    Add Comment
                  </Button>
                </div>
              ) : null}
            </div>
          );
        })}
      {newComment?.body.lineId === commentInfo.lineId && (
        <CommentBlock
          comment={newComment}
          commentsIds={commentsIds}
          clearNewComment={clearNewComment}
          commentInfo={commentInfo}
        />
      )}
    </>
  );
};
