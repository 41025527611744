import React from 'react';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { TeamNote } from './TeamNote';
import { Loader } from '../../../shared/components/Loader';
import { NoteTextarea } from './NoteTextarea';
import { NoteType } from '../../../typings/enums';

interface Props {}

export const Notes = (props: Props) => {
  const currentCandidate = useShallowEqualSelector(
    (state) => state.candidatesReducer.currentCandidate
  );

  const notes = useShallowEqualSelector((state) => state.notesReducer.notes);
  const pendingNotes = useShallowEqualSelector(
    (state) => state.notesReducer.pendingNotes
  );

  if (!currentCandidate) {
    return null;
  }

  const currentCandidateNotes = notes?.[currentCandidate.position.uuid] || [];

  return (
    <>
      <div className='font-weight-bold text-lg mb-1'>Notes</div>
      <div className='mb-3'>
        <NoteTextarea currentCandidate={currentCandidate} />
      </div>
      {pendingNotes ? <Loader /> : null}
      {currentCandidateNotes.length > 0 && (
        <div className='pb-20 mb-4'>
          {currentCandidateNotes
            .sort(
              (a, b) =>
                Date.parse(b.date_created as string) -
                Date.parse(a.date_created as string)
            )
            .map(
              (note) =>
                note.body.type !== NoteType.to_recruiter && (
                  <TeamNote key={note.id} note={note} />
                )
            )}
        </div>
      )}
    </>
  );
};
