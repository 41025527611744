import React, { useCallback, useState } from 'react';
import { Table } from 'react-bootstrap';
import { UserRoles } from '../../../typings/enums';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import cn from 'classnames';
import { Rows } from './Rows';
import './Commits.scss';
import { currentCandidateSelector, userRoleSelector } from '../../../selectors';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { useParams } from 'react-router-dom';

interface Props {
  isHidden?: boolean;
}

export const Commits: React.FC<Props> = ({ isHidden }) => {
  const [height, setHeight] = useState(0);
  const { shareuuid } = useParams();

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const checks = useSelector((state: RootState) => state.checksReducer.checks);
  const isReviewer =
    useShallowEqualSelector(userRoleSelector) === UserRoles.REVIEWER;

  const currentCandidate = useShallowEqualSelector(currentCandidateSelector);

  const tableHeader = ['Commit', 'Message', 'Time'];

  if (!currentCandidate) {
    return null;
  }

  return (
    <div data-testid={`candidate-commits-${currentCandidate.candidate.uuid}`}>
      {isReviewer && (
        <div className='text-2xl font-weight-bold mb-2'>Commits</div>
      )}
      {checks && checks.length > 1 ? (
        <div
          className={cn('border rounded-lg p-4', {
            'position-relative': !isReviewer,
          })}
        >
          <Table responsive>
            <thead ref={measuredRef}>
              <tr>
                {tableHeader.map((title, i) => (
                  <th
                    className={'py-2 px-0 border-0 font-weight-normal'}
                    key={i}
                  >
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {checks ? (
                <Rows
                  checks={checks}
                  currentCandidate={currentCandidate}
                  shareuuid={shareuuid}
                />
              ) : null}
            </tbody>
          </Table>
          {!isReviewer && checks && checks.length > 1 && (
            <div
              className='position-absolute hidden-commits d-flex align-items-end justify-content-center rounded-lg'
              style={{
                height: `calc(100% / ${checks.length} * ${
                  checks.length - 1
                } - ${height}px - 2px)`,
                left: '2px',
                right: '2px',
                bottom: '2px',
              }}
            >
              <div className='pb-2'>Log in to see details</div>
            </div>
          )}
        </div>
      ) : (
        <div>There are no commits yet</div>
      )}
    </div>
  );
};
