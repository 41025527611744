import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { BackBtn } from '../../shared/components/BackBtn';
import { UserRoles, Stages } from '../../typings/enums';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { getPositions } from '../../actions/positionsActions';
import { useDispatch } from 'react-redux';
import { userRoleSelector, positionsSelector } from '../../selectors';

interface Props {
  stage?: string | null;
  backBtnTitle?: string;
}

export const Header: React.FC<Props> = ({ stage, backBtnTitle }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { positionId, ticketId } = useParams();

  const positions = useShallowEqualSelector(positionsSelector);

  const curPosition =
    positions && positions.find((position) => position.uuid === positionId);

  const userRole = useShallowEqualSelector(userRoleSelector);

  useEffect(() => {
    !positions && dispatch(getPositions());
  }, [dispatch, positions]);

  const goBack = () => {
    if (ticketId) {
      return history.push(`/position/${positionId}/task`);
    }
    if (stage) {
      if (
        userRole === UserRoles.RECRUITER &&
        (stage === Stages.ACCEPTED || stage === Stages.REJECTED)
      ) {
        return history.push(`/position/${positionId}/reviewed/${stage}/`);
      } else {
        return history.push(`/position/${positionId}/${stage}/`);
      }
    }
    if (backBtnTitle) return history.push('/dashboard');
    history.push(`/dashboard#position-${positionId}`);
  };

  const renderBackBtnTitle = () => {
    if (!ticketId) {
      if (stage) {
        return renderStageTitle(stage, userRole);
      }
      return curPosition && curPosition.title;
    }
    return curPosition && curPosition.task.challenge.title + ' Challenge';
  };

  return (
    <div className='d-flex justify-content-between mb-4'>
      <div className='d-flex align-items-center'>
        {(curPosition || backBtnTitle) && (
          <BackBtn
            className='mr-4'
            onClick={goBack}
            title={backBtnTitle || renderBackBtnTitle()}
          />
        )}
      </div>
      <div className='d-flex justify-content-end'>
        <div>
          <button
            className='bg-transparent border-0 p-0 d-block'
            onClick={() => history.push(`/dashboard#position-${positionId}`)}
            data-testid='close-btn'
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

const renderStageTitle = (stage: string, userRole: string | undefined) => {
  if (stage !== Stages.SUBMITTED)
    return stage[0].toUpperCase() + stage.slice(1);
  return userRole === UserRoles.RECRUITER ? 'Submitted' : 'To review';
};
