export const SHOW_SLIDE_MENU = 'SHOW_SLIDE_MENU';
export const HIDE_SLIDE_MENU = 'HIDE_SLIDE_MENU';

interface SlideMenuActions {
  type: typeof SHOW_SLIDE_MENU | typeof HIDE_SLIDE_MENU;
}

export type SlideMenuActionTypes = SlideMenuActions;

export interface SlideMenuState {
  isShown: boolean;
}
