import { useState } from 'react';
import { sendFeedback } from '../actions/candidatesActions';
import { ISolution } from '../typings/interfaces';
import { useShallowEqualSelector } from './useShallowEqualSelector';
import { useDispatch } from 'react-redux';
import { attachedFeedbacksSelector } from '../selectors';

export const useFeedback = (currentCandidate: ISolution | undefined) => {
  const dispatch = useDispatch();
  const [feedbackValue, setFeedbackValue] = useState<
    string | number | string[] | undefined
  >(currentCandidate?.feedback?.body || undefined);

  const attachedFeedbacks = useShallowEqualSelector(attachedFeedbacksSelector);

  const isFeedbackAttached =
    currentCandidate &&
    attachedFeedbacks.includes(currentCandidate.candidate.uuid);

  const updateFeedback = async (newFeedbackData: any = undefined) => {
    const feedbackData = {
      is_positive: currentCandidate?.feedback?.is_positive || null,
      is_sent: currentCandidate?.feedback?.is_sent,
      is_attached: currentCandidate?.feedback?.is_attached,
      body: currentCandidate?.feedback?.body,
    };
    try {
      await dispatch(
        sendFeedback(currentCandidate!, {
          ...feedbackData,
          body: feedbackValue || null,
          is_attached: isFeedbackAttached,
          ...newFeedbackData,
        })
      );
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return {
    feedbackValue,
    setFeedbackValue,
    updateFeedback,
  };
};
