import { LikesActionsTypesConstants, LikesActionsTypes } from './types';
import { ILike } from '../typings/interfaces';
import { LikesContentTypeEnum, AlertType } from '../typings/enums';
import { apiFetch } from '../util/apiFetch';
import { showAlert } from './alertActions';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';
import { Action } from 'redux';

enum LikesAction {
  GET = 'GET',
  CLEAR = 'CLEAR',
}

const getLikeActionType = (
  contentType: LikesContentTypeEnum,
  action: string
) => {
  switch (contentType) {
    case LikesContentTypeEnum.CANDIDATE_POSITION:
      return LikesActionsTypesConstants[
        `${action}_LIKES_BY_CANDIDATE_POSITION` as LikesActionsTypesConstants
      ];
    case LikesContentTypeEnum.CANDIDATE:
      return LikesActionsTypesConstants[
        `${action}_LIKES_BY_CANDIDATE` as LikesActionsTypesConstants
      ];
    case LikesContentTypeEnum.NOTE:
      return LikesActionsTypesConstants[
        `${action}_LIKES_BY_NOTE;` as LikesActionsTypesConstants
      ];
    case LikesContentTypeEnum.FEEDBACK:
      return LikesActionsTypesConstants[
        `${action}_LIKES_BY_FEEDBACK;` as LikesActionsTypesConstants
      ];
  }
};

export const getLikes = (
  contentType: LikesContentTypeEnum,
  objectId?: string
): ThunkAction<void, RootState, LikesActionsTypes, Action<string>> => async (
  dispatch
) => {
  try {
    dispatch({
      type: LikesActionsTypesConstants.PENDING_LIKES,
    });

    let url = `/like/?content_type_id=${contentType}`;
    if (objectId) {
      url = url + `&object_id=${objectId}`;
    }

    const res = await apiFetch('social', url);

    if (res.error) throw new Error(res.error);

    dispatch({
      type: getLikeActionType(contentType, LikesAction.GET),
      payload: res.data,
    });
  } catch (err) {
    dispatch(showAlert({ type: AlertType.fail, message: err.message }));
    dispatch({
      type: LikesActionsTypesConstants.ERROR_LIKES,
      payload: err.message,
    });
  }
};

export const postLike = (
  contentType: LikesContentTypeEnum,
  objectId: string,
  likeExist: boolean,
  likes: ILike[],
  negate: boolean
): ThunkAction<void, RootState, LikesActionsTypes, Action<string>> => async (
  dispatch
) => {
  try {
    const res = await apiFetch('social', '/like/', 'post', {
      content_type_id: contentType,
      object_id: objectId,
      negate,
    });

    if (res.error) throw new Error(res.error);

    let updatedLikes;
    if (likeExist) {
      updatedLikes = likes.map((like) => {
        if (
          like.content_type_id === LikesContentTypeEnum.CANDIDATE_POSITION &&
          like.created_by.uuid === res.data.created_by.uuid &&
          like.object_id === res.data.object_id
        ) {
          return res.data;
        }
        return like;
      });
    } else {
      updatedLikes = [...likes, res.data];
    }
    dispatch({
      type: getLikeActionType(contentType, LikesAction.GET),
      payload: updatedLikes,
    });
  } catch (err) {
    dispatch(showAlert({ type: AlertType.fail, message: err.message }));
    dispatch({
      type: LikesActionsTypesConstants.ERROR_LIKES,
      payload: err.message,
    });
  }
};

export const clearLikes = (contentType: LikesContentTypeEnum) => ({
  type: getLikeActionType(contentType, LikesAction.CLEAR),
});
