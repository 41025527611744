import React, { useEffect } from 'react';
import { CandidateCard } from '../../components/CandidateCard';
import { useParams, useHistory } from 'react-router-dom';
import { ILike } from '../../../typings/interfaces';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useDispatch } from 'react-redux';
import { getNotes, clearNotes } from '../../../actions/notesActions';
import { Loader } from '../../../shared/components/Loader';
import { useLikes } from '../../../hooks/useLikes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LikesContentTypeEnum } from '../../../typings/enums';
import { StageTitle } from '../../../shared/components/StageTitle';
import { isComment } from '../../../typings/typeGuards';
import { Hint } from './Hint';
import {
  pendingCandidatesSelector,
  notesSelector,
  pendingNotesSelector,
  selectFilteredCandidates,
} from '../../../selectors';
import './index.scss';

export const CandidatesToInform: React.FC = () => {
  const dispatch = useDispatch();
  const { positionId, candidatesType } = useParams();
  const { showLikeDeslikeFeature } = useFlags();

  let history = useHistory();
  const pendingNotes = useShallowEqualSelector(pendingNotesSelector);

  const pendingCandidates = useShallowEqualSelector(pendingCandidatesSelector);

  const notes = useShallowEqualSelector(notesSelector);

  const filteredCandidates = useShallowEqualSelector((state) =>
    selectFilteredCandidates(state, positionId, candidatesType)
  );

  useEffect(() => {
    if (positionId && filteredCandidates && filteredCandidates.length === 0) {
      history.push(`/dashboard#position-${positionId}`);
    }
  }, [filteredCandidates, history, positionId]);

  useEffect(() => {
    dispatch(getNotes(positionId));
    return () => {
      dispatch(clearNotes());
    };
  }, [dispatch, positionId]);

  const { likesByCandidatePosition, pendingLikes } = useLikes(
    filteredCandidates
  );

  let isLoading =
    pendingCandidates.includes(positionId) || pendingLikes || pendingNotes;

  if (isLoading) {
    return <Loader />;
  }

  if (!filteredCandidates || pendingLikes || pendingNotes) {
    return null;
  }

  return (
    <>
      <StageTitle
        title={
          candidatesType &&
          candidatesType[0].toUpperCase() + candidatesType.slice(1)
        }
        qty={filteredCandidates.length}
      />
      <div className='candidate-to-inform-wrap'>
        <div className='candidate-strech'>
          {filteredCandidates.map((candidate: any) => {
            let currentCandidateLikes: ILike[] | undefined;

            if (showLikeDeslikeFeature && likesByCandidatePosition) {
              currentCandidateLikes = likesByCandidatePosition.filter(
                (like) =>
                  like.content_type_id ===
                    LikesContentTypeEnum.CANDIDATE_POSITION &&
                  like.object_id === String(candidate.id)
              );
            }

            const curCandidateComments = notes?.[
              candidate.position.uuid
            ]?.filter(
              (note) =>
                isComment(note) &&
                note.user_id === candidate.candidate.user?.uuid
            );

            return (
              <div key={candidate.candidate.uuid}>
                <CandidateCard
                  candidate={candidate}
                  votes={currentCandidateLikes}
                  curCandidateComments={curCandidateComments}
                />
              </div>
            );
          })}
        </div>
        <div>
          <Hint isAccepted={candidatesType === 'accepted'} />
        </div>
      </div>
    </>
  );
};
