import React from 'react';
import { ReactComponent as FlashIcon } from '../../../assets/twoMonthsFreeTrial/flash.svg';
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg';
import { VerticalStep } from './VerticalStep';
import { v4 as uuid } from 'uuid';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import sourcingImgDesktop from '../../../assets/twoMonthsFreeTrial/sourcing_desktop.png';
import sourcingImgMobile from '../../../assets/twoMonthsFreeTrial/sourcing_mobile.png';
import hrImgDesktop from '../../../assets/twoMonthsFreeTrial/hr_desktop.png';
import hrImgMobile from '../../../assets/twoMonthsFreeTrial/hr_mobile.png';
import screeningImgDesktop from '../../../assets/twoMonthsFreeTrial/screening_desktop.png';
import screeningImgMobile from '../../../assets/twoMonthsFreeTrial/screening_mobile.png';
import InterviewImgDesktop from '../../../assets/twoMonthsFreeTrial/interview_desktop.png';
import InterviewImgMobile from '../../../assets/twoMonthsFreeTrial/interview_mobile.png';
import OfferImgDesktop from '../../../assets/twoMonthsFreeTrial/offer_desktop.png';
import OfferImgMobile from '../../../assets/twoMonthsFreeTrial/offer_mobile.png';

interface Props {}

const Steps = [
  {
    text: (
      <div className='mb-2'>
        <div className='font-weight-bold text-xl'>Sourcing</div>
        <div className='font-weight-normal text-base'>Identify candidates</div>
      </div>
    ),
    mobileImg: sourcingImgMobile,
    desktopImg: sourcingImgDesktop,
    stepName: 'Sourcing',
  },
  {
    text: <div className='mb-2 font-weight-bold text-xl'>HR Call</div>,
    mobileImg: hrImgMobile,
    desktopImg: hrImgDesktop,
    stepName: 'HR Call',
  },
  {
    text: (
      <div className='mb-2'>
        <div className='d-flex align-items-center'>
          <div className='font-weight-bold text-xl'>Sfeeda Screening</div>
          <FlashIcon className='ml-3' />
        </div>
        <div className='font-weight-normal text-base'>
          Don’t miss even a single valuable candidate. Screen all maybes
        </div>
        <div className='d-md-flex align-items-md-start my-2 text-base font-weight-normal text-13'>
          <ClockIcon className='icon--16 mr-2 d-none d-md-block' />
          <div className='md:text-15 leading-none'>
            <div className='d-block d-md-none text-orange-500 text-lg'>
              Up to 15 min
            </div>
            <span className='d-none d-md-inline'>Up to 15 min</span> of
            engineers’ time per candidate
          </div>
        </div>
      </div>
    ),
    mobileImg: screeningImgMobile,
    desktopImg: screeningImgDesktop,
    stepName: 'Sfeeda Screening',
  },
  {
    text: (
      <>
        <div className='font-weight-bold text-xl'>Tech Interview</div>
        <div>Proceed with the most relevant candidates</div>
      </>
    ),
    mobileImg: InterviewImgMobile,
    desktopImg: InterviewImgDesktop,
    stepName: 'Tech Interview',
  },
  {
    text: (
      <>
        <div className='font-weight-bold text-xl'>Offer</div>
        <div>34% of candidates whose solutions were accepted get offered</div>
      </>
    ),
    mobileImg: OfferImgMobile,
    desktopImg: OfferImgDesktop,
    stepName: 'Offer',
  },
];

export const DecreseTimeToHire = (props: Props) => {
  const [isMatchMediaQuery] = useMatchMedia(992);

  return (
    <div className='pb-10 container' style={{ paddingTop: '104px' }}>
      <div className='text-center text-27 lh-32 mb-6 d-md-none'>
        Assess more candidates
        <br /> in less time
      </div>
      <div className='d-flex'>
        <div className='md:w-6/12 d-none d-md-block'>
          <div className='text-left text-4-5xl leading-thin'>
            Assess more candidates
            <br /> in less time
          </div>
        </div>
        <div className='md:w-6/12'>
          <ul className='events w-100 pl-2'>
            {Steps.map(({ text, mobileImg, desktopImg, stepName }) => (
              <VerticalStep
                key={uuid()}
                text={text}
                mobileImg={mobileImg}
                isMatchMediaQuery={isMatchMediaQuery}
                desktopImg={desktopImg}
                isSfeedaStep={stepName === 'Sfeeda Screening'}
                stepName={stepName}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
