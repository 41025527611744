import { IAcceptance } from '../../typings/interfaces';

export enum AcceptanceTypesConstants {
  GET_ACCEPTANCE_BY_POSITION = 'GET_ACCEPTANCE_BY_POSITION',
  PENDING_ACCEPTANCE_BY_POSITION = 'PENDING_ACCEPTANCE_BY_POSITION',
  ERROR_ACCEPTANCE_BY_POSITION = 'ERROR_ACCEPTANCE_BY_POSITION'
}

export interface AcceptanceState {
  candidateAcceptance: IAcceptance[] | undefined;
  pendingCandidateAcceptance: boolean;
  errorCandidateAcceptance: string;
}

interface PendingAcceptanceByPositionAction {
  type: typeof AcceptanceTypesConstants.PENDING_ACCEPTANCE_BY_POSITION;
}
interface ErrorAcceptanceByPositionAction {
  type: typeof AcceptanceTypesConstants.ERROR_ACCEPTANCE_BY_POSITION;
  payload: string;
}

interface GetAcceptanceByPositionAction {
  type: typeof AcceptanceTypesConstants.GET_ACCEPTANCE_BY_POSITION;
  payload: IAcceptance[];
}

export type AcceptanceActionsTypes =
  | GetAcceptanceByPositionAction
  | PendingAcceptanceByPositionAction
  | ErrorAcceptanceByPositionAction;
