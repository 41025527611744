import {
  PositionsActionTypesV2,
  GETPOSITIONS,
  PositionTypesV2,
  ChallengeTypesV2,
  CandidatesTypesV2,
  GETCHALLENGES,
  GETCANDIDATES,
} from '../actions/types/positionTypesV2';

export interface positionReducerStateV2Types {
  position: PositionTypesV2[];
  challenges: ChallengeTypesV2[];
  candidates: CandidatesTypesV2[];
}

const initialState: positionReducerStateV2Types = {
  position: [],
  challenges: [],
  candidates: [],
};

export const positionReducerV2 = (
  state = initialState,
  action: PositionsActionTypesV2
) => {
  switch (action.type) {
    case GETPOSITIONS:
      return {
        ...state,
        position: action.payload,
      };
    case GETCHALLENGES:
      return {
        ...state,
        challenges: action.payload,
      };
    case GETCANDIDATES:
      return {
        ...state,
        candidates: action.payload,
      };
    default:
      return state;
  }
};
