import { ITicket } from '../../typings/interfaces';

export enum TicketsTypesConstants {
  GET_TICKETS_BY_CHALLENGE = 'GET_TICKETS_BY_CHALLENGE',
  PENDING_TICKETS_BY_CHALLENGE = 'PENDING_TICKETS_BY_CHALLENGE',
  ERROR_TICKETS_BY_CHALLENGE = 'ERROR_TICKETS_BY_CHALLENGE',
  SET_ACTIVE_TICKETS = 'SET_ACTIVE_TICKETS',
  CLEAR_ACTIVE_TICKETS = 'CLEAR_ACTIVE_TICKETS',
  SET_INSTANCE_TICKETS = 'SET_INSTANCE_TICKETS'
}

interface GetTicketsByChallengeAction {
  type: typeof TicketsTypesConstants.GET_TICKETS_BY_CHALLENGE;
  payload: ITicket[];
}

interface PendingTicketsByChallengeAction {
  type: typeof TicketsTypesConstants.PENDING_TICKETS_BY_CHALLENGE;
}
interface ErrorTicketsByChallengeAction {
  type: typeof TicketsTypesConstants.ERROR_TICKETS_BY_CHALLENGE;
  payload: string;
}

interface SetActiveTicketsAction {
  type: typeof TicketsTypesConstants.SET_ACTIVE_TICKETS;
  payload: number[];
}
interface ClearActiveTicketsAction {
  type: typeof TicketsTypesConstants.CLEAR_ACTIVE_TICKETS;
}

export interface SetInstanceTicketsAction {
  type: typeof TicketsTypesConstants.SET_INSTANCE_TICKETS;
  payload: InstanceTicketTypes
}

export interface InstanceTicketTypes{
  duration: number;
  estimate: string;
  story_points: number;
  tickets_mandatory: number[];
  tickets: number[];
}

export interface TicketsState {
  tickets: undefined | ITicket[];
  pendingTickets: boolean;
  errorTickets: string;
  activeTickets: undefined | number[];
  instanceTicket: undefined | InstanceTicketTypes
}

export type TicketsActionsTypes =
  | GetTicketsByChallengeAction
  | PendingTicketsByChallengeAction
  | ErrorTicketsByChallengeAction
  | ClearActiveTicketsAction
  | SetActiveTicketsAction 
  | SetInstanceTicketsAction;
