import {
  ChecksActionsTypes,
  SET_CHECKS,
  PENDING_CHECKS,
  CLEAR_CHECKS,
  ERROR_CHECKS,
} from './types';
import { Action } from 'redux';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { getChecksWithArtifactsData } from '../util/getArtifactsData';
import { apiFetch } from '../util/apiFetch';
import { IPipeline } from '../typings/interfaces';
import { stringify } from 'query-string';

export const getChecks = (
  positionId: string,
  withArtifacts: boolean = true,
  candidate_uuid?: string
): ThunkAction<void, RootState, ChecksActionsTypes, Action<string>> => async (
  dispatch
) => {
  try {
    dispatch({ type: PENDING_CHECKS });

    const response = await apiFetch(
      'git',
      `/pipeline/${positionId}/?${stringify({user_id: candidate_uuid} ,{skipNull: true})}`
    );

    if (response.error) throw new Error(response.error);

    const pipelines =
      response.data && response.data instanceof Array ? response.data : [];

    const checks = pipelines.filter((pipeline: IPipeline) => {
      return (
        pipeline.pipeline_type === 'LINT_TEST' &&
        pipeline.status === 'SUCCESSFUL'
      );
    });

    dispatch({
      type: SET_CHECKS,
      payload: checks,
    });
    dispatch({
      type: SET_CHECKS,
      // payload: await getChecksWithArtifactsData(checks)
      payload: withArtifacts
        ? await getChecksWithArtifactsData(checks)
        : checks,
    });
  } catch (err) {
    dispatch({
      type: ERROR_CHECKS,
      payload: err.message,
    });
  }
};

export const clearChecks = () => ({ type: CLEAR_CHECKS });
