import React from 'react';
import cn from 'classnames';

interface StepProps {
  num: any;
  title: string;
  text: string;
  className?: string;
}

export const Step: React.FC<StepProps> = ({ num, title, text, className }) => {
  return (
    <div className={cn([className])}>
      <div className='rounded-circle bg-black text-white d-flex align-items-center justify-content-center icon--32 flex-shrink-0 mr-4'>
        {num}
      </div>
      <div>
        <div className='font-weight-bold text-xl'>{title}</div>
        <div className='text-xl'>{text}</div>
      </div>
    </div>
  );
};
