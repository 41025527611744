import { Dispatch } from 'redux';
import {
  saveViewCountTypes,
  RequestToPostSocialData,
  SharedCandidateDataTypes,
  ShareSolutionToggleTypes,
  ShareOffRedirect,
  SAVEVIEWCOUNT,
  SHAREDCANDIDATEDATA,
  SHARESOLUTIONTOGGLE,
  SHAREOFFREDIRECT,
} from './types/socialActionTypes';
import { apiFetch } from '../util/apiFetch';
import { candidatesActionTypesConstants } from './types';

export const saveSocialViews = (payload: RequestToPostSocialData) => async (
  dispatch: Dispatch<saveViewCountTypes>
) => {
  const req = await apiFetch('social', '/counter/', 'POST', payload);
  dispatch({
    type: SAVEVIEWCOUNT,
    payload: req.data,
  });
};

export const checkSharedSolutionCandidate = (shareUUID: string) => async (
  dispatch: Dispatch<
    SharedCandidateDataTypes | ShareSolutionToggleTypes | ShareOffRedirect | any
  >
) => {
  const req = await apiFetch('company', `/v1/candidate_share/${shareUUID}/`);
  
  if (req.status === 200) {
    const trackerList = await apiFetch('ticket', '/tracker/');
    const ticketList = await apiFetch('ticket', '/ticket/');

    trackerList.data.map((item: any) => {
      item.ticket = {};
      ticketList.data.map((point: any) => {
        if (item.ticket_id === point.id) {
          return Object.assign(item.ticket, point);
        }
      });
    });

    req.data.candidate.trackers = [];

    trackerList.data.map((point: any) => {
      if (req.data.candidate.user.uuid === point.user_id) {
        return req.data.candidate.trackers.push(point);
      }
    });

    dispatch({
      type: SHAREDCANDIDATEDATA,
      payload: req.data,
    });
    dispatch({
      type: SHARESOLUTIONTOGGLE,
      payload: true,
    });
    dispatch({
      type: candidatesActionTypesConstants.SET_CURRENT_CANDIDATE,
      payload: req.data,
    });
  } else {
    dispatch({
      type: SHAREOFFREDIRECT,
      payload: true,
    });
  }
};
