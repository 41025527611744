import React from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { LoaderMini } from '../components/LoaderMini';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { IFields } from '../../typings/interfaces';
import { FormFields } from '../components/FormFields';
import { Button } from 'react-bootstrap';
import { errorText } from '../../util/fetchWithToken';
import { AlertType } from '../../typings/enums';
import { showAlert } from '../../actions/alertActions';
import { BackBtn } from '../components/BackBtn';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { apiFetch } from '../../util/apiFetch';

interface FormValues {
  oldPassword: string;
  newPassword: string;
}
const initialValues: FormValues = {
  oldPassword: '',
  newPassword: '',
};

export const ChangePassword: React.SFC = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const userFetchError = useShallowEqualSelector(
    (state) => state.userReducer.userFetchError
  );

  const fields: IFields = {
    oldPassword: {
      label: 'Old Password',
      id: 'field_old_password',
    },
    newPassword: {
      label: 'New Password',
      id: 'field_new_password',
    },
  };

  return (
    <div className='container py-4 d-flex flex-column min-vh-full'>
      <div className='d-flex justify-content-between mb-4'>
        <div className='d-flex align-items-center'>
          <BackBtn className='mr-4' to='/dashboard' title='Back to Dashboard' />
        </div>
        <div className='d-flex justify-content-end'>
          <div>
            <Link
              className='bg-transparent border-0 p-0 d-block'
              to='/dashboard'
            >
              <CloseIcon />
            </Link>
          </div>
        </div>
      </div>
      <div className='flex-grow-1 d-flex'>
        <Formik
          initialValues={initialValues}
          onSubmit={async ({ oldPassword, newPassword }) => {
            try {
              const res = await apiFetch('auth', '/password/update/', 'PUT', {
                existing_password: oldPassword,
                new_password: newPassword,
              });

              if (res.error) {
                throw new Error(errorText);
              }

              dispatch(
                showAlert({
                  message: 'Password has been changed',
                  type: AlertType.success,
                })
              );
              history.push('/dashboard');
            } catch (error) {
              dispatch(
                showAlert({ message: error.message, type: AlertType.fail })
              );
            }
          }}
          validationSchema={Yup.object({
            oldPassword: Yup.string()
              .required('Required')
              .min(2, 'Must be 2 characters or more'),
            newPassword: Yup.string()
              .required('Required')
              .min(2, 'Must be 2 characters or more'),
          })}
        >
          {({ errors, touched, isSubmitting }) => (
            <div
              style={{ maxWidth: '430px', paddingBottom: '56px' }}
              className='m-auto w-100'
            >
              <h1 className='text-2xl font-weight-bold mb-4'>
                Change Password
              </h1>
              {userFetchError && (
                <div className='text-danger mb-3' id='login_form_error'>
                  {userFetchError.split('\n').map((str: string, i: number) => (
                    <div key={`str-${i}`}>{str}</div>
                  ))}
                </div>
              )}
              <Form className='items-center d-flex flex-column w-100'>
                <FormFields fields={fields} errors={errors} touched={touched} />
                <Button
                  variant='warning'
                  disabled={isSubmitting}
                  id='sign_in'
                  type='submit'
                  className='md:text-xl mb-6 rounded-lg d-flex align-items-center justify-content-center h-10 md:h-16 font-weight-bold'
                >
                  {isSubmitting ? <LoaderMini /> : 'Update'}
                </Button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
