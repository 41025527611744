import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import {
  getPositionCandidates,
  clearCandidates,
} from '../../actions/candidatesActions';
import { Header } from './Header';
import { useHistory } from 'react-router-dom';
import { Stages, UserRoles } from '../../typings/enums';
import { userRoleSelector } from '../../selectors';

interface Props {
  children: React.ReactNode;
}

export const CandidatesContainer: React.SFC<Props> = ({ children }) => {
  const { positionId, candidatesType } = useParams();

  const dispatch = useDispatch();

  const userRole = useShallowEqualSelector(userRoleSelector);

  let history = useHistory();

  if (userRole === UserRoles.REVIEWER) {
    if (
      candidatesType === Stages.EXPIRED ||
      candidatesType === Stages.EXPIRING ||
      candidatesType === Stages.SENT
    ) {
      history.push('/404');
    }
  }

  useEffect(() => {
    positionId && dispatch(getPositionCandidates(positionId));
  }, [positionId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearCandidates());
    };
  }, [dispatch]);

  return (
    <div className='bg-white px-4 px-md-5 py-3'>
      <Header />
      {children}
    </div>
  );
};
