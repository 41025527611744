import React from 'react';
import { CardComponent } from '../../../shared/components/CardComponent';
import { ReactComponent as HelloIcon } from '../../../assets/hello.svg';

interface Props {}

export const Welcome: React.FC<Props> = () => {
  return (
    <CardComponent>
      <div className='d-flex flex-wrap'>
        <HelloIcon className='mr-5' />
        <div className='text-lg'>
          Welcome on board!
          <br />
          We have prepared Air Polution Task to test candidates’ hard skills.
          <br />
          Once recruiter invites candidates you will see them here
        </div>
      </div>
    </CardComponent>
  );
};
