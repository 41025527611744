import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../actions/modalActions';
import { showAlert } from '../../../actions/alertActions';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AlertType } from '../../../typings/enums';

interface Props {
  invitationLink: string;
  email: string;
}

export const NewCopyLinkModal: React.FC<Props> = ({
  invitationLink,
  email,
}) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(hideModal());
    dispatch(
      showAlert({
        type: AlertType.success,
        message: 'Link has been copied',
      })
    );
  };
  return (
    <div className='d-flex text-sm'>
      <div className='flex-grow-1 pr-4'>
        <div>{email}</div>
        <a
          href={invitationLink}
          target='_blank'
          rel='noopener noreferrer'
          id='invitation-link'
        >
          {invitationLink}
        </a>
      </div>
      <div className='flex-shrink-0 pt-2'>
        <CopyToClipboard text={invitationLink}>
          <Button
            variant='warning'
            onClick={handleClick}
            id='copy-invitation-btn'
          >
            Copy Link
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};
