import { RootState } from '../reducers';

export const pendingFilesDiffSelector = (state: RootState) =>
  state.gitReducer.pendingFilesDiff;
export const errorFilesDiffSelector = (state: RootState) =>
  state.gitReducer.errorFilesDiff;
export const filesDiffSelector = (state: RootState) =>
  state.gitReducer.filesDiff;

export const repoInfoSelector = (state: RootState) => state.gitReducer.repoInfo;
