import React from 'react';
import { v4 as uuid } from 'uuid';
import screen from '../../../assets/hiring_team/challenge.png';
import { TryButton } from '../../components/TryButton';
import { Tip } from './Tip';

const Paragraphs = [
  'Hiring process in tech companies is time consuming,',
  'therefore companies use algorithmic tasks to test hard skills. However, they aren\'t effective and don\'t relate to the real world.',
  'We want to change that, therefore we\'ve built our own application to let candidates solve real problems according to a real world workflow.',
  'In turn, companies manage reviews faster with preliminary results and a common git-like interface.',
];

const TIPS = [
  'Real application',
  'Built solution',
  'Familiar development environment',
];

export const TestRealSkills = () => {
  return (
    <div className='container d-flex flex-column flex-lg-row py-20 justify-content-lg-between'>
      <div className='lg:w-6/12 pr-lg-8 mb-6 mb-lg-0'>
        <div className='text-2xl md:text-4xl font-weight-bold leading-thin mb-6'>
          Test real skills with
          <br />a real application
        </div>
        <div className='mb-6'>
          {Paragraphs.map((paragraph) => (
            <div key={uuid()} className='md:text-xl mb-2'>
              {paragraph}
            </div>
          ))}
        </div>
        <div>
          {TIPS.map((text) => (
            <Tip key={uuid()} text={text} />
          ))}
        </div>
      </div>
      <div className='lg:w-5/12'>
        <img
          src={screen}
          alt='TestRealSkills'
          className='w-100 rounded-lg d-block'
        />
        <div className='text-center mt-6'>
          <TryButton className='border border-yellow-600 hover:bg-yellow-700 py-2 text-2xl' />
        </div>
      </div>
    </div>
  );
};
