import { TeamActionsTypesConstants, TeamsActionTypes } from './types';
import { apiFetch } from '../util/apiFetch';
import { ITeam } from '../typings/interfaces';

import { Action } from 'redux';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { errorText } from '../util/fetchWithToken';
import { showAlert } from './alertActions';
import { AlertType } from '../typings/enums';

export const getTeams = (): ThunkAction<
  void,
  RootState,
  null,
  Action<string>
> => async (dispatch) => {
  dispatch({
    type: TeamActionsTypesConstants.PENDING_TEAMS,
  });
  try {
    const response = await fetch('/api/teams.json');

    if (response.ok) {
      dispatch({
        type: TeamActionsTypesConstants.GET_TEAMS,
        payload: await response.json(),
      });
    }
  } catch (err) {
    dispatch({
      type: TeamActionsTypesConstants.ERROR_TEAMS,
      payload: errorText,
    });
  }
};

export const clearTeams = (): TeamsActionTypes => ({
  type: TeamActionsTypesConstants.CLEAR_TEAMS,
});

export const addTeam = (newTeam: ITeam): TeamsActionTypes => ({
  type: TeamActionsTypesConstants.ADD_TEAM,
  payload: newTeam,
});

export const getTeamEmployees = (
  teamId: string
): ThunkAction<void, RootState, TeamsActionTypes, Action<string>> => async (
  dispatch
) => {
  try {
    dispatch({
      type: TeamActionsTypesConstants.PENDING_TEAM_EMPLOYEES,
      payload: teamId,
    });
    const res = await apiFetch('company', `/team/${teamId}/employee/`);

    if (res.error) throw new Error(res.error);

    dispatch({
      type: TeamActionsTypesConstants.GET_TEAM_EMPLOYEES,
      payload: { employees: res.data, teamId },
    });
  } catch (err) {
    dispatch({
      type: TeamActionsTypesConstants.ERROR_TEAM_EMPLOYEES,
      payload: err.message,
    });
  }
};

export const deleteReviewer = (
  teamId: string,
  employeeId: string
): ThunkAction<void, RootState, TeamsActionTypes, Action<string>> => async (
  dispatch
) => {
  try {
    const res = await apiFetch(
      'company',
      `/team/${teamId}/employee/${employeeId}`,
      'DELETE'
    );

    if (res.error) throw new Error(res.error);

    dispatch({
      type: TeamActionsTypesConstants.REMOVE_EMPLOYEE,
      payload: { employeeId, teamId },
    });
    dispatch(
      showAlert({
        message: 'Reviewer successfully deleted',
        type: AlertType.success,
      })
    );
  } catch (err) {
    dispatch(showAlert({ message: err.message, type: AlertType.fail }));
  }
};

export const updateEmployees = (
  teamId: string,
  employeeIds: string[],
  showNames: boolean
): ThunkAction<void, RootState, TeamsActionTypes, Action<string>> => async (
  dispatch
) => {
  const promisesArr = [];

  for (let employeeId of employeeIds) {
    promisesArr.push(
      apiFetch('company', `/team/${teamId}/employee/${employeeId}`, 'PUT', {
        is_names_visible: showNames,
      })
    );
  }

  try {
    const responses = await Promise.all(promisesArr);
    const isError = responses.find((response) => response.error);
    if (isError) throw new Error(isError.error);
  } catch (err) {
    dispatch(showAlert({ message: err.message, type: AlertType.fail }));
  }
};
