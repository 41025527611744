import React, { useMemo } from 'react';
import { ISolution } from '../../../typings/interfaces';
import { isExpired, isExpiring } from '../../../util/filterFunctions';
import moment from 'moment';
import cn from 'classnames';

interface Props {
  candidate: ISolution;
}

export const Expires: React.FC<Props> = ({ candidate }) => {
  const hoursLeft = useMemo(
    () =>
      moment
        .duration(Date.parse(candidate.date_due as string) - Date.now())
        .hours(),
    [candidate.date_due]
  );
  const daysLeft = useMemo(
    () =>
      moment
        .duration(Date.parse(candidate.date_due as string) - Date.now())
        .days(),
    [candidate.date_due]
  );
  const minutesLeft = useMemo(
    () =>
      moment
        .duration(Date.parse(candidate.date_due as string) - Date.now())
        .minutes(),
    [candidate.date_due]
  );

  return (
    <div className='text-sm mb-2'>
      <span
        data-testid={`expires-${candidate.candidate.uuid}`}
        className={cn({
          'font-weight-bold text-orange-300':
            isExpired(candidate) || isExpiring(candidate),
        })}
      >
        {!isExpired(candidate) ? 'Expires ' : 'Expired '}
        {isExpired(candidate)
          ? moment.utc(candidate.date_due).local().format('DD/MM/YYYY')
          : null}
        {isExpiring(candidate) && !isExpired(candidate) ? (
          <>
            in <span>{hoursLeft ? `${hoursLeft}h` : `${minutesLeft}m`}</span>
          </>
        ) : null}
        {!isExpiring(candidate) && !isExpired(candidate) ? (
          <>
            in{' '}
            <span>
              {daysLeft ? `${daysLeft}d` : ''}{' '}
              {hoursLeft ? `${hoursLeft}h` : ''}
            </span>
          </>
        ) : null}
      </span>
    </div>
  );
};
