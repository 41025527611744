import React from 'react';
import { Form } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { FieldArray, FormikValues } from 'formik';
import { ITicket } from '../../../typings/interfaces';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { formatComplexity, formatKindStr } from '../../../util/ticketHelpers';
import { CustomSwitch } from '../../../shared/components/CustomSwitch';
import './index.scss';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  tickets: ITicket[];
  selectedTickets: FormikValues;
}

export const TableRows: React.FC<Props> = ({
  tickets,
  selectedTickets,
}) => {
  const { positionId } = useParams();

  return (
    <FieldArray
      name='tickets'
      render={(arrayHelpers) => {
        return tickets.map((ticketsItem: any) => {
          const {
            uid,
            title,
            kind,
            skills_meta,
            story_points,
            id,
          } = ticketsItem;

          return (
            <tr key={id}>
              <td className='text-center align-top'>
                <Form.Check
                  custom
                  name='tickets'
                  inline
                  type='checkbox'
                  id={id}
                  label=''
                  checked={selectedTickets.includes(id)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const idx = selectedTickets.indexOf(id);
                    if (e.target.checked) {
                      arrayHelpers.push(id);
                    } else {
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
              </td>
              <td className='align-top'>
                <Link to={`/position/${positionId}/task/ticket/${uid}`}>
                  {uid}
                </Link>
              </td>
              <td className='align-top'>{title}</td>
              <td className='align-top'>{formatKindStr(kind)}</td>
              <td className='align-top pl-7'>
                {skills_meta.map((skill: any) => (
                  <div key={uuid()}>
                    {skill.title}:{' '}
                    <span className='text-xs text-gray-600'>
                      {formatComplexity(skill.complexity)}
                    </span>
                  </div>
                ))}
              </td>
              <td className='align-top text-right'>{story_points}</td>
            </tr>
          );
        });
      }}
    />
  );
};
