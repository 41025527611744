import {
  SET_TASK,
  PENDING_TASK,
  ERROR_TASK,
  CLEAR_TASK,
  TaskActionsTypes,
} from './types';
import {
  SetInstanceTicketsAction,
  TicketsTypesConstants,
} from './types/ticketsActionsTypes';

import { showAlert } from './alertActions';

import { Action } from 'redux';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { apiFetch } from '../util/apiFetch';
import { AlertType } from '../typings/enums';
import { Dispatch } from 'react';

export const getTask = (positionId: string) => async (
  dispatch: Dispatch<SetInstanceTicketsAction | TaskActionsTypes>
) => {
  try {
    const req = await apiFetch('company', `/v1/task/${positionId}/`);

    if (req.error) throw new Error(req.error);

    const payload = {
      duration: req.data.duration,
      estimate: req.data.estimate,
      story_points: req.data.story_points,
      tickets_mandatory: req.data.tickets_mandatory,
      tickets: req.data.tickets,
    };

    dispatch({
      type: SET_TASK,
      payload: req.data,
    });
    dispatch({
      type: TicketsTypesConstants.SET_INSTANCE_TICKETS,
      payload,
    });
  } catch (e) {}
  // try {
  //   dispatch({
  //     type: PENDING_TASK,
  //   });

  //   const response = await apiFetch('company', `/v1/task/${positionId}/`);

  //   if (response.error) throw new Error(response.error);

  //   dispatch({
  //     type: SET_TASK,
  //     payload: response.data,
  //   });
  // } catch (err) {
  //   return dispatch({
  //     type: ERROR_TASK,
  //     payload: err.message,
  //   });
  // }
};

export const updateTask = (
  positionId: string,
  taskData: {
    duration: number;
    estimate: number | string;
    story_points: number;
    tickets: number[];
    tickets_mandatory: number[];
  },
  cb: () => void
): ThunkAction<void, RootState, TaskActionsTypes, Action<string>> => async (
  dispatch
) => {
  try {
    const response = await apiFetch(
      'company',
      `/v1/task/${positionId}/`,
      'PUT',
      taskData
    );

    if (response.error) throw new Error(response.error);

    dispatch(
      showAlert({
        type: AlertType.success,
        message: 'Task updated successfully',
      })
    );

    dispatch({
      type: SET_TASK,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: ERROR_TASK,
      payload: err.message,
    });
  } finally {
    cb();
  }
};

export const clearTask = () => ({
  type: CLEAR_TASK,
});
