import React from 'react';
import { useParams } from 'react-router-dom';
import { GitContent } from './GitContent';
import { Header } from '../../../reviewer/pages/FilesChanged/Header';

export const Repo = () => {
  const { positionId, path } = useParams();

  if (typeof positionId === 'undefined') {
    return <div>Position not found</div>;
  }

  return (
    <div className='repo-wrap'>
      <Header title={'Repository'} />
      <GitContent path={path} positionId={positionId} />
    </div>
  );
};
