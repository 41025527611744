import React, { ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserRoles } from './typings/enums';
import { useShallowEqualSelector } from './hooks/useShallowEqualSelector';
import { Loader } from './shared/components/Loader';
import { userSelector } from './selectors';
interface PrivateRouteProps {
  children: ReactNode;
  role?: UserRoles;
  exact?: boolean;
  path: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  role,
  ...rest
}: any) => {
  const user = useShallowEqualSelector(userSelector);

  const currentRole = user && user.user_claims.roles[0];

  if (currentRole === UserRoles.CANDIDATE) {
    window.location.href = window.OPTIONS.CANDIDATE_APP_URL + '/challenge';
    return <Loader />;
  }

  if (!user || (user && role && currentRole !== role)) {
    return <Redirect to='/' />;
  }
  return <Route {...rest}>{children}</Route>;
};
