import {
  ERROR_FORM_SUBSCRIBE,
  CLEAR_ERROR_FORM_SUBSCRIBE,
} from '../actions/types';
import { SubscribesActionsTypes, SubscribesState } from '../actions/types';
import { SubscribeError } from '../typings/interfaces';

const initialState: SubscribesState = {
  errorSubscribes: [],
};

export const subscribesReducer = (
  state = initialState,
  action: SubscribesActionsTypes
): SubscribesState => {
  switch (action.type) {
    case ERROR_FORM_SUBSCRIBE:
      return {
        ...state,
        errorSubscribes: [
          ...state.errorSubscribes,
          action.payload as SubscribeError,
        ],
      };
    case CLEAR_ERROR_FORM_SUBSCRIBE:
      return {
        ...state,
        errorSubscribes: state.errorSubscribes.filter((err) => {
          return err.type !== action.payload.type;
        }),
      };
    default:
      return state;
  }
};
