import React, { FC } from 'react';
import htmlParse from 'html-react-parser';

interface ChallengeDescProps {
  text: string;
}

export const ChallengeDesc: FC<ChallengeDescProps> = ({ text }) => {
  return <div className='challenge-desc'>{htmlParse(text)}</div>;
};
