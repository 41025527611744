import React from 'react';
import { HeaderDropdown } from './HeaderDropdown';
import { Avatar } from '../../shared/components/Avatar';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { Link } from 'react-router-dom';
import { Dropdowns } from '../../typings/enums';
import { useDispatch } from 'react-redux';
import { signout } from '../../actions/userActions';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { meSelector, pendingMeSelector } from '../../selectors';
import { MiniLoader } from '../../shared/components/MiniLoader';

interface Props {
  isDropdownOpen: {
    isOpen: boolean;
    type: string;
  };
  setIsDropdownOpen: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      type: string;
    }>
  >;
  onClickOutside: (event: MouseEvent | TouchEvent) => void;
  curRef: React.MutableRefObject<any>;
}

const LINKS = [
  { label: 'Change Password', to: '/password/change' },
  { label: 'Accepted Policies', to: '/cookie' },
  { label: 'Sign Out', to: '/', id: 'sign-out-btn' }
];

export const UserDropdown: React.FC<Props> = ({
  isDropdownOpen,
  setIsDropdownOpen,
  onClickOutside,
  curRef
}) => {
  useOnClickOutside(curRef, onClickOutside);

  const dispatch = useDispatch();
  const me = useShallowEqualSelector(meSelector);
  const pendingMe = useShallowEqualSelector(pendingMeSelector);

  const handleSingOut = () => {
    dispatch(signout());
  };

  if (pendingMe) return <MiniLoader />;
  if (!me) return null;

  return (
    <HeaderDropdown
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      curRef={curRef}
      icon={<Avatar data={me} />}
      dropdown={Dropdowns['user-profile']}
      className={'px-0 rounded-circle'}
    >
      <div className='pt-2' data-testid='user-dropdown'>
        <div className='px-4'>
          <Avatar data={me} className='icon--72 text-3xl' />
          <div className='mt-2 text-lg font-weight-bold'>
            {me?.first_name} {me?.last_name}
          </div>
        </div>
        {LINKS.map(({ to, label, id }) => (
          <Link
            onClick={label === 'Sign Out' ? handleSingOut : undefined}
            to={to}
            className='text-gray-600 d-block py-2 hover:bg-blue-100 px-4 hover:text-gray-600 leading-none'
            key={label}
            id={id}
          >
            {label}
          </Link>
        ))}
      </div>
    </HeaderDropdown>
  );
};
