import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';

interface Props {
  handleSave: () => void;
  handleCancel: () => void;
  saveBtnTitle?: string;
  disabled?: boolean;
}

export const NoteBtns: React.FC<Props> = ({
  handleSave,
  handleCancel,
  saveBtnTitle = 'Save',
  disabled,
}) => {
  const pendingNoteUpdate = useShallowEqualSelector(
    (state) => state.notesReducer.pendingNoteUpdate
  );

  const saveNote = useCallback(async () => {
    try {
      await handleSave();
    } catch (error) {
      console.error(error);
    }
  }, [handleSave]);

  return (
    <>
      <Button
        variant='warning'
        className='mr-2 text-xs'
        onClick={saveNote}
        disabled={pendingNoteUpdate || disabled}
      >
        {saveBtnTitle}
      </Button>
      <Button
        className='text-xs'
        variant='outline-dark'
        onClick={handleCancel}
        disabled={pendingNoteUpdate}
      >
        Cancel
      </Button>
    </>
  );
};
