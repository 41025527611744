import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { IUser } from '../typings/interfaces';
import { identifyUser } from '../actions/userActions';

export const useIdentify = (
  user: IUser | null,
  isIdentified: boolean,
  dispatch: Dispatch
) => {
  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient && user && !isIdentified) {
      ldClient.identify({ key: user.identity });
      if (window.OPTIONS.CLIENT_SIDE_ID === '5e62b2d1683ac10aa6a25d2d') {
        window.ldClient = ldClient;
      }
      dispatch(identifyUser());
    }
  }, [ldClient, user, isIdentified, dispatch]);
};
