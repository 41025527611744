import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../actions/modalActions';
import { Actions } from '../../../typings/enums';
import { IPosition } from '../../../typings/interfaces';
import { setCurrentPosition } from '../../../actions/positionsActions';

interface Props {
  position: IPosition;
}

export const InviteMemberBtn: React.FC<Props> = ({ position }) => {
  const dispatch = useDispatch();
  return (
    <button
      className='icon--32 border-blue-600 border-dashed border rounded-circle bg-blue-200 d-flex align-items-center justify-content-center text-2xl text-primary'
      onClick={() => {
        dispatch(setCurrentPosition(position));
        dispatch(
          showModal({ type: Actions.invite, title: 'Invite team members' })
        );
      }}
    >
      +
    </button>
  );
};
