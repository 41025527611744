import { IConsent } from '../typings/interfaces';
import { apiFetch } from '../util/apiFetch';
import { PoliciesActionsTypesConstants } from './types/policiesActionsTypes';

export const postConsent = async (consentData: IConsent) => {
  try {
    const res = await apiFetch('gdpr', '/consent/', 'POST', consentData);
    return res;
  } catch (err) {
    return err
  }
};

export const agreeCookies = (timestamp: number) => ({
  type: PoliciesActionsTypesConstants.AGREE_COOKIES,
  payload: timestamp
});

export const refuseCookies = () => ({
  type: PoliciesActionsTypesConstants.REFUSE_COOKIES
});
