import React from 'react';
import { ReactComponent as LogoMini } from '../../../assets/logo_mini_white.svg';

interface Props {
  isSfeedaStep: boolean | undefined;
}

export const Point: React.FC<Props> = ({ isSfeedaStep }) => {
  return (
    <div className='point'>
      {!isSfeedaStep ? (
        <div className='point-content d-flex icon--24'>
          <div className='rounded-circle icon--12 bg-gray-900 m-auto'></div>
        </div>
      ) : (
        <div
          className='point-content d-flex icon--32'
          style={{ background: '#C7E7FF' }}
        >
          <div className='rounded-circle icon--22 m-auto bg-blue-500 d-flex'>
            <LogoMini className='m-auto' />
          </div>
        </div>
      )}
    </div>
  );
};
