import React from 'react';
import { useDispatch } from 'react-redux';
import { hideAlert } from '../../../actions/alertActions';
import ReactGA from 'react-ga';
import { agreeCookies } from '../../../actions/policiesActions';
import { setCookie } from '../../../util/setCookie';
import { getEnv } from '../../../util/getEnv';
import { capitalize } from '../../../util/capitalize';

export const CookiesAlert = () => {
  const dispatch = useDispatch();
  return (
    <div className='text-white d-flex flex-column flex-md-row align-items-md-center'>
      <div className='mb-4 mb-md-0' id='cookie-alert__text'>
        By continuing to use this site you consent to the use of cookies in
        accordance with our{' '}
        <a
          className='text-white'
          href={'/cookie/'}
          target='_blank'
          rel='noopener noreferrer'
        >
          <u>cookie policy</u>
        </a>
      </div>
      <div>
        <button
          className='btn-warning rounded-pill ml-md-4 btn py-1'
          onClick={() => {
            const timestamp = Date.now();

            const env = capitalize(getEnv());
            setCookie(`cookiePolicyConsent${env}`, 'true');
            setCookie(`cookiePolicyConsentTimestamp${env}`, `${timestamp}`);
            dispatch(agreeCookies(timestamp));
            ReactGA.initialize('UA-156554747-1');
            dispatch(hideAlert());
          }}
          id='btn-close-cookie-alert'
        >
          Agree
        </button>
      </div>
    </div>
  );
};
