import {
  SHOW_ALERT,
  HIDE_ALERT,
  AlertActionTypes,
  AlertState,
} from '../actions/types';

export const initialState: AlertState = {
  isShown: false,
  message: '',
  type: 'fail',
};

export const alertReducer = (
  state = initialState,
  action: AlertActionTypes
): AlertState => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        isShown: true,
        type: action.payload.type,
        message: action.payload.message,
      };
    case HIDE_ALERT:
      return {
        ...state,
        isShown: false,
        message: '',
        type: 'fail',
      };
    default:
      return state;
  }
};
