import React from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setCurrentPosition } from '../../../actions/positionsActions';
import { showModal, modalInviteWithoutEmail } from '../../../actions/modalActions';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { IPosition } from '../../../typings/interfaces';
import { Actions } from '../../../typings/enums';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  initialInProgressState: boolean | undefined;
  position: IPosition;
  taskSent: undefined | number;
}

export const SendInviteBtn: React.FC<Props> = ({
  initialInProgressState,
  position,
  taskSent,
}) => {
  const inviteCandidateEmailWithWithout = false;
  const dispatch = useDispatch();

  const openModalWithEmail = () => {
    
    dispatch(
      showModal({
        type: Actions.inviteCandidate,
        title: 'Invite new candidate',
      })
    );
    dispatch(setCurrentPosition(position));
  }

  const openModalWithoutEmail = () => {
    // rewiever interface
    dispatch(modalInviteWithoutEmail(true));
  };

  return (
    <button
      id={`send-invite-${position.uuid}`}
      className={cn('border-0', 'flex-grow-1', {
        'bg-transparent': initialInProgressState,
        'pb-4': initialInProgressState,
      })}
      onClick={() => inviteCandidateEmailWithWithout ? openModalWithEmail() : openModalWithoutEmail()}
      data-testid={'large-invite-btn'}
    >
      <FontAwesomeIcon
        icon={faPlusCircle}
        size={initialInProgressState ? '3x' : '2x'}
        color={initialInProgressState ? '#FFCA00' : '#C1C1C1'}
        className='mb-3'
      />
      <div
        className={cn({
          'text-2xl': initialInProgressState,
          'text-sm': taskSent && !initialInProgressState,
        })}
      >
        Invite
      </div>
    </button>
  );
};
