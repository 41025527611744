import React from 'react';
import candidateImgPath from '../../../assets/twoMonthsFreeTrial/candidate_experience.png';
import { Carousel } from 'react-bootstrap';
import candidateImgPath2 from '../../../assets/twoMonthsFreeTrial/candidate_experience2.png';
import { ISlideBtn } from './ImgRightSlider';
import { SliderBtn } from './SliderBtn';

interface Props {
  index: number;
  handleSelect: (selectedIndex: number, e: object | null) => void;
  slidesDesc: ISlideBtn[];
  isMatchMediaQuery: boolean;
}

export const CandidatesCarousel: React.FC<Props> = ({
  index,
  handleSelect,
  slidesDesc,
  isMatchMediaQuery,
}) => {
  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      interval={null}
      controls={false}
    >
      <Carousel.Item>
        <img
          className='d-block w-100'
          src={candidateImgPath}
          alt='Highly-relevant task'
        />
        {!isMatchMediaQuery && (
          <SliderBtn
            btn={slidesDesc[0]}
            handleSelect={handleSelect}
            btnIndex={0}
            index={index}
            minHeight='102px'
          />
        )}
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src={candidateImgPath2}
          alt='Commonly used tools'
        />
        {!isMatchMediaQuery && (
          <SliderBtn
            btn={slidesDesc[1]}
            handleSelect={handleSelect}
            btnIndex={1}
            index={index}
            minHeight='102px'
          />
        )}
      </Carousel.Item>
    </Carousel>
  );
};
