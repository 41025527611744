import React, { useState } from 'react';
import { RadioBtn } from './CreateFirstPosition/RadioBtn';

interface Props {}

export const Interview: React.FC<Props> = () => {
  const [values, setValues] = useState<any>({
    interview: 'no',
  });
  const valueName = 'interview';
  const handleChange = () => {
    setValues((prevValues: any) => ({
      ...prevValues,
      interview: prevValues.interview === 'yes' ? 'no' : 'yes',
    }));
  };

  return (
    <div>
      <div className='mb-1'>Interview scheduled</div>
      <div className='btn-group btn-group-toggle' data-toggle='buttons'>
        <RadioBtn
          condition={{
            'border-blue-700': values[valueName] === 'yes',
            'border-2': values[valueName] === 'yes',
          }}
        >
          <input
            type='radio'
            name={valueName}
            checked={values[valueName] === 'yes'}
            id={`${valueName}-yes`}
            value='yes'
            onChange={handleChange}
          />
          Yes
        </RadioBtn>
        <RadioBtn
          condition={{
            'border-blue-700': values[valueName] === 'no',
            'border-2': values[valueName] === 'no',
          }}
        >
          <input
            type='radio'
            name={valueName}
            checked={values[valueName] === 'no'}
            id='radio-no'
            value={`${valueName}-no`}
            onChange={handleChange}
          />{' '}
          No
        </RadioBtn>
      </div>
    </div>
  );
};
