import { Action } from 'redux';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';

import { GET_CHALLENGES_CATEGORIES, ChallengesActionTypes } from './types';
import { apiFetch } from '../util/apiFetch';
import { showAlert } from './alertActions';
import { AlertType } from '../typings/enums';

export const getChallengesCategories = (): ThunkAction<
  void,
  RootState,
  ChallengesActionTypes,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await apiFetch('company', '/category/');

    if (response.error) throw new Error(response.error);

    dispatch({
      type: GET_CHALLENGES_CATEGORIES,
      payload: response.data,
    });
  } catch (err) {
    dispatch(
      showAlert({
        type: AlertType.fail,
        message: err.message,
      })
    );
  }
};

// export const getChallenge = (
//   id: string | undefined
// ): ThunkAction<
//   void,
//   RootState,
//   ChallengesActionTypes,
//   Action<string>
// > => async (dispatch) => {
//   try {
//     const response = await apiFetch('company', `/challenge/${id}/`);

//     if (response.error) throw new Error(response.error);

//     dispatch({
//       type: GET_CHALLENGE,
//       payload: response.data,
//     });
//   } catch (err) {
//     dispatch(
//       showAlert({
//         type: AlertType.fail,
//         message: err.message,
//       })
//     );
//   }
// };
