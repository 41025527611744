import React from 'react';
import { ILike } from '../../../typings/interfaces';
import { Avatar } from '../Avatar';
import { Like } from './Like';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  votes: ILike[];
}

export const MultipleLike: React.FC<Props> = ({ votes }) => {
  return (
    <div
      className='d-flex position-relative'
      style={{
        width: `${33 + votes.length * 27}px`,
        height: '33px',
      }}
    >
      <div className='position-absolute' style={{ zIndex: votes.length + 1 }}>
        <Like like={votes[0]} />
      </div>
      {votes.map((vote, i: number) => (
        <div
          key={`like-${vote.created_by.uuid}-${vote.object_id}`}
          className='position-absolute'
          style={{ zIndex: votes.length - i, left: `${(i + 1) * 27}px` }}
        >
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip
                id={`tooltip-like-${vote.created_by.uuid}-${vote.object_id}`}
              >
                <div>
                  {vote.created_by.first_name} {vote.created_by.last_name}
                </div>
              </Tooltip>
            }
          >
            <div>
              <Avatar data={vote.created_by} />
            </div>
          </OverlayTrigger>
        </div>
      ))}
    </div>
  );
};
