import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ISolution, ILike } from '../../../typings/interfaces';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useDispatch } from 'react-redux';
import { getNotes, clearNotes } from '../../../actions/notesActions';
import { Loader } from '../../../shared/components/Loader';
import { StageTitle } from '../../../shared/components/StageTitle';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLikes } from '../../../hooks/useLikes';
import { LikesContentTypeEnum, Stages } from '../../../typings/enums';
import { isComment } from '../../../typings/typeGuards';
import { CandidateCard } from '../../components/CandidateCard';
import {
  pendingCandidatesSelector,
  notesSelector,
  pendingNotesSelector,
  selectFilteredCandidates,
} from '../../../selectors';

export const ProcessedCandidates: React.FC = () => {
  const { positionId } = useParams();
  const { showLikeDeslikeFeature } = useFlags();

  const dispatch = useDispatch();

  const notes = useShallowEqualSelector(notesSelector);
  const pendingCandidates = useShallowEqualSelector(pendingCandidatesSelector);
  const pendingNotes = useShallowEqualSelector(pendingNotesSelector);
  useEffect(() => {
    dispatch(getNotes(positionId));
    return () => {
      dispatch(clearNotes());
    };
  }, [dispatch, positionId]);

  const filteredCandidates = useShallowEqualSelector((state) =>
    selectFilteredCandidates(state, positionId, Stages.PROCESSED)
  );

  const { likesByCandidatePosition, pendingLikes } = useLikes(
    filteredCandidates
  );

  let isLoading =
    pendingCandidates.includes(positionId) || pendingLikes || pendingNotes;

  if (isLoading) {
    return <Loader />;
  }

  if (!filteredCandidates || pendingLikes || pendingNotes) {
    return null;
  }

  return (
    <>
      <StageTitle
        title='Processed to the next step'
        qty={filteredCandidates.length}
      />
      {filteredCandidates.length &&
        filteredCandidates
          .sort(
            (a, b) =>
              Date.parse(b.feedback?.date_created as string) -
              Date.parse(a.feedback?.date_created as string)
          )
          .map((candidate: ISolution) => {
            let currentCandidateLikes: ILike[] | undefined;

            if (showLikeDeslikeFeature && likesByCandidatePosition) {
              currentCandidateLikes = likesByCandidatePosition.filter(
                (like) =>
                  like.content_type_id ===
                    LikesContentTypeEnum.CANDIDATE_POSITION &&
                  like.object_id === String(candidate.id)
              );
            }

            const curCandidateComments = notes?.[
              candidate.position.uuid
            ]?.filter(
              (note) =>
                isComment(note) &&
                note.user_id === candidate.candidate.user?.uuid
            );
            return (
              <CandidateCard
                candidate={candidate}
                key={candidate.candidate.uuid}
                votes={currentCandidateLikes}
                curCandidateComments={curCandidateComments}
              />
            );
          })}
    </>
  );
};
