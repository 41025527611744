import React from 'react';
import { ICheck, ISolution } from '../../../typings/interfaces';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UserRoles } from '../../../typings/enums';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { userRoleSelector } from '../../../selectors';

interface Props {
  checks: ICheck[];
  currentCandidate: ISolution;
  shareuuid: string | undefined;
}

export const Rows: React.FC<Props> = ({
  checks,
  currentCandidate,
  shareuuid,
}) => {
  const commitsArr = checks.sort(
    (a, b) => Date.parse(b.date_created) - Date.parse(a.date_created)
  );

  const isReviewer =
    useShallowEqualSelector(userRoleSelector) === UserRoles.REVIEWER;

  return (
    <>
      {commitsArr.map((check, i: number) => {
        const { commit, date_created, message } = check;

        if (check.pipeline_type !== 'LINT_TEST' || i === commitsArr.length - 1)
          return null;
        // temporary return works only with auth user
        const url =
          shareuuid !== undefined
            ? `/share/${shareuuid}/${currentCandidate.position.uuid}/${
                currentCandidate.candidate.uuid
              }/code/${commitsArr[i + 1].commit}/${commit}`
            : `/position/${currentCandidate.position.uuid}/candidate/${
                currentCandidate.candidate.uuid
              }/code/${commitsArr[i + 1].commit}/${commit}`;

        return (
          <tr
            key={commit}
            data-testid={commit.slice(0, 7)}
            data-class='tr-check'
          >
            <td
              className='py-2 px-0 pr-3 border-0 align-top'
              data-testid={`check-commit-${commit.slice(0, 7)}`}
            >
              {!isReviewer && i !== 0 ? (
                <div className='text-primary'>XXXXXX</div>
              ) : (
                <Link to={url}>#{commit.slice(0, 7)}</Link>
              )}
            </td>
            <td
              className='py-2 px-0 pr-3 border-0 align-top'
              data-testid={`check-message-${commit.slice(0, 7)}`}
            >
              {!isReviewer && i !== 0 ? message.replace(/\w/g, 'X') : message}
            </td>
            <td
              className='py-2 px-0 border-0 align-top'
              data-testid={`check-date-${commit.slice(0, 7)}`}
            >
              {!isReviewer && i !== 0
                ? 'XXXXXXX'
                : moment.utc(date_created).local().format('DD MMM, HH:mm')}
            </td>
          </tr>
        );
      })}
    </>
  );
};
