import React from 'react';

import cn from 'classnames';
interface Props {
  qty: number;
  type: string;
}

export const SummaryItem: React.SFC<Props> = ({ qty, type }) => {
  const renderTitle = () => {
    switch (type) {
      case 'expiring':
        return (
          <span>
            Expiring
            <br />
            Tasks
          </span>
        );
      case 'accepted':
        return (
          <span>
            Accepted
            <br />
            Tasks
          </span>
        );
      case 'submitted':
        return (
          <span>
            Under
            <br />
            Review
          </span>
        );
      case 'rejected':
        return (
          <span>
            Rejected
            <br />
            Tasks
          </span>
        );
      case 'task_sent':
        return <span>Tasks Sent</span>;
      default:
        return <span>Tasks Sent</span>;
    }
  };
  const setModalTitle = () => {
    switch (type) {
      case 'expiring':
        return 'Expiring Tasks';
      case 'accepted':
        return 'Accepted Tasks';
      case 'submitted':
        return 'Under Review';
      case 'rejected':
        return 'Rejected Tasks';
      case 'task_sent':
        return 'Task Sent';
      default:
        return 'Task Sent';
    }
  };

  const handleClick = ({
    modalTitle,
    type,
  }: {
    modalTitle: string;
    type: string;
  }) => {
    // dispatch(getSummaryCandidates(type));
    /* dispatch(
      showModal({
        type: `summary_${type}`,
        title: modalTitle,
        size: 'full-screen'
      })
    ); */
  };

  return (
    <button
      className={cn(
        'rounded-lg',
        'border',
        'border-2',
        'border-gray-600',
        'bg-white',
        'py-2',
        'd-flex',
        'flex-column',
        'justify-content-between',
        'align-items-center',
        {
          'mr-2': type !== 'rejected',
          'mr-4': type === 'rejected',
        }
      )}
      style={{ minHeight: '108px', minWidth: '94px' }}
      onClick={() =>
        handleClick({
          modalTitle: setModalTitle(),
          type,
        })
      }
    >
      <div
        className={cn(
          'text-4-7xl',
          'text-center',
          'font-bebas',
          'leading-none',
          'text-center',
          {
            'text-orange-300': type && type === 'expiring',
            'text-success': type && type === 'accepted',
          }
        )}
      >
        {qty}
      </div>
      <div className='text-sm text-center'>{renderTitle()}</div>
    </button>
  );
};
