import React, { useState } from 'react';
import { ITracker } from '../../../typings/interfaces';
import { TicketInfo } from '../TicketInfo';
import { CustomCollapse } from '../CustomCollapse';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow_down.svg';

interface Props {
  tracker: ITracker;
}

export const ShareTicket: React.FC<Props> = ({ tracker }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const expandHandler = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='p-4 border rounded-lg bg-white mb-4 position-relative d-flex flex-column'>
      <div>
        <div className='px-0 text-center w-100 '>
          <div
            className='d-flex justify-content-between w-100 align-items-center mb-2 cursor-pointer'
            onClick={expandHandler}
          >
            <div className='text-lg font-weight-bold lg:w-10/12'>
              {tracker.ticket.uid} {tracker.ticket.title}
            </div>
            <div className='d-flex align-items-center'>
              <div className='text-lg'>{tracker.ticket.story_points}SP</div>
              <ArrowIcon
                className='ml-2'
                style={{
                  transform: isExpanded ? '' : 'rotate(-90deg)',
                  transition: 'transform .15s',
                }}
              />
            </div>
          </div>
        </div>
        <div className='d-flex flex-column justify-content-between flex-grow-1'>
          <div className='flex-grow-1'>
            <CustomCollapse
              cardInitialHeight={140}
              isExpanded={isExpanded}
              onExpand={expandHandler}
            >
              <div id={`collapse-${tracker.ticket.id}`}>
                <div>
                  <div>
                    {tracker.ticket.sections.map((section) => {
                      return (
                        <div key={section.id}>
                          <div className='font-weight-bold mb-1'>
                            {section.title}
                          </div>
                          <TicketInfo body={section.body} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </CustomCollapse>
          </div>
        </div>
      </div>
    </div>
  );
};
