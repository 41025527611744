import React, { useEffect } from 'react';
import { ReactComponent as WelcomeIcon } from '../../../assets/welcome.svg';
import { Button } from 'react-bootstrap';
import { Link, useParams, Redirect } from 'react-router-dom';
import { ShareTop } from './ShareTop';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../reducers';
import { checkSharedSolutionCandidate } from '../../../actions/socialActions';

export const Share = () => {
  const { shareuuid, candidateId, positionId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!shareuuid) {
      dispatch(checkSharedSolutionCandidate(shareuuid));
    }
  }, [shareuuid]);

  const { redirect } = useSelector((state: RootState) => state.socialReducer);

  // if (!shareuuid && redirect) {
  //   return <Redirect to='/' />;
  // }

  return (
    <div className='bg-blue-900'>
      <ShareTop
        height={34}
        gap={120}
        topWrapAddClass={'absolute'}
        shareNavsStyle={'light'}
      />
      <div className='container min-vh-full d-flex w-100 flex-column'>
        <div className='m-auto text-blue-100 d-md-flex justify-content-center'>
          <div className='xl:w-5/12 mr-xl-6 md:w-6/12 mb-8 mb-md-0'>
            <div className='mb-4'>
              <WelcomeIcon /> Welcome on Sfeeda — <br />
              coding skills assessment platform
            </div>
            <div
              style={{ maxWidth: '435px' }}
              className='text-4xl font-weight-bold leading-thin mb-5'
            >
              You are invited to check the code challenge
            </div>
            <Button
              as={Link}
              variant='warning'
              to={`/share/${shareuuid}/${positionId}/${candidateId}/overview`}
              className='rounded-lg font-weight-bold h-12 d-inline-flex align-items-center justify-content-center'
              style={{ minWidth: '156px' }}
            >
              Check It
            </Button>
          </div>
          <div className='xl:w-5/12 md:w-6/12'>
            <div className='text-gray-400'>Skills tested</div>
            <div className='mb-2'>React, API, Testing, CSS</div>
            <div className='text-gray-400'>Story Points requested</div>
            <div className='mb-2'>6SP</div>
            <div className='text-gray-400'>Challenge</div>
            <div className='mb-2' style={{ maxWidth: '427px' }}>
              Air Polution. The application that shows the air quality data from
              all over the world. It utilises{' '}
              <a
                href='http://openaq.org'
                target='_blank'
                rel='noopener noreferrer'
              >
                http://openaq.org
              </a>{' '}
              to obtain the data
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
