import React from 'react';
import { LoaderMini } from '../LoaderMini';
import { Button } from 'react-bootstrap';
import cn from 'classnames';
import { useMatchMedia } from '../../../hooks/useMatchMedia';

interface Props {
  btnTitle: string;
  isSubmitting: boolean;
  size?: 'sm' | 'lg';
  id?: string;
}

export const SubmitBtn: React.FC<Props> = ({
  btnTitle,
  isSubmitting,
  size = 'lg',
  id,
}) => {
  const [isMatchMediaQuery] = useMatchMedia(769);

  return (
    <>
      <Button
        variant='warning'
        disabled={isSubmitting}
        id={id}
        type='submit'
        className={cn(
          'w-100 mt-3 mb-4 rounded d-flex align-items-center justify-content-center font-weight-bold h-10',
          {
            'md:h-16': isMatchMediaQuery && size === 'lg',
            'text-xl': isMatchMediaQuery && size === 'lg',
          }
        )}
      >
        {isSubmitting ? <LoaderMini /> : btnTitle}
      </Button>
    </>
  );
};
