import {
  SHOW_SLIDE_MENU,
  HIDE_SLIDE_MENU,
  SlideMenuActionTypes,
} from './types';

export const toggleNavigationMenu = (
  isShown: boolean
): SlideMenuActionTypes => {
  if (isShown) {
    return {
      type: HIDE_SLIDE_MENU,
    };
  } else {
    return {
      type: SHOW_SLIDE_MENU,
    };
  }
};

export const closeNavigationMenu = (): SlideMenuActionTypes => {
  return {
    type: HIDE_SLIDE_MENU,
  };
};
