import React from 'react';
import { ICheck } from '../../../typings/interfaces';
import { ReactComponent as CompleteIcon } from '../../../assets/completed.svg';
import { ReactComponent as AlertIcon } from '../../../assets/alert.svg';
import { MiniLoader } from '../../../shared/components/MiniLoader';

interface Props {
  check: ICheck;
  isHidden?: boolean;
}

export const UnitTestInfo: React.FC<Props> = ({ check, isHidden }) => {
  const { status, commit } = check;
  if (
    check.hasOwnProperty('testsData') &&
    status !== 'RUNNING' &&
    check.testsData
  ) {
    if (check.testsData.hasOwnProperty('error') && check.testsData.error)
      return <span>error</span>;
    return (
      <div className='d-flex align-items-top'>
        <div>
          {check.testsData.numFailedTests > 0 ? (
            <AlertIcon className='icon--16 mr-2 mt-n1' />
          ) : (
            <CompleteIcon className='icon--16 mr-2 mt-n1' />
          )}
        </div>
        <div className='d-flex flex-wrap'>
          <div>
            <span data-testid={`failed-tests-${commit.slice(0, 7)}`}>
              {isHidden ? 'X' : check.testsData.numFailedTests}
            </span>{' '}
            tests failed,
          </div>
          <div>
            <span data-testid={`total-tests-${commit.slice(0, 7)}`}>
              {isHidden ? 'X' : check.testsData.numTotalTests}
            </span>{' '}
            tests completed
          </div>
        </div>
      </div>
    );
  }
  return <MiniLoader />;
};
