import React from 'react';
import { ISolution } from '../../typings/interfaces';
import { PredictionBadge } from '../../shared/components/PredictionBadge';
import { Stages, UserRoles } from '../../typings/enums';
import { CandidateStage } from './CandidateStage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import cn from 'classnames';
import { userRoleSelector, positionsSelector } from '../../selectors';
import { filterSolutionByStage } from '../../util/filterSolutionByStage';

interface Props {
  candidate: ISolution;
  className?: string;
  positionId: string;
}

export const CandidateInfo: React.FC<Props> = ({
  candidate,
  className,
  positionId
}) => {
  const { shotGoodBadFit } = useFlags();
  const userRole = useShallowEqualSelector(userRoleSelector);
  const positions = useShallowEqualSelector(positionsSelector);

  const curPosition =
    positions && positions.find((position) => position.uuid === positionId);

  if (!candidate) {
    return null;
  }

  const dataJSON: any = JSON.parse(candidate.data);

  return (
    <>
      {userRole === UserRoles.RECRUITER ? (
        <div className='text-primary'>
          {curPosition?.title}{' '}
          <span className='text-gray-600'>in {curPosition?.team.title}</span>
        </div>
      ) : null}
      <div
        className={cn('d-flex justify-content-between flex-wrap', [className])}
      >
        <div className='d-flex align-items-center'>
          <div className='mr-2 font-weight-bold leading-none h2 mb-0'>
            {candidate.candidate.first_name} {candidate.candidate.last_name}
          </div>
          <span className='text-gray-900 font-weight-normal leading-none h2 mb-0'>
            {candidate.candidate.uuid.slice(0, 6).toUpperCase()}
          </span>
          {shotGoodBadFit &&
            filterSolutionByStage(candidate, userRole, Stages.SUBMITTED) && (
              <PredictionBadge
                fit={dataJSON ? dataJSON.fit : undefined}
                candidateId={candidate.candidate.uuid}
                className='ml-2'
              />
            )}
        </div>
        <CandidateStage candidate={candidate} />
      </div>
    </>
  );
};
