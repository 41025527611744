import React from 'react';
import feature1ImgPath from '../../../assets/hiring_team/feature1.png';
import feature2ImgPath from '../../../assets/hiring_team/feature2.png';
import feature3ImgPath from '../../../assets/hiring_team/feature3.png';
import { BlockImgLeft } from './BlockImgLeft';
import { v4 as uuid } from 'uuid';

interface Props {
  setRef: (node: HTMLDivElement | null) => void;
}

const BLOCKS = [
  {
    title: 'Tailored challenges \nto fit your needs',
    text:
      'We\'ve built our own application to test hard skills. Just select the tickets most relevant to your projects. Adjust duration and number of tasks to be completed.',
    imagePath: feature1ImgPath,
    tips: [
      'Tasks relevant to real projects',
      'Tests code skills as well as coding standards',
    ],
    className: 'mb-20',
  },
  {
    title: 'Code Overview',
    text:
      'Identify task completeness without looking at code. We show you what\'s done and what\'s missed in advance and how good a candidate performs compare to others.',
    imagePath: feature2ImgPath,
    tips: [
      'Run test application instantly',
      'Identify non-fitting candidates without code review',
    ],
    className: 'mb-20',
  },
  {
    title: 'Code Changes',
    text:
      'Solutions that merit attention are visible at a glance. Save your time on deeper analysis for only the most relevant candidates.',
    imagePath: feature3ImgPath,
    tips: ['Common Git Interface', 'Clone code when it\'s needed'],
    additionalContent: (
      <div className='d-flex'>
        <div className='text-3xl font-weight-bold mr-3'>6/8</div>
        <div style={{ maxWidth: '350px' }}>
          reviewers during beta testing identified weak solutions without deep
          code analysis
        </div>
      </div>
    ),
  },
];

export const HiringTeams: React.FC<Props> = ({ setRef }) => {
  return (
    <div className='pb-10' ref={setRef} data-refkey='hiringTeams'>
      {BLOCKS.map((props) => (
        <BlockImgLeft {...props} key={uuid()} />
      ))}
    </div>
  );
};
