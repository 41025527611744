import React, { useState, useEffect, useRef } from 'react';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { v4 as uuid } from 'uuid';
import AnimateHeight from 'react-animate-height';
import { useDispatch } from 'react-redux';

import {
  getCandidateNote,
  clearCandidateNote
} from '../../../actions/candidatesActions';
import { ISolution } from '../../../typings/interfaces';
import {
  candidateNoteSelector,
  pendingCandidateNoteSelector,
  errorCandidateNoteSelector
} from '../../../selectors';

interface Props {
  candidate: ISolution;
  positionId: string;
}

export const CandidateNote: React.FC<Props> = ({ candidate, positionId }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOveflowed, setIsOveflowed] = useState<boolean>(false);

  const candidateNote = useShallowEqualSelector(candidateNoteSelector);
  const pendingCandidateNote = useShallowEqualSelector<boolean>(
    pendingCandidateNoteSelector
  );
  const errorCandidateNote = useShallowEqualSelector(
    errorCandidateNoteSelector
  );

  enum cardSize {
    height = 296
  }

  useEffect(() => {
    if (candidate.candidate.user.uuid && positionId) {
      dispatch(getCandidateNote(positionId, candidate.candidate.user.uuid));
    }
  }, [dispatch, candidate.candidate.user.uuid, positionId]);

  useEffect(() => {
    return () => {
      dispatch(clearCandidateNote());
    };
  }, [dispatch]);

  useEffect(() => {
    if (contentRef && candidateNote && contentRef.current) {
      const height = contentRef.current.offsetHeight;
      if (height && height > cardSize.height) {
        setIsOveflowed(true);
      }
    }
  }, [candidateNote, cardSize.height, contentRef]);

  let text;
  if (candidateNote && candidateNote.body) {
    if (typeof candidateNote.body === 'string') {
      text = candidateNote.body;
    } else {
      text = candidateNote.body.text;
    }
  }

  return (
    <div className='position-relative' data-testid='candidate-note'>
      <div className='border-bottom pb-2 mb-2 text-lg font-weight-bold'>
        Candidate’s note
      </div>
      <AnimateHeight
        duration={500}
        height={isExpanded ? 'auto' : cardSize.height}
      >
        <div ref={contentRef}>
          {errorCandidateNote ? errorCandidateNote : null}
          {pendingCandidateNote ? 'Loading...' : null}
          {candidateNote && !candidateNote.body ? (
            <div className='mb-4'>Candidate did not post any notes</div>
          ) : (
            <div>
              {text &&
                text
                  .split('\\n')
                  .map((str: string) => <div key={uuid()}>{str}</div>)}
            </div>
          )}
        </div>
      </AnimateHeight>
      {isOveflowed ? (
        <>
          {!isExpanded ? (
            <div
              className='position-absolute d-flex align-items-end'
              style={{
                height: '55px',
                width: '100%',
                bottom: '39px',
                background:
                  'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.66) 0.01%, #FFFFFF 100%)'
              }}
            ></div>
          ) : null}
          <button
            className={'align-items-end py-2 px-0 border-0 focus:outline-none bg-white w-100 text-left'}
            style={{
              color: '#3D5470'
            }}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {!isExpanded ? 'Show more' : 'Collapse'}
          </button>
        </>
      ) : null}
    </div>
  );
};
