import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurCandidate } from '../../../actions/candidatesActions';
import { ISolution } from '../../../typings/interfaces';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { UserRoles, Stages } from '../../../typings/enums';
import { getSolutionStage } from '../../../util/getSolutionStage';
import { userRoleSelector } from '../../../selectors';
import { NameWithShortId } from '../NameWithShortId';

interface Props {
  candidate: ISolution;
  className?: string | undefined;
}

const stagesForLink = [
  Stages.SUBMITTED,
  Stages.ACCEPTED,
  Stages.REJECTED,
  Stages.PROCESSED,
  Stages.ARCHIVED,
];

export const CandidateLink: React.SFC<Props> = ({ candidate, className }) => {
  const dispatch = useDispatch();
  const userRole = useShallowEqualSelector(userRoleSelector);
  const category = userRole === UserRoles.REVIEWER ? '/overview' : '';
  const handleClick = () => {
    dispatch(setCurCandidate(candidate));
  };

  if (!stagesForLink.includes(getSolutionStage(candidate, userRole))) {
    return (
      <span className={className}>
        <NameWithShortId
          firstName={candidate.candidate.first_name}
          lastName={candidate.candidate.last_name}
          uuid={candidate.candidate.uuid}
        />
      </span>
    );
  }

  return (
    <Link
      to={`/position/${candidate.position.uuid}/candidate/${candidate.candidate.uuid}${category}`}
      className={className}
      onClick={handleClick}
    >
      <NameWithShortId
        firstName={candidate.candidate.first_name}
        lastName={candidate.candidate.last_name}
        uuid={candidate.candidate.uuid}
      />
    </Link>
  );
};
