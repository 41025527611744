import React from 'react';
import { Dropdown, InputGroup, Form } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import {
  currentCandidateSelector,
  meSelector,
  repoInfoSelector,
} from '../../../selectors';

interface Props {
  handleLinkCopy: () => void;
}

const LinkInput: React.FC<Props> = ({ handleLinkCopy }) => {
  const currentCandidate = useShallowEqualSelector(currentCandidateSelector);

  const repoInfo = useShallowEqualSelector(repoInfoSelector);

  const me = useShallowEqualSelector(meSelector);

  if (!currentCandidate) return null;

  const link =
    repoInfo?.[0]?.repo_url &&
    me?.codecommit_username &&
    me?.codecommit_password
      ? `https://${me.codecommit_username}:${me.codecommit_password}@${repoInfo[0].repo_url}`
      : undefined;

  return (
    <>
      <div className='d-flex w-100 align-items-center justify-content-between mb-2'>
        <div className='text-sm font-weight-bold'>Clone with HTTPS</div>
      </div>
      {link ? (
        <InputGroup>
          <Form.Control
            type='text'
            value={link}
            readOnly
            className='bg-white border-gray-600 focus:border-gray-600'
          />
          <InputGroup.Append>
            <InputGroup.Text className='bg-warning border-yellow-700  p-0'>
              <CopyToClipboard text={link}>
                <Dropdown.Item
                  eventKey='1'
                  className='p-0 bg-transparent'
                  as='span'
                >
                  <button
                    className='bg-transparent border-0 p-0 focus:bg-yellow-700'
                    style={{ width: '32px', height: '36px' }}
                    onClick={handleLinkCopy}
                  >
                    <FontAwesomeIcon
                      icon={faCopy}
                      size='lg'
                      color='black'
                      style={{ width: '12px' }}
                    />
                  </button>
                </Dropdown.Item>
              </CopyToClipboard>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      ) : (
        <div>no link</div>
      )}
    </>
  );
};

export default LinkInput;
