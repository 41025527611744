import React from 'react';
import { FormikErrors, Field, FormikTouched } from 'formik';

interface Props {
  title: string;
  isError:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  name: string;
  id: string;
  ref: any;
  href: string;
  tabIndex?: number;
}

export const ConsentCheckbox: React.FC<Props> = React.forwardRef(
  ({ title, isError, isTouched, name, id, href, tabIndex }, ref) => {
    return (
      <>
        <div className='w-100 h-9 relative mb-1'>
          <div className='custom-control custom-checkbox'>
            <Field
              type='checkbox'
              className='custom-control-input'
              id={id}
              name={name}
              tabIndex={tabIndex}
            />
            <label
              className={
                isError && isTouched && isError
                  ? 'custom-control-label-error text-black leading-tight'
                  : 'custom-control-label text-black leading-tight'
              }
              htmlFor={id}
              id={`${id}-label`}
              ref={ref as any}
            >
              I do accept the{' '}
              <a
                href={href}
                target='_blank'
                rel='noopener noreferrer'
                key={href}
              >
                {title}
              </a>
            </label>
          </div>
          <p
            className={
              isError && isTouched && isError
                ? 'text-red-400 text-left mt-0'
                : 'mt-3'
            }
          >
            {isError && isTouched && isError}
          </p>
        </div>
      </>
    );
  }
);
