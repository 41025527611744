import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { HeaderDropdown } from './HeaderDropdown';
import { Dropdowns } from '../../typings/enums';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

interface Props {
  isDropdownOpen: {
    isOpen: boolean;
    type: string;
  };
  setIsDropdownOpen: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      type: string;
    }>
  >;
  onClickOutside: (event: MouseEvent | TouchEvent) => void;
  curRef: React.MutableRefObject<any>;
}

export const NotificationDropdown: React.FC<Props> = ({
  isDropdownOpen,
  setIsDropdownOpen,
  onClickOutside,
  curRef,
}) => {
  const { hideNotificationsBellIcon } = useFlags();
  useOnClickOutside(curRef, onClickOutside);

  if (hideNotificationsBellIcon) return null;

  return (
    <HeaderDropdown
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      curRef={curRef}
      icon={
        <>
          <span className={cn('counter', 'rounded-circle')}>2</span>
          <FontAwesomeIcon icon={faBell} size='lg' color='gray' />
        </>
      }
      className='mr-4'
      dropdown={Dropdowns.notifications}
    >
      <ul className='list-unstyled'>
        <li className='border-bottom text-secondary'>
          <Link to='/candidates/b7352e1e-df10-4b67-af91-2c20492f42b4/overview'>
            Gaurav Ganesh
          </Link>{' '}
          has finished a task
        </li>
        <li className='border-bottom text-secondary'>
          <Link to='/candidates/b7352e1e-df10-4b67-af91-2c20492f42b4/overview'>
            Ivan Masalov
          </Link>{' '}
          has finished a task
        </li>
      </ul>
    </HeaderDropdown>
  );
};
