import React from 'react';
import { IPosition } from '../../../typings/interfaces';
import { Link } from 'react-router-dom';
import { InviteMemberBtn } from '../../../shared/components/InviteMemberBtn';
import { ReactComponent as GearIcon } from '../../../assets/gear.svg';
import { Participants } from '../../../shared/components/Participants';
import './index.scss';

interface Props {
  position: IPosition;
}

export const PositionHeader: React.FC<Props> = ({ position }) => {
  return (
    <div className='mb-2 position-header-wrap'>
      <div className='position-title'>
        <div className='d-flex flex-wrap justify-content-between align-items-center'>
          <div className='d-flex flex-wrap align-items-center'>
            <div className='text-2xl mr-3'>
              <span className='font-weight-bold'>{position.title} </span>/
              <span> {position.team.title}</span>
            </div>
            <div className='mr-3'>
              <Link
                className='bg-transparent border-0 d-flex align-items-center'
                to={`/position/${position.uuid}/task`}
              >
                <GearIcon className='mr-2' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='participants-list'>
        <Participants position={position} className='mr-2' />
        <InviteMemberBtn position={position} />
      </div>
    </div>
  );
};
