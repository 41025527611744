import {
  SHOW_SLIDE_MENU,
  HIDE_SLIDE_MENU,
  SlideMenuState,
  SlideMenuActionTypes,
} from '../actions/types';

export const initialState: SlideMenuState = {
  isShown: false,
};

export const slideMenuReducer = (
  state = initialState,
  action: SlideMenuActionTypes
): SlideMenuState => {
  switch (action.type) {
    case SHOW_SLIDE_MENU:
      return { ...state, isShown: true };
    case HIDE_SLIDE_MENU:
      return { ...state, isShown: false };
    default:
      return state;
  }
};
