import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Header } from '../../containers/CandidatesContainer/Header';
import { useDispatch } from 'react-redux';
import { Container, Button } from 'react-bootstrap';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import {
  getNotifications,
  clearNotifications,
} from '../../actions/notificationsActions';
import { Loader } from '../components/Loader';
import { getPositions } from '../../actions/positionsActions';
import {
  getPositionCandidates,
  clearCandidates,
} from '../../actions/candidatesActions';
import { RootState } from '../../reducers';
import { ISolution, FormatedNotification } from '../../typings/interfaces';
import { Search } from '../components/Search';
import { KeyActivitiesGrid } from '../components/KeyActivitiesGrid';
import { generateKeyActivityEvent } from '../../util/generateKeyActivityEvent';
import {
  candidatesSelector,
  pendingCandidatesSelector,
  userRoleSelector,
  postionsIdsSelector,
  notificationsSelector,
  pendingNotificationsSelector,
  errorNotificationsSelector,
} from '../../selectors';

export const KeyActivitiesExpand = () => {
  const dispatch = useDispatch();
  const [filtered, setFiltered] = useState<FormatedNotification[] | undefined>(
    undefined
  );
  const [isPending, setIsPending] = useState<boolean>(false);
  const positions = useShallowEqualSelector(
    (state: RootState) => state.positionsReducer.positions
  );
  const positionsIds = useShallowEqualSelector((state) =>
    postionsIdsSelector(state)
  );

  const notifications = useShallowEqualSelector(notificationsSelector);
  const pendingNotifications = useShallowEqualSelector(
    pendingNotificationsSelector
  );
  const errorNotifications = useShallowEqualSelector(
    errorNotificationsSelector
  );

  const candidates = useShallowEqualSelector(candidatesSelector);
  const pendingCandidates = useShallowEqualSelector(pendingCandidatesSelector);

  const userRole = useShallowEqualSelector(userRoleSelector);

  const formatedNotifications = useMemo(() => {
    return notifications?.items.map((notification) => {
      const candidate = candidates?.[notification.rel_position_pk]?.find(
        (candidate: ISolution) =>
          candidate.candidate.user.uuid === notification.rel_user_pk
      );

      return {
        candidate,
        event: generateKeyActivityEvent(notification, candidate, 'page'),
        position: {
          title: candidate?.position.title,
        },
        date_created: notification.date_created,
        uuid: notification.uuid,
      };
    });
  }, [notifications, candidates]);

  useEffect(() => {
    dispatch(getPositions());
  }, [dispatch]);

  useEffect(() => {
    positions?.forEach((position) => {
      dispatch(getPositionCandidates(position.uuid));
    });
  }, [positions, dispatch]);

  useEffect(() => {
    positionsIds &&
      !notifications &&
      dispatch(
        getNotifications(
          {
            rel_position_pk: positionsIds,
          },
          userRole!
        )
      );
  }, [dispatch, positionsIds, userRole, notifications]);

  useEffect(() => {
    return () => {
      dispatch(clearNotifications());
      dispatch(clearCandidates());
    };
  }, [dispatch]);

  const loadMoreNotifications = useCallback(async () => {
    setIsPending(true);
    await dispatch(
      getNotifications(
        {
          rel_position_pk: positionsIds!,
          page: notifications!.page + 1,
        },
        userRole!
      )
    );
    setIsPending(false);
  }, [notifications, positionsIds, userRole, dispatch]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let currentList = [];
      let newList: any;
      if (formatedNotifications) {
        if (e.target.value !== '') {
          currentList = formatedNotifications;

          newList = currentList.filter((listItem: any) => {
            const lc = `${listItem.candidate?.candidate.first_name.toLowerCase()} ${listItem.candidate?.candidate.last_name.toLowerCase()} ${
              listItem.position?.title
            }`.toLocaleLowerCase();

            const filter = e.target.value.toLowerCase();
            return lc.includes(filter);
          });
        } else {
          newList = undefined;
        }
      }

      setFiltered(newList);
    },
    [formatedNotifications]
  );

  return (
    <Container fluid className='pt-3 pb-8'>
      <Header backBtnTitle='To Dashboard' />
      <div className='d-flex align-items-center w-100 justify-content-between'>
        <div className='d-flex align-items-start'>
          <div className='text-2xl font-weight-bold px-2'>Key Activities</div>
          {!pendingNotifications && (
            <div className='text-xs text-gray-600'>{notifications?.total}</div>
          )}
        </div>
        <Search
          handleChange={handleChange}
          placeholder='Search by name, position'
        />
      </div>
      <div>
        {errorNotifications && errorNotifications}
        {(filtered || formatedNotifications) && pendingCandidates.length === 0 && (
          <>
            <KeyActivitiesGrid
              notifications={filtered || formatedNotifications}
            />
            {notifications &&
              notifications.items.length < notifications.total &&
              !isPending && (
                <div className='mt-2 text-center'>
                  <Button
                    variant='outline-dark'
                    className='text-sm px-2 w-100'
                    onClick={loadMoreNotifications}
                    style={{ maxWidth: '280px' }}
                  >
                    Load More
                  </Button>
                </div>
              )}
          </>
        )}
        {(pendingNotifications || pendingCandidates.length > 0) && <Loader />}
      </div>
    </Container>
  );
};
