import { getCookie } from './getCookie';
import { setCookie } from './setCookie';
import { getEnv } from './getEnv';

export const saveTokensCookies = async (json: any) => {
  const env = getEnv();
  const keepSigned = getCookie(`keep_signin_${env}`);
  const maxAge = !keepSigned ? 60 * 60 * 2 : 60 * 60 * 24 * 30;

  setCookie(`access_token_${env}`, json.access_token, maxAge);
  setCookie(`refresh_token_${env}`, json.refresh_token, maxAge);
};
