import React from 'react';
import { ErrorPage } from './ErrorPage';
import { ReactComponent as Image404 } from '../../../assets/404.svg';

export const Page404: React.SFC = () => {
  return (
    <ErrorPage
      title='Oh no! We couldn’t find the page you were looking for'
      text='However here are some options that might help you get back on track:'
    >
      <div className='text-center mb-10'>
        <Image404 />
      </div>
    </ErrorPage>
  );
};
