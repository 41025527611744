import React from 'react';
import cn from 'classnames';
import { BlockTitle } from '../../components/BlockTitle';

interface Props {
  category?: string;
  title: string;
  text: JSX.Element | string;
  additionalContent?: JSX.Element;
  imagePath: string;
  className?: string;
}

export const BlockImgRight: React.FC<Props> = ({
  category,
  title,
  additionalContent,
  imagePath,
  text,
  className,
}) => {
  return (
    <div className={cn('pb-2', [className])}>
      <div className='d-flex flex-column flex-grow-1 flex-lg-row justify-content-lg-between'>
        <div className='lg:w-5/12 pr-lg-10 mb-8'>
          {category && <BlockTitle title={category} color='primary' />}
          <div className='text-2-5xl lh-32 md:text-4-6xl font-weight-bold md:leading-thin mb-2'>
            {title}
          </div>
          <div className='md:text-xl md:lh-32 mb-4'>{text}</div>
          {additionalContent}
        </div>
        <div className='lg:w-7/12'>
          <img
            src={imagePath}
            alt={title}
            className='d-block w-100 d-block rounded-lg'
            // style={{ boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.05)' }}
          />
        </div>
      </div>
    </div>
  );
};
