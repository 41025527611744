import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { Stages } from '../../typings/enums';
import './PipelineItem.scss';

interface Props {
  type: string;
  title: string;
  positionId: string;
  needsRecruiterReview?: undefined | boolean;
  stageType?: string;
  length: number | undefined;
}

export const PipelineItem: React.SFC<Props> = ({
  type,
  title,
  positionId,
  needsRecruiterReview,
  stageType,
  length,
}) => {
  let history = useHistory();

  const handleClick = ({ type }: { type: string }) => {
    if (stageType === 'in_progress' || stageType === 'reviewed') {
      history.push(`/position/${positionId}/${stageType}/${type}`);
    } else {
      history.push(`/position/${positionId}/${type}`);
    }
  };

  return (
    <button
      className={cn(
        'bg-white',
        'py-1',
        'border-0',
        'rounded-lg',
        'px-4',
        'rounded-lg',
        'square-60',
        'mb-2',
        'flex-shrink-0',
        'w-100'
      )}
      onClick={() =>
        handleClick({
          type,
        })
      }
      disabled={
        (type === Stages.ACCEPTED && needsRecruiterReview) || length === 0
      }
    >
      <div className={cn('d-flex')}>
        <div
          className={cn(
            'text-4-7xl',
            'font-bebas',
            'position-relative',
            'leading-none',
            {
              'text-orange-500': type === Stages.EXPIRING,
              'text-success':
                type === Stages.ACCEPTED || type === Stages.PROCESSED,
              'opacity-50': type === Stages.ACCEPTED && needsRecruiterReview,
            }
          )}
          data-testid={`${type}-qty`}
        >
          {length}
        </div>
      </div>

      <div
        className={cn('mb-0', 'leading-normal', 'text-left', {
          'opacity-50': type === Stages.ACCEPTED && needsRecruiterReview,
        })}
      >
        {title}
      </div>
    </button>
  );
};
