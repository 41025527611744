import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import cn from 'classnames';

export const SinglePlatform = () => {
  return (
    <div className='pb-8 pb-md-20 single-platform'>
      <div className='text-warning text-xs md:text-sm lh-24 mb-4'>
        {'Single Platform for all Parties'.toUpperCase()}
      </div>
      <Row className='justify-content-between'>
        {SECTIONS.map(({ title, text, link }, i) => (
          <Col
            xs={12}
            md={6}
            key={title}
            className={cn('mb-8 mb-md-0', {
              'md:border-r': i === 0,
              'pr-md-12': i === 0,
              'pl-md-12': i === 1,
            })}
          >
            <p className='text-blue-100 text-2xl lh-32 mb-2'>{title}</p>
            <p className='text-silver text-2xl lh-32 mw-md-400'>{text}</p>
            <Button
              as={Link}
              to={link}
              className='rounded-lg font-weight-bold text-13 d-flex align-items-center justify-content-center h-10'
              style={{
                maxWidth: '150px',
              }}
            >
              Learn More
            </Button>
          </Col>
        ))}
        <Col xs={12} md={6}></Col>
      </Row>
    </div>
  );
};

const SECTIONS = [
  {
    title: 'Hiring Teams',
    text: 'Learn how Sfeeda helps to provide deep code analysis in 10 mins ',
    link: '/hiring_teams',
  },
  {
    title: 'Recruiters',
    text:
      'Learn how to shortlist the best software developers for tech interviews',
    link: '/recruiters',
  },
];
