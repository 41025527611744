import React from 'react';
import { ReactComponent as TipIcon } from '../../../assets/tip.svg';

interface Props {
  text: string;
}

export const Tip: React.FC<Props> = ({ text }) => {
  return (
    <div className='md:text-xl mb-2 d-flex align-items-start'>
      <div className='d-flex align-items-center justify-content-center mr-2 pt-2'>
        <TipIcon />
      </div>{' '}
      {text}
    </div>
  );
};
