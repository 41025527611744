import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CustomSwitch } from '../../shared/components/CustomSwitch';
import { IEmployee } from '../../typings/interfaces';
import { updateEmployees } from '../../actions/teamsActions';
interface Props {
  teamId: string;
  curTeamEmployees: IEmployee[];
  initialState: boolean;
}

const getEmployeesIds = (employees: IEmployee[]) => {
  return employees.reduce((acc: string[], employee: IEmployee) => {
    acc.push(employee.employee.uuid);
    return acc;
  }, []);
};

export const ShowNamesToggle: React.FC<Props> = ({
  teamId,
  curTeamEmployees,
  initialState,
}) => {
  const dispatch = useDispatch();

  const [showNames, setShowNames] = useState(initialState);

  return (
    <div>
      <div className='text-sm lh-24'>Hide candidate names for reviewers</div>
      <CustomSwitch
        onChange={() => {
          dispatch(
            updateEmployees(
              teamId,
              getEmployeesIds(curTeamEmployees),
              !showNames
            )
          );
          setShowNames(!showNames);
        }}
        isChecked={!showNames}
        name='switch'
        className='justify-content-end'
      />
    </div>
  );
};
