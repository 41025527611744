import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getChallengesCategories } from '../../../actions/challengesActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { FormikErrors, Field, FormikTouched, FormikValues } from 'formik';
import { Form } from 'react-bootstrap';
import { SelectField } from '../../../shared/components/SelectField';
import { TextField } from '../../../shared/components/TextField';
import { IChallengeCategory } from '../../../typings/interfaces';

import cn from 'classnames';

interface Props {
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  values: FormikValues;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
}

const LEVELS = ['Trainee', 'Junior', 'Middle', 'Senior', 'Team Lead'];

export const CreatePositionForm: React.SFC<Props> = ({
  errors,
  touched,
  values,
  setFieldTouched,
}) => {
  const dispatch = useDispatch();
  const categories = useShallowEqualSelector(
    (state): IChallengeCategory[] => state.challengesReducer.categories
  );
  useEffect(() => {
    dispatch(getChallengesCategories());
  }, [dispatch]);
  return (
    <>
      <Form.Group className='mb-0 flex-grow-1'>
        <Form.Label className='text-sm mb-1'>Name:</Form.Label>
        <TextField name='name' isError={errors.name} isTouched={touched.name} />
      </Form.Group>
      <Form.Label className='text-sm mb-1'>Type:</Form.Label>
      {categories.length > 0 ? (
        <Field
          name='categoyId'
          component={SelectField}
          setFieldTouched={setFieldTouched}
          selectOptions={categories}
          selectValueField='id'
          selectLabelField='title'
          selectPlaceholder='choose type'
          className='text-xl h-10 md:h-16'
        />
      ) : null}

      <div
        className={
          errors.categoyId && touched.categoyId && errors.categoyId
            ? 'text-danger my-0'
            : 'mb-3'
        }
      >
        {errors.categoyId && touched.categoyId && errors.categoyId}
      </div>
      {LEVELS.map((levelsItem: any) => (
        <label
          className={cn(
            'inline',
            'border',
            'mr-2',
            'rounded-pill',
            'py-1',
            'px-4',
            'my-1',
            'cursor-pointer',
            'position-relative',
            { 'border-blue-800': values.level !== levelsItem },
            { 'bg-blue-700': values.level === levelsItem },
            { 'border-blue-300': values.level === levelsItem },
            { 'text-white': values.level === levelsItem }
          )}
          key={levelsItem}
          htmlFor={levelsItem}
        >
          <Field
            type='radio'
            value={levelsItem}
            id={levelsItem}
            name='level'
            checked={values.level === levelsItem}
            className='position-absolute inset-0 w-100 h-100 opacity-0 cursor-pointer'
            style={{ zIndex: 100 }}
          />
          {levelsItem}
        </label>
      ))}
      <div
        className={
          errors.level && touched.level && errors.level
            ? 'text-danger my-0 mb-3'
            : 'mb-3'
        }
      >
        {errors.level && touched.level && errors.level}
      </div>
    </>
  );
};
