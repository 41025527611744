import React, { useState } from 'react';
import { ITracker } from '../../../typings/interfaces';
import { Collapse } from 'react-bootstrap';
import { AcceptanceItemDetails } from './AcceptanceItemDetails';
import { TicketInfo } from '../TicketInfo';
import { IAcceptance } from '../../../typings/interfaces';

interface Props {
  tracker: ITracker;
  acceptance?: IAcceptance[];
  edge?: any;
  withVisibility: boolean;
}

enum tabsNames {
  result = 'result',
  desc = 'desc',
}

export const Ticket: React.FC<Props> = ({
  tracker,
  acceptance,
  withVisibility,
}) => {
  const [isOpen] = useState(true);
  const [activeTab, setActiveTab] = useState<tabsNames>(tabsNames.result);
  const acceptanceTests =
    acceptance?.filter((acceptanceItem) => {
      if (withVisibility)
        return acceptanceItem.compulsory && acceptanceItem.is_visible;
      return acceptanceItem.compulsory;
    }) || [];

  const edgeCasesTests =
    acceptance?.filter((acceptanceItem) => {
      if (withVisibility)
        return !acceptanceItem.compulsory && acceptanceItem.is_visible;
      return !acceptanceItem.compulsory;
    }) || [];

  return (
    <div className='p-4 border rounded-lg bg-white d-flex flex-column mb-4 position-relative'>
      <div>
        <div className='px-0 text-center w-100 '>
          <div className='d-flex justify-content-between w-100 align-items-center mb-2'>
            <div className='text-lg font-weight-bold lg:w-10/12'>
              {tracker.ticket.uid} {tracker.ticket.title}
            </div>
            <div className='d-flex align-items-center'>
              <div className='text-lg'>{tracker.ticket.story_points}SP</div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column justify-content-between flex-grow-1'>
          <div className='flex-grow-1'>
            <Collapse in={isOpen}>
              <div id={`collapse-${tracker.ticket.id}`}>
                <div className='d-flex mb-2'>
                  {[tabsNames.result, tabsNames.desc].map((tab) => (
                    <button
                      className={`d-block leading-none mr-2 navlink text-sm py-2 bg-transparent border-right-0 border-left-0 border-top-0 ${
                        activeTab === tab ? 'active' : ''
                      } focus:outline-none`}
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab[0].toUpperCase() + tab.slice(1)}
                    </button>
                  ))}
                </div>
                <div>
                  {activeTab === tabsNames.result ? (
                    <div>
                      {acceptance &&
                      acceptanceTests.length + edgeCasesTests.length > 0 ? (
                        <>
                          {acceptanceTests.length > 0 && (
                            <AcceptanceItemDetails
                              acceptance={acceptanceTests}
                              title='Acceptance criteria'
                            />
                          )}
                          {edgeCasesTests.length > 0 && (
                            <AcceptanceItemDetails
                              acceptance={edgeCasesTests}
                              title='Edge cases'
                            />
                          )}
                        </>
                      ) : (
                        <div className='text-left'>
                          No automaticaly tested acceptance criteria.{' '}
                        </div>
                      )}
                    </div>
                  ) : null}
                  {activeTab === tabsNames.desc ? (
                    <div>
                      {tracker.ticket.sections.map((section) => {
                        return (
                          <div key={section.id}>
                            <div className='font-weight-bold mb-1'>
                              {section.title}
                            </div>
                            <TicketInfo body={section.body} />
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};
