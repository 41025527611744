import { RootState } from '../reducers';
import { Invite } from '../typings/interfaces';
import { createSelector } from 'reselect';
import { UserRoles } from '../typings/enums';

export const inviteSelector = (state: RootState) => state.invitesReducer.invite;
export const invitesSelector = (state: RootState) =>
  state.invitesReducer.invites;
export const errorInviteSelector = (state: RootState) =>
  state.invitesReducer.errorInvite;
export const pendingInviteSelector = (state: RootState) =>
  state.invitesReducer.pendingInvite;
export const errorSendInviteSelector = (state: RootState) =>
  state.invitesReducer.errorSendInvite;
export const pendingInvitesSelector = (state: RootState) =>
  state.invitesReducer.pendingInvites;

export const selectConsumedInvitesForPosition = createSelector(
  [invitesSelector, (_: any, positionId: string) => positionId],
  (invites, positionId) =>
    invites?.filter(
      (invite: Invite) =>
        invite?.data?.position_id === positionId && invite.consumed_by
    )
);

export const selectCandidtesNotConsumedInvites = createSelector(
  [invitesSelector, (_: any, positionId: string) => positionId],
  (invites, positionId) =>
    invites?.filter(
      (invite: Invite) =>
        invite?.data?.position_id === positionId &&
        !invite.consumed_by &&
        UserRoles.CANDIDATE
    )
);
