import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToElement = () => {
  let location = useLocation();
  const { hash } = location;

  const [refs, setRefs] = useState<any>({});
  const setRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setRefs((prevState: any) => {
        return {
          ...prevState,
          [`${node.dataset.refkey}`]: node,
        };
      });
    }
  }, []);

  const scrollToElement = (elRefKey: string) => {
    const element = refs[elRefKey];
    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLinkClick = (e: React.MouseEvent) => {
    const link = (e.target as HTMLButtonElement).getAttribute('data-scrollto');
    if (link) scrollToElement(link);
  };

  useEffect(() => {
    if (hash) {
      const element: HTMLElement | null = document.getElementById(
        hash.toString().slice(1)
      );
      if (!element) return;
      element.scrollIntoView();
    }
  }, [hash]);

  return {
    setRef,
    handleLinkClick,
  };
};
