import React from 'react';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { UserRoles, Stages } from '../../typings/enums';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { NotesCount } from './NotesCount';
import { CommentsCount } from '../../shared/components/CommentsCount';
import { isComment } from '../../typings/typeGuards';
import { DecisionBtnBlock } from '../../reviewer/components/DecisionBtnBlock';
import { VoteLike } from '../../shared/components/Likes/VoteLike';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ISolution } from '../../typings/interfaces';
import cn from 'classnames';
import { notesSelector } from '../../selectors';
import { filterSolutionByStage } from '../../util/filterSolutionByStage';

interface Props {
  className?: string;
  userRole: string | undefined;
  codeLink: string;
  candidate: ISolution;
}

export const Tabs: React.FC<Props> = ({
  className,
  userRole,
  codeLink,
  candidate,
}) => {
  const { candidateId, positionId } = useParams();
  const { showLikeDeslikeFeature } = useFlags();

  const notes = useShallowEqualSelector(notesSelector);

  const comments = notes?.[positionId]?.filter((note) => isComment(note)) || [];

  const LINKS =
    userRole === UserRoles.REVIEWER
      ? [
          {
            path: `/position/${positionId}/candidate/${candidateId}/overview`,
            title: 'overview',
          },
          {
            path: `/position/${positionId}/candidate/${candidateId}/repo`,
            title: 'repo',
          },
          {
            path: codeLink,
            title: 'code',
          },
          {
            path: `/position/${positionId}/candidate/${candidateId}/checks`,
            title: 'checks',
          },
          {
            path: `/position/${positionId}/candidate/${candidateId}/notes`,
            title: 'notes',
          },
        ]
      : [
          {
            path: `/position/${positionId}/candidate/${candidateId}`,
            title: 'summary',
          },
          {
            path: codeLink,
            title: 'code',
          },
        ];

  const renderComments = (link: { path: string; title: string }) => {
    if (link.title !== 'code' || comments.length === 0) return null;

    return <CommentsCount qty={comments.length} size='xs' className='ml-1' />;
  };
  const renderNotes = (link: { path: string; title: string }) => {
    if (link.title !== 'notes' || notes?.[positionId]?.length === 0)
      return null;

    return <NotesCount qty={notes?.[positionId]?.length || 0} />;
  };

  if (!candidate) return null;

  return (
    <div className='d-flex justify-content-between'>
      <div className='d-flex'>
        {LINKS.map((link) => (
          <NavLink
            to={link.path}
            className={cn(
              'd-flex align-items-center leading-none mr-6 navlink text-sm py-2',
              [className]
            )}
            key={link.title}
            exact={!link.path.includes('code')}
          >
            {link.title.toUpperCase()}
            {userRole === UserRoles.RECRUITER && renderComments(link)}
            {userRole === UserRoles.REVIEWER && renderNotes(link)}
          </NavLink>
        ))}
      </div>
      <div
        className={cn('pb-4', {
          'd-flex align-items-center': showLikeDeslikeFeature,
        })}
      >
        {showLikeDeslikeFeature && candidate.candidate.user?.uuid && (
          <VoteLike solutionId={String(candidate.id)} />
        )}
        {userRole === UserRoles.REVIEWER &&
          filterSolutionByStage(candidate, userRole, Stages.SUBMITTED) && (
            <DecisionBtnBlock />
          )}
      </div>
    </div>
  );
};
