import {
  AcceptanceTypesConstants,
  AcceptanceState,
  AcceptanceActionsTypes,
} from '../actions/types';

const initialState: AcceptanceState = {
  candidateAcceptance: undefined,
  pendingCandidateAcceptance: false,
  errorCandidateAcceptance: '',
};

export const acceptanceReducer = (
  state = initialState,
  action: AcceptanceActionsTypes
): AcceptanceState => {
  switch (action.type) {
    case AcceptanceTypesConstants.GET_ACCEPTANCE_BY_POSITION:
      return {
        ...state,
        candidateAcceptance: action.payload,
        pendingCandidateAcceptance: false,
        errorCandidateAcceptance: '',
      };
    case AcceptanceTypesConstants.PENDING_ACCEPTANCE_BY_POSITION:
      return {
        ...state,
        pendingCandidateAcceptance: true,
      };
    case AcceptanceTypesConstants.ERROR_ACCEPTANCE_BY_POSITION:
      return {
        ...state,
        pendingCandidateAcceptance: false,
        errorCandidateAcceptance: action.payload,
      };
    default:
      return state;
  }
};
