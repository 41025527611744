import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from './Logo';
import { SignInBtn } from './SignInBtn';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { Links } from './Header/Links';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import cn from 'classnames';
import { userSelector } from '../../selectors';

interface Props {
  handleLinkClick?: (
    e: React.MouseEvent<Element, MouseEvent>
  ) => void | undefined;
  darkText?: boolean;
  links: any[];
}

export const NavBar: React.FC<Props> = ({
  handleLinkClick,
  darkText,
  links,
}) => {
  const [isMatchMediaQuery] = useMatchMedia(992);
  const user = useShallowEqualSelector(userSelector);

  return (
    <>
      <div className='d-flex justify-content-between w-100 align-items-center mb-lg-10 mb-4'>
        <div>
          <Link to='/'>
            <Logo height={32} />
          </Link>
        </div>
        {isMatchMediaQuery && (
          <div className='flex-grow-1 mr-lg-2 text-right'>
            <Links
              handleLinkClick={handleLinkClick}
              links={links}
              darkText={darkText}
            />
          </div>
        )}
        <SignInBtn
          className={cn({ 'border hover:border-blue-500 px-4': !darkText })}
          user={user}
          dark={darkText}
        />
      </div>
      {!isMatchMediaQuery && (
        <div className='mb-6 container-fuild'>
          <hr
            className='w-100 mt-0 mb-2'
            style={{
              borderColor: !darkText ? '#F8FBFF' : '#000000',
              opacity: 0.1,
            }}
          />
          <div
            className='d-flex justify-content-between'
            style={{ overflowX: 'auto' }}
          >
            <Links
              handleLinkClick={handleLinkClick}
              links={links}
              darkText={darkText}
            />
          </div>
        </div>
      )}
    </>
  );
};
