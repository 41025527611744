import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as MailIcon } from '../../../assets/mail.svg';

interface Props {
  subscriber: string | undefined;
}

export const ThankYouBlock: React.FC<Props> = ({ subscriber }) => {
  return (
    <div
      className='mx-auto'
      style={{ maxWidth: '430px' }}
      id='subscribe_thankyou'
    >
      <div className='mb-6 text-center'>
        <MailIcon />
      </div>
      <div className='text-4-6xl font-weight-bold text-center mx-auto leading-thin mb-4'>
        Thanks, {subscriber && subscriber}!
      </div>
      <div
        className='text-2-5xl text-center mb-6'
        style={{ lineHeight: '40px' }}
      >
        We will send you invite shortly
      </div>
      <div className='text-center'>
        <Link
          to='/'
          className='text-2xl py-2 mt-3 mb-4 rounded-lg btn btn-warning'
        >
          Go Homepage
        </Link>
      </div>
    </div>
  );
};
