import React from 'react';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';
import { Row, Col, Container } from 'react-bootstrap';

export const HowItWorks = () => {
  return (
    <div className='pb-lg-12 pb-8'>
      <p className='text-27 text-blue-100 d-lg-none'>How it works</p>
      <div className='d-none d-lg-block text-warning text-sm lh-24 mb-4'>
        {'How it works'.toUpperCase()}
      </div>
      <div className='d-lg-flex justify-content-between'>
        <Container fluid='lg' className='px-lg-0'>
          <Row>
            {STEPS.map(({ title, text }, i: number) => (
              <Col
                xs={12}
                lg={3}
                className={cn(
                  'd-lg-block d-flex text-blue-100 mb-4 pb-6 lg:border-b-0 px-lg-4 px-0',
                  {
                    'border-bottom': i !== STEPS.length - 1,
                  }
                )}
                key={uuid()}
              >
                <div className='text-3xl lh-32 lg:text-4-7xl mr-3 mr-lg-0 mb-lg-6'>
                  {i + 1}
                </div>
                <div>
                  <div className='font-weight-bold mb-2 lg:text-xl'>
                    {title}
                  </div>
                  <div className='lg:text-silver lg:text-xl'>{text}</div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

const STEPS = [
  {
    title: 'Invite candidates',
    text: 'Just send the link, we’ll take care of everything else',
  },
  {
    title: 'Manage progress',
    text: 'Identify who is doing their best and who decided to drop out',
  },
  {
    title: 'Review',
    text:
      'See results directly on Sfeeda with no additional tools, all in about 10 mins',
  },
  {
    title: 'Make a decision',
    text:
      'Based upon your engineers’ feedback directly inform candidates from Sfeeda',
  },
];
