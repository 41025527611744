import { NotesTypesConstants, NotesActionsTypes } from './types';
import { Action } from 'redux';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { INote, IComment } from '../typings/interfaces';
import { apiFetch } from '../util/apiFetch';
import { showAlert } from './alertActions';
import { AlertType } from '../typings/enums';
import { stringify } from 'query-string';

export const getNotes = (
  positionId: string,
  userId?: string
): ThunkAction<void, RootState, NotesActionsTypes, Action<string>> => async (
  dispatch
) => {
  try {
    dispatch({
      type: NotesTypesConstants.PEDNING_NOTES,
    });

    const response = await apiFetch(
      'company',
      `/v1/note/?${stringify(
        { position_id: positionId, user_id: userId },
        { skipNull: true }
      )}`
    );

    if (response.error) throw new Error(response.error);

    let notes = [];
    if (response.data instanceof Array) {
      notes = response.data.filter((note: INote) => {
        return note.created_by?.uuid !== userId;
      });
    }

    dispatch({
      type: NotesTypesConstants.GET_NOTES,
      payload: { notes, positionId },
    });
  } catch (err) {
    dispatch({
      type: NotesTypesConstants.ERROR_NOTES,
      payload: err.message,
    });
  }
};

export const createNote = (
  noteData: INote | IComment
): ThunkAction<void, RootState, NotesActionsTypes, Action<string>> => async (
  dispatch
) => {
  try {
    dispatch({ type: NotesTypesConstants.PENDING_NOTE_UPDATE });

    const response = await apiFetch('company', '/v1/note/', 'POST', noteData);

    if (response.error) throw new Error(response.error);

    dispatch({
      type: NotesTypesConstants.CREATE_NOTE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: NotesTypesConstants.ERROR_CREATE_NOTE,
      payload: err.message,
    });
    dispatch(showAlert({ message: err.message, type: AlertType.fail }));
    throw new Error(err.message);
  }
};

export const updateNote = (
  noteId: number,
  body: any
): ThunkAction<
  Promise<number | void>,
  RootState,
  NotesActionsTypes,
  Action<string>
> => async (dispatch) => {
  try {
    dispatch({ type: NotesTypesConstants.PENDING_NOTE_UPDATE });

    const response = await apiFetch(
      'company',
      `/v1/note/${noteId}/`,
      'PUT',
      body
    );
    if (response.error) throw new Error(response.error);

    dispatch({
      type: NotesTypesConstants.UPDATE_NOTE,
      payload: response.data,
    });

    return response.status;
  } catch (err) {
    dispatch({
      type: NotesTypesConstants.ERROR_CREATE_NOTE,
      payload: err.message,
    });
    dispatch(showAlert({ message: err.message, type: AlertType.fail }));
    throw new Error(err.message);
  }
};

export const clearNotes = () => ({
  type: NotesTypesConstants.CLEAR_NOTES,
});
