import {
  ChecksActionsTypes,
  SET_CHECKS,
  PENDING_CHECKS,
  ERROR_CHECKS,
  CLEAR_CHECKS,
  ChecksState,
} from '../actions/types';

const initialState: ChecksState = {
  checks: undefined,
  pendingChecks: false,
  errorChecks: '',
};

export const checksReducer = (
  state = initialState,
  action: ChecksActionsTypes
): ChecksState => {
  switch (action.type) {
    case SET_CHECKS:
      return {
        ...state,
        checks: action.payload,
        pendingChecks: false,
        errorChecks: '',
      };
    case PENDING_CHECKS:
      return {
        ...state,
        pendingChecks: true,
      };
    case ERROR_CHECKS:
      return {
        ...state,
        pendingChecks: false,
        errorChecks: action.payload,
      };
    case CLEAR_CHECKS:
      return {
        ...state,
        checks: undefined,
        pendingChecks: false,
        errorChecks: '',
      };
    default:
      return state;
  }
};
