import { IBlobChanges } from '../../typings/interfaces';

export enum GitActionsConstants {
  GET_FILES_DIFF = 'GET_FILES_DIFF',
  ERROR_FILES_DIFF = 'ERROR_FILES_DIFF',
  PENDING_FILES_DIFF = 'PENDING_FILES_DIFF',
  CLEAR_CANDIDATE_FILES_CHANGED = 'CLEAR_CANDIDATE_FILES_CHANGED',
  GET_REPO_INFO = 'GET_REPO_INFO',
  PENDING_REPO_INFO = 'PENDING_REPO_INFO',
  ERROR_REPO_INFO = 'ERROR_REPO_INFO',
  GETGITFOLDERS = 'GETGITFOLDERS',
  GET_GIT_BRANCHES = 'GET_GIT_BRANCHES',
  PENDING_GIT_BRANCHES = 'PENDING_GIT_BRANCHES',
  CLEAR_GIT_BRANCHES = 'CLEAR_GIT_BRANCHES',
  CLEAR_GIT_CONTENT = 'CLEAR_GIT_CONTENT',
  CLEAR_GIT_FILE = 'CLEAR_GIT_FILE',
  GET_GIT_CONTENT = 'GET_GIT_CONTENT',
  GET_GIT_FILE = 'GET_GIT_FILE',
}

export interface RepoInfoTypes {
  demo_url: string;
  position_id: string
  repo_url: string
  user_id: string
  uuid: string
}
export interface GitContentState {
  filesDiff: IBlobChanges[] | undefined;
  pendingFilesDiff: boolean;
  errorFilesDiff: string;
  filesChangedLoaded: boolean;
  repoInfo: RepoInfoTypes[] | undefined;
  pendingRepoInfo: boolean;
  errorRepoInfo: string;
  gitContent: GitContentTypes | null;
  gitFile: any;
  gitBranches: any;
  pendingGitBranches: boolean;
}

export interface GitContentTypes {
  commitId: string;
  date: string;
  files: GitFilesTypes[];
  folderPath: string;
  message: string; //'Initial commit by AWS CodeCommit',
  subFolders: GitSubFoldersTypes[];
  subModules: any | [];
  symbolicLinks: any | [];
  treeId: string; // '826723303212d4907a1bc6bb8d89dade494132b5'
}

interface GitFilesTypes {
  absolutePath: string; // '.babelrc',
  blobId: string; // 'd6bf93bd26b6e93a0e70e81acd300f5182190d71',
  fileMode: string; // 'NORMAL'
  relativePath: string; // '.babelrc'
}

interface GitSubFoldersTypes {
  absolutePath: string; // 'src',
  relativePath: string; // 'src',
  treeId: string; // '54012d465e06903bf5f9be8c2d2ae4c2c29136cf'
}

interface RepoInfo {
  items: RepoInfoItems[];
  page: number;
  per_page: number;
  total: number;
}
interface RepoInfoItems {
  content_type_pk: string; // 'feedback'
  date_created: string; // '2020-08-27T23:49:32'
  meta: string; // '{"is_attached": false, "is_positive": true, "is_sent": false}' json parse require
  object_pk: string; // '12e16c28-27af-4bda-bfb7-2999d631f77e'
  rel_position_pk: string; // 'aceaeea9-2d45-47c3-9152-a6f7d2767ade'
  rel_user_pk: string; // '43d54c76-0c5f-432b-9dfa-c5882df96bdc'
  user_pk: string; // 'a3da4d62-55d2-4293-9ef7-7816dc04d1c8'
  uuid: string; // '7b248d2a-3c6d-4b4a-8177-cb8ceed38d8c'
  verb: string; // 'created'
}

interface GetFilesDiffAction {
  type: typeof GitActionsConstants.GET_FILES_DIFF;
  payload: any;
}

interface ClearCandidateFilesChangedAction {
  type: typeof GitActionsConstants.CLEAR_CANDIDATE_FILES_CHANGED;
}
interface ErrorFilesDiffAction {
  type: typeof GitActionsConstants.ERROR_FILES_DIFF;
  payload: string;
}
interface PendingFilesDiffAction {
  type: typeof GitActionsConstants.PENDING_FILES_DIFF;
}
interface GetRepoInfoAction {
  type: typeof GitActionsConstants.GET_REPO_INFO;
  payload: any;
}
interface PendingRepoInfoAction {
  type: typeof GitActionsConstants.PENDING_REPO_INFO;
}
interface ErrorRepoInfoAction {
  type: typeof GitActionsConstants.ERROR_REPO_INFO;
  payload: string;
}

export interface GetGitFolders {
  type: typeof GitActionsConstants.GETGITFOLDERS;
  payload: any;
}

export interface PendingGitBranchesTypes {
  type: typeof GitActionsConstants.PENDING_GIT_BRANCHES;
  payload: boolean;
}

export interface clearGitBrancheType {
  type: typeof GitActionsConstants.CLEAR_GIT_BRANCHES;
  gitBranches: any;
  pendingGitBranches: boolean;
}

export interface clearGitContentTypes {
  type: typeof GitActionsConstants.CLEAR_GIT_CONTENT;
  gitContent: boolean;
}

export interface ClearGitFileTypes {
  type: typeof GitActionsConstants.CLEAR_GIT_FILE;
  gitFile: boolean;
}

export interface GetGitContentTypes {
  type: typeof GitActionsConstants.GET_GIT_CONTENT;
  payload: any;
}

export interface GetGitBranches {
  type: typeof GitActionsConstants.GET_GIT_BRANCHES;
  payload: any;
  pendingGitBranches: boolean;
}

export interface GetGitFileTypes {
  type: typeof GitActionsConstants.GET_GIT_FILE;
  payload: any;
}

export type gitActionsTypes =
  | PendingRepoInfoAction
  | ErrorRepoInfoAction
  | GetRepoInfoAction
  | GetFilesDiffAction
  | ClearCandidateFilesChangedAction
  | PendingFilesDiffAction
  | ErrorFilesDiffAction
  | GetGitFolders
  | PendingGitBranchesTypes
  | clearGitBrancheType
  | clearGitContentTypes
  | ClearGitFileTypes
  | GetGitContentTypes
  | GetGitBranches
  | GetGitFileTypes;
