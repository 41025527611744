import React, { FC } from 'react';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { IPosition } from '../../typings/interfaces';
import { InProgress } from './InProgress';
import { Reviewing } from './Reviewing';
import { Completed } from './Completed';
import { ToInform } from './ToInform';
import { ReactComponent as CompletedIcon } from '../../assets/completed.svg';
import { InviteMemberBtn } from '../../shared/components/InviteMemberBtn';
import { Participants } from '../../shared/components/Participants';
import { ShowNamesToggle } from './ShowNamesToggle';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Container, Row, Col } from 'react-bootstrap';
import { CardComponent } from '../../shared/components/CardComponent';
import {
  pendingCandidatesSelector,
  employeesSelector,
  selectRecruiterPositionPipeline,
} from '../../selectors';

interface Props {
  position: IPosition;
}

export const RecruiterPosition: FC<Props> = ({ position }) => {
  const { showNamesFeature, showKeyActivities } = useFlags();

  const pipeline = useShallowEqualSelector((state) =>
    selectRecruiterPositionPipeline(state, position.uuid)
  );

  const pendingCandidates = useShallowEqualSelector(pendingCandidatesSelector);
  const curTeamEmployees = useShallowEqualSelector(employeesSelector)?.[
    position.team.uuid
  ];

  return (
    <CardComponent id={`position-${position.uuid}`}>
      <div className='d-flex flex-wrap justify-content-between align-items-center mb-2'>
        <div>
          {!position.task && <CompletedIcon className='mr-2' />}
          <div className='text-lg mr-3 mb-1 lh-24'>
            <span className='font-weight-bold'>{position.title} </span>
            <span> {position.team.title}</span>
          </div>
          <div className='d-flex'>
            <Participants position={position} className='mr-2' />
            <InviteMemberBtn position={position} />
          </div>
        </div>
        {curTeamEmployees &&
          curTeamEmployees.length > 0 &&
          showNamesFeature && (
            <ShowNamesToggle
              teamId={position.team.uuid}
              curTeamEmployees={curTeamEmployees}
              initialState={curTeamEmployees[0].is_names_visible}
            />
          )}
      </div>
      {!position.task ? (
        <div>
          {position.team.title} team now needs to choose the task for
          candindates.
          <br />
          Once it is done you will be able to invite candidates
        </div>
      ) : (
        <Container fluid className='px-0'>
          <Row
            noGutters
            className='align-items-stretched flex-column flex-xl-row'
          >
            <Col xl={showKeyActivities ? 4 : 5} className='xl:mw-350'>
              <InProgress
                pipeline={pipeline}
                pendingCandidates={pendingCandidates}
                position={position}
              />
            </Col>
            <Col className='xl:mw-350'>
              <Reviewing
                pipeline={pipeline}
                pendingCandidates={pendingCandidates}
                positionId={position.uuid}
              />
            </Col>
            <Col className='xl:mw-350'>
              <ToInform
                pipeline={pipeline}
                pendingCandidates={pendingCandidates}
                positionId={position.uuid}
              />
            </Col>
            <Col xl={3} className='xl:mw-350'>
              <Completed
                pipeline={pipeline}
                pendingCandidates={pendingCandidates}
                positionId={position.uuid}
              />
            </Col>
          </Row>
        </Container>
      )}
    </CardComponent>
  );
};
