import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import * as Yup from 'yup';
import { clearSignUpError } from '../../actions/userActions';
import { FormFields } from '../components/FormFields';
import { AgreeCheckbox } from './SubscribeForms/AgreeCheckbox';
import { SubmitBtn } from './SubscribeForms/SubmitBtn';
import { errorSignUpSelector } from '../../selectors';
import { GuestFormTypes } from '../../typings/interfaces/GuestFormTypes';
import { guestSession } from '../../actions/userActions';

enum validationErrors {
  SHORT = 'Too Short(min length 2)',
  LONG = 'Too Long(max length 255)',
  SHORTPASS = 'Too Short(min length 8)',
}

export const ShareSignup = () => {
  const dispatch = useDispatch();
  const errorSignUp = useShallowEqualSelector(errorSignUpSelector);

  const initialValues = {
    company: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    agree: false,
  };

  const SignupSchema = Yup.object().shape({
    company: Yup.string()
      .min(2, validationErrors.SHORT)
      .max(255, validationErrors.LONG)
      .required('Required'),
    firstName: Yup.string()
      .min(2, validationErrors.SHORT)
      .max(255, validationErrors.LONG)
      .required('Required'),
    lastName: Yup.string()
      .min(2, validationErrors.SHORT)
      .max(255, validationErrors.LONG)
      .required('Required'),
    password: Yup.string()
      .min(8, validationErrors.SHORTPASS)
      .max(255, validationErrors.LONG)
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    agree: Yup.bool().oneOf([true], 'Field must be checked'),
  });

  // useEffect(() => {
  //   dispatch(guestCompanyData());
  //   return () => {
  //     dispatch(clearSignUpError());
  //   };
  // }, [dispatch]);

  const fields = {
    company: {
      label: 'Company',
    },
    email: {
      label: 'Email',
    },
    firstName: {
      label: 'First name',
    },
    lastName: {
      label: 'Last name',
    },
    password: {
      label: 'Password',
    },
  };

  const guestSubmit = (values: GuestFormTypes) => {
    dispatch(guestSession(values));
  };

  return (
    <div className='px-10'>
      {errorSignUp ? <p className='text-red-400'>{errorSignUp}</p> : null}
      <p style={{ paddingBottom: 5, fontWeight: 'bold', fontSize: 18 }}>
        Sign up to check the whole solution
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => guestSubmit(values)}
        validationSchema={SignupSchema}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form style={{ minWidth: '335px' }}>
            <FormFields
              errors={errors}
              touched={touched}
              fields={fields}
              size='md'
            />
            <AgreeCheckbox isError={errors.agree} isTouched={touched.agree} />
            <SubmitBtn
              isSubmitting={isSubmitting}
              btnTitle='Sign Up'
              id='sign-up'
              size='sm'
            />
            <div className='text-center mb-6 mt-2 text-sm md:text-base'>
              Absolutely free. No credit card required
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
