import React from 'react';
import { useDispatch } from 'react-redux';

import { ISolution } from '../../../typings/interfaces';
import { Link } from 'react-router-dom';
import { Stages, UserRoles } from '../../../typings/enums';
import { getSolutionStage } from '../../../util/getSolutionStage';
import { setCurCandidate } from '../../../actions/candidatesActions';

interface Props {
  candidate: ISolution;
  userRole: string | undefined;
  handleClear: () => void;
}

export const ResultRow: React.FC<Props> = ({
  candidate,
  userRole,
  handleClear,
}) => {
  const dispatch = useDispatch();
  const category = userRole === UserRoles.REVIEWER ? '/overview' : '';

  const candidateStage = getSolutionStage(candidate, userRole);
  const row = (
    <>
      <div className='w-1/3 d-flex flex-shrink-0'>
        {candidate.candidate.first_name} {candidate.candidate.last_name}
      </div>
      <div className='w-2/3 flex-wrap d-flex justify-content-end pl-5 text-black'>
        <div className='mr-1'>{candidate.position.title} &#9679;</div>
        <div className='mr-1'>{candidate.position.team.title} &#9679;</div>
        <div className='mr-1'>{formatStageTitle(candidateStage)}</div>
      </div>
    </>
  );

  if (!stagesForLink.includes(candidateStage))
    return (
      <div
        key={candidate.candidate.uuid}
        className='d-flex justify-content-between hover:bg-blue-100 p-2 search-item'
      >
        {row}
      </div>
    );
  return (
    <Link
      to={`/position/${candidate.position.uuid}/candidate/${candidate.candidate.uuid}${category}`}
      key={candidate.candidate.uuid}
      className='d-flex justify-content-between hover:bg-blue-100 p-2 search-item'
      onClick={() => {
        handleClear();
        dispatch(setCurCandidate(candidate));
      }}
    >
      {row}
    </Link>
  );
};

const stagesForLink = [
  Stages.SUBMITTED,
  Stages.ACCEPTED,
  Stages.REJECTED,
  Stages.PROCESSED,
  Stages.ARCHIVED,
];

export const formatStageTitle = (candidatesStage: string) => {
  if (candidatesStage === Stages.SUBMITTED) return 'Under Review';
  return candidatesStage[0].toUpperCase() + candidatesStage.slice(1);
};
