import React from 'react';
import cn from 'classnames';
import { ReactComponent as CompletedIcon } from '../../../assets/completed.svg';

interface Props {
  participants: any[];
  setParticipants: React.Dispatch<any>;
}

export const Participants: React.FC<Props> = ({ participants }) => {
  return (
    <>
      {participants && participants.length > 0 ? (
        <div className='mb-3'>
          <div
            className={cn(
              'd-flex',
              'w-100',
              'flex-wrap',
              'justify-content-between',
              'mb-1'
            )}
          >
            <div className='mb-0 text-xs'>Participants:</div>
          </div>
          <div
            className={cn({
              'mb-1': participants.length > 0,
            })}
          >
            {participants.map((participantsItem: any, i: number) => {
              return (
                <div
                  className='d-flex align-items-start w-100 justify-content-between position-relative'
                  key={participantsItem.uuid}
                >
                  <div>
                    {participantsItem.first_name ? (
                      `${participantsItem.first_name} ${
                        participantsItem.last_name && participantsItem.last_name
                      }`
                    ) : (
                      <div>
                        <div>{participantsItem.email}</div>
                        <div className='d-flex align-items-center'>
                          <CompletedIcon className='mr-2' />
                          <span className='text-success text-xs'>
                            Invite sent
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};
