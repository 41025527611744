import React from 'react';
import { capitalize } from '../../../util/capitalize';

interface Props {
  curRole: string;
  activeRole: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RoleBtn: React.FC<Props> = ({
  curRole,
  activeRole,
  setFieldValue,
  setShow,
}) => {
  return (
    <div
      className='text-left cursor-pointer hover:opacity-50'
      onClick={() => {
        setFieldValue('role', curRole);
        setShow(false);
      }}
    >
      {capitalize(curRole)} {activeRole === curRole && '✓'}
    </div>
  );
};
