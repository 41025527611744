import {
  PoliciesActionTypes,
  PoliciesActionsTypesConstants,
  PoliciesState,
} from '../actions/types/policiesActionsTypes';

const initialState: PoliciesState = {
  isCookiesAgreed: false,
  cookiesConsentTimestamp: undefined,
};

export const policiesReducer = (
  state = initialState,
  action: PoliciesActionTypes
): PoliciesState => {
  switch (action.type) {
    case PoliciesActionsTypesConstants.AGREE_COOKIES:
      return {
        ...state,
        isCookiesAgreed: true,
        cookiesConsentTimestamp: action.payload,
      };
    case PoliciesActionsTypesConstants.REFUSE_COOKIES:
      return {
        ...state,
        isCookiesAgreed: false,
        cookiesConsentTimestamp: undefined,
      };
    default:
      return state;
  }
};
