import { getEnv } from './getEnv';
import { deleteCookie } from './deleteCookie';

export const deleteTokens = () => {
  const env = getEnv();
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  deleteCookie(`access_token_${env}`);
  deleteCookie(`refresh_token_${env}`);
  deleteCookie(`keep_signin_${env}`);
};
