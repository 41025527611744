import { ILike } from '../../typings/interfaces';

export enum LikesActionsTypesConstants {
  GET_LIKES_BY_CANDIDATE = 'GET_LIKES_BY_CANDIDATE',
  GET_LIKES_BY_CANDIDATE_POSITION = 'GET_LIKES_BY_CANDIDATE_POSITION',
  GET_LIKES_BY_FEEDBACK = 'GET_LIKES_BY_FEEDBACK',
  GET_LIKES_BY_NOTE = 'GET_LIKES_BY_NOTE',
  CLEAR_LIKES_BY_CANDIDATE = 'CLEAR_LIKES_BY_CANDIDATE',
  CLEAR_LIKES_BY_CANDIDATE_POSITION = 'CLEAR_LIKES_BY_CANDIDATE_POSITION',
  CLEAR_LIKES_BY_FEEDBACK = 'CLEAR_LIKES_BY_FEEDBACK',
  CLEAR_LIKES_BY_NOTE = 'CLEAR_LIKES_BY_NOTE',
  PENDING_LIKES = 'PENDING_LIKES',
  ERROR_LIKES = 'ERROR_LIKES',
}

export interface LikesState {
  likesByCandidate: ILike[] | undefined;
  likesByCandidatePosition: ILike[] | undefined;
  likesByNote: ILike[] | undefined;
  likesByFeedback: ILike[] | undefined;
  pendingLikes: boolean;
  errorLikes: string;
}

interface getLikesAction {
  type:
    | typeof LikesActionsTypesConstants.GET_LIKES_BY_CANDIDATE
    | typeof LikesActionsTypesConstants.GET_LIKES_BY_CANDIDATE_POSITION
    | typeof LikesActionsTypesConstants.GET_LIKES_BY_NOTE
    | typeof LikesActionsTypesConstants.GET_LIKES_BY_FEEDBACK;
  payload: ILike[];
}

interface pendingLikes {
  type: typeof LikesActionsTypesConstants.PENDING_LIKES;
}
interface clearLikesByCandidateAction {
  type: typeof LikesActionsTypesConstants.CLEAR_LIKES_BY_CANDIDATE;
}
interface clearLikesByCandidatePositionAction {
  type: typeof LikesActionsTypesConstants.CLEAR_LIKES_BY_CANDIDATE_POSITION;
}
interface clearLikesByFeedbackAction {
  type: typeof LikesActionsTypesConstants.CLEAR_LIKES_BY_FEEDBACK;
}
interface clearLikesByNoteAction {
  type: typeof LikesActionsTypesConstants.CLEAR_LIKES_BY_NOTE;
}

interface errorLikesAction {
  type: typeof LikesActionsTypesConstants.ERROR_LIKES;
  payload: string;
}

export type LikesActionsTypes =
  | getLikesAction
  | pendingLikes
  | clearLikesByCandidatePositionAction
  | clearLikesByCandidateAction
  | clearLikesByFeedbackAction
  | clearLikesByNoteAction
  | errorLikesAction;
