export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL';
export const HIDE_CONFIRM_MODAL = 'HIDE_CONFIRM_MODAL';
export const INVITEWITHOUTEMAILMODAL = 'INVITEWITHOUTEMAILMODAL'; 

interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: { type: string; title: string };
}
interface HideModalAction {
  type: typeof HIDE_MODAL;
}

export interface InviteWithoutEmailModal {
  type: typeof INVITEWITHOUTEMAILMODAL;
  payload: boolean;
}

export type ModalsActionTypes = ShowModalAction | HideModalAction | InviteWithoutEmailModal;

export interface ModalsState {
  isModalShown: boolean;
  modalType: string | undefined;
  modalTitle: string | undefined;
  modalWithoutEmail: boolean;
}
