import { Invite } from '../../typings/interfaces';

export enum InvitesActionTypesConstants {
  GET_INVITES = 'GET_INVITES',
  PENDING_INVITES = 'PENDING_INVITES',
  ERROR_INVITES = 'ERROR_INVITES',
  CREATE_INVITE = 'CREATE_INVITE',
  ERROR_SEND_INVITE = 'ERROR_SEND_INVITE',
  CLEAR_ERROR_SEND_INVITE = 'CLEAR_ERROR_SEND_INVITE',
  PENDING_INVITE = 'PENDING_INVITE',
  GET_INVITE = 'GET_INVITE',
  ERROR_INVITE = 'ERROR_INVITE',
  CLEAR_INVITE = 'CLEAR_INVITE'
}

interface ClearErrorSendInviteAction {
  type: typeof InvitesActionTypesConstants.CLEAR_ERROR_SEND_INVITE;
}
interface GetInvitesAction {
  type: typeof InvitesActionTypesConstants.GET_INVITES;
  payload: Invite[];
}
interface GetInviteAction {
  type: typeof InvitesActionTypesConstants.GET_INVITE;
  payload: Invite;
}
interface PendingInvitesAction {
  type: typeof InvitesActionTypesConstants.PENDING_INVITES;
}
interface PendingInviteAction {
  type: typeof InvitesActionTypesConstants.PENDING_INVITE;
}
interface ErrorInvitesAction {
  type: typeof InvitesActionTypesConstants.ERROR_INVITES;
  payload: string;
}
interface ErrorInviteAction {
  type: typeof InvitesActionTypesConstants.ERROR_INVITE;
  payload: string;
}
interface ErrorSendInviteAction {
  type: typeof InvitesActionTypesConstants.ERROR_SEND_INVITE;
  payload: string;
}
interface ErrorSendInviteAction {
  type: typeof InvitesActionTypesConstants.ERROR_SEND_INVITE;
  payload: string;
}
interface CreateInviteAction {
  type: typeof InvitesActionTypesConstants.CREATE_INVITE;
  payload: Invite;
}
interface ClearInviteAction {
  type: typeof InvitesActionTypesConstants.CLEAR_INVITE;
}

export interface InvitesState {
  invites: Invite[] | [];
  pendingInvites: boolean;
  errorInvites: string;
  errorSendInvite: undefined | string;
  invite: undefined | Invite;
  pendingInvite: boolean;
  errorInvite: string;
}
export type InvitesActionsTypes =
  | ClearErrorSendInviteAction
  | CreateInviteAction
  | ErrorSendInviteAction
  | ErrorInvitesAction
  | GetInvitesAction
  | GetInviteAction
  | PendingInviteAction
  | ErrorInviteAction
  | ClearInviteAction
  | PendingInvitesAction;
