import { ERROR_FORM_SUBSCRIBE, CLEAR_ERROR_FORM_SUBSCRIBE } from './types';

import { sleep } from '../util/sleep';
import { Subscribes } from '../typings/enums';
import { Action } from 'redux';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { apiFetch } from '../util/apiFetch';

let i = 0;

export const sendSubscribe = (
  data: any,
  subscribeType: Subscribes,
  cb: (response: any) => void
): ThunkAction<void, RootState, null, Action<string>> => async (dispatch) => {
  let response;
  try {
    response = await apiFetch(
      'company',
      `/${subscribeType === Subscribes.TRY ? 'try' : 'subscribe'}/`,
      'POST',
      data
    );

    if (response.error) {
      if (i !== 3) {
        await sleep(500);
        dispatch(sendSubscribe(data, subscribeType, cb));
        i++;
      } else {
        cb(response);
        throw new Error(response.error);
      }
    } else {
      cb(response);
      i = 0;
      clearSubscribeError(subscribeType);
    }
  } catch (err) {
    dispatch({
      type: ERROR_FORM_SUBSCRIBE,
      payload: {
        type: subscribeType,
        message: 'Sorry, something went wrong.\nPlease try it again',
      },
    });
  }
};

export const clearSubscribeError = (type: string) => ({
  type: CLEAR_ERROR_FORM_SUBSCRIBE,
  payload: type,
});
