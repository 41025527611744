import React from 'react';
import { ConfirmModalContent } from '../ConfirmModalContent';
import { ReviewForm } from '../../../reviewer/components/ReviewForm';
import { InviteCandidate } from '../InviteCandidate';
import { ParticipantsModal } from '../ParticipantsModal';
import { Actions } from '../../../typings/enums';

interface Props {
  modalType: string | undefined;
}

export const ModalContent: React.FC<Props> = ({ modalType }) => {
  if (modalType === Actions.inviteCandidate) return <InviteCandidate />;
  if (
    modalType === Actions.ARCHIVE ||
    modalType === Actions.PROLONG ||
    modalType === Actions.inform ||
    modalType === Actions.rejectExpired
  )
    return <ConfirmModalContent />;
  if (modalType === Actions.accept || modalType === Actions.reject)
    return <ReviewForm resolution={modalType} />;
  if (modalType === Actions.invite) return <ParticipantsModal />;
  return null;
};
