import React from 'react';
import moment from 'moment';

interface Props {
  date: string | undefined | null | number;
  title: string;
  testId: string;
}

export const SummaryRow: React.FC<Props> = ({ date, title, testId }) => {
  let timeSpent;

  if (typeof date === 'number') {
    const duration = moment.duration(date);

    if (duration.hours() < 1) {
      timeSpent = 'less 1h';
    } else {
      timeSpent = `${duration.days() ? duration.days() + 'd' : ''} ${
        duration.hours() ? duration.hours() + 'h' : ''
      }`;
    }
  }

  return (
    <tr data-testid={`candidate-summary-${testId}`}>
      <td className='text-gray-600 pr-8'>{title}</td>
      {typeof date === 'string' && (
        <td>
          {moment.duration(Date.parse(date)).days() < 1
            ? moment.utc(date).fromNow()
            : moment.utc(date).local().format('Do MMMM, H:mm')}
        </td>
      )}
      {typeof date === 'number' && timeSpent && <td>{timeSpent}</td>}
    </tr>
  );
};
