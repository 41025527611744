import React from 'react';

interface Props {
  min: number;
}

export const AvarageTime: React.FC<Props> = ({ min }) => {
  return (
    <div className='py-10'>
      <div className='text-4-7xl font-weight-bold text-center'>{min} Min</div>
      <div
        className='text-xl text-center mx-auto'
        style={{ maxWidth: '503px' }}
      >
        Average revieviewing time for one solution during our our betta testing
        with real candidates and reviewers
      </div>
    </div>
  );
};
