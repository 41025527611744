import React, { useState } from 'react';
import { Loader } from '../../shared/components/Loader';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { IBlobChanges, ISolution } from '../../typings/interfaces';
import { CustomSwitch } from '../../shared/components/CustomSwitch';
import { DiffView } from './DiffView';
import { isComment } from '../../typings/typeGuards';
import { v4 as uuid } from 'uuid';
import {
  notesSelector,
  pendingFilesDiffSelector,
  errorFilesDiffSelector,
} from '../../selectors';

interface IProps {
  filesDiff: Array<IBlobChanges>;
  beforeCommit: string | undefined;
  afterCommit: string | undefined;
  candidate: ISolution;
  isHidden?: boolean;
}

export const FilesDiff: React.FC<IProps> = ({
  filesDiff,
  beforeCommit,
  afterCommit,
  candidate,
  isHidden,
}) => {
  const [splitView, setSplitView] = useState<boolean>(
    localStorage.splitView ? JSON.parse(localStorage.splitView) : true
  );

  const pendingFilesDiff = useShallowEqualSelector(pendingFilesDiffSelector);
  const errorFilesDiff = useShallowEqualSelector(errorFilesDiffSelector);
  const notes = useShallowEqualSelector(notesSelector);

  if (errorFilesDiff) {
    return <div className='text-danger'>{errorFilesDiff}</div>;
  }

  if (pendingFilesDiff) {
    return <Loader />;
  }

  const comments = notes?.[candidate.position.uuid]?.filter((note) =>
    isComment(note)
  );

  return (
    <>
      {filesDiff.length > 0 && beforeCommit && afterCommit ? (
        <>
          <CustomSwitch
            onChange={() => {
              localStorage.setItem('splitView', JSON.stringify(!splitView));
              setSplitView(!splitView);
            }}
            isChecked={splitView}
            label='Split view'
            name='switch'
          />
          {filesDiff.map((fileData: any, index: number) => {
            return (
              <DiffView
                hidden={isHidden && index > 1}
                key={uuid()}
                fileData={fileData}
                splitView={splitView}
                beforeCommit={beforeCommit}
                afterCommit={afterCommit}
                comments={comments || []}
                candidate={candidate}
              />
            );
          })}
        </>
      ) : null}
    </>
  );
};
