import React from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../../actions/alertActions';
import { Dropdown } from 'react-bootstrap';
import LinkInput from './LinkInput';
import { AlertType } from '../../../typings/enums';
import './GitClone.scss';
import downloadIcon from '../../../assets/download.svg';

export const GitClone: React.FC = () => {
  const dispatch = useDispatch();
  const handleLinkCopy = () => {
    dispatch(
      showAlert({
        type: AlertType.success,
        message: 'Link has been copied'
      })
    );
  };
  return (
    <Dropdown className='dropdown-no-arrow'>
      <Dropdown.Toggle
        id='git-clone-button'
        data-testid='git-clone-button'
        variant='outline-dark'
        className='rounded-lg'
      >
        <span><img src={downloadIcon} alt='' /> Git clone</span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        onSelect={() => ({ isOpen: 'rootClose' })}
        rootCloseEvent='click'
        className='p-2 rounded-lg mt-0'
        style={{ maxWidth: '260px' }}
        alignRight
      >
        <LinkInput handleLinkCopy={handleLinkCopy} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

