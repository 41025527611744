import React from 'react';
import { ReactComponent as CompletedIcon } from '../../assets/completed.svg';
import { ReactComponent as RejectIcon } from '../../assets/rejected.svg';
import { ReactComponent as EyeIcon } from '../../assets/eye.svg';
import { Stages, UserRoles } from '../../typings/enums';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { ISolution, IFeedback } from '../../typings/interfaces';
import moment from 'moment';
import { userSelector } from '../../selectors';
import { filterSolutionByStage } from '../../util/filterSolutionByStage';

interface Props {
  candidate: ISolution;
}

const renderStatus = (feedback: IFeedback) => {
  if (!feedback.is_sent) {
    return feedback.is_positive ? 'Accepted' : 'Rejected';
  } else {
    return 'Informed';
  }
};

export const CandidateStage: React.FC<Props> = ({ candidate }) => {
  const user = useShallowEqualSelector(userSelector);
  const isRecruiter = user?.user_claims.roles[0] === UserRoles.RECRUITER;

  return (
    <>
      {filterSolutionByStage(
        candidate,
        user?.user_claims.roles[0],
        Stages.SUBMITTED
      ) &&
        isRecruiter && (
          <div className='lg:w-4/12'>
            <div className='d-flex align-items-center mb-3'>
              <EyeIcon className='mr-2' />
              <div className='font-weight-bold text-lg'>Under Review</div>
            </div>
          </div>
        )}
      {filterSolutionByStage(candidate, user?.user_claims.roles[0], [
        Stages.ACCEPTED,
        Stages.REJECTED,
        Stages.PROCESSED
      ]) && (
        <div className='lg:w-4/12'>
          <div
            className='d-flex align-items-start'
            data-testid='accept_reject_resolution'
          >
            {candidate.feedback!.is_positive ? (
              <CompletedIcon className='mr-2' data-testid />
            ) : (
              <RejectIcon className='mr-2' />
            )}
            <div>
              <div className='text-2xl font-weight-bold leading-none mb-1'>
                {renderStatus(candidate.feedback!)}
              </div>
              <div className='text-xs text-gray-600'>
                {moment.utc(candidate.feedback!.date_created).fromNow()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
