import { ICheck } from '../../typings/interfaces';

export const SET_CHECKS = 'SET_CHECKS';
export const PENDING_CHECKS = 'PENDING_CHECKS';
export const ERROR_CHECKS = 'ERROR_CHECKS';
export const CLEAR_CHECKS = 'CLEAR_CHECKS';

interface ErrorChecksAction {
  type: typeof ERROR_CHECKS;
  payload: string;
}

interface SetChecksAction {
  type: typeof SET_CHECKS;
  payload: ICheck[];
}
interface PendingChecksAction {
  type: typeof PENDING_CHECKS;
}
interface ClearChecksAction {
  type: typeof CLEAR_CHECKS;
}

export interface ChecksState {
  checks: undefined | any[];
  pendingChecks: boolean;
  errorChecks: string;
}

export type ChecksActionsTypes =
  | ErrorChecksAction
  | SetChecksAction
  | PendingChecksAction
  | ClearChecksAction;
