import React from 'react';
import { ISolution, ILike } from '../../../typings/interfaces';
import { CandidateLink } from '../../../shared/components/CandidateLink';
import { countStoryPoints } from '../../../util/countStoryPoints';
import { Stages } from '../../../typings/enums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PredictionBadge } from '../../../shared/components/PredictionBadge';
import { LikesBlock } from '../../../shared/components/LikesBlock';
import { Avatar } from '../../../shared/components/Avatar';
import { LikesRenderType } from '../../../typings/enums';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { userRoleSelector } from '../../../selectors';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { filterSolutionByStage } from '../../../util/filterSolutionByStage';

interface Props {
  candidate: ISolution;
  stageType: string | undefined;
  currentCandidateLikes: ILike[] | undefined;
}

export const CandidateRow: React.FC<Props> = ({
  candidate,
  stageType,
  currentCandidateLikes,
}) => {
  const {
    shotGoodBadFit,
    showLikeDeslikeFeature,
    recruiterDataFlag,
  } = useFlags();
  const userRole = useShallowEqualSelector(userRoleSelector);

  const completedTrackers = candidate.candidate.trackers.filter((tracker) => {
    return tracker.status === 'COMPLETED';
  });

  const JSONData = candidate.data ? JSON.parse(candidate.data) : undefined;
  const fit = JSONData ? JSONData.fit : undefined;

  if (!candidate) return null;

  return (
    <tr data-testid={`candidate-${candidate.candidate.uuid}`}>
      <td className='border-0'>
        <div className='d-flex align-items-center'>
          <CandidateLink candidate={candidate} />
          {shotGoodBadFit &&
            filterSolutionByStage(candidate, userRole, Stages.SUBMITTED) && (
              <PredictionBadge
                fit={fit}
                candidateId={candidate.candidate.uuid}
                className='ml-2'
              />
            )}
        </div>
      </td>
      <td className='border-0' data-testdata={candidate.date_created}>
        {candidate.date_created
          ? moment.utc(candidate.date_created).fromNow()
          : null}
      </td>
      <td className='border-0'>
        {candidate.date_ready
          ? moment.utc(candidate.date_ready).local().format('DD/MM/YY HH:mm')
          : null}
      </td>
      {stageType && (stageType === 'accepted' || stageType === 'rejected') ? (
        <td className='border-0'>
          {candidate.feedback
            ? moment
                .utc(candidate.feedback.date_created)
                .local()
                .format('DD/MM/YY HH:mm')
            : null}
        </td>
      ) : null}
      <td className='border-0'>{countStoryPoints(completedTrackers)}SP</td>
      {recruiterDataFlag && (
        <td className='border-0'>
          {candidate.recruiter?.first_name} {candidate.recruiter?.last_name}
        </td>
      )}
      {showLikeDeslikeFeature &&
      currentCandidateLikes &&
      stageType === Stages.SUBMITTED ? (
        <td className='border-0'>
          {currentCandidateLikes.length > 0 ? (
            <LikesBlock
              votes={currentCandidateLikes}
              type={LikesRenderType.count}
            />
          ) : (
            <span className='text-gray-500'>Be the first to review</span>
          )}
        </td>
      ) : null}
      {stageType === Stages.ACCEPTED || stageType === Stages.REJECTED ? (
        <td className='border-0'>
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Tooltip
                id={`tooltip-feedback-author-${candidate.feedback?.created_by.uuid}-${candidate.candidate.user?.uuid}`}
              >
                <div>
                  {candidate.feedback?.created_by.first_name}{' '}
                  {candidate.feedback?.created_by.last_name}
                </div>
              </Tooltip>
            }
          >
            <div className='icon--33'>
              <Avatar data={candidate.feedback?.created_by} />
            </div>
          </OverlayTrigger>
        </td>
      ) : null}
    </tr>
  );
};
