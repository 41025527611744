import React from 'react';
import './LoaderMini.scss';

export const LoaderMini = () => {
  return (
    <div className='lds-spinner'>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
