import React from 'react';
import { IPosition } from '../../../typings/interfaces';
import { PositionStages } from './PositionStages';
import { PositionHeader } from './PositionHeader';
import { PositionNoTask } from './PositionNoTask';
import { CardComponent } from '../../../shared/components/CardComponent';

interface Props {
  position: IPosition;
}

export const ReviewerPosition: React.SFC<Props> = ({ position }) => {
  if (!position.task) {
    return <PositionNoTask position={position} />;
  }

  return (
    <CardComponent id={`position-${position.uuid}`}>
      <PositionHeader position={position} />
      <PositionStages position={position} />
    </CardComponent>
  );
};
