import React, { useEffect, useRef } from 'react';
import { usePrevious } from '../../hooks/usePrevious';
import anime from 'animejs';

interface Props {
  qty: number | undefined;
}

const animate = () => {
  anime({
    targets: '.pulse',
    keyframes: [
      { scale: 1.2, translateY: '-2px' },
      { scale: 1, translateY: '0px' },
    ],
    duration: 300,
    easing: 'easeInOutExpo',
  });
};

export const NotesCount: React.FC<Props> = ({ qty }) => {
  const mounted = useRef<boolean | undefined>();
  const prevAmount = usePrevious({ qty });
  useEffect(() => {
    if (mounted.current && prevAmount?.qty !== qty) {
      animate();
    }
    if (!mounted.current) {
      mounted.current = true;
    }
  }, [qty, prevAmount]);
  return <div className='text-gray-600 ml-1 pulse'>{qty}</div>;
};
