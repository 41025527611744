import React from 'react';
import { useScrollToElement } from '../../../hooks/useScrollToElement';
import { Header } from './Header';
import { DecreseTimeToHire } from './DecreseTimeToHire';
import { HowItWorks } from './HowItWorks';
import { ExperienceBlock } from './ExperienceBlock';
import { FormBlock } from './FormBlock';
import { BlueFooter } from '../../components/BlueFooter';
import './TwoMonthsFreeTrial.scss';

export const TwoMonthsFreeTrial = () => {
  const { setRef, handleLinkClick } = useScrollToElement();

  return (
    <div className='two-months-free-trial'>
      <Header handleLinkClick={handleLinkClick} />
      <DecreseTimeToHire />
      <HowItWorks />
      <ExperienceBlock />
      <FormBlock setRef={setRef} />
      <BlueFooter />
    </div>
  );
};
