import React from 'react';
import routineImg from '../../../assets/newLanding/routine.png';
import routineMobImg from '../../../assets/newLanding/routine_mob.png';
import { Container, Col, Row } from 'react-bootstrap';
import { useMatchMedia } from '../../../hooks/useMatchMedia';

export const RoutineTasks = () => {
  const [isDesktop] = useMatchMedia(992);
  return (
    <div className='pb-8 pb-md-12'>
      <div
        style={{ background: 'rgba(0,0,0,0.33)' }}
        className='rounded-lg px-4 pt-6 pb-8 pb-lg-0'
      >
        <Container>
          <Row className='justify-content-between'>
            <Col
              xs={12}
              lg={6}
              xl={7}
              className='order-lg-2 pt-lg-6 mb-6 mb-lg-0'
            >
              <img
                src={isDesktop ? routineImg : routineMobImg}
                alt='Test real life coding skills'
                className='img-fluid w-100'
              />
            </Col>
            <Col xs={12} lg={6} xl={5}>
              <p
                className='text-23 md:text-2xl lg:text-2-6xl text-blue-100 mb-2'
                style={{ lineHeight: '40px' }}
              >
                Wave goodbye to routine tasks
              </p>
              <p className='text-silver lg:text-xl mb-0'>
                We take care of building applications using code, running tests,
                and applying code analyzers
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
