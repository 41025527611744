import React, { useState, useRef, useEffect, ReactElement } from 'react';
import AnimateHeight from 'react-animate-height';

interface Props {
  children: ReactElement;
  cardInitialHeight: number;
  isExpanded: boolean;
  onExpand: () => void;
}

export const CustomCollapse: React.FC<Props> = ({
  children,
  cardInitialHeight,
  isExpanded,
  onExpand,
}) => {
  const contentRef = useRef<any>(null);

  const [isOveflowed, setIsOveflowed] = useState<boolean>(false);

  useEffect(() => {
    if (contentRef && contentRef.current) {
      const height = contentRef.current.offsetHeight;
      if (height && height > cardInitialHeight) {
        setIsOveflowed(true);
      }
    }
  }, [cardInitialHeight, contentRef]);

  const content = React.cloneElement(children, { ref: contentRef });

  return (
    <div className='position-relative mb-1' data-testid='candidate-note'>
      <AnimateHeight
        duration={500}
        height={isExpanded ? 'auto' : cardInitialHeight}
        className='w-100'
      >
        {content}
      </AnimateHeight>
      {isOveflowed ? (
        <>
          {!isExpanded ? (
            <>
              <div className='position-absolute inset-0'></div>
              <div
                className='position-absolute d-flex align-items-end'
                style={{
                  height: 'calc(45%)',
                  width: '100%',
                  bottom: '27px',
                  background:
                    'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.66) 0.01%, #FFFFFF 80%)',
                }}
              ></div>
            </>
          ) : null}
          <button
            className={
              'align-items-end py-2 px-0 border-0 focus:outline-none bg-white w-100 text-left text-primary position-relative'
            }
            style={{ zIndex: 2 }}
            onClick={onExpand}
          >
            {!isExpanded ? 'Show more' : 'Collapse'}
          </button>
        </>
      ) : null}
    </div>
  );
};
