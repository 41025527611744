import { ThunkAction } from 'redux-thunk';
import { PositionsActionTypes } from './types';
import { RootState } from '../reducers';
import { Action } from 'redux';
import { apiFetch } from '../util/apiFetch';
import { stringify } from 'query-string';
import { NotificationsContants } from '../actions/types';
import { UserRoles } from '../typings/enums';
import { NotificationContentTypes } from '../typings/enums';
import { INotification } from '../typings/interfaces';

export const getNotifications = (
  query: {
    rel_position_pk: string | string[];
    page?: number;
    content_type_pk?: string | string[];
  },
  userRole: string
): ThunkAction<void, RootState, PositionsActionTypes, Action<string>> => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: NotificationsContants.PENDING_NOTIFICATIONS,
      payload: query.rel_position_pk,
    });

    if (userRole === UserRoles.RECRUITER) {
      query.content_type_pk = [
        NotificationContentTypes.user,
        NotificationContentTypes.position,
        NotificationContentTypes.feedback,
      ];
    }
    if (userRole === UserRoles.REVIEWER) {
      query.content_type_pk = [
        NotificationContentTypes.user,
        NotificationContentTypes.position,
        NotificationContentTypes.feedback,
        NotificationContentTypes.note,
      ];
    }

    const response = await apiFetch(
      'notifier',
      `/notification/?${stringify(query)}`
    );
    if (response.error) throw new Error(response.error);

    const curNotifications = getState().notificationsReducer.notifications;

    if (
      curNotifications?.items &&
      curNotifications?.page !== response.data.page
    ) {
      dispatch({
        type: NotificationsContants.GET_NOTIFICATIONS,
        payload: {
          positionId: query.rel_position_pk,
          notifications: {
            ...response.data,
            items: [...curNotifications.items, ...response.data.items].sort(
              (a, b) => Date.parse(b.date_created) - Date.parse(a.date_created)
            ),
          },
        },
      });
    } else {
      dispatch({
        type: NotificationsContants.GET_NOTIFICATIONS,
        payload: {
          positionId: query.rel_position_pk,
          notifications: {
            ...response.data,
            items: response.data.items.sort(
              (a: INotification, b: INotification) =>
                Date.parse(b.date_created) - Date.parse(a.date_created)
            ),
          },
        },
      });
    }
  } catch (err) {
    dispatch({
      type: NotificationsContants.ERROR_NOTIFICATIONS,
      payload: err.message,
    });
  }
};

export const startConnection = () => ({
  type: NotificationsContants.START_SOCKET,
});

export const stopConnection = () => ({
  type: NotificationsContants.STOP_SOCKET,
});
export const clearNotifications = () => ({
  type: NotificationsContants.CLEAR_NOTIFICATIONS,
});
