import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../Logo';

interface Props {
  children: React.ReactNode;
}

export const FormContainer: React.FC<Props> = ({ children }) => {
  return (
    <>
      <header>
        <div className='d-flex flex-column justify-content-between pt-6'>
          <div>
            <div className='container'>
              <div className='d-flex justify-content-lg-between justify-content-center w-100 align-items-center mb-lg-10 mb-4'>
                <div>
                  <Link to='/' className='text-xl text-black'>
                    <Logo type='dark' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='container p-4'>
        <div className='d-flex flex-column flex-lg-row'>{children}</div>
      </div>
    </>
  );
};
