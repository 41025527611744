import React from 'react';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { IPosition } from '../../../typings/interfaces';
import { ReviewerPosition } from '../../components/ReviewerPosition';
import { Welcome } from './Welcome';
import { PositionsContainer } from '../../../containers/PositionsContainer';
import {
  positionsSelector,
  userSelector,
  positionsErrorSelector,
} from '../../../selectors';

export const ReviewerDashboard: React.SFC = () => {
  const user = useShallowEqualSelector(userSelector);
  const positions = useShallowEqualSelector(positionsSelector);

  const positionsError = useShallowEqualSelector(positionsErrorSelector);

  if (!positions) return null;

  return (
    <div data-testid='reviewer'>
      {user?.fresh && <Welcome />}
      {positionsError && <div>{positionsError}</div>}
      <PositionsContainer>
        {positions.map((position: IPosition) => (
          <ReviewerPosition position={position} key={position.uuid} />
        ))}
      </PositionsContainer>
    </div>
  );
};
