import React from 'react';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { ISolution, INote } from '../../typings/interfaces';
import { NoteType, Stages } from '../../typings/enums/index';
import cn from 'classnames';
import { ReactComponent as ExclamationIcon } from '../../assets/exclamation.svg';
import { notesSelector, userRoleSelector } from '../../selectors';
import { filterSolutionByStage } from '../../util/filterSolutionByStage';

interface Props {
  candidate: ISolution;
  className?: string;
  titleText?: string;
}

export const RecruiterNote: React.FC<Props> = ({
  candidate,
  className,
  titleText,
}) => {
  const notes = useShallowEqualSelector(notesSelector);
  const userRole = useShallowEqualSelector(userRoleSelector);

  let recruiterNote: INote | undefined;
  if (notes) {
    recruiterNote = notes?.[candidate.position.uuid]?.find(
      (note) =>
        note.body.type === NoteType.to_recruiter &&
        note.user_id === candidate.candidate.user.uuid
    );
  }

  const title = (
    <div className='font-weight-bold text-lg mb-1 d-flex align-items-center'>
      {titleText}{' '}
      {filterSolutionByStage(candidate, userRole, Stages.SUBMITTED) &&
        recruiterNote && (
          <ExclamationIcon className='orange-exclamation ml-2' />
        )}
    </div>
  );

  if (notes && !recruiterNote) {
    return (
      <>
        {title}
        <div className={className}>No note given</div>
      </>
    );
  }

  return (
    <div className={cn('flex-shrink-0 w-100', [className])}>
      {title}
      {recruiterNote && (
        <div>
          {recruiterNote.body.text.split('\n').map((str: string, i: number) => {
            return (
              <div className='leading-normal pr-6' key={`str-${i}`}>
                {str}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
