import React, { FC } from 'react';
import { GitClone } from '../../components/GitClone';
import cn from 'classnames';
import { UserRoles } from '../../../typings/enums';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { userRoleSelector } from '../../../selectors';
import { RunSolution } from '../../components/RunSolution';
import './header.scss';

interface HiringHeaderPropTypes {
  title: string;
}

export const Header: FC<HiringHeaderPropTypes> = ({title}) => {
  const isReviewer =
    useShallowEqualSelector(userRoleSelector) === UserRoles.REVIEWER;

  return (
    <div
      className={cn(
        'd-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center mb-4',
        {
          'mt-4': !isReviewer
        }
      )}
    >
      <div
        className={cn('font-weight-bold mb-2 mb-lg-0', {
          'text-2xl': isReviewer,
          'text-lg': !isReviewer
        })}
      >
        {title}
      </div>
      {isReviewer ? (
        <div className='impl-run-git'>
          <RunSolution
          className='impl-run-border'
          iconColor='#C6C6C6'
          text='Check implementation'
        />
          <GitClone />
        </div>
      ) : null}
    </div>
  );
};
