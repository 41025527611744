import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
interface Props {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

export const Search: React.FC<Props> = ({
  handleChange,
  placeholder = 'Search',
}) => {
  const [isIconShown, setIsIconShown] = useState<boolean>(true);
  return (
    <div
      className='position-relative'
      style={{ maxWidth: '400px', minWidth: '263px' }}
    >
      <input
        type='search'
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value !== '') {
            setIsIconShown(false);
          } else {
            setIsIconShown(true);
          }
          handleChange(e);
        }}
        className='rounded hover:border-gray-400 border w-100 px-2 py-1 border-purple-200 focus:outline-none mb-4 bg-gray-700 rounded-lg focus:outline-none hover:border-gray-400 focus:bg-gray-700 pr-5 text-sm'
        data-testid='search'
      />
      {isIconShown && (
        <FontAwesomeIcon
          icon={faSearch}
          className='position-absolute'
          style={{ top: '5px', right: '10px' }}
          color='#A3A3A3'
          size='lg'
        />
      )}
    </div>
  );
};
