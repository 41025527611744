import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { ReactComponent as PencilIcon } from '../../assets/pencil-white.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ISolution } from '../../typings/interfaces';
import { createNote } from '../../actions/notesActions';

interface Props {
  candidate: ISolution;
}

export const NoteWidget: React.FC<Props> = ({ candidate }) => {
  const [isFormShown, setIsFormShown] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>('');

  const closeForm = () => setIsFormShown(false);

  return (
    <div
      className='position-fixed'
      style={{
        left: '20px',
        bottom: '15px',
        zIndex: 3
      }}
    >
      {!isFormShown ? (
        <button
          className='bg-dark text-white px-4 py-2 rounded border-0 d-flex align-items-center focus:outline-none'
          onClick={() => setIsFormShown(true)}
        >
          <PencilIcon className='mr-2' />
          {value ? 'Notes' : 'Add Note'}
        </button>
      ) : (
        <div
          className='border rounded-lg py-2 px-4 bg-white d-flex flex-column'
          style={{
            width: '267px',
            height: '240px',
            maxHeight: '100%',
            maxWidth: '100%'
          }}
        >
          <div className='d-flex align-items-center justify-content-between mb-2'>
            <div className='text-sm font-weight-bold'>Notes</div>
            <div>
              <button
                className='bg-transparent border-0 p-0 d-block'
                onClick={closeForm}
              >
                <CloseIcon className='icon--16' />
                <form></form>
              </button>
            </div>
          </div>
          <textarea
            name='team_note'
            className='w-100 border-0 flex-grow-1 mb-2'
            style={{ resize: 'none' }}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setValue(e.target.value)
            }
            value={value}
          ></textarea>
          <div className='text-right'>
            <Button
              variant='warning'
              disabled={!value}
              onClick={async () => {
                const status = await dispatch(
                  createNote({
                    body: { text: value },
                    position_id: candidate.position.uuid,
                    user_id: candidate.candidate.user.uuid
                  })
                );

                if (typeof status === 'number' && status === 201) {
                  setValue('');
                  closeForm();
                }
              }}
            >
              Add
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
