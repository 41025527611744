import React from 'react';
import '../../../styles/backgrounds.css';
import { Header } from '../../components/Header';
import { useScrollToElement } from '../../../hooks/useScrollToElement';
import { FeaturesBlock } from './FeaturesBlock';
import { Interface } from './Interface';
import { ForCustomers } from '../../components/ForCustomers';
import { CandidateExperience } from '../../components/CandidateExperience';
import { Pricing } from '../../components/Pricing';
import { Footer } from '../../components/Footer';
import { BookADemoBtn } from '../../components/BookADemoBtn';
import { FreeTrialBtn } from './FreeTrialBtn';

interface Props {}

export const Recruiters = (props: Props) => {
  const { setRef, handleLinkClick } = useScrollToElement();
  return (
    <div className='w-100 recruiters'>
      <Header
        isHeroImage={false}
        headerText={
          <div className='text-white'>
            <h1
              className='w-100  md:text-4-7xl text-4xl lg:w-9/12 lg:leading-thin pt-lg-10 font-weight-normal mb-4'
              style={{ maxWidth: '910px' }}
            >
              Shortlist strong candidates for interviews with ready-made code
              challenges.
            </h1>
            <div className='mb-6' style={{ fontSize: '22px' }}>
              Let candidates demonstrate real coding skills according to a real
              workflow.
            </div>
          </div>
        }
        btns={
          <div className='d-flex flex-column flex-md-row align-items-center'>
            <BookADemoBtn className='mr-lg-4 mb-4 mb-md-0 py-2 text-2xl rounded-lg' />
            <FreeTrialBtn />
          </div>
        }
        handleLinkClick={handleLinkClick}
      />
      <FeaturesBlock />
      <div className='pt-20 pb-10 bg-gray-200'>
        <div className='container'>
          <Interface />
          <div className='d-lg-flex py-20'>
            <div className='mb-6 mb-lg-0 mr-lg-6 lg:w-8-12'>
              <CandidateExperience />
            </div>
            <div className='lg:w-4-12'>
              <ForCustomers
                title='Hiring Teams'
                text='Hire the perfect fit faster and and at a lower cost'
                link='/hiring_teams'
              />
            </div>
          </div>
        </div>
      </div>
      <Pricing setRef={setRef} />
      <Footer />
    </div>
  );
};
