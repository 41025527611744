import React from 'react';
import { Link } from 'react-router-dom';
import './ForCustomers.scss';

interface Props {
  title: string;
  text: string;
  link: string;
}

export const ForCustomers: React.FC<Props> = ({ title, text, link }) => {
  return (
    <div
      className='bg-blue-500 p-4 text-white d-flex flex-column justify-content-between leading-thin rounded'
      style={{ minHeight: '280px' }}
      data-refkey='recruiters'
    >
      <div>
        <div className='text-2-5xl font-weight-bold mb-2'>{title}</div>
        <div>{text}</div>
      </div>
      <div>
        <Link to={link} className='rounded-lg btn-dark btn'>
          Learn more
        </Link>
      </div>
    </div>
  );
};
