export * from './CommitsTypes';
export * from './LikesContentTypeEnum';
export * from './LikesRenderType';
export * from './AlertType';
export * from './NotificationContentTypes';

export enum ArtifactFormat {
  HTML = 'html',
  JSON = 'json',
}

export enum ArtifactType {
  UNIT = 'unit',
  LINT = 'lint',
}

export enum Stages {
  SENT = 'task_sent',
  IN_PROGRESS = 'in_progress',
  STARTED = 'started',
  EXPIRED = 'expired',
  EXPIRING = 'expiring',
  SUBMITTED = 'submitted',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PROCESSED = 'processed',
  ARCHIVED = 'archived',
  WORKING = 'working',
}

export enum UserRoles {
  REVIEWER = 'reviewer',
  RECRUITER = 'recruiter',
  CANDIDATE = 'candidate',
}

export enum Dropdowns {
  notifications = 'notifications',
  'user-profile' = 'user-profile',
}

export enum Env {
  DEV = 'dev',
  TEST = 'test',
  STAGING = 'staging',
  PROD = 'prod',
}

export enum Subscribes {
  BOOK = 'BOOK',
  TRY = 'TRY',
  FREE_TRIAL = 'FREE_TRIAL',
}

export enum Actions {
  inform = 'inform',
  ARCHIVE = 'ARCHIVE',
  PROLONG = 'PROLONG',
  accept = 'accept',
  reject = 'reject',
  rejectExpired = 'rejectExpired',
  inviteCandidate = 'inviteCandidate',
  invite = 'invite',
  update_team = 'update_team',
}

export enum CompanyData {
  email = 'sfeeda@sfeeda.com',
  phone = '+49 162 584 14 16',
  companyName = 'Sfeeda UG',
  address = 'Bastianstraße 22, Berlin, 13357',
  TRN = 'HRB 217704',
  TaxNumber = '30 / 532 / 51453',
  director = 'Dmytro Malyshenko',
  vatId = 'DE330757158',
}

export enum StepKeys {
  sent = 'sent',
  started = 'started',
  commit = 'commit',
  tickets = 'tickets',
}

export enum NoteType {
  to_recruiter = 'to_recruiter',
}
