import React, { ReactNode, CSSProperties } from 'react';
import { Container } from 'react-bootstrap';
import cn from 'classnames';

interface Props {
  children: ReactNode;
  id?: string | undefined;
  className?: string;
  style?: CSSProperties;
}

export const CardComponent: React.FC<Props> = ({
  children,
  id,
  className,
  style,
}) => {
  return (
    <Container
      className={cn(
        'rounded-lg border-0 shadow-sm pt-4 pb-5 mb-4 bg-white mx-0',
        [className]
      )}
      id={id ? id : undefined}
      style={style}
    >
      {children}
    </Container>
  );
};
