import { apiFetch } from '../util/apiFetch';
import { stringify } from 'query-string';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';
import { AcceptanceActionsTypes, AcceptanceTypesConstants } from './types';
import { showAlert } from './alertActions';
import { AlertType } from '../typings/enums';

export const getAcceptance = (
  positionId: string,
  userId: string
): ThunkAction<
  void,
  RootState,
  AcceptanceActionsTypes,
  Action<string>
> => async (dispatch) => {
  try {
    dispatch({
      type: AcceptanceTypesConstants.PENDING_ACCEPTANCE_BY_POSITION,
    });
    const params = {
      position_id: positionId,
      user_id: userId,
    };
    const response = await apiFetch(
      'ticket',
      `/acceptance/?${stringify(params)}`
    );

    // that trycatch block works on prod only
    try {
      const persentCandidate = await apiFetch('ticket', '/acceptance_stat/');
      response.data.map((item: any) => {
        item.persents = [];
        persentCandidate.map((persent: any) => {
          if (item.test_id === persent.test_id) {
            const persentCount = persent.success + persent.fail;
            item.persents = {
              ...persent,
              persentFail: Math.round((persent.fail / persentCount) * 100),
              persentSuccess: Math.round(
                (persent.success / persentCount) * 100
              ),
            };
          }
        });
      });
    } catch (e) {}

    if (response.error) throw new Error(response.error);
    dispatch({
      type: AcceptanceTypesConstants.GET_ACCEPTANCE_BY_POSITION,
      payload: response.data,
    });
  } catch (err) {
    dispatch(showAlert({ message: err.message, type: AlertType.fail }));
  }
};
