import React from 'react';
import './Plans.scss';
import { v4 as uuid } from 'uuid';

interface Props {
  title?: string;
}

const PLANS = [
  {
    title: 'Plan A',
    desc: ['10 assessments/month', '3 positions'],
    oldPrice: '€99',
    newPrice: '€89',
  },
  {
    title: 'Plan B',
    desc: ['40 assessments/month', '10 positions'],
    oldPrice: '€299',
    newPrice: '€269',
  },
  {
    title: 'Plan C',
    desc: [
      '250 assessments/month',
      'Unlimited number of positions',
      '+ Dedicated Account Manager',
    ],
    oldPrice: '€999',
    newPrice: '€900',
  },
];

export const Plans: React.FC<Props> = ({ title = 'Discounted Plans' }) => {
  return (
    <div className='plans'>
      <div className='text-xl leading-thin font-weight-bold mb-4'>{title}</div>
      {PLANS.map((props) => (
        <Plan {...props} key={props.title} />
      ))}
    </div>
  );
};

interface PlanProps {
  title: string;
  desc: string[];
  oldPrice: string;
  newPrice: string;
  className?: string;
}

const Plan: React.FC<PlanProps> = ({ title, desc, oldPrice, newPrice }) => {
  return (
    <div className='pt-4 pb-6'>
      <div className='d-flex justify-content-between border-bottom mb-2'>
        <div className='text-xl leading-thin mb-2'>{title}</div>
        <div className='w-4/12 d-flex justify-content-end text-xl leading-thin'>
          <div className='text-gray-plan mr-3'>{oldPrice}</div>
          <div>{newPrice}</div>
        </div>
      </div>
      {desc.map((str) => (
        <div className='mb-1 text-gray-plan' key={uuid()}>
          {str}
        </div>
      ))}
    </div>
  );
};
