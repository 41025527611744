import {
  InvitesActionTypesConstants,
  InvitesActionsTypes,
  InvitesState,
} from '../actions/types';

const initialState: InvitesState = {
  invites: [],
  pendingInvites: false,
  errorInvites: '',
  errorSendInvite: '',
  invite: undefined,
  pendingInvite: false,
  errorInvite: '',
};

export const invitesReducer = (
  state = initialState,
  action: InvitesActionsTypes
): InvitesState => {
  switch (action.type) {
    case InvitesActionTypesConstants.CREATE_INVITE:
      return {
        ...state,
        invites: [...state.invites, action.payload],
        pendingInvites: false,
        errorInvites: '',
      };
    case InvitesActionTypesConstants.GET_INVITES:
      return {
        ...state,
        invites: action.payload,
        pendingInvites: false,
        errorInvites: '',
      };
    case InvitesActionTypesConstants.GET_INVITE:
      return {
        ...state,
        invite: action.payload,
        pendingInvite: false,
        errorInvite: '',
      };
    case InvitesActionTypesConstants.CLEAR_INVITE:
      return {
        ...state,
        invite: undefined,
        pendingInvite: false,
        errorInvite: '',
      };
    case InvitesActionTypesConstants.PENDING_INVITES:
      return {
        ...state,
        pendingInvites: true,
      };
    case InvitesActionTypesConstants.PENDING_INVITE:
      return {
        ...state,
        pendingInvite: true,
      };
    case InvitesActionTypesConstants.ERROR_INVITES:
      return {
        ...state,
        pendingInvites: false,
        errorInvites: action.payload,
      };
    case InvitesActionTypesConstants.ERROR_INVITE:
      return {
        ...state,
        pendingInvite: false,
        errorInvite: action.payload,
      };
    default:
      return state;
  }
};
