import React from 'react';
import { SelectField } from '../../../shared/components/SelectField';
import { Field } from 'formik';

interface Props {
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  storyPoints: number;
}

export const StoryPointsSelect: React.FC<Props> = ({
  setFieldTouched,
  storyPoints,
}) => {
  return (
    <div className='mr-8'>
      <div className='d-flex align-items-center flex-wrap'>
        <div className='mr-2'>Story Points</div>
        <Field
          name='story_points'
          component={SelectField}
          setFieldTouched={setFieldTouched}
          selectOptions={valuesArr()}
          selectValueField='value'
          selectLabelField='value'
          selectPlaceholder=''
          className='text-sm'
          initialValue={[{ value: storyPoints }]}
        />
      </div>
    </div>
  );
};

const valuesArr = (): Array<{ value: number }> => {
  let i = 0;
  let arr: Array<{ value: number }> = [];
  while (i <= 10) {
    arr.push({ value: i });
    i++;
  }
  return arr;
};
