import React, { useState } from 'react';
import { Formik, FormikValues, FormikErrors, Form } from 'formik';
import { AddTeamFormFields } from '../CreateFirstPosition/AddTeamFormFields';
import { Button } from 'react-bootstrap';
import { addTeam } from '../../../actions/teamsActions';
import { useDispatch } from 'react-redux';
import { BackBtn } from '../../../shared/components/BackBtn';

import { v4 as uuid } from 'uuid';

interface Props {
  setAddTeamStep: React.Dispatch<React.SetStateAction<boolean>>;
}

export const initialValues: FormValues = {
  team_title: '',
  participant_email: '',
  company_name: '',
  hiring_manager: '',
  reveal: 'yes',
};

export interface FormValues {
  team_title: string;
  company_name: string;
  hiring_manager: string;
  participant_email: string;
  reveal: string;
}

export const AddTeamForm: React.FC<Props> = ({ setAddTeamStep }) => {
  const dispatch = useDispatch();

  const [participants, setParticipants] = useState<any>([]);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { validateForm }) => {
        if (validateForm(values)) {
          const hiringManager = {
            uuid: uuid(),
            first_name: '',
            last_name: '',
            email: values.hiring_manager,
          };

          const teamParticipants = [hiringManager, ...participants];

          dispatch(
            addTeam({
              uuid: uuid(),
              title: values.team_title,
              participants: teamParticipants,
              showNames: values.reveal === 'yes' ? true : false,
            })
          );
          setAddTeamStep(false);
        }
      }}
      validate={(values) => {
        const errors: FormikErrors<FormikValues> = {};

        if (!values.team_title) {
          errors.team_title = 'Required';
        }
        if (!values.company_name) {
          errors.company_name = 'Required';
        }
        if (!values.hiring_manager) {
          errors.hiring_manager = 'Required';
        }

        return errors;
      }}
    >
      {({ handleSubmit, errors, touched, values, setFieldTouched }) => (
        <Form>
          <div className='d-flex align-items-stretch'>
            <div className='flex-grow-1 w-100 pr-2 d-flex flex-column'>
              <div className='flex-grow-1'>
                <div className='h2'>Create hiring team</div>
                <div className='leading-normal'>
                  Invite people who will choose a task, check it <br />
                  and make final decisions
                </div>
              </div>
              <div>
                <BackBtn
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    setAddTeamStep(false);
                  }}
                />
              </div>
            </div>
            <div className='flex-grow-1 w-100 pl-4'>
              <AddTeamFormFields
                errors={errors}
                touched={touched}
                values={values}
                participants={participants}
                setParticipants={setParticipants}
              />
              <div className='d-flex justify-content-between'>
                <Button
                  variant='warning'
                  className='rounded-lg custom-btn'
                  style={{ minWidth: '160px' }}
                  type='submit'
                >
                  Add Team
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
