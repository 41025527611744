import {
  NotificationsContants,
  NotificationsState,
  NotificationsActionsTypes,
} from '../actions/types';

export const initialState: NotificationsState = {
  notifications: undefined,
  pendingNotifications: false,
  errorNotifications: '',
  isConnected: false,
};

export const notificationsReducer = (
  state = initialState,
  action: NotificationsActionsTypes
): NotificationsState => {
  switch (action.type) {
    case NotificationsContants.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...action.payload.notifications,
        },
        pendingNotifications: false,
        errorNotifications: '',
      };
    case NotificationsContants.PENDING_NOTIFICATIONS:
      return {
        ...state,
        pendingNotifications: true,
      };
    case NotificationsContants.ERROR_NOTIFICATIONS:
      return {
        ...state,
        errorNotifications: action.payload,
        pendingNotifications: false,
      };
    case NotificationsContants.START_SOCKET:
      return {
        ...state,
        isConnected: true,
      };
    case NotificationsContants.STOP_SOCKET:
      return {
        ...state,
        isConnected: false,
      };
    case NotificationsContants.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: undefined,
      };
    default:
      return state;
  }
};
