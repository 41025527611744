import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export const BookADemoBtn: React.FC<Props> = ({ className, style }) => {
  return (
    <Link
      to='/book'
      className={`w-100 btn btn-warning book-btn ${className}`}
      style={{ maxWidth: '228px', ...style }}
    >
      Book a demo
    </Link>
  );
};
