import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { setInstanceSetup } from '../../../actions/ticketsActions';

interface ChallengeSetupProps {
  storyPoints: { min: number; max: number; count: number };
  duration: number;
}

export const ChallengeSetup: FC<ChallengeSetupProps> = ({
  storyPoints,
  duration,
}) => {
  const [durationCount, setDurationCount] = useState(duration);
  const [durationFormat, setDurationFormat] = useState(false);
  const [storyPointLocal, setStoryPointLocal] = useState(storyPoints.count);
  const spLength = storyPoints.max - storyPoints.min + 1;
  const spRange = Array.from(
    new Array(spLength),
    (_, i) => i + storyPoints.min
  );

  useEffect(() => {
    setInstanceTicketFromChallengeSetup();
  }, [durationCount, durationFormat, storyPointLocal]);

  const dispatch = useDispatch();

  const { instanceTicket } = useSelector(
    (state: RootState) => state.ticketsReducer
  );

  const setInstanceTicketFromChallengeSetup = () => {
    if (instanceTicket === undefined) {
      return null;
    }
    const payload = {
      duration: durationFormat ? Math.round(durationCount * 24) : durationCount,
      estimate: instanceTicket.estimate,
      story_points: storyPointLocal,
      tickets_mandatory: instanceTicket.tickets_mandatory,
      tickets: instanceTicket.tickets,
    };
    dispatch(setInstanceSetup(payload));
  };

  const switchDurationDay = () => {
    if (!durationFormat) {
      setDurationCount(Math.round(durationCount / 24));
    }
    setDurationFormat(true);
  };

  const switchDurationHour = () => {
    if (durationFormat) {
      setDurationCount(Math.round(durationCount * 24));
    }
    setDurationFormat(false);
  };

  const setDuration = (e: any) => {
    if (e.currentTarget.value < 1) {
      return setDurationCount(1);
    }
    setDurationCount(e.currentTarget.value);
  };

  const setStoryPoint = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStoryPointLocal(parseFloat(e.currentTarget.value));
  };
  return (
    <div className='challenge-setup'>
      <p>Set up challenge</p>
      <div className='selected-fields'>
        <div className='challenge-sp'>
          <span>Story Points</span>
          <select
            defaultValue={storyPointLocal}
            onChange={(e) => setStoryPoint(e)}
          >
            {spRange.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className='challenge-duration'>
          <span>Duration</span>
          <input
            type='number'
            name='duration'
            value={durationCount}
            onChange={(e) => setDuration(e)}
          />
        </div>
        <div className='challenge-format'>
          <span
            style={{
              border: durationFormat
                ? '2px solid #0c74ef'
                : '1px solid rgb(235, 235, 235)',
            }}
            onClick={switchDurationDay}
          >
            Days
          </span>
          <span
            style={{
              border: durationFormat
                ? '1px solid rgb(235, 235, 235)'
                : '2px solid #0c74ef',
            }}
            onClick={switchDurationHour}
          >
            Hours
          </span>
        </div>
      </div>
    </div>
  );
};
