import React from 'react';
import { NavLink } from 'react-router-dom';
import { BackBtn } from '../../shared/components/BackBtn';
import { useHistory } from 'react-router-dom';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { userSelector } from '../../selectors';

interface Props {}

const LINKS = [
  {
    title: 'Cookie Policy',
    href: '/cookie',
  },
  {
    title: 'Terms Of Service',
    href: '/terms-of-service',
  },
  {
    title: 'Data Protection Agreement',
    href: '/data-protection',
  },
  {
    title: 'Privacy Policy',
    href: '/privacy-policy',
  },
];

export const Sidebar = (props: Props) => {
  const user = useShallowEqualSelector(userSelector);
  let history = useHistory();

  return (
    <div style={{ width: '350px' }}>
      <div className='position-sticky' style={{ top: '70px' }}>
        {user && (
          <div className='py-3'>
            <BackBtn
              title='Back to dashboard'
              onClick={() => history.push('/dashboard')}
            />
          </div>
        )}
        <p className='text-lg font-weight-bold'>Legal Agreements</p>
        {LINKS.map((link) => (
          <NavLink
            exact
            to={link.href}
            key={link.href}
            className='d-block mb-2'
          >
            {link.title}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
