import { ICheck } from '../typings/interfaces';
import { ArtifactFormat, ArtifactType } from '../typings/enums';

export const getChecksWithArtifactsData = async (checks: ICheck[]) => {
  const lintPromises: Promise<any>[] = [];
  const testsPromises: Promise<any>[] = [];

  for (const check of checks) {
    for (const artifact of check.data.artifacts) {
      if (artifact.format !== ArtifactFormat.JSON) {
        continue;
      }

      if (artifact.type === ArtifactType.LINT) {
        lintPromises.push(getArtifactData(artifact.src));
      }

      if (artifact.type === ArtifactType.UNIT) {
        testsPromises.push(getArtifactData(artifact.src));
      }
    }
  }

  const [lintDataAll, testsDataAll] = await Promise.all([
    Promise.all(lintPromises),
    Promise.all(testsPromises),
  ]);

  return checks.map((check, i) => {
    const htmlTestArtifact = check.data.artifacts.find(
      (artifact) =>
        artifact.format === ArtifactFormat.HTML &&
        artifact.type === ArtifactType.UNIT
    );
    const htmlLintArtifact = check.data.artifacts.find(
      (artifact) =>
        artifact.format === ArtifactFormat.HTML &&
        artifact.type === ArtifactType.LINT
    );

    return {
      ...check,
      lintData: lintDataAll[i],
      testsData: testsDataAll[i],
      htmlTestLink: htmlTestArtifact ? htmlTestArtifact.src : undefined,
      htmlLintLink: htmlLintArtifact ? htmlLintArtifact.src : undefined,
    };
  });
};

const getArtifactData = async (link: string) => {
  try {
    const response = await fetch(link);
    return response.json();
  } catch (error) {
    console.log(error);
  }
};
