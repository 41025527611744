import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { IUser } from '../../../typings/interfaces';
import { UserRoles } from '../../../typings/enums';
import cn from 'classnames';

interface Props {
  user: IUser | null | undefined;
  className?: string;
  dark?: boolean;
}

export const SignInBtn: React.FC<Props> = ({ user, className, dark }) => {
  let history = useHistory();
  const styles = { padding: '3px 16px' };
  const classes = 'rounded-lg focus:border-blue-300';

  if (!user) {
    return (
      <Link
        id='sign_dashboard'
        className={cn(
          classes,
          'd-block',
          {
            'border-white text-white bg-transparent': !dark,
            'btn btn-outline-dark': dark,
          },
          [className]
        )}
        to='/signin'
        style={styles}
      >
        Sign in
      </Link>
    );
  }

  return (
    <button
      id='sign_dashboard'
      className={cn(
        classes,
        {
          'border-white text-white bg-transparent': !dark,
          'btn btn-outline-dark': dark,
        },
        [className]
      )}
      onClick={() => {
        const role = user.user_claims.roles[0];
        if (role === UserRoles.CANDIDATE) {
          return (window.location.href = window.OPTIONS.CANDIDATE_APP_URL);
        }
        if (role === UserRoles.RECRUITER || role === UserRoles.REVIEWER) {
          return history.push('/dashboard');
        }
      }}
      style={styles}
    >
      To Dashboard
    </button>
  );
};
