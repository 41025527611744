import {
  GET_CHALLENGES_CATEGORIES,
  GET_CHALLENGE,
  ChallengesState,
  ChallengesActionTypes,
} from '../actions/types';

export const initialState: ChallengesState = {
  categories: [],
  challenge: null,
};

export const challengesReducer = (
  state = initialState,
  action: ChallengesActionTypes
): ChallengesState => {
  switch (action.type) {
    case GET_CHALLENGE:
      return {
        ...state,
        challenge: action.payload,
      };
    case GET_CHALLENGES_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    default:
      return state;
  }
};
