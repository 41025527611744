import { RootState } from '../reducers';

export const isConnectedSelector = (state: RootState) =>
  state.notificationsReducer.isConnected;
export const notificationsSelector = (state: RootState) =>
  state.notificationsReducer.notifications;
export const pendingNotificationsSelector = (state: RootState) =>
  state.notificationsReducer.pendingNotifications;
export const errorNotificationsSelector = (state: RootState) =>
  state.notificationsReducer.errorNotifications;
