import { Env, UserRoles } from '../typings/enums';

export const isExpired = (date: string, duration: number) => {
  return Date.now() > Date.parse(date) + duration * 60 * 60 * 1000;
};

export const isExpiring = (date: string, duration: number) => {
  return (
    Date.parse(date) + duration * 60 * 60 * 1000 - Date.now() <
    24 * 60 * 60 * 1000
  );
};

export const generateInviteLink = (uuid: string, role: string) => {
  let env = window.OPTIONS.API_URL.match(/(api-)(\w+)/)[2] || Env.DEV;

  if (role === UserRoles.CANDIDATE) {
    return env === Env.PROD
      ? `https://candidate.sfeeda.com/invitation/${uuid}`
      : `https://candidate-${env}.sfeeda.com/invitation/${uuid}`;
  }
  return `${window.location.origin}/invitation/${uuid}`;
};
