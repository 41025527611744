import React from 'react';
import { ReactComponent as CommentsIcon } from '../../../assets/message.svg';
import cn from 'classnames';

interface Props {
  qty: number;
  size: 'xs' | 'sm';
  className?: string;
}

export const CommentsCount: React.FC<Props> = ({ qty, size, className }) => {
  let sizeNum = 16;
  if (size === 'xs') sizeNum = 16;
  if (size === 'sm') sizeNum = 24;

  return (
    <div className={cn('d-flex align-items-center', [className])}>
      <CommentsIcon className={`icon--${sizeNum} mr-1`} />
      <span
        className={cn({
          'text-xs': size === 'xs',
          'ml-1': size === 'sm',
        })}
      >
        {qty}
      </span>
    </div>
  );
};
