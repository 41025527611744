import React from 'react';
import { INote } from '../../typings/interfaces';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { getColor } from '../../util/getColor';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { NoteType } from '../pages/NotesFeedback/TeamNote';
import { userSelector } from '../../selectors';

interface Props {
  note: INote;
  handleClick: () => void;
  className?: string;
  type?: NoteType;
}

export const Note: React.FC<Props> = ({
  note,
  handleClick,
  className,
  type,
}) => {
  const { candidateId, positionId } = useParams();

  const user = useShallowEqualSelector(userSelector);

  const commentAuthorSymbols = note.created_by
    ? (
        note.created_by.first_name?.[0] + note.created_by.last_name?.[0]
      ).toUpperCase()
    : '';

  let text: any;
  if (typeof note.body === 'string') {
    text = note.body;
  } else {
    text = note.body.text;
  }

  let beforeCommit, afterCommit;
  if (note && note.body.specifier) {
    const specifierArr = note.body.specifier.split('-');
    beforeCommit = specifierArr[0];
    afterCommit = specifierArr[1];
  }

  return (
    <div className={cn([className])}>
      <div className='d-flex justify-content-between mb-2'>
        <div>
          <div className='d-flex align-items-center'>
            <div
              className='mr-2 rounded-circle icon--24 bg-blue-300 text-white text-sm d-flex align-items-center justify-content-center leading-none'
              style={{ background: `#${getColor(commentAuthorSymbols[1])}` }}
            >
              {commentAuthorSymbols}
            </div>
            {note.created_by ? (
              <>
                <div className='mr-2 text-sm leading-none'>
                  {note.created_by.first_name} {note.created_by.last_name}
                </div>
                <div className='text-xs leading-none text-gray-600'>
                  {moment.utc(note.date_updated || note.date_created).fromNow()}
                </div>
              </>
            ) : null}
          </div>
          {note.body.lineId && type === NoteType.TeamNote ? (
            <div>
              <Link
                className='text-sm'
                to={`/position/${positionId}/candidate/${candidateId}/code/${beforeCommit}/${afterCommit}#${note.body.lineId}`}
              >
                <span className='mr-2'>{note.body.fileId}</span>
                <span>line{note.body.lineNumber}</span>
              </Link>
            </div>
          ) : null}
        </div>
        {user && user.identity === note.created_by?.uuid ? (
          <button
            onClick={handleClick}
            className='border-0 bg-transparent d-flex px-0'
          >
            <div className='rounded-circle icon--3 bg-blue-800 mr-1'></div>
            <div className='rounded-circle icon--3 bg-blue-800 mr-1'></div>
            <div className='rounded-circle icon--3 bg-blue-800'></div>
          </button>
        ) : null}
      </div>
      <div className='text-sm'>
        {text.split('\n').map((str: string, i: number) => (
          <div key={i}>{str}</div>
        ))}
      </div>
    </div>
  );
};
