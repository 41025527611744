import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getNotes, clearNotes } from '../../actions/notesActions';
import { PipelineItem } from './PipelineItem';
import { StageContainer } from './StageContainer';
import { Loader } from '../../shared/components/Loader';
import { Stages } from '../../typings/enums';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { Pipeline } from '../../typings/interfaces';
import { ReactComponent as ExclamationIcon } from '../../assets/exclamation.svg';
import { candidatesSelector, selectRecruiterNote } from '../../selectors';

interface Props {
  pipeline: Pipeline | undefined;
  positionId: string;
  pendingCandidates: string[];
}

export const Reviewing: React.FC<Props> = ({
  pipeline,
  positionId,
  pendingCandidates,
}) => {
  const dispatch = useDispatch();

  const positionSolutions = useShallowEqualSelector(candidatesSelector)?.[
    positionId
  ];

  const recruiterNote = useShallowEqualSelector((state) =>
    selectRecruiterNote(state, positionSolutions, positionId)
  );

  useEffect(() => {
    dispatch(getNotes(positionId));
    return () => {
      dispatch(clearNotes());
    };
  }, [dispatch, positionId]);

  return (
    <StageContainer
      condition={{
        'opacity-50': !pipeline?.submitted,
      }}
      classes={['border-blue-300', 'mr-lg-3']}
    >
      <div className='text-sm font-weight-bold mb-4'>Reviewing by team</div>
      {!pendingCandidates.includes(positionId) ? (
        <div className='max-w-146 position-relative w-100'>
          <PipelineItem
            type={Stages.SUBMITTED}
            title='Tasks'
            positionId={positionId}
            stageType='submitted'
            length={pipeline?.submitted}
          />
          {recruiterNote && (
            <div className='position-absolute' style={{ right: 5, top: 0 }}>
              <ExclamationIcon
                style={{ fill: '#FF820F' }}
                className='orange-exclamation'
              />
            </div>
          )}
        </div>
      ) : null}
      {pendingCandidates.includes(positionId) ? <Loader /> : null}
    </StageContainer>
  );
};
