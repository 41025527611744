import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import * as Yup from 'yup';
import { useParams, useHistory, Link } from 'react-router-dom';
import { signup, clearSignUpError } from '../../../actions/userActions';
import { FormFields } from '../../components/FormFields';
import { InviteInfo } from './InviteInfo';
import { ConsentCheckbox } from '../../components/ConsentCheckbox';
import terms from '../../../docs/terms';
import dataProtection from '../../../docs/dataProtection';
import { errorSignUpSelector } from '../../../selectors';

enum validationErrors {
  SHORT = 'Too Short(min length 2)',
  LONG = 'Too Long(max length 255)'
}

export const Signup = () => {
  const dataProtectionConsentRef = useRef<any>();
  const termsOfServiceConsentRef = useRef<any>();
  let history = useHistory();
  const { inviteId } = useParams();
  const dispatch = useDispatch();
  const errorSignUp = useShallowEqualSelector(errorSignUpSelector);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    dataProtectionConsent: false,
    termsOfserviceConsent: false
  };

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, validationErrors.SHORT)
      .max(255, validationErrors.LONG)
      .required('Required'),
    lastName: Yup.string()
      .min(2, validationErrors.SHORT)
      .max(255, validationErrors.LONG)
      .required('Required'),
    password: Yup.string()
      .min(8, 'Too short(min length 8 characters)')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    dataProtectionConsent: Yup.boolean().oneOf([true], 'Required'),
    termsOfserviceConsent: Yup.boolean().oneOf([true], 'Required')
  });

  useEffect(() => {
    return () => {
      dispatch(clearSignUpError());
    };
  }, [dispatch]);

  const fields = {
    firstName: {
      label: 'First Name',
      tabIndex: 1
    },
    lastName: {
      label: 'Last Name',
      tabIndex: 2
    },
    email: {
      label: 'Email',
      autoComplete: 'username',
      tabIndex: 3
    },
    password: {
      label: 'Password',
      id: 'new-password',
      tabIndex: 4
    }
  };

  return (
    <>
      <div className='bg-white rounded-lg pt-4 pb-5 pl-5 pr-20 mb-5 shadow-sm d-flex w-100'>
        <div className='lg:w-6/12 d-flex flex-column justify-content-between'>
          <div>
            <p
              className='text-2xl font-weight-bold lh-32'
              style={{ maxWidth: '389px' }}
            >
              Create profile and join the team you have been invited to
            </p>
            <InviteInfo />
          </div>
          <div>
            <Link to={`/signin/${inviteId}`}>I have account</Link>
          </div>
        </div>
        <div className='lg:w-6/12'>
          {errorSignUp ? <p className='text-red-400'>{errorSignUp}</p> : null}
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(
                signup(
                  {
                    email: values.email,
                    password: values.password,
                    first_name: values.firstName,
                    last_name: values.lastName
                  },
                  inviteId,
                  (response) => {
                    setSubmitting(false);
                    if (response.data) {
                      history.push('/dashboard');
                    }
                  },
                  [
                    {
                      kind: 'TERMS',
                      link_text:
                        dataProtectionConsentRef?.current?.innerText || '',
                      text: dataProtection
                    },
                    {
                      kind: 'TERMS',
                      link_text:
                        termsOfServiceConsentRef?.current?.innerText || '',
                      text: terms
                    }
                  ]
                )
              );
            }}
            validationSchema={SignupSchema}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <FormFields errors={errors} touched={touched} fields={fields} />
                <>
                  <ConsentCheckbox
                    isError={errors.dataProtectionConsent}
                    id='data-protection-consent'
                    isTouched={touched.dataProtectionConsent}
                    name='dataProtectionConsent'
                    ref={dataProtectionConsentRef}
                    href='https://sfeeda.com/data-protection'
                    title='data protection agreement'
                    tabIndex={5}
                  />
                  <ConsentCheckbox
                    isError={errors.termsOfserviceConsent}
                    id='terms-of-services-consent'
                    isTouched={touched.termsOfserviceConsent}
                    name='termsOfserviceConsent'
                    href='https://sfeeda.com/terms-of-service'
                    ref={termsOfServiceConsentRef}
                    title='terms of service'
                    tabIndex={6}
                  />
                </>
                <Button
                  type='submit'
                  id='create-proceed'
                  data-testid='create-proceed'
                  variant='warning'
                  disabled={isSubmitting}
                  className='text-lg px-5 rounded-lg'
                  tabIndex={7}
                >
                  Create and join team
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
