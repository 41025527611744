import React, { useState } from 'react';
import { Feedback } from './Feedback';
import { ISolution } from '../../typings/interfaces';
import { RecruiterNote } from './RecruiterNote';
import { CustomCollapse } from '../../shared/components/CustomCollapse';

interface Props {
  candidate: ISolution;
}

export const FeedbackNote: React.FC<Props> = React.forwardRef(
  ({ candidate }, ref: any) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const expandHandler = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <CustomCollapse
        cardInitialHeight={160}
        isExpanded={isExpanded}
        onExpand={expandHandler}
      >
        <div className='d-flex w-100 justify-content-between' ref={ref}>
          <div className='w-6/12 text-sm'>
            <div className='text-gray-850'>Feedback to candidate</div>
            <Feedback currentCandidate={candidate} />
          </div>
          <div className='w-5/12 text-sm'>
            <div className='text-gray-850'>Team Note</div>
            <RecruiterNote candidate={candidate} />
          </div>
        </div>
      </CustomCollapse>
    );
  }
);
