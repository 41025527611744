import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { closeNavigationMenu } from '../../../actions/slideMenuActions';
import { toggleNavigationMenu } from '../../../actions/slideMenuActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import './SlideMenu.scss';

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

interface Props {
  children: React.ReactNode;
}

export const SlideMenu: React.SFC<Props> = ({ children }) => {
  const dispatch = useDispatch();

  const slideMenu = useRef<any>();
  const overLay = useRef<any>();

  const isShown = useShallowEqualSelector(
    (state) => state.slideMenuReducer.isShown
  );

  useEffect(() => {
    const slideMenuElement = slideMenu.current; // for fix warning
    if (isShown) {
      disableBodyScroll(slideMenu.current);
    } else {
      enableBodyScroll(slideMenu.current);
    }
    return () => {
      enableBodyScroll(slideMenuElement);
    };
  }, [isShown]);

  const overLayStyles = !isShown
    ? {
        zIndex: -1,
        opacity: 0,
      }
    : {
        zIndex: 1000,
        opacity: 1,
      };

  const slideMenuStyles = !isShown
    ? {
        transform: 'translate3d(-100%, 0, 0)',
      }
    : {
        transform: 'translate3d(0, 0, 0)',
      };

  const closeMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === overLay.current) dispatch(closeNavigationMenu());
  };

  return (
    <>
      <div
        className='slide-menu-overlay position-fixed h-100 w-100'
        style={overLayStyles}
        onClick={closeMenu}
        ref={overLay}
        id='overlay'
      ></div>
      <div
        className='h-100 position-fixed bg-sidebar slide-menu w-100 pb-5'
        style={slideMenuStyles}
        ref={slideMenu}
      >
        {children}
      </div>
      <button
        className='position-fixed rounded-circle border-0 bg-white shadow-sm'
        style={{
          bottom: '10px',
          right: '10px',
          zIndex: 1001,
          height: '45px',
          width: '45px',
        }}
        type='button'
        onClick={() => dispatch(toggleNavigationMenu(isShown))}
      >
        {!isShown ? (
          <FontAwesomeIcon icon={faAlignLeft} size='lg' />
        ) : (
          <CloseIcon />
        )}
      </button>
    </>
  );
};
