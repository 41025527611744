import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../../../styles/normalize.scss';
import './index.scss';
import { Header } from '../../../containers/CandidatesContainer/Header';
import { RootState } from '../../../reducers';
import { ChallengeDesc } from './ChallengeDesc';
import { ChallengeSetup } from './ChallengeSetup';
import { TicketDashboard } from './TicketDashboard';
import { Loader } from '../../../shared/components/Loader';
import {
  ticketsFromChallange,
  putUpdatedTickets,
} from '../../../actions/ticketsActions';

export const TaskTableSimple = () => {
  const { positionId } = useParams();
  const dispatch = useDispatch();

  const challenge = useSelector((state: RootState) => state.taskReducer);
  const { tickets, instanceTicket } = useSelector(
    (state: RootState) => state.ticketsReducer
  );

  useEffect(() => {
    dispatch(ticketsFromChallange(positionId));
  }, []);

  if (!challenge.task || !tickets || !instanceTicket) {
    return <Loader />;
  }

  return (
    <div className='task-table-wrap'>
      <Header />
      <div className='challenge-info'>
        <h1>{challenge.task?.challenge.title} Challenge</h1>
        <ChallengeDesc
          text={`Air Pollution data from all over the world. <br/> The app utilises <a href=${'http://openaq.org'} target="_blank" rel="noopener noreferrer" >http://openaq.org</a> to obtain the data.`}
        />
        <ChallengeSetup
          storyPoints={{
            min: challenge.task.min_story_points,
            max: challenge.task.max_story_points,
            count: instanceTicket.story_points,
          }}
          duration={instanceTicket.duration}
        />

        <TicketDashboard ticketsEntity={tickets} />
      </div>
      <div
        className='update-task-btn'
        onClick={() => dispatch(putUpdatedTickets(instanceTicket, positionId))}
      >
        Update task
      </div>
    </div>
  );
};
