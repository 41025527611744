import React from 'react';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { capitalize } from '../../../util/capitalize';
import { inviteSelector, positionSelector } from '../../../selectors';

interface FieldProps {
  title: string | undefined;
  value: string | undefined;
}

const Field: React.FC<FieldProps> = ({ title, value }) => {
  return (
    <>
      <div className='text-gray-600'>{title}</div>
      <p className='font-weight-bold'>{value}</p>
    </>
  );
};

export const InviteInfo = () => {
  const invite = useShallowEqualSelector(inviteSelector);
  const position = useShallowEqualSelector(positionSelector);

  return (
    <div>
      {position ? (
        <>
          <Field title='Company' value={position.company.title} />
          <Field title='Team' value={position.team.title} />
        </>
      ) : null}
      {invite && <Field title='Role' value={capitalize(invite.role.name)} />}
    </div>
  );
};
