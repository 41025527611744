import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Logo, LogoProps } from '../../components/Logo';
import { Avatar } from '../../../shared/components/Avatar/index';
import './index.scss';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { meSelector } from '../../../selectors';

interface ShareTopProps extends LogoProps {
  topWrapAddClass?: string;
  gap?: number;
  shareNavsStyle: 'light' | 'dark';
}

export const ShareTop: FC<ShareTopProps> = ({
  withEnv,
  height,
  type,
  topWrapAddClass,
  gap = 60,
  shareNavsStyle,
}) => {
  const user = useShallowEqualSelector(meSelector);
  return (
    <div className={`share-top-wrap ${topWrapAddClass}`}>
      <div className='share-top' style={{ width: `calc(100% - ${gap}px)` }}>
        <Logo withEnv={withEnv} height={height} type={type} />
        <div className={`share-navs-${shareNavsStyle}`}>
          <Link to='/contact'>Contact</Link>
          {user ? (
            <Avatar
              data={{ first_name: user.first_name, last_name: user.last_name }}
            />
          ) : (
            <Link to='/signin'>Sign in</Link>
          )}
        </div>
      </div>
    </div>
  );
};
