import React from 'react';
import { ReactComponent as SourcingImg } from '../../../assets/newLanding/sourcing.svg';
import { ReactComponent as ScreeningImg } from '../../../assets/newLanding/screening.svg';
import { ReactComponent as InterviewImg } from '../../../assets/newLanding/interview.svg';
import { Row, Col } from 'react-bootstrap';

export const Steps = () => {
  return (
    <div className='pb-8 pb-md-12'>
      <Row>
        <Col className='d-none d-lg-block text-blue-100' lg={3}>
          <div>
            <span className='display-2'>10</span>
            <span className='text-2xl'>min</span>
          </div>
          <div className='text-lg' style={{ maxWidth: '187px' }}>
            of engineers’ time needed to asses a single candidate
          </div>
        </Col>
        <Col>
          {STEPS.map(({ label, title, img, text, imgText }) => (
            <Row className='mb-8 justify-content-lg-between' key={label}>
              <Col xs={12} lg={7} style={{ maxWidth: '500px' }}>
                <div className='text-warning text-xs md:text-sm lh-24'>
                  {label.toUpperCase()}
                </div>
                <p className='text-blue-100 text-xl mb-2 lg:text-2-6xl lh-32'>
                  {title}
                </p>
                <div className='text-silver pr-2 lg:text-xl mb-2'>{text}</div>
              </Col>
              <Col xs={12} lg={5}>
                {img}
                <div className='text-silver text-13 mt-2'>{imgText}</div>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </div>
  );
};

const STEPS = [
  {
    label: 'Sourcing',
    title: 'Process a higher volume of candidates via the coding assessment',
    text:
      'Never let that special candidate slip through your fingers again. Sfeeda allows you to screen more candidates in less time',
    img: <SourcingImg />,
    imgText: 'Invite all "maybes"',
  },
  {
    label: 'Screening',
    title: 'Reduce your engineers’ time by 66%',
    text:
      'We help you identify candidates who are not up to scratch in advance. Engineers spend less than 10 minutes evaluating each candidate.',
    img: <ScreeningImg />,
    imgText: 'Assess 30 candidates in 5 hours',
  },
  {
    label: 'Interview only the best',
    title: 'Interview only the promising candidates',
    text:
      'Hiring managers only spend their time interviewing the most promising and interesting candidates.',
    img: <InterviewImg />,
    imgText: 'All promising candidates invited to interview',
  },
];
