import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFilesDiff } from '../../../actions/gitActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { FilesDiff } from '../../components/FilesDiff';
import { ICheck } from '../../../typings/interfaces';
import { useParams } from 'react-router-dom';
import { CommitDropdowns } from '../../components/CommitDropdowns';
import { Header } from './Header';
import {
  userSelector,
  currentCandidateSelector,
  checksSelector,
  errorChecksSelector,
  filesDiffSelector,
} from '../../../selectors';

interface Props {
  share?: boolean;
}

export const FilesChanged: React.FC<Props> = ({ share }) => {
  const { sourceCommit, destinationCommit } = useParams();
  const dispatch = useDispatch();

  const checks = useShallowEqualSelector(checksSelector);
  const errorChecks = useShallowEqualSelector(errorChecksSelector);
  const user = useShallowEqualSelector(userSelector);
  const currentCandidate = useShallowEqualSelector(currentCandidateSelector);
  const filesDiff = useShallowEqualSelector(filesDiffSelector);

  const commitsArray = checks
    ? checks.sort(
        (a, b) => Date.parse(b.date_created) - Date.parse(a.date_created)
      )
    : [];

  let beforeCommit, afterCommit;

  if (commitsArray && commitsArray.length > 1) {
    const destinationCommitIndex = commitsArray.findIndex(
      (commit: ICheck) => commit.commit === destinationCommit
    );
    const sourceCommitIndex = commitsArray.findIndex(
      (commit: ICheck) => commit.commit === sourceCommit
    );

    beforeCommit =
      sourceCommitIndex !== -1 ? commitsArray[sourceCommitIndex] : undefined;
    afterCommit =
      destinationCommitIndex !== -1
        ? commitsArray[destinationCommitIndex]
        : undefined;
  }

  const positionId = currentCandidate?.position.uuid;
  const userId = currentCandidate?.candidate.user.uuid;

  useEffect(() => {
    if (positionId && userId && sourceCommit && destinationCommit) {
      dispatch(
        getFilesDiff(positionId, userId, sourceCommit, destinationCommit)
      );
    }
  }, [dispatch, positionId, sourceCommit, destinationCommit, userId]);

  if (!currentCandidate) {
    return null;
  }

  return (
    <div>
      {share ? (
        <div className='text-lg font-weight-bold mb-2'>
          Check what code changes the candidate did
        </div>
      ) : (
        <Header title={'Code Changes'} />
      )}
      {errorChecks ? <div>{errorChecks}</div> : null}
      {checks && checks.length < 2 ? (
        <div>No changes</div>
      ) : (
        <div>
          {!share && (
            <div
              className='d-flex flex-column flex-lg-row flex-wrap mb-3'
              data-testid='commits-dropdowns'
            >
              {commitsArray && (
                <CommitDropdowns
                  beforeCommit={beforeCommit}
                  afterCommit={afterCommit}
                  commitsArray={commitsArray}
                />
              )}
            </div>
          )}
          {commitsArray && (!destinationCommit || !sourceCommit) ? (
            <div>Choose commits for compare</div>
          ) : (
            <FilesDiff
              isHidden={share && !user}
              filesDiff={filesDiff || []}
              beforeCommit={sourceCommit}
              afterCommit={destinationCommit}
              candidate={currentCandidate}
            />
          )}
        </div>
      )}
    </div>
  );
};
