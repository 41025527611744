import React from 'react';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { NoteTextarea } from '../pages/NotesFeedback/NoteTextarea';
import { NoteType } from '../../typings/enums';

interface Props {}

export const RecruiterNote = (props: Props) => {
  const currentCandidate = useShallowEqualSelector(
    (state) => state.candidatesReducer.currentCandidate
  );

  const notes = useShallowEqualSelector((state) => state.notesReducer.notes);
  const pendingNotes = useShallowEqualSelector(
    (state) => state.notesReducer.pendingNotes
  );

  let noteForRecruiter;
  if (notes && currentCandidate) {
    noteForRecruiter = notes[currentCandidate.position.uuid]?.find(
      (note) =>
        note.body.type === NoteType.to_recruiter &&
        note.user_id === currentCandidate.candidate.user.uuid
    );
  }

  if (!currentCandidate) {
    return null;
  }

  return (
    <div>
      <div className='font-weight-bold text-lg mb-1'>Note for recruiter</div>
      {!pendingNotes && (
        <NoteTextarea
          currentCandidate={currentCandidate}
          note={noteForRecruiter}
          type={NoteType.to_recruiter}
          placeholder='Candidate won’t see it. Be honest'
        />
      )}
    </div>
  );
};
