import { useState, useEffect } from 'react';

export const useMatchMedia = (minWidth: number): [boolean] => {
  const [isMatchMediaQuery, setIsMatchMediaQuery] = useState<boolean>(false);

  useEffect(() => {
    let mql = window.matchMedia(`(min-width: ${minWidth}px)`);

    if (mql.matches) {
      setIsMatchMediaQuery(true);
    } else {
      setIsMatchMediaQuery(false);
    }

    const handleDimension = () => {
      if (mql.matches) {
        return setIsMatchMediaQuery(true);
      }
      setIsMatchMediaQuery(false);
    };

    mql.addListener(handleDimension);

    return () => {
      mql.removeListener(handleDimension);
    };
  }, [setIsMatchMediaQuery, minWidth]);

  return [isMatchMediaQuery];
};
