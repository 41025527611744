import React from 'react';

interface Props {
  qty: number;
  title: string;
}

export const StageTitle: React.FC<Props> = ({ title, qty }) => {
  return (
    <div className='d-flex mb-2 pl-1'>
      <div className='text-2xl lh-32 mr-2 font-weight-bold'>{title}</div>
      <span className='text-gray-600'>{qty}</span>
    </div>
  );
};
