import React from 'react';
import '../../../styles/backgrounds.css';
import cn from 'classnames';
import { NavBar } from '../NavBar';

interface Props {
  handleLinkClick?: (
    e: React.MouseEvent<Element, MouseEvent>
  ) => void | undefined;
  headerText: JSX.Element;
  btns: JSX.Element;
  isLanding?: boolean;
  isHeroImage?: boolean;
}

const LINKS = [
  {
    titleDesktop: 'Hiring teams',
    titleMob: 'Teams',
    href: '/hiring_teams',
  },
  {
    titleDesktop: 'Recruiters',
    titleMob: 'Recruiters',
    href: '/recruiters',
  },

  {
    titleDesktop: 'Pricing',
    titleMob: 'Pricing',
    href: 'pricing',
  },
  {
    titleDesktop: 'Contact',
    titleMob: 'Contact',
    href: '/contact',
  },
];

export const Header: React.FC<Props> = ({
  handleLinkClick,
  headerText,
  btns,
  isLanding,
  isHeroImage = true,
}) => {
  return (
    <header
      className={cn('bg-blue-900', {
        'pb-6': !isHeroImage,
      })}
    >
      <div
        className={cn(
          'd-flex flex-column pt-6 justify-content-between min-vh-full',
          {
            'md:min-vh-50': !isHeroImage,
          }
        )}
      >
        <div>
          <div className='container'>
            <NavBar handleLinkClick={handleLinkClick} links={LINKS} />
            {headerText}
            {btns}
          </div>
        </div>
        {isHeroImage && <div className='bg-hero w-100 flex-grow-1'></div>}
      </div>
    </header>
  );
};
