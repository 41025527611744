import { INotifications } from '../../typings/interfaces';

export enum NotificationsContants {
  CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS',
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
  PENDING_NOTIFICATIONS = 'PENDING_NOTIFICATIONS',
  ERROR_NOTIFICATIONS = 'ERROR_NOTIFICATIONS',
  START_SOCKET = 'START_SOCKET',
  STOP_SOCKET = 'STOP_SOCKET',
}

export interface NotificationsState {
  notifications: INotifications | undefined;
  pendingNotifications: boolean;
  isConnected: boolean;
  errorNotifications: string;
}

interface GetNotifications {
  type: typeof NotificationsContants.GET_NOTIFICATIONS;
  payload: { positionId: string; notifications: INotifications };
}

interface PendingNotificationsAction {
  type: typeof NotificationsContants.PENDING_NOTIFICATIONS;
}
interface ErrorNotifications {
  type: typeof NotificationsContants.ERROR_NOTIFICATIONS;
  payload: string;
}
interface StartSocketAction {
  type: typeof NotificationsContants.START_SOCKET;
}
interface StopSocketAction {
  type: typeof NotificationsContants.STOP_SOCKET;
}
interface ClearNotificationsAction {
  type: typeof NotificationsContants.CLEAR_NOTIFICATIONS;
}

export type NotificationsActionsTypes =
  | GetNotifications
  | PendingNotificationsAction
  | StartSocketAction
  | StopSocketAction
  | ClearNotificationsAction
  | ErrorNotifications;
