import { INote } from '../../typings/interfaces';

export enum NotesTypesConstants {
  GET_NOTES = 'GET_NOTES',
  PEDNING_NOTES = 'PENDING_NOTES',
  ERROR_NOTES = 'ERROR_NOTES',
  ERROR_CREATE_NOTE = 'ERROR_CREATE_NOTE',
  CREATE_NOTE = 'CREATE_NOTE',
  UPDATE_NOTE = 'UPDATE_NOTE',
  CLEAR_NOTES = 'CLEAR_NOTES',
  PENDING_NOTE_UPDATE = 'PENDING_NOTE_UPDATE',
}

export interface NotesState {
  notes: { [key: string]: INote[] } | undefined;
  pendingNotes: boolean;
  errorNotes: string;
  errorCreateNote: string;
  pendingNoteUpdate: boolean;
}

interface getNotesAction {
  type: typeof NotesTypesConstants.GET_NOTES;
  payload: { notes: INote[]; positionId: string };
}

interface pendingNotesAction {
  type: typeof NotesTypesConstants.PEDNING_NOTES;
}
interface pendingNoteUpdateAction {
  type: typeof NotesTypesConstants.PENDING_NOTE_UPDATE;
}
interface clearNotesAction {
  type: typeof NotesTypesConstants.CLEAR_NOTES;
}

interface errorCommentsAction {
  type: typeof NotesTypesConstants.ERROR_NOTES;
  payload: string;
}
interface errorcreateNoteAction {
  type: typeof NotesTypesConstants.ERROR_CREATE_NOTE;
  payload: string;
}
interface createNoteAction {
  type: typeof NotesTypesConstants.CREATE_NOTE;
  payload: INote;
}
interface updateCommentAction {
  type: typeof NotesTypesConstants.UPDATE_NOTE;
  payload: INote;
}

export type NotesActionsTypes =
  | pendingNotesAction
  | errorCommentsAction
  | errorcreateNoteAction
  | createNoteAction
  | getNotesAction
  | clearNotesAction
  | pendingNoteUpdateAction
  | updateCommentAction;
