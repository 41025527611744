import {
  ISolution,
  PositionsCandidates,
  INote
} from '../../typings/interfaces';

export enum candidatesActionTypesConstants {
  GET_CANDIDATE_FEEDBACK = 'GET_CANDIDATE_FEEDBACK',
  ERROR_SEND_FEEDBACK = 'ERROR_SEND_FEEDBACK',
  CLEAR_ERROR_SEND_FEEDBACK = 'CLEAR_ERROR_SEND_FEEDBACK',
  GET_POSITION_CANDIDATES = 'GET_POSITION_CANDIDATES',
  PENDING_CANDIDATES = 'PENDING_CANDIDATES',
  ERROR_CANDIDATES = 'ERROR_CANDIDATES',
  CLEAR_CANDIDATES = 'CLEAR_CANDIDATES',
  CLEAR_CANDIDATE = 'CLEAR_CANDIDATE',
  SET_CURRENT_CANDIDATE = 'SET_CURRENT_CANDIDATE',
  ATTACH_FEEDBACK = 'ATTACH_FEEDBACK',
  DETACH_FEEDBACK = 'DETACH_FEEDBACK',
  PENDING_CANDIDATE = 'PENDING_CANDIDATE',
  ERROR_CANDIDATE = 'ERROR_CANDIDATE',
  GET_CANDIDATE_NOTE = 'GET_CANDIDATE_NOTE',
  ERROR_CANDIDATE_NOTE = 'ERROR_CANDIDATE_NOTE',
  PENDING_CANDIDATE_NOTE = 'PENDING_CANDIDATE_NOTE',
  CLEAR_CANDIDATE_NOTE = 'CLEAR_CANDIDATE_NOTE'
}

export interface CandidatesState {
  errorFeedback: string[];
  teamNote: string;
  pendingCandidates: string[];
  errorCandidates: string[];
  attachedFeedbacks: string[];
  currentCandidate: ISolution | undefined;
  candidates: PositionsCandidates | undefined;
  pendingCandidate: boolean;
  errorCandidate: string;
  candidateNote: INote | undefined;
  errorCandidateNote: string | undefined;
  pendingCandidateNote: boolean;
}

interface GetCandidatesAction {
  type: typeof candidatesActionTypesConstants.GET_POSITION_CANDIDATES;
  payload: { positionId: string; candidates: ISolution[] };
}
interface PendingCandidatesAction {
  type: typeof candidatesActionTypesConstants.PENDING_CANDIDATES;
  payload: string;
}
interface ErrorCandidatesAction {
  type: typeof candidatesActionTypesConstants.ERROR_CANDIDATES;
  payload: string;
}
interface ErrorFeedbackAction {
  type: typeof candidatesActionTypesConstants.ERROR_SEND_FEEDBACK;
  payload: string;
}
interface ErrorCandidateAction {
  type: typeof candidatesActionTypesConstants.ERROR_CANDIDATE;
  payload: string;
}
interface ClearErrorFeedbackAction {
  type: typeof candidatesActionTypesConstants.CLEAR_ERROR_SEND_FEEDBACK;
  payload: string;
}
interface ClearCandidatesAction {
  type: typeof candidatesActionTypesConstants.CLEAR_CANDIDATES;
}
interface ClearCandidateAction {
  type: typeof candidatesActionTypesConstants.CLEAR_CANDIDATE;
}
interface SetCandidateAction {
  type: typeof candidatesActionTypesConstants.SET_CURRENT_CANDIDATE;
  payload: ISolution;
}
interface PendingCandidateAction {
  type: typeof candidatesActionTypesConstants.PENDING_CANDIDATE;
}
interface AttachFeedbackAction {
  type: typeof candidatesActionTypesConstants.ATTACH_FEEDBACK;
  payload: string;
}
interface DetachFeedbackAction {
  type: typeof candidatesActionTypesConstants.DETACH_FEEDBACK;
  payload: string[];
}
interface GetCandidateNoteAction {
  type: typeof candidatesActionTypesConstants.GET_CANDIDATE_NOTE;
  payload: INote;
}
interface PendingtCandidateNoteAction {
  type: typeof candidatesActionTypesConstants.PENDING_CANDIDATE_NOTE;
}
interface ErrorCandidateNoteAction {
  type: typeof candidatesActionTypesConstants.ERROR_CANDIDATE_NOTE;
  payload: string;
}
interface ClearCandidateNoteAction {
  type: typeof candidatesActionTypesConstants.CLEAR_CANDIDATE_NOTE;
}

export type CandidatesActionsTypes =
  | GetCandidatesAction
  | PendingCandidatesAction
  | ErrorCandidatesAction
  | ErrorFeedbackAction
  | ClearCandidatesAction
  | ClearErrorFeedbackAction
  | AttachFeedbackAction
  | DetachFeedbackAction
  | PendingCandidateAction
  | ErrorCandidateAction
  | GetCandidateNoteAction
  | PendingtCandidateNoteAction
  | ErrorCandidateNoteAction
  | ClearCandidateNoteAction
  | ClearCandidateAction
  | SetCandidateAction;
