import React from 'react';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow_down.svg';

interface Props {
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isExpanded: boolean;
  path: string;
}

export const PathBtn: React.FC<Props> = ({
  setIsExpanded,
  isExpanded,
  path,
}) => {
  return (
    <button
      className='px-4 pt-2 pb-1 border-0 bg-transparent w-100 focus:outline-none'
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div
        className='text-lg mb-1 d-flex align-items-center'
        style={{ lineHeight: '24px' }}
      >
        <ArrowIcon
          className='mr-2'
          style={{
            transform: isExpanded ? '' : 'rotate(-90deg)',
            transition: 'transform .15s',
          }}
        />
        {path}
      </div>
    </button>
  );
};
