import React from 'react';
import { Step } from './Step';
import cn from 'classnames';

const Blocks = [
  {
    title: 'Invite candidates',
    text:
      'With a recruiter or on your own. Just send the link, we’ll take care of everything else',
  },
  {
    title: 'Work in progress',
    text:
      'Candidates work as a part of a team with tickets, their own repository and their own editor',
  },
  {
    title: 'Simplified tech review',
    text:
      'Your engineers get a pre-built solution and preliminary code analysis to speed up the process',
  },
];

export const Steps = () => {
  return (
    <div className='container d-flex flex-column flex-lg-row justify-content-lg-between mb-20'>
      {Blocks.map((step, i: number) => (
        <Step
          num={i + 1}
          key={i}
          className={cn('d-flex lg:w-4/12 mb-6 mb-lg-0', {
            'mr-3': i !== Blocks.length - 1,
          })}
          title={step.title}
          text={step.text}
        />
      ))}
    </div>
  );
};
