import React, { ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
  link: string | undefined;
  isHidden?: boolean;
  testId?: string;
}

export const CheckItem: React.FC<Props> = ({
  title,
  children,
  link,
  isHidden,
  testId
}) => {
  const boxContent = (
    <>
      <div
        className='font-weigth-bold text-lg font-weight-bold text-left'
        data-testid={testId || false}
      >
        {title}
      </div>
      <div className='text-body'>{children}</div>
    </>
  );

  if (link && !isHidden) {
    return (
      <a
        href={link}
        target='_blank'
        rel='noopener noreferrer'
        className='border border-gray-600 bg-transparent rounded-lg w-100 pt-2 pb-3 px-4 cursor-pointer mr-3 hover:text-purple hover:border-blue-700 check-item'
        style={{ maxWidth: '330px' }}
      >
        {boxContent}
      </a>
    );
  } else {
    return (
      <div
        className='border border-gray-600 bg-transparent rounded-lg w-100 pt-2 pb-3 px-4 cursor-pointer mr-3 hover:text-purple hover:border-blue-700 check-item'
        style={{ maxWidth: '330px' }}
      >
        {boxContent}
      </div>
    );
  }
};
