import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { LoaderMini } from '../components/LoaderMini';
import { FormContainer } from '../components/SubscribeForms/FormContainer';
import * as Yup from 'yup';
import { IFields } from '../../typings/interfaces';
import { FormFields } from '../components/FormFields';
import { Button } from 'react-bootstrap';
import { errorText } from '../../util/fetchWithToken';
import { showAlert } from '../../actions/alertActions';
import { AlertType } from '../../typings/enums';

const initialValues: FormValues = {
  email: '',
};

interface FormValues {
  email: string;
}

export const ForgotPassword: React.SFC = () => {
  const [isSent, setIsSent] = useState(false);
  const dispatch = useDispatch();

  const fields: IFields = {
    email: {
      label: 'Email',
      id: 'field_username',
      autoComplete: 'username',
    },
  };

  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            const res = await fetch(
              `${window.OPTIONS.API_URL}/auth/password/reset/`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email: values.email,
                }),
              }
            );
            if (!res.ok) throw new Error(errorText);
            setIsSent(true);
          } catch (error) {
            dispatch(
              showAlert({ message: error.message, type: AlertType.fail })
            );
          }
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        })}
      >
        {({ errors, touched, isSubmitting }) => (
          <div style={{ maxWidth: '430px' }} className='mx-auto'>
            <h1 className='text-4-5xl text-center font-weight-bold'>
              Forgot password?
            </h1>
            <div className='text-center text-sm mb-4'>
              <div>
                If account exists, an email will be sent with further
                instructions.
              </div>
              <div>In case of any problems contact us sfeeda@sfeeda.com</div>
            </div>
            <Form className='items-center d-flex flex-column w-100'>
              <FormFields fields={fields} errors={errors} touched={touched} />
              <Button
                variant='warning'
                disabled={isSubmitting}
                id='sign_in'
                type='submit'
                className='md:text-xl mb-6 rounded-lg d-flex align-items-center justify-content-center h-10 md:h-16 font-weight-bold'
              >
                {isSubmitting ? <LoaderMini /> : 'Request Password Reset'}
              </Button>
            </Form>
            {isSent && (
              <div className='text-center text-orange-300'>
                We have emailed you a link to reset your password
              </div>
            )}
          </div>
        )}
      </Formik>
    </FormContainer>
  );
};
