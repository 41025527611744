import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { IPosition } from '../../typings/interfaces';
import { showAddPostion } from '../../actions/positionsActions';
import { Logo } from '../../shared/components/Logo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import { UserRoles, Stages } from '../../typings/enums';
import './Sidebar.scss';
import { userSelector, userRoleSelector } from '../../selectors';
import { positionsSelector, candidatesSelector } from '../../selectors';
import { filterSolutionByStage } from '../../util/filterSolutionByStage';

export const Sidebar: React.FC = () => {
  const { hideAddPositionBtn } = useFlags();

  const [active, setActive] = useState<string>('');
  const dispatch = useDispatch();

  const user = useShallowEqualSelector(userSelector);
  const userRole = useShallowEqualSelector(userRoleSelector);
  const positions = useShallowEqualSelector(positionsSelector);

  const candidates = useShallowEqualSelector(candidatesSelector);

  const scrollToElement = (selector: string) => {
    const element = document.querySelector(selector) as HTMLDivElement;
    if (!element) return;
    window.scrollTo({ top: element.offsetTop - 70, left: 0 });
  };

  const scrollHandler = useCallback(() => {
    positions && setActive(getElementInView(positions));
  }, [positions]);

  useEffect(() => {
    scrollHandler();
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  const isRecruiter = user && user.user_claims.roles[0] === UserRoles.RECRUITER;

  return (
    <div className='py-4 px-4 position-sticky' style={{ top: 0 }}>
      <Link to='/' className='text-xl text-white mb-8 d-block'>
        <Logo withEnv />
      </Link>
      {positions && positions.length > 0 && !hideAddPositionBtn && (
        <button
          className='btn btn-warning rounded-pill py-0 mb-4'
          onClick={() => dispatch(showAddPostion(true))}
        >
          + Add position
        </button>
      )}
      <div className='text-gray-800 text-xs mb-4'>
        <span>POSITIONS </span>{' '}
        {positions && positions.length > 1 && (
          <span className='text-gray-300'>{positions && positions.length}</span>
        )}
      </div>
      {positions && (
        <div className='recruiter-scrollspy'>
          {Object.entries(sortByCompany(positions)).map(
            ([companyName, companyPositions]: any) => {
              return (
                <div key={uuid()}>
                  <div className='mb-6'>
                    {Object.keys(sortByCompany(positions)).length > 1 && (
                      <div className='text-white text-lg mb-2'>
                        {companyName.toUpperCase()}
                      </div>
                    )}
                    {companyPositions.map((position: IPosition) => {
                      const processed =
                        candidates &&
                        candidates[position.uuid]?.filter((solution) =>
                          filterSolutionByStage(
                            solution,
                            userRole,
                            Stages.PROCESSED
                          )
                        );
                      return (
                        <a
                          key={position.uuid}
                          href={`#position-${position.uuid}`}
                          className={cn('d-block text-sm mb-2', {
                            active: active === position.uuid,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            scrollToElement(`#position-${position.uuid}`);
                            setActive(position.uuid);
                          }}
                        >
                          {position.title}{' '}
                          {isRecruiter && processed?.length ? (
                            <span className='rounded-pill bg-red-400 px-1 text-sm'>
                              {processed.length}
                            </span>
                          ) : null}
                        </a>
                      );
                    })}
                  </div>
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

const sortByCompany = (positions: IPosition[]) => {
  let sortedPositions = positions.sort((a, b) => {
    if (b.title.toLocaleLowerCase() > a.title.toLocaleLowerCase()) return -1;
    return 1;
  });

  return sortedPositions.reduce((acc: any, position) => {
    acc[position.company.title] = [
      ...(acc?.[position.company.title] || []),
      position,
    ];
    return acc;
  }, {});
};

const getElementInView = (positions: IPosition[]) => {
  return (
    positions
      .reduce((acc: any[], position) => {
        const element = document.querySelector(`#position-${position.uuid}`);
        if (element) {
          const bounding = element.getBoundingClientRect();
          if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.right <=
              (window.innerWidth || document.documentElement.clientWidth) &&
            bounding.bottom <=
              (window.innerHeight || document.documentElement.clientHeight)
          ) {
            acc.push({
              id: position.uuid,
              top: bounding.top,
            });
          }
        }
        return acc;
      }, [])
      .sort((a, b) => a.top - b.top)?.[0]?.id || ''
  );
};
