import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Tree } from './Tree';
import { Blob } from './Blob';

interface GitContentProps {
  id?: string;
  path: string;
  positionId: string;
}

export const GitContent: FC<GitContentProps> = ({ id, path, positionId }) => {
  let { gitContentType } = useParams();

  return (
    <div style={{ width: '80%' }}>
      {gitContentType === 'blob' ? (
        <Blob id={id} path={path} positionId={positionId} />
      ) : (
        <Tree id={id} path={path} positionId={positionId} />
      )}
    </div>
  );
};
