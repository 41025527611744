import React from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  showModal,
  modalInviteWithoutEmail,
} from '../../../actions/modalActions';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { setCurrentPosition } from '../../../actions/positionsActions';
import { Stages } from '../../../typings/enums';
import { CandidatesQty } from './CandidatesQty';
import { InvitesQty } from './InvitesQty';
import { Actions } from '../../../typings/enums';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  position: any;
  title: string;
  type: Stages;
  borderColor?: string;
  modalTitle: string;
}

export const Stage: React.FC<Props> = ({
  position,
  title,
  type,
  modalTitle,
}) => {
  const inviteCandidateEmailWithWithout = false;
  const dispatch = useDispatch();

  let history = useHistory();

  const handleClick = ({ title, type }: { title: string; type: string }) => {
    if (type !== Stages.WORKING) {
      return history.push(`/position/${position.uuid}/${type}`);
    }
    history.push(`/position/${position.uuid}/in_progress/${type}`);
  };

  const openModalWithEmail = () => {
    dispatch(
      showModal({
        type: Actions.inviteCandidate,
        title: 'Invite new candidate',
      })
    );
    dispatch(setCurrentPosition(position));
  };

  const openModalWithoutEmail = () => {
    // reviewer interface
    dispatch(setCurrentPosition(position));
    dispatch(modalInviteWithoutEmail(true));
  };

  return (
    <div
      className={cn(
        'd-flex',
        'flex-column',
        'rounded',
        'bg-content',
        'mb-4',
        'border',
        'border-top-4',
        'border-bottom-0',
        'border-left-0',
        'border-right-0',
        'border-4',
        'justify-content-between',
        'position-relative',
        'w-100',
        {
          'border-yellow-700': type === Stages.WORKING,
          'border-blue-300': type === Stages.SUBMITTED,
          'border-red-500': type === Stages.REJECTED,
          'border-green-600': type === Stages.ACCEPTED,
          'border-purple-800': type === Stages.SENT,
        }
      )}
      style={{ minHeight: '304px' }}
    >
      <div className={cn('px-4', 'pt-2')}>
        <div className='text-sm font-weight-bold mb-4'>
          {title.split('\n').map((str, i: number) => (
            <div key={i}>{str}</div>
          ))}
        </div>
      </div>
      {type === Stages.SENT ? (
        <InvitesQty
          type={type}
          modalTitle={modalTitle}
          handleClick={handleClick}
          title={title}
          positionId={position.uuid}
        />
      ) : (
        <CandidatesQty
          type={type}
          modalTitle={modalTitle}
          handleClick={handleClick}
          title={title}
          positionId={position.uuid}
        />
      )}

      {type === Stages.SENT ? (
        <button
          id={`send-invite-${position.uuid}`}
          className='border-0 bg-gray-600 rounded-bottom py-2 d-flex align-items-center justify-content-center position-absolute w-100'
          style={{ bottom: 0 }}
          onClick={() =>
            inviteCandidateEmailWithWithout
              ? openModalWithEmail()
              : openModalWithoutEmail()
          }
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            size='lg'
            color='#C1C1C1'
            className='mr-2'
          />
          <div className='text-sm'>Invite</div>
        </button>
      ) : null}
    </div>
  );
};
