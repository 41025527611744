import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
interface Props {
  order: boolean;
  setOrder: React.Dispatch<React.SetStateAction<boolean>>;
  propertyKey: string;
  setPropertyKey: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  property?: string;
  gridCell?: boolean;
}

export const HeaderCell: React.FC<Props> = ({
  order,
  setOrder,
  propertyKey,
  setPropertyKey,
  property,
  title,
  gridCell,
}) => {
  const content = (
    <>
      {title}
      {property && (
        <FontAwesomeIcon
          icon={order && propertyKey === property ? faAngleDown : faAngleUp}
          size='sm'
          color='#656565'
          className='ml-2'
        />
      )}
    </>
  );
  if (gridCell) {
    return (
      <div
        className='border-0 font-weight-normal cursor-pointer'
        onClick={() => {
          property && setPropertyKey(property);
          setOrder(!order);
        }}
      >
        {content}
      </div>
    );
  }
  return (
    <th
      className='border-0 font-weight-normal cursor-pointer'
      onClick={() => {
        property && setPropertyKey(property);
        setOrder(!order);
      }}
    >
      {content}
    </th>
  );
};
