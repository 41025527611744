import React from 'react';
import { ICheck, ISolution } from '../../../typings/interfaces';
import { Link } from 'react-router-dom';
import { UnitTestInfo } from './UnitTestInfo';
import { LintInfo } from './LintInfo';
import moment from 'moment';

interface Props {
  checks: ICheck[];
  candidate: ISolution | undefined;
  isHidden?: boolean;
}

export const TableRows: React.FC<Props> = ({ checks, candidate, isHidden }) => {
  if (!candidate) {
    return null;
  }

  const commitsArr = checks.sort(
    (a, b) => Date.parse(b.date_created) - Date.parse(a.date_created)
  );

  return (
    <>
      {commitsArr.map((check, i: number) => {
        const {
          commit,
          date_created,
          message,
          htmlTestLink,
          htmlLintLink,
        } = check;

        if (check.pipeline_type !== 'LINT_TEST') return null;

        let url = `/position/${candidate.position.uuid}/candidate/${candidate.candidate.uuid}/code`;

        if (i !== commitsArr.length - 1) {
          url = `${url}/${commitsArr[i + 1].commit}/${commit}`;
        } else {
          url = `${url}/${commit}`;
        }

        return (
          <tr
            key={commit}
            data-testid={commit.slice(0, 7)}
            data-class='tr-check'
          >
            <td
              className='py-2 px-0 pr-3 border-0 align-top'
              data-testid={`check-commit-${commit.slice(0, 7)}`}
            >
              {isHidden ? (
                'XXXXXXX'
              ) : (
                <Link to={url}>#{commit.slice(0, 7)}</Link>
              )}
            </td>
            <td
              className='py-2 px-0 pr-3 border-0 align-top'
              data-testid={`check-date-${commit.slice(0, 7)}`}
            >
              {isHidden
                ? 'XXXXXXX'
                : moment.utc(date_created).local().format('DD MMM, HH:mm')}
            </td>
            <td
              className='py-2 px-0 pr-3 border-0 align-top'
              data-testid={`check-message-${commit.slice(0, 7)}`}
            >
              {isHidden ? message.replace(/\w/g, 'X') : message}
            </td>
            <td className='py-2 px-0 pr-3 border-0 text-primary align-top'>
              {htmlLintLink || isHidden ? (
                <a
                  href={htmlLintLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <LintInfo check={check} isHidden={isHidden} />
                </a>
              ) : (
                <LintInfo check={check} isHidden={isHidden} />
              )}
            </td>
            <td className='py-2 px-0 pr-3 border-0 text-primary align-top'>
              {htmlTestLink || isHidden ? (
                <a
                  href={htmlTestLink}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <UnitTestInfo check={check} isHidden={isHidden} />
                </a>
              ) : (
                <UnitTestInfo check={check} isHidden={isHidden} />
              )}
            </td>
          </tr>
        );
      })}
    </>
  );
};
