import React, { useState } from 'react';
import { BlockTitle } from '../../components/BlockTitle';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import { CandidatesCarousel } from './CandidatesCarousel';
import { EngineersCarousel } from './EngineersCarousel';
import { SliderBtn } from './SliderBtn';

interface Props {
  category?: string;
  title: string;
  btns: ISlideBtn[];
  carousel: string;
  className?: string;
}

export interface ISlideBtn {
  title: string;
  text: string;
}

export const ImgRightSlider: React.FC<Props> = ({
  category,
  title,
  carousel,
  btns,
  className,
}) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number, e: object | null) => {
    setIndex(selectedIndex);
  };
  const [isMatchMediaQuery] = useMatchMedia(992);

  const renderCarousel = () => {
    switch (carousel) {
      case 'candidates':
        return (
          <CandidatesCarousel
            index={index}
            handleSelect={handleSelect}
            slidesDesc={btns}
            isMatchMediaQuery={isMatchMediaQuery}
          />
        );
      case 'engineers':
        return (
          <EngineersCarousel
            index={index}
            handleSelect={handleSelect}
            slidesDesc={btns}
            isMatchMediaQuery={isMatchMediaQuery}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className={className}>
      <div className='d-flex flex-column flex-grow-1 flex-lg-row justify-content-lg-between position-relative'>
        <div className='lg:w-5/12 pr-lg-10 mb-lg-8 mb-4'>
          {category && (
            <BlockTitle
              title={category}
              color='orange-500'
              className='text-sm'
            />
          )}
          <div className='text-2-5xl lh-32 md:text-4-6xl font-weight-bold md:leading-thin mb-2'>
            {title}
          </div>
          {isMatchMediaQuery && (
            <div className='md:text-xl md:lh-32 mb-4'>
              {btns.map((btn: any, i: number) => (
                <SliderBtn
                  btn={btn}
                  handleSelect={handleSelect}
                  btnIndex={i}
                  index={index}
                  key={btn.title}
                />
              ))}
            </div>
          )}
        </div>
        <div className='lg:w-7/12 mb-6 mb-lg-0'>{renderCarousel()}</div>
      </div>
    </div>
  );
};
