import React, { useRef, useState, useEffect } from 'react';
import { AttachFeedbackCheckbox } from './AttachFeedbackCheckbox';
import TextareaAutosize from 'react-autosize-textarea';
import { useFeedback } from '../../hooks/useFeedback';
import { InformCandidateBtn } from '../pages/CandidatesToInform/InformCandidateBtn';
import { ISolution } from '../../typings/interfaces';
import { FeedbackBtns } from '../pages/CandidateProfile/FeedbackBtns';
import cn from 'classnames';
import { FeedbackAuthor } from './FeedbackAuthor';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { UserRoles } from '../../typings/enums';
import { userRoleSelector, errorFeedbackSelector } from '../../selectors';

interface Props {
  currentCandidate: ISolution;
  withAuthor?: boolean;
  withInformBtn?: boolean;
}

export const Feedback: React.SFC<Props> = ({
  currentCandidate,
  withAuthor,
  withInformBtn,
}) => {
  const errorFeedback = useShallowEqualSelector(errorFeedbackSelector).includes(
    currentCandidate.candidate.uuid
  );
  const userRole = useShallowEqualSelector(userRoleSelector);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const { feedbackValue, setFeedbackValue, updateFeedback } = useFeedback(
    currentCandidate
  );

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    !currentCandidate?.feedback?.body && setFeedbackValue('');
  }, [currentCandidate, setFeedbackValue]);

  if (!currentCandidate?.feedback?.body && userRole === UserRoles.RECRUITER) {
    return <div className='mb-3'>No feedback given</div>;
  }

  return (
    <div className='mb-3 flex-shrink-0 w-100'>
      <div className='w-100 mb-4 rounded-lg'>
        <div className='mb-2 w-100'>
          {!currentCandidate?.feedback?.body &&
          currentCandidate?.feedback?.is_sent ? (
            <div className='mb-3'>No feedback given</div>
          ) : (
            <TextareaAutosize
              value={feedbackValue}
              className={cn('w-100 focus:outline-none mb-2', {
                'border-0': feedbackValue && !isEditable && !errorFeedback,
                'border bg-gray-500 py-2 px-4 rounded':
                  !feedbackValue || isEditable,
                'border-red-500 rounded py-2 px-4': errorFeedback,
              })}
              ref={textAreaRef}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (!currentCandidate.feedback?.is_sent) {
                  setFeedbackValue(e.target.value);
                }
              }}
              readOnly={currentCandidate.feedback?.is_sent}
              onFocus={() => {
                if (!currentCandidate.feedback?.is_sent) {
                  setIsEditable(true);
                }
              }}
              placeholder='Explain what do you like and what you don’t'
              rows={!currentCandidate.feedback ? 5 : undefined}
            />
          )}

          {withAuthor && currentCandidate?.feedback && (
            <FeedbackAuthor
              feedback={currentCandidate.feedback}
              className='mb-2'
            />
          )}
          <div className='d-flex'>
            <FeedbackBtns
              isEditable={isEditable}
              textAreaRef={textAreaRef}
              currentCandidate={currentCandidate}
              setFeedbackValue={setFeedbackValue}
              updateFeedback={updateFeedback}
              setIsEditable={setIsEditable}
              userRole={userRole}
            />
            {!currentCandidate?.feedback?.is_sent &&
            userRole === UserRoles.RECRUITER &&
            !isEditable ? (
              <AttachFeedbackCheckbox
                candidate={currentCandidate}
                id={'attach-feedback'}
              />
            ) : null}
          </div>
        </div>
      </div>
      {!currentCandidate?.feedback?.is_sent &&
        userRole === UserRoles.RECRUITER &&
        withInformBtn && <InformCandidateBtn candidate={currentCandidate} />}
    </div>
  );
};
