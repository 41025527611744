import React from 'react';
import { Stages } from '../../../typings/enums';

interface Props {
  type: Stages;
  length: number;
  expiring?: number;
  expired?: number;
  handleClick: ({ title, type }: { title: string; type: string }) => void;
  modalTitle: string;
  title: string;
}

export const Quantity: React.FC<Props> = ({
  type,
  expiring,
  expired,
  length,
  handleClick,
  modalTitle,
  title
}) => {
  return (
    <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
      <button
        className='border-0 bg-transparent p-0 w-100 text-center focus:outline-none'
        disabled={length === 0}
        onClick={() => handleClick({ title: modalTitle || title, type: type })}
      >
        <div className='position-relative'>
          <div
            className={`font-bebas leading-none ${type}-qty`}
            style={{ fontSize: '6rem' }}
          >
            {length}
          </div>
          {type === Stages.WORKING ? (
            <div className='text-orange-500 position-absolute w-100 text-center text-sm'>
              {expiring && expiring > 0 ? (
                <div className={`${type}-expiring`}>expiring {expiring}</div>
              ) : null}
              {expired && expired > 0 ? (
                <div className={`${type}-expired`}>expired {expired}</div>
              ) : null}
            </div>
          ) : null}
          {type === Stages.SENT ? (
            <div className='text-orange-500 position-absolute w-100 text-center text-sm'>
              {expiring && expiring > 0 ? (
                <div className={`${type}-expiring`}>expiring {expiring}</div>
              ) : null}
            </div>
          ) : null}
        </div>
      </button>
    </div>
  );
};
