export const GETPOSITIONS = 'GETPOSITIONS';
export const GETCHALLENGES = 'GETCHALLENGES';
export const GETCANDIDATES = 'GETCANDIDATES';

export interface PositionTypesV2 {
  category: {
    id: number;
    title: string;
  };
  company: {
    id: number;
    title: string;
  };
  level: string;
  pipeline: {
    accepted: {
      new: number;
      total: number;
    };
    archived: {
      new: number;
      total: number;
    };
    expired: {
      new: number;
      total: number;
    };
    expiring: {
      new: number;
      total: number;
    };
    next_step: {
      new: number;
      total: number;
    };
    processed: {
      new: number;
      total: number;
    };
    rejected: {
      new: number;
      total: number;
    };
    started: {
      new: number;
      total: number;
    };
    submitted: {
      new: number;
      total: number;
    };
    task_sent: {
      new: number;
      total: number;
    };
    task: {
      challenge: {
        id: number;
        title: string;
      };
      challenge_id: number;
      duration: number;
      duration_dev: number;
      estimate: string;
      id: number;
      is_archived: boolean;
      max_story_points: number;
      min_story_points: number;
      position_id: string;
      story_points: number;
      title: string;
    };
    team: {
      company: {
        id: number;
        title: string;
      };
      title: string;
      uuid: string;
    };
    title: string;
    uuid: string;
  };
}

export interface ChallengeTypesV2 {
  data: null | string;
  user_id: string;
  status: string;
  ticket_id: number;
  date_ready: string;
  ticket: {
    kind: string;
    challenge_id: number;
    skills_meta: {
      complexity: number;
      skill: {
        id: number;
        title: string;
      };
    }[];
    title: string;
    uid: string;
    body: null | string;
    story_points: number;
    id: number;
    sections: {
      id: number;
      body: string;
      title: string;
    }[];
  };
  is_mandatory: boolean;
  task_id: number;
}

export interface CandidatesTypesV2 {
  candidate: {
    email: string;
    first_name: string;
    full_name: string;
    last_name: string;
    score: number | null;
    trackers: [];
    user: {
      date_created: string;
      email: string;
      first_name: string;
      full_name: string;
      is_active: true;
      last_name: string;
      uuid: string;
    };
    uuid: string;
  };
  data: null | string;
  date_archived: null | string;
  date_created: string;
  date_due: string;
  date_extended: null | string;
  date_ready: string;
  date_received: null | string;
  date_started: null | string;
  feedback: null;
  https_clone_url: string;
  id: number;
  is_archived: false;
  is_extended: false;
  is_ready: true;
  is_received: false;
  is_started: false;
  position: PositionTypesV2[];
  recruiter: null | string;
}

export interface GetPositionsTypes {
  type: typeof GETPOSITIONS;
  payload: PositionTypesV2;
}

export interface GetChallengesTypes {
  type: typeof GETCHALLENGES;
  payload: ChallengeTypesV2;
}

export interface GetCandidatesTypes {
  type: typeof GETCANDIDATES;
  payload: CandidatesTypesV2;
}

export type PositionsActionTypesV2 =
  | GetPositionsTypes
  | GetChallengesTypes
  | GetCandidatesTypes;
