import React, { useEffect } from 'react';
import { Logo } from '../../shared/components/Logo';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { Loader } from '../../shared/components/Loader';
import { getInvite, clearInvite } from '../../actions/invitesActions';
import { getPosition } from '../../actions/positionsActions';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Page404 } from '../../shared/pages/ErrorPages/Page404';
import {
  inviteSelector,
  pendingInviteSelector,
  errorInviteSelector,
  pendingPositionSelector,
} from '../../selectors';

interface Props {
  children: React.ReactNode;
}

export const InvitationLayout: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const { inviteId } = useParams();

  const errorInvite = useShallowEqualSelector(errorInviteSelector);
  const invite = useShallowEqualSelector(inviteSelector);
  const pendingInvite = useShallowEqualSelector(pendingInviteSelector);

  const pendingPosition = useShallowEqualSelector(pendingPositionSelector);

  useEffect(() => {
    inviteId && dispatch(getInvite(inviteId));
    return () => {
      dispatch(clearInvite());
    };
  }, [dispatch, inviteId]);

  useEffect(() => {
    if (invite?.data.position_id) {
      dispatch(getPosition(invite.data.position_id));
    }
  }, [dispatch, invite]);

  if (pendingPosition || pendingInvite) {
    return <Loader />;
  }

  if (errorInvite) {
    return <Page404 />;
  }

  return (
    <div className='bg-gray-500 min-vh-full'>
      <div className='container px-md-0 py-3 d-flex flex-column min-vh-full'>
        <div className='d-flex justify-content-between w-100 align-items-center px-4 mb-10'>
          <Link to='/'>
            <Logo type='dark' />
          </Link>
        </div>
        <div className='d-flex flex-column flex-grow-1 w-100 justify-content-center'>
          <div className='mx-auto w-100 container'>{children}</div>
        </div>
      </div>
    </div>
  );
};
