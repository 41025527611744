import React, { useState } from 'react';
import { IComment, INote } from '../../typings/interfaces';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { Note } from './Note';
import { NoteBtns } from '../../shared/components/NoteBtns';
import { updateNote, createNote } from '../../actions/notesActions';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { CommentInfo } from 'react-diff-viewer';
import { userSelector } from '../../selectors';

interface Props {
  comment: IComment | INote | undefined;
  commentsIds: number[];
  clearNewComment: () => void;
  commentInfo: CommentInfo;
  className?: string;
}

export const CommentBlock: React.FC<Props> = ({
  comment,
  commentsIds,
  clearNewComment,
  className,
}) => {
  const dispatch = useDispatch();

  const user = useShallowEqualSelector(userSelector);
  const [isEditable, setIsEditable] = useState<boolean>(!comment?.body.text);
  const [text, setText] = useState<string>(comment?.body.text || '');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  if (!comment) {
    return null;
  }

  const handleSave = async () => {
    if (comment?.id && commentsIds.includes(comment?.id)) {
      return await dispatch(
        updateNote(comment.id as number, {
          body: { ...comment.body, text },
        })
      );
    }
    await dispatch(createNote({ ...comment, body: { ...comment.body, text } }));
  };
  const handleCancel = () => {
    clearNewComment();
    if (comment && comment.body.text) {
      setText(comment.body.text);
    }
    setIsEditable(false);
  };

  if (isEditable) {
    return (
      <div className='p-2'>
        <div className='form-group mb-3'>
          <textarea
            onChange={handleChange}
            value={text}
            className='form-control'
          />
        </div>
        <NoteBtns
          handleSave={handleSave}
          handleCancel={handleCancel}
          saveBtnTitle={comment.body.text ? 'Save' : 'Add Comment'}
          disabled={!text}
        />
      </div>
    );
  }

  return (
    <div className={cn('pt-2 pb-3 px-3 border', [className])}>
      <Note
        handleClick={() => setIsEditable(true)}
        note={comment}
        className={cn({
          'mb-3': user && user.identity !== comment.created_by?.uuid,
        })}
      />
    </div>
  );
};
