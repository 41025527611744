import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTask, clearTask } from '../../../actions/taskActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../shared/components/Loader';
import { Header } from '../../../containers/CandidatesContainer/Header';
import { RunSolution } from '../../components/RunSolution';
import { TaskSettingsForm } from './TaskSettingsForm';
import { pendingTaskSelector, taskSelector } from '../../../selectors';

export const TaskSettings: React.FC = () => {
  const dispatch = useDispatch();
  const { positionId } = useParams();

  const task = useShallowEqualSelector(taskSelector);
  const pendingTask = useShallowEqualSelector(pendingTaskSelector);

  useEffect(() => {
    if (positionId) {
      dispatch(getTask(positionId));
    }

    return () => {
      dispatch(clearTask());
    };
  }, [dispatch, positionId]);

  if (pendingTask || !task) {
    return <Loader />;
  }

  return (
    <div className='bg-white px-4 px-md-5 py-3'>
      <Header />
      <div className='container mx-0 pb-8'>
        <div className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-center'>
          <div>
            <div className='h2 font-weight-bold'>
              {task?.challenge.title} Challenge
            </div>
          </div>
          <div>
            <RunSolution
              className='border-blue-700'
              iconColor='#0c74ef'
              text='Open Application'
            />
          </div>
        </div>
        <div>{task?.challenge.title} data from all over the world.</div>
        <div className='mb-6'>
          The app utilises{' '}
          <a href='http://openaq.org' target='_blank' rel='noopener noreferrer'>
            http://openaq.org
          </a>{' '}
          to obtain the data.
        </div>
        <TaskSettingsForm task={task} positionId={positionId} />
      </div>
    </div>
  );
};
