import React from 'react';
import { Actions } from '../../../typings/enums';
import { ISolution } from '../../../typings/interfaces';
import { AttachFeedbackCheckbox } from '../../../recruiter/components/AttachFeedbackCheckbox';
import { CandidateLink } from '../CandidateLink';

interface Props {
  modalType: Actions | string | undefined;
  candidate: ISolution;
}

export const Content: React.FC<Props> = ({ modalType, candidate }) => {
  if (modalType === Actions.inform) {
    return (
      <>
        <div className='mb-2'>
          Inform {candidate.candidate.first_name}{' '}
          {candidate.candidate.last_name} that his solution has been{' '}
          {candidate.feedback?.resolution}?
        </div>
        {candidate.feedback?.body && (
          <AttachFeedbackCheckbox
            candidate={candidate}
            id={'attach-feedback'}
          />
        )}
      </>
    );
  }
  if (modalType === Actions.ARCHIVE) {
    return (
      <div>
        Add <CandidateLink candidate={candidate} className='mr-2' /> to archive?
      </div>
    );
  }
  if (modalType === Actions.PROLONG) {
    return <>Prolong execution of the task for another 7 days?</>;
  }
  if (modalType === Actions.rejectExpired) {
    return (
      <div>
        Reject solution for <CandidateLink candidate={candidate} /> ?
      </div>
    );
  }
  return null;
};
