import React from 'react';
import { CandidateNote } from '../../../reviewer/pages/OverviewCandidate/CandidateNote';
import { SummaryInfo } from '../../../shared/components/SummaryInfo';
import { Tickets } from '../../../shared/components/Tickets';
import { Feedback } from '../../components/Feedback';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useParams } from 'react-router-dom';
import { RecruiterNote } from '../../components/RecruiterNote';
import {
  userSelector,
  currentCandidateSelector,
  positionsSelector,
} from '../../../selectors';

export const CandidateProfile = () => {
  const { positionId } = useParams();

  const currentCandidate = useShallowEqualSelector(currentCandidateSelector);
  const user = useShallowEqualSelector(userSelector);
  const positions = useShallowEqualSelector(positionsSelector);
  const curPosition =
    positions && positions.find((position) => position.uuid === positionId);

  if (!currentCandidate) {
    return null;
  }

  return (
    <>
      <div className='d-flex flex-column flex-lg-row w-100 flex-grow-1 mb-8 justify-content-lg-between '>
        <div className='flex-grow-1 pr-8'>
          <div style={{ maxWidth: '600px' }}>
            <RecruiterNote
              candidate={currentCandidate}
              className='mb-4'
              titleText='Team Note'
            />
            <div className='font-weight-bold text-lg mb-1'>
              Feedback to candidate
            </div>
            <Feedback
              currentCandidate={currentCandidate}
              withAuthor
              withInformBtn
            />
          </div>
          <div style={{ maxWidth: '900px' }} className='mb-4 mb-lg-0'>
            <Tickets candidate={currentCandidate} positionId={positionId} />
          </div>
        </div>
        <div className='lg:w-4/12'>
          {curPosition && (
            <SummaryInfo
              candidate={currentCandidate}
              position={curPosition}
              user={user}
            />
          )}
          <CandidateNote positionId={positionId} candidate={currentCandidate} />
        </div>
      </div>
    </>
  );
};
