import React from 'react';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { UserRoles } from '../../typings/enums';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { NotesCount } from './NotesCount';
import { CommentsCount } from '../../shared/components/CommentsCount';
import { isComment } from '../../typings/typeGuards';
import cn from 'classnames';
import { notesSelector } from '../../selectors';

interface Props {
  className?: string;
  userRole: string | undefined;
  codeLink: string;
}

export const ShareTabs: React.FC<Props> = ({
  className,
  userRole,
  codeLink
}) => {
  const { shareuuid, candidateId, positionId } = useParams();
  const notes = useShallowEqualSelector(notesSelector);

  const comments = notes?.[positionId]?.filter((note) => isComment(note)) || [];

  const LINKS = [
    {
      path: `/share/${shareuuid}/${positionId}/${candidateId}/overview`,
      title: 'overview'
    },
    {
      path: codeLink,
      title: 'code'
    },
    {
      path: `/share/${shareuuid}/${positionId}/${candidateId}/checks`,
      title: 'checks'
    }
  ];

  const renderComments = (link: { path: string; title: string }) => {
    if (link.title !== 'code' || comments.length === 0) return null;

    return <CommentsCount qty={comments.length} size='xs' className='ml-1' />;
  };
  const renderNotes = (link: { path: string; title: string }) => {
    if (link.title !== 'notes' || notes?.[positionId]?.length === 0)
      return null;

    return <NotesCount qty={notes?.[positionId]?.length || 0} />;
  };

  return (
    <div className='d-flex'>
      {LINKS.map((link) => (
        <NavLink
          to={link.path}
          className={cn(
            'd-flex align-items-center leading-none mr-6 navlink text-sm py-2',
            [className]
          )}
          key={link.title}
          exact={!link.path.includes('code')}
        >
          {link.title.toUpperCase()}
          {userRole === UserRoles.RECRUITER && renderComments(link)}
          {userRole === UserRoles.REVIEWER && renderNotes(link)}
        </NavLink>
      ))}
    </div>
  );
};
