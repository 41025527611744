import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

export const Path: FC<any> = ({ path, returnPath }) => {
  let fullPath: any;
  const { positionId, candidateId } = useParams();
  fullPath = path.split('/');
  return (
    <>
      {fullPath && path ? (
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb bg-transparent mb-0'>
            <li className='breadcrumb-item'>
              <Link
                to={`/position/${positionId}/candidate/${candidateId}/repo`}
              >
                ..
              </Link>
            </li>
            {fullPath.map((fullPathItem: any, i: any) => {
              if (fullPath && i !== fullPath.length - 1) {
                return (
                  <li className='breadcrumb-item' key={i}>
                    <Link
                      to={`/position/${positionId}/candidate/${candidateId}/repo/tree/${returnPath}`}
                    >
                      {fullPathItem}
                    </Link>
                  </li>
                );
              } else {
                return (
                  <li className='breadcrumb-item active' key={i}>
                    <span className='active'>{fullPathItem}</span>
                  </li>
                );
              }
            })}
          </ol>
        </nav>
      ) : null}
    </>
  );
};
