import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Formik, FormikErrors, FormikValues } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { createPosition } from '../../../actions/positionsActions';
import { AddTeamFormFields } from './AddTeamFormFields';
import { CreatePositionForm } from './CreatePositionForm';
import { addTeam } from '../../../actions/teamsActions';
import { CardComponent } from '../../../shared/components/CardComponent';
import { BackBtn } from '../../../shared/components/BackBtn';
import { v4 as uuid } from 'uuid';

export interface FormValues {
  name: string;
  level: string;
  categoyId: string;
  company_name: string;
  hiring_manager: string;
  team_title: string;
  participant_email: string;
  reveal: string;
}

export const CreateFirstPosition: React.SFC = () => {
  const dispatch = useDispatch();

  const [addTeamStep, setAddTeamStep] = useState<boolean>(false);
  const [participants, setParticipants] = useState<any>([]);

  const initialValues: FormValues = {
    name: '',
    level: '',
    categoyId: '',
    team_title: '',
    company_name: '',
    hiring_manager: '',
    participant_email: '',
    reveal: 'yes',
  };

  return (
    <CardComponent>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          const hiringManager = {
            uuid: uuid(),
            first_name: '',
            last_name: '',
            email: values.hiring_manager,
          };

          const teamParticipants = [hiringManager, ...participants];
          const showNames = values.reveal === 'yes' ? true : false;

          actions.setSubmitting(false);

          dispatch(
            addTeam({
              uuid: uuid(),
              title: values.team_title,
              participants: teamParticipants,
              showNames,
            })
          );

          dispatch(
            createPosition({
              title: `${values.name} ${values.categoyId} ${values.level}`,
              level: values.level,
              uuid: uuid(),
              company: { id: uuid(), title: values.company_name },
              team: {
                uuid: uuid(),
                title: values.team_title,
                participants: teamParticipants,
                showNames,
              },
              category: {
                id: uuid(),
                title: values.categoyId,
              },
              task: '',
              pipeline: {},
            })
          );
        }}
        validate={(values) => {
          const errors: FormikErrors<FormikValues> = {};

          if (!values.name) {
            errors.name = 'Required';
          }
          if (!values.level) {
            errors.level = 'Choose level for position';
          }
          if (!values.categoyId) {
            errors.categoyId = 'Required';
          }

          if (!values.team_title) {
            errors.team_title = 'Required';
          }
          if (!values.company_name) {
            errors.company_name = 'Required';
          }
          if (!values.hiring_manager) {
            errors.hiring_manager = 'Required';
          }
          if (!values.level) {
            errors.level = 'Required';
          }
          if (
            values.participant_email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
              values.participant_email
            )
          ) {
            errors.participant_email = 'Invalid email address';
          }

          return errors;
        }}
      >
        {({
          handleSubmit,
          errors,
          touched,
          values,
          setFieldTouched,
          validateField,
        }) => (
          <div className='d-flex align-items-stretch'>
            <div className='flex-grow-1 w-100 pr-2 d-flex flex-column'>
              {!addTeamStep ? (
                <>
                  <div className='h2'>Create your first position</div>
                  <div className='leading-normal'>
                    Send a position to the company team to let them choose the
                    task. Once you have a task, invite candidates to work on it.
                    You will get preliminary results as soon as a task is
                    submitted.
                  </div>
                </>
              ) : (
                <>
                  <div className='flex-grow-1'>
                    <div className='h2 font-weight-bold'>
                      Setup a hiring team
                    </div>
                    <div className='leading-normal'>
                      Invite people who will choose a task, check it <br />
                      and make final decisions
                    </div>
                  </div>
                  <div>
                    <div className='mb-4'>
                      <div>Position</div>
                      <div>
                        {values.level} {values.categoyId} {values.name}
                      </div>
                    </div>
                    <BackBtn
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        setAddTeamStep(false);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div className='flex-grow-1 w-100 pl-4'>
              <Form
                className='items-center flex flex-col'
                onSubmit={handleSubmit as any}
                style={{ maxWidth: '500px' }}
              >
                {!addTeamStep ? (
                  <>
                    <CreatePositionForm
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldTouched={setFieldTouched}
                    />
                    <Button
                      variant='warning'
                      className='rounded-lg custom-btn py-3 px-4 leading-none mb-8'
                      onClick={() => {
                        if (
                          touched.name &&
                          touched.categoyId &&
                          touched.level &&
                          !errors.level &&
                          !errors.name &&
                          !errors.categoyId
                        ) {
                          setAddTeamStep(true);
                        } else {
                          const fieldsToValidate = [
                            'name',
                            'categoyId',
                            'level',
                          ];
                          fieldsToValidate.forEach((field) => {
                            setFieldTouched(field, true);
                          });
                        }
                      }}
                    >
                      Save and add team
                    </Button>
                  </>
                ) : (
                  <>
                    <AddTeamFormFields
                      errors={errors}
                      touched={touched}
                      values={values}
                      participants={participants}
                      setParticipants={setParticipants}
                    />
                    <div>
                      <Button
                        variant='warning'
                        className='rounded-lg custom-btn py-3 px-4 leading-none'
                        style={{ minWidth: '160px' }}
                        type='submit'
                      >
                        Finish
                      </Button>
                    </div>
                  </>
                )}
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </CardComponent>
  );
};
