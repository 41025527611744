import { ISolution } from '../typings/interfaces';
import { getSolutionStage } from '../util/getSolutionStage';
import { Stages, UserRoles } from '../typings/enums';

export const filterSolutionByStage = (
  solution: ISolution,
  userRole: string | UserRoles | undefined,
  toCompare: Stages | string | string[]
) => {
  if (toCompare instanceof Array) {
    return toCompare.includes(getSolutionStage(solution, userRole));
  }
  return getSolutionStage(solution, userRole) === toCompare;
};
