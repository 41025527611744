import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import smoothscroll from 'smoothscroll-polyfill';
import * as Sentry from '@sentry/browser';
import './styles/index.scss';
import './styles/custom-utilities.scss';
smoothscroll.polyfill();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://dac7e4a552294492a4e994811e2d983b@o380706.ingest.sentry.io/5206944',
  });
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: window.OPTIONS.CLIENT_SIDE_ID,
  });
  ReactDOM.render(
    <LDProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </LDProvider>,
    document.getElementById('root')
  );
})();
