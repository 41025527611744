import React, { useState, useRef } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import cn from 'classnames';

interface Props {
  feedbackValue: string | number | string[] | undefined;
  setFeedbackValue: React.Dispatch<
    React.SetStateAction<string | number | string[] | undefined>
  >;
  sendWithoutFeedback: boolean;
}

export const Feedback: React.FC<Props> = ({
  feedbackValue,
  setFeedbackValue,
  sendWithoutFeedback
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [isEditable, setIsEditable] = useState<boolean>(!!!feedbackValue);

  return (
    <div className='mb-3'>
      <div className='font-weight-bold text-lg mb-1'>Feedback</div>
      <TextareaAutosize
        data-testid='feedback-textarea'
        rows={10}
        value={feedbackValue as string}
        className={cn('w-100 focus:outline-none', {
          'border-0': !isEditable,
          'border bg-gray-500 py-2 px-4 rounded': isEditable
        })}
        readOnly={sendWithoutFeedback}
        ref={textAreaRef}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setFeedbackValue(e.target.value)
        }
        onFocus={() => setIsEditable(true)}
      />
    </div>
  );
};
