import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { showModal } from '../../../actions/modalActions';
import { setCurCandidate } from '../../../actions/candidatesActions';
import { Actions } from '../../../typings/enums';
import { ISolution } from '../../../typings/interfaces';

interface Props {
  candidate: ISolution;
}

export const InformCandidateBtn = ({ candidate }: Props) => {
  const dispatch = useDispatch();

  const informCandidateConfirm = () => {
    dispatch(setCurCandidate(candidate));
    dispatch(
      showModal({
        type: Actions.inform,
        title: 'Candidate informed',
      })
    );
  };

  return (
    <Button
      variant='warning'
      className='rounded-lg custom-btn mb-2'
      onClick={informCandidateConfirm}
    >
      Inform Candidate
    </Button>
  );
};
