import {
  PositionsActionsTypesConstants,
  PositionsState,
  PositionsActionTypes,
} from '../actions/types';

export const initialState: PositionsState = {
  positions: undefined,
  pending: false,
  error: undefined,
  curPosition: undefined,
  isActiveAddPosition: false,
  summaryPipeline: undefined,
  errorSummaryPipeline: undefined,
  pendingSummaryPipeline: false,
  position: undefined,
  pendingPosition: false,
  errorPosition: '',
};

export const positionsReducer = (
  state = initialState,
  action: PositionsActionTypes
): PositionsState => {
  switch (action.type) {
    case PositionsActionsTypesConstants.GET_POSITIONS:
      return { ...state, positions: action.payload, pending: false, error: '' };
    case PositionsActionsTypesConstants.GET_POSITION:
      return {
        ...state,
        position: action.payload,
        pendingPosition: false,
        errorPosition: '',
      };
    case PositionsActionsTypesConstants.UPDATE_POSITIONS:
      return { ...state, positions: action.payload };
    case PositionsActionsTypesConstants.PENDING_POSITIONS:
      return { ...state, pending: true };
    case PositionsActionsTypesConstants.PENDING_POSITION:
      return { ...state, pendingPosition: true };
    case PositionsActionsTypesConstants.PENDING_SUMMARY_PIPELINE:
      return { ...state, pendingSummaryPipeline: true };
    case PositionsActionsTypesConstants.ERROR_POSITIONS:
      return { ...state, pending: false, error: action.payload };
    case PositionsActionsTypesConstants.ERROR_POSITION:
      return {
        ...state,
        pendingPosition: false,
        errorPosition: action.payload,
      };
    case PositionsActionsTypesConstants.CREATE_POSITION:
      return {
        ...state,
        positions: state.positions
          ? [...state.positions, action.payload]
          : [action.payload],
      };
    case PositionsActionsTypesConstants.CLEAR_POSITIONS:
      return { ...state, positions: undefined, pending: false, error: '' };
    case PositionsActionsTypesConstants.GET_SUMMARY_PIPELINE:
      return {
        ...state,
        summaryPipeline: action.payload,
      };
    case PositionsActionsTypesConstants.SET_CURRENT_POSITION:
      return { ...state, curPosition: action.payload };
    case PositionsActionsTypesConstants.TOGGLE_ADD_POSITION:
      return { ...state, isActiveAddPosition: action.payload };
    default:
      return state;
  }
};
