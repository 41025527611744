import React from 'react';
import { Field, FormikValues } from 'formik';
import { ButtonGroup, Button } from 'react-bootstrap';
import cn from 'classnames';
import { Duration } from './TaskSettingsForm';

interface Props {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  values: FormikValues;
  setDurationType: React.Dispatch<React.SetStateAction<Duration>>;
  durationType: Duration;
}

export const DurationInput: React.FC<Props> = ({
  setFieldValue,
  values,
  setDurationType,
  durationType,
}) => {
  const handleClick = (
    type: Duration,
    value: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const newValue =
      type === Duration.days ? Math.round(value / 24) : Math.round(value * 24);

    setDurationType(type);
    setFieldValue('duration', newValue, true);
  };

  return (
    <div className='d-flex align-items-center flex-wrap'>
      <div className='mr-2'>Duration</div>
      <Field
        type='number'
        name='duration'
        id='duration'
        className='rounded border bg-gray-500 py-1 px-3 border-purple-200 text-sm mr-3'
        style={{
          height: '37px',
          maxWidth: '75px',
          color: '#495057',
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(
            'duration',
            !isNaN(parseInt(e.target.value))
              ? Math.abs(parseInt(e.target.value))
              : e.target.value,
            true
          );
        }}
      />
      <ButtonGroup>
        <Button
          variant='outline-dark'
          className={cn('focus:outline-none', {
            'border-2 border-blue-700': durationType === Duration.days,
          })}
          onClick={() => {
            handleClick(Duration.days, values.duration, setFieldValue);
          }}
        >
          Days
        </Button>
        <Button
          variant='outline-dark'
          className={cn('focus:outline-none', {
            'border-2 border-blue-700': durationType === Duration.hours,
          })}
          onClick={() => {
            handleClick(Duration.hours, values.duration, setFieldValue);
          }}
        >
          Hours
        </Button>
      </ButtonGroup>
    </div>
  );
};
