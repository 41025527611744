import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './BackBtn.scss';
import { Link } from 'react-router-dom';

interface Props {
  onClick?: (event: React.MouseEvent<any, MouseEvent>) => void;
  className?: string;
  title?: string;
  to?: string;
}

export const BackBtn: React.FC<Props> = ({
  onClick,
  className,
  to,
  title = 'Back',
}) => {
  if (to) {
    return (
      <Link
        className={`bg-transparent border-0 px-0 d-flex align-items-center btn-back ${className}`}
        to={to}
        onClick={onClick}
      >
        <div className='icon--24 rounded-circle bg-gray-600 mr-3 d-flex align-items-center justify-content-center'>
          <FontAwesomeIcon icon={faChevronLeft} size='sm' />
        </div>
        {title}
      </Link>
    );
  }

  return (
    <button
      className={`bg-transparent border-0 px-0 d-flex align-items-center btn-back ${className}`}
      onClick={onClick}
    >
      <div className='icon--24 rounded-circle bg-gray-600 mr-3 d-flex align-items-center justify-content-center'>
        <FontAwesomeIcon icon={faChevronLeft} size='sm' />
      </div>
      {title}
    </button>
  );
};
