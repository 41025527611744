import React from 'react';
import { useScrollToElement } from '../../../hooks/useScrollToElement';
import { Header } from '../../components/Header';
import { TestRealSkills } from './TestRealSkills';
import { Steps } from './Steps';
import { HiringTeams } from './HiringTeams';
import { Features } from './Features';
import { AvarageTime } from './AvarageTime';
import { CandidateExperience } from '../../components/CandidateExperience';
import { ForCustomers } from '../../components/ForCustomers';
import { Pricing } from '../../components/Pricing';
import { Footer } from '../../components/Footer';
import { BookADemoBtn } from '../../components/BookADemoBtn';

export const HiringTeam: React.FC = () => {
  const { setRef, handleLinkClick } = useScrollToElement();

  return (
    <div className='w-100 hiring-team'>
      <Header
        headerText={
          <h1
            className='text-white md:text-4-7xl lg:text-6xl text-4xl text-center lg:w-9/12 mx-auto lg:leading-thin pt-lg-10 mb-8 font-weight-normal'
            style={{ maxWidth: '1041px' }}
          >
            Hire the perfect fit faster and and at a lower cost
          </h1>
        }
        btns={
          <div className='text-center mb-10'>
            <BookADemoBtn className='mr-lg-4 mb-0 mb-lg-0 py-2 text-2xl rounded-lg' />
          </div>
        }
        handleLinkClick={handleLinkClick}
      />
      <TestRealSkills />
      <Steps />
      <div className='py-10 bg-gray-200'>
        <div className='container'>
          <HiringTeams setRef={setRef} />
          <Features />
          <AvarageTime min={15} />
          <div className='d-lg-flex py-20'>
            <div className='mb-6 mb-lg-0 mr-lg-6 lg:w-8-12'>
              <CandidateExperience />
            </div>
            <div className='lg:w-4-12'>
              <ForCustomers
                title='Recruiters'
                text='Process a higher volume of candidates through technical screening. Proceed with the most relevant candidates.'
                link='/recruiters'
              />
            </div>
          </div>
        </div>
      </div>
      <Pricing setRef={setRef} />
      <Footer />
    </div>
  );
};
