import React from 'react';
import { Table } from 'react-bootstrap';
import { TableRows } from './TableRows';
import { Loader } from '../../../shared/components/Loader';
import { LatestCheck } from './LatestCheck';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import cn from 'classnames';
import './Checks.scss';
import {
  userSelector,
  checksSelector,
  currentCandidateSelector,
  pendingChecksSelector,
  errorChecksSelector,
} from '../../../selectors';
import { RunSolution } from '../../components/RunSolution';

interface Props {
  share?: boolean;
}

export const Checks: React.FC<Props> = ({ share }) => {
  const currentCandidate = useShallowEqualSelector(currentCandidateSelector);
  const checks = useShallowEqualSelector(checksSelector);
  const user = useShallowEqualSelector(userSelector);
  const pendingChecks = useShallowEqualSelector(pendingChecksSelector);
  const errorChecks = useShallowEqualSelector(errorChecksSelector);

  const tableHeader = ['Commit', 'Time', 'Message', 'Lint Check', 'Unit Test'];

  if (pendingChecks) {
    return <Loader />;
  }

  console.log(checks);
  

  if (!checks) {
    return null;
  }

  const isChecks = checks && checks.length > 0;

  const isHidden = share && !user;

  return (
    <div className={cn({ 'position-relative': isHidden }, 'checks')}>
      <div className='checks-impl'>
        <p className='text-2xl mb-2 font-weight-bold'>Checks</p>
        <RunSolution
          className='border-gray-300'
          text='Check implementation'
          iconColor='gray'
        />
      </div>
      {errorChecks ? <div>{errorChecks}</div> : null}
      {!errorChecks ? (
        <>
          {isChecks ? (
            <LatestCheck checks={checks} isHidden={isHidden} />
          ) : null}
          {isChecks ? (
            <Table responsive>
              <thead className='border-bottom border-gray-700'>
                <tr>
                  {tableHeader.map((title, i) => (
                    <th
                      className='py-2 pl-0 pr-2 border-0 font-weight-normal'
                      key={i}
                    >
                      {title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <TableRows
                  checks={checks}
                  candidate={currentCandidate}
                  isHidden={isHidden}
                />
              </tbody>
            </Table>
          ) : (
            <div>There are no active checks yet</div>
          )}
        </>
      ) : null}
      {isHidden && (
        <div
          className={cn({
            'position-absolute inset-0 hidden d-flex rounded-lg': isHidden,
          })}
        >
          <div
            className='m-auto text-lg text-center'
            style={{ maxWidth: '337px' }}
          >
            After Sign Up here you will see Lint and Units tests that were
            failed and fixed
          </div>
        </div>
      )}
    </div>
  );
};
