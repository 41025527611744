import React from 'react';
import './Loader.scss';

export interface IProps {
  classes?: string;
}

export const Loader: React.FC<IProps> = ({ classes }): React.ReactElement => {
  return (
    <div
      className={`d-flex justify-content-center mt-3 flex justify-center ${classes}`}
    >
      <div className='lds-ring'>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
