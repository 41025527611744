import { InvitesActionTypesConstants, InvitesActionsTypes } from './types';
import { Action } from 'redux';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { apiFetch } from '../util/apiFetch';

export const getInvites = (): ThunkAction<
  void,
  RootState,
  InvitesActionsTypes,
  Action<string>
> => async (dispatch) => {
  try {
    dispatch({ type: InvitesActionTypesConstants.PENDING_INVITES });

    const response = await apiFetch('auth', '/invite/');

    if (response.error) throw new Error(response.error);

    dispatch({
      type: InvitesActionTypesConstants.GET_INVITES,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: InvitesActionTypesConstants.ERROR_INVITES,
      payload: err.message,
    });
  }
};

export const sendInvite = (
  inviteData: any,
  cb: (response: any) => void
): ThunkAction<void, RootState, InvitesActionsTypes, Action<string>> => async (
  dispatch
) => {
  let response;
  try {
    response = await apiFetch<any>('auth', '/invite/', 'post', inviteData);

    if (response.error) throw new Error(response.error);

    dispatch({
      type: InvitesActionTypesConstants.CLEAR_ERROR_SEND_INVITE,
    });

    dispatch({
      type: InvitesActionTypesConstants.CREATE_INVITE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: InvitesActionTypesConstants.ERROR_SEND_INVITE,
      payload: err.message,
    });
  } finally {
    cb(response);
  }
};

export const clearInviteError = () => ({
  type: InvitesActionTypesConstants.CLEAR_ERROR_SEND_INVITE,
});

export const getInvite = (
  inviteId: string
): ThunkAction<void, RootState, InvitesActionsTypes, Action<string>> => async (
  dispatch
) => {
  dispatch({
    type: InvitesActionTypesConstants.PENDING_INVITE,
  });

  const response = await apiFetch('auth', `/invite/${inviteId}/`);
  if (response.data) {
    dispatch({
      type: InvitesActionTypesConstants.GET_INVITE,
      payload: response.data,
    });
  } else {
    dispatch({
      type: InvitesActionTypesConstants.ERROR_INVITE,
      payload: response.status,
    });
  }
};

export const clearInvite = () => ({
  type: InvitesActionTypesConstants.CLEAR_INVITE,
});
