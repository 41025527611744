import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import { hideModal } from '../../../actions/modalActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { InviteForm } from './InviteForm';
import { FreshInvites } from './FreshInvites';
import { useMembers } from '../../../hooks/useMembers';
import { Member } from './Member';
import { InvitationLink } from '../../../shared/components/InvitationLink';
import cn from 'classnames';
export const ParticipantsModal = () => {
  const dispatch = useDispatch();

  const [invitationLink, setInvitationLink] = useState<string>('');
  const [isInvitationLinkShown, setIsInvitationLinkShown] = useState<boolean>(
    false
  );
  const [currentRole, setCurrentRole] = useState('');

  const modalTitle = useShallowEqualSelector(
    (state) => state.modalReducer.modalTitle
  );

  const curPosition = useShallowEqualSelector(
    (state) => state.positionsReducer.curPosition
  );
  const { members } = useMembers(curPosition);

  if (!curPosition) {
    return null;
  }

  return (
    <>
      <Modal.Header
        className={cn('py-3 align-items-center', {
          'border-bottom-0': isInvitationLinkShown,
        })}
      >
        {!isInvitationLinkShown ? (
          <Modal.Title className='text-sm font-weight-bold leading-none'>
            {modalTitle}
          </Modal.Title>
        ) : null}

        <button
          className={cn(
            'bg-transparent border-0 p-0 d-flex align-items-center justify-content-center',
            {
              'ml-auto': isInvitationLinkShown,
            }
          )}
          onClick={() => dispatch(hideModal())}
        >
          <CloseIcon className='icon--16' />
        </button>
      </Modal.Header>
      <Modal.Body className='py-2'>
        {isInvitationLinkShown ? (
          <InvitationLink
            invitationLink={invitationLink}
            onProceed={() => setIsInvitationLinkShown(false)}
            text={
              <>
                Copy link to send to
                <br /> the {currentRole}
              </>
            }
          />
        ) : (
          <>
            <InviteForm
              membersLength={members.length}
              setInvitationLink={setInvitationLink}
              setIsInvitationLinkShown={setIsInvitationLinkShown}
              setCurrentRole={setCurrentRole}
            />
            <FreshInvites />
            {members.map((member) => (
              <Member
                data={member.data}
                key={member.data.uuid}
                role={member.role}
                curPosition={curPosition}
              />
            ))}
          </>
        )}
      </Modal.Body>
    </>
  );
};
