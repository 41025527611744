import {
  NotesActionsTypes,
  NotesTypesConstants,
  NotesState,
} from '../actions/types';

const initialState: NotesState = {
  pendingNotes: false,
  errorNotes: '',
  errorCreateNote: '',
  notes: undefined,
  pendingNoteUpdate: false,
};

export const notesReducer = (
  state = initialState,
  action: NotesActionsTypes
): NotesState => {
  switch (action.type) {
    case NotesTypesConstants.GET_NOTES:
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.payload.positionId as string]: action.payload.notes,
        },
        pendingNotes: false,
        errorNotes: '',
      };
    case NotesTypesConstants.ERROR_NOTES:
      return {
        ...state,
        pendingNotes: false,
        errorNotes: action.payload,
      };
    case NotesTypesConstants.ERROR_CREATE_NOTE:
      return {
        ...state,
        errorCreateNote: action.payload,
        pendingNoteUpdate: false,
      };
    case NotesTypesConstants.UPDATE_NOTE:
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.payload.position_id]:
            state.notes?.[action.payload.position_id].map((comment) => {
              if (comment.id === action.payload.id) {
                return action.payload;
              }
              return comment;
            }) || [],
        },
        pendingNoteUpdate: false,
      };
    case NotesTypesConstants.CREATE_NOTE:
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.payload.position_id]: state.notes?.[
            action.payload.position_id
          ]
            ? [...state.notes[action.payload.position_id], action.payload]
            : [action.payload],
        },
        pendingNoteUpdate: false,
      };
    case NotesTypesConstants.PEDNING_NOTES:
      return {
        ...state,
        pendingNotes: true,
      };
    case NotesTypesConstants.PENDING_NOTE_UPDATE:
      return {
        ...state,
        pendingNoteUpdate: true,
      };
    case NotesTypesConstants.CLEAR_NOTES:
      return {
        ...state,
        notes: undefined,
      };
    default:
      return state;
  }
};
