import { RootState } from '../reducers';
import { ISolution, INote } from '../typings/interfaces';
import { createSelector } from 'reselect';
import { NoteType, Stages } from '../typings/enums';
import { getCandidatesUserIdsArray } from '../util/getCandidatesUserIdsArray';
import { userRoleSelector } from './user';
import { filterSolutionByStage } from '../util/filterSolutionByStage';

export const notesSelector = (state: RootState) => state.notesReducer.notes;
export const pendingNotesSelector = (state: RootState) =>
  state.notesReducer.pendingNotes;
export const errorCreateNoteSelector = (state: RootState) =>
  state.notesReducer.errorCreateNote;
export const pendingNoteUpdateSelector = (state: RootState) =>
  state.notesReducer.pendingNoteUpdate;

export const selectRecruiterNote = createSelector(
  notesSelector,
  userRoleSelector,
  (_: any, positionSolutions: ISolution[] | undefined, __: any) =>
    positionSolutions,
  (_: any, __: any, positionId: string) => positionId,
  (notes, userRole, positionSolutions, positionId) =>
    notes?.[positionId]?.find(
      (note: INote) =>
        note.body.type === NoteType.to_recruiter &&
        positionSolutions &&
        getCandidatesUserIdsArray(
          positionSolutions.filter((solution) =>
            filterSolutionByStage(solution, userRole, Stages.SUBMITTED)
          )
        ).includes(note.user_id)
    )
);
