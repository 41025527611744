import React from 'react';
import { ReactComponent as ProgressIcon } from '../../../assets/hiring_team/progress_tracking.svg';
import { ReactComponent as ChecksIcon } from '../../../assets/hiring_team/checks.svg';
import { ReactComponent as NotesIcon } from '../../../assets/hiring_team/notes.svg';
import { v4 as uuid } from 'uuid';
import { Feature } from './Feature';
const BLOCKS = [
  {
    Icon: <ProgressIcon />,
    title: 'Progress Tracking',
    text: 'Track candidates’ engagement \nand react on time',
  },
  {
    Icon: <ChecksIcon />,
    title: 'Checks',
    text: 'Test reports, linter, and builds prepared \nfor you in advance',
  },
  {
    Icon: <NotesIcon />,
    title: 'Notes',
    text:
      'Make notes and comment code to help other \nreviewers make a decision faster',
  },
];

export const Features = () => {
  return (
    <div className='py-10 d-flex flex-column flex-lg-row justify-content-lg-between container'>
      {BLOCKS.map((props, i: number) => (
        <Feature {...props} key={uuid()} />
      ))}
    </div>
  );
};
