import React from 'react';
import cn from 'classnames';
import { Field } from 'formik';

interface Props {
  isError: string | string[] | undefined;
  isTouched: boolean | undefined;
}

export const AgreeCheckbox: React.FC<Props> = ({ isError, isTouched }) => {
  return (
    <>
      <div className='w-100 position-relative'>
        <div className='custom-control custom-checkbox'>
          <Field
            type='checkbox'
            className='custom-control-input'
            id='subscribe-form-checkbox'
            name='agree'
          />
          <label
            className={cn('text-sm md:text-base', {
              'custom-control-label-error leading-tight':
                isError && isTouched && isError,
              'custom-control-label leading-tight':
                (isTouched && !isError) || !isTouched,
            })}
            id='label-subscribe-form-checkbox'
            htmlFor='subscribe-form-checkbox'
          >
            I give my consent to the processing of my personal data
          </label>
        </div>
      </div>
      <div
        className={
          isError && isTouched && isError
            ? 'text-danger text-left mt-0 text-left mr-auto'
            : ''
        }
      >
        {isError && isTouched && isError}
      </div>
    </>
  );
};
