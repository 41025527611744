// Alert
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

export interface AlertState {
  isShown: boolean;
  message: string;
  type: string;
}

interface ShowAlertAction {
  type: typeof SHOW_ALERT;
  payload: {
    message: string;
    type: string;
  };
}
interface HideAlertAction {
  type: typeof HIDE_ALERT;
}

export type AlertActionTypes = ShowAlertAction | HideAlertAction;
