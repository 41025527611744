import { Stages } from '../typings/enums';

export const renderInProgressStageTitle = (
  candidatesType: string | undefined
) => {
  switch (candidatesType) {
    case Stages.SENT:
      return 'Task Sent';
    case Stages.STARTED:
      return 'Started with Task';
    case Stages.EXPIRED:
      return 'Task Expired';
    case Stages.EXPIRING:
      return 'Task expiring';
    case Stages.WORKING:
      return 'Working on task';
    default:
      return '';
  }
};
