import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Search } from '../../../shared/components/Search';
import { CandidateLink } from '../../../shared/components/CandidateLink';
import { compareValues } from '../../../util/compareValues';
import { ISolution } from '../../../typings/interfaces';
import moment from 'moment';
import { HeaderCell } from '../../../shared/components/HeaderCell';
import { useParams } from 'react-router-dom';
import { Stages } from '../../../typings/enums';
import cn from 'classnames';
import { isExpired, isExpiring } from '../../../util/filterFunctions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { StageTitle } from '../../../shared/components/StageTitle';
import { Loader } from '../../../shared/components/Loader';
import {
  pendingCandidatesSelector,
  selectFilteredCandidates,
} from '../../../selectors';

const TABLE_HEADER = [
  {
    title: 'Candidate',
    property: 'candidate.first_name',
  },
  {
    title: 'Time',
    property: 'date_archived',
  },
  {
    title: 'Status',
    property: 'feedback.resolution',
  },
];

export const Archived: React.SFC = () => {
  const { positionId } = useParams();

  const [filtered, setFiltered] = useState<any[]>([]);
  const [order, setOrder] = useState(true);
  const [propertyKey, setPropertyKey] = useState('Candidate');
  const pendingCandidates = useShallowEqualSelector(pendingCandidatesSelector);
  const filteredCandidates = useShallowEqualSelector((state) =>
    selectFilteredCandidates(state, positionId, Stages.ARCHIVED)
  );
  useEffect(() => {
    filteredCandidates && setFiltered(filteredCandidates);
  }, [filteredCandidates]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let currentList = [];
    let newList: any[] = [];
    if (filteredCandidates) {
      if (e.target.value !== '') {
        currentList = filteredCandidates;

        newList = currentList.filter((listItem: any) => {
          const lc = `${listItem.candidate.first_name.toLowerCase()} ${listItem.candidate.last_name.toLowerCase()}`;
          const filter = e.target.value.toLowerCase();
          return lc.includes(filter);
        });
      } else {
        newList = [...filteredCandidates];
      }
    }

    setFiltered(newList);
  };

  if (pendingCandidates.includes(positionId)) return <Loader />;

  return (
    <>
      <div style={{ maxWidth: '985px' }}>
        <div className='d-flex flex-lg-row flex-columng align-items-center justify-content-lg-between'>
          <StageTitle title='Archived Candidates' qty={filtered.length} />
          <Search handleChange={handleChange} />
        </div>
        <div className='border rounded px-4 py-3'>
          <Table responsive size='sm'>
            <thead>
              <tr>
                {TABLE_HEADER.map((cell) => (
                  <HeaderCell
                    key={cell.title}
                    order={order}
                    setOrder={setOrder}
                    propertyKey={propertyKey}
                    setPropertyKey={setPropertyKey}
                    property={cell.property}
                    title={cell.title}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {filtered
                .sort(compareValues(propertyKey, order))
                .map((candidate: ISolution) => {
                  return (
                    <tr key={candidate.candidate.uuid}>
                      <td className='border-top-0'>
                        <CandidateLink
                          candidate={candidate}
                          className='font-weight-bold'
                        />
                      </td>
                      <td className='border-top-0'>
                        {typeof candidate.date_archived === 'string'
                          ? moment.utc(candidate.date_archived).fromNow()
                          : null}
                      </td>
                      <td className='border-top-0'>
                        <span
                          className={cn({
                            'text-success':
                              candidate?.feedback?.resolution ===
                              Stages.ACCEPTED.toUpperCase(),
                            'text-danger':
                              candidate?.feedback?.resolution ===
                              Stages.REJECTED.toUpperCase(),
                          })}
                        >
                          {candidate?.feedback?.resolution
                            ? candidate?.feedback?.resolution
                            : null}
                          {!candidate?.feedback && isExpired(candidate)
                            ? 'Expired'
                            : null}
                          {!candidate?.feedback && isExpiring(candidate)
                            ? 'Expiring'
                            : null}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
