import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollTop } from './ScrollTop';

import { verifyToken, saveTokens } from './actions/userActions';
import { PrivateRoute } from './PrivateRoute';
import { initializeChat } from './CollectChat';
import { useShallowEqualSelector } from './hooks/useShallowEqualSelector';

import { Layout } from './containers/Layout';
import { RecruiterDashboard } from './recruiter/pages/RecruiterDashboard';
import { ReviewerDashboard } from './reviewer/pages/ReviewerDashboard';
import { CandidatesContainer } from './containers/CandidatesContainer';
import { SubmittedReviewed } from './reviewer/pages/SubmittedReviewed';
import { CandidateContainer } from './containers/CandidateContainer';
import { CandidateProfile } from './recruiter/pages/CandidateProfile';
import { OverviewCandidate } from './reviewer/pages/OverviewCandidate';
import { NotesFeedback } from './reviewer/pages/NotesFeedback';
import { Submitted } from './recruiter/pages/Submitted';
import { CandidatesToInform } from './recruiter/pages/CandidatesToInform';
import { Checks } from './reviewer/pages/Checks';
import { InProgressCandidates } from './shared/pages/InProgressCandidates';
import { FilesChanged } from './reviewer/pages/FilesChanged';
import { ProcessedCandidates } from './recruiter/pages/ProcessedCandidates';
import { Code } from './recruiter/pages/Code';
import { TaskSent } from './shared/pages/TaskSent';
import { Ticket } from './reviewer/pages/Ticket';
import { Archived } from './recruiter/pages/Archived';

import { Share } from './shared/pages/Share/Share';
import { Home } from './shared/pages/Home';
import { BookADemo } from './shared/pages/BookADemo';
import { Page404 } from './shared/pages/ErrorPages/Page404';
import { Page500 } from './shared/pages/ErrorPages/Page500';
import { ModalComponent } from './shared/components/ModalComponent';
import { AlertComponent } from './shared/components/AlertComponent';
import { TryChallenge } from './shared/pages/TryChallenge';
import { CookiePolicy } from './shared/pages/CookiePolicy';
import { ImPrint } from './shared/pages/ImPrint';
import { TaskSettings } from './reviewer/pages/TaskSettings';
import { HiringTeam } from './shared/pages/HiringTeam';
import { Recruiters } from './shared/pages/Recruiters';
import { Singin } from './shared/pages/Singin';
import { FreeTrial } from './shared/pages/FreeTrial';
import { InvitationLayout } from './containers/InvitationLayout';
import { Signup } from './shared/pages/Signup';
import { PoliciesContainer } from './containers/PoliciesContainer';
import { TwoMonthsFreeTrial } from './shared/pages/TwoMonthsFreeTrial';
import { LegalDocument } from './shared/pages/LegalDocument';
import { Contact } from './shared/pages/Contact';
import { ForgotPassword } from './shared/pages/ForgotPassword';
import { ResetPassword } from './shared/pages/ResetPassword';
import { ChangePassword } from './shared/pages/ChangePassword';
import { UserRoles } from './typings/enums';
import { useReactGA } from './hooks/useReactGA';
import { useRefreshToken } from './hooks/useRefreshToken';
import { getUserInfo } from './actions/userActions';
import { KeyActivitiesExpand } from './shared/pages/KeyActivitiesExpand';
import { NotificationsSocket } from './NotificationsSocket';
import {
  userSelector,
  intialUserLoadedSelector,
  userRoleSelector,
} from './selectors';
import { getTokens } from './util/getTokens';
import { DashboardInit } from './common/DashboardInit';
import { Repo } from './containers/CandidateContainer/Repo/index';
import { TaskTableSimple } from './reviewer/pages/TaskSettings/TaskTableSimple';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const notificationsSocket = new NotificationsSocket(
  window.OPTIONS
    ? window.OPTIONS.NOTIFICATION_WS_URL
    : 'wss://socket-dev.sfeeda.com/notifier'
);

export const App: React.FC = () => {
  const { mandatoryTickets } = useFlags();
  const dispatch = useDispatch();

  const user = useShallowEqualSelector(userSelector);
  const intialUserLoaded = useShallowEqualSelector(intialUserLoadedSelector);
  const userRole = useShallowEqualSelector(userRoleSelector);

  useEffect(() => {
    user && dispatch(getUserInfo());
  }, [dispatch, user]);

  useEffect(() => {
    const tokens = getTokens();
    dispatch(saveTokens(tokens));
    dispatch(verifyToken());
  }, [dispatch]);

  useEffect(() => {
    if (window.OPTIONS.CLIENT_SIDE_ID !== '5e62b2d1683ac10aa6a25d2d')
      initializeChat(window, document);
  }, []);

  useReactGA();
  useRefreshToken();

  if (!intialUserLoaded) {
    return null;
  }

  return (
    <BrowserRouter>
      <DashboardInit />
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route exact path='/contact'>
          <Contact />
        </Route>
        <Route exact path='/share/:shareuuid/:positionId/:candidateId'>
          <Share />
        </Route>
        <Route
          exact
          path='/share/:shareuuid/:positionId/:candidateId/code/:sourceCommit?/:destinationCommit?'
        >
          <CandidateContainer>
            <FilesChanged share={true} />
          </CandidateContainer>
        </Route>
        <Route exact path='/share/:shareuuid/:positionId/:candidateId/overview'>
          <CandidateContainer>
            <OverviewCandidate />
          </CandidateContainer>
        </Route>
        <Route exact path='/share/:shareuuid/:positionId/:candidateId/checks'>
          <CandidateContainer>
            <Checks share={true} />
          </CandidateContainer>
        </Route>
        <PrivateRoute exact path='/key_activities'>
          <KeyActivitiesExpand />
        </PrivateRoute>
        <Route exact path='/signin/:inviteId?'>
          <Singin />
        </Route>
        <Route exact path='/password/forgot/'>
          {!user ? <ForgotPassword /> : <Redirect to='/dashboard' />}
        </Route>
        <Route exact path='/password/reset/'>
          {!user ? <ResetPassword /> : <Redirect to='/dashboard' />}
        </Route>
        <Route exact path='/password/change/'>
          {user ? <ChangePassword /> : <Redirect to='/dashboard' />}
        </Route>
        <Route exact path='/2months-free-trial'>
          <TwoMonthsFreeTrial />
        </Route>
        <Route exact path='/free-trial'>
          <FreeTrial />
        </Route>
        <Route exact path='/book'>
          <BookADemo />
        </Route>
        <Route exact path='/try'>
          <TryChallenge />
        </Route>
        <Route exact path='/invitation/:inviteId'>
          <InvitationLayout>
            <Signup />
          </InvitationLayout>
        </Route>
        <Route exact path='/hiring_teams'>
          <HiringTeam />
        </Route>
        <Route exact path='/recruiters'>
          <Recruiters />
        </Route>
        <Route exact path='/imprint'>
          <ImPrint />
        </Route>
        <PrivateRoute exact path='/dashboard'>
          <Layout>
            {userRole === UserRoles.REVIEWER && <ReviewerDashboard />}
            {userRole === UserRoles.RECRUITER && <RecruiterDashboard />}
          </Layout>
        </PrivateRoute>
        <PrivateRoute
          exact
          path='/position/:positionId/candidate/:candidateId/overview'
          role={UserRoles.REVIEWER}
        >
          <CandidateContainer>
            <OverviewCandidate />
          </CandidateContainer>
        </PrivateRoute>
        <PrivateRoute
          exact
          path='/position/:positionId/candidate/:candidateId/checks'
          role={UserRoles.REVIEWER}
        >
          <CandidateContainer>
            <Checks />
          </CandidateContainer>
        </PrivateRoute>
        <PrivateRoute
          path='/position/:positionId/candidate/:candidateId/repo/'
          exact
          role={UserRoles.REVIEWER}
        >
          <CandidateContainer>
            <Repo />
          </CandidateContainer>
        </PrivateRoute>
        <PrivateRoute
          path='/position/:positionId/candidate/:candidateId/repo/:gitContentType?/:path+'
          exact
          role={UserRoles.REVIEWER}
        >
          <CandidateContainer>
            <Repo />
          </CandidateContainer>
        </PrivateRoute>
        <PrivateRoute
          exact
          path='/position/:positionId/candidate/:candidateId/notes'
          role={UserRoles.REVIEWER}
        >
          <CandidateContainer>
            <NotesFeedback />
          </CandidateContainer>
        </PrivateRoute>
        <PrivateRoute
          exact
          path='/position/:positionId/task'
          role={UserRoles.REVIEWER}
        >
          {mandatoryTickets ? <TaskTableSimple /> : <TaskSettings />}
        </PrivateRoute>
        <PrivateRoute
          exact
          path='/position/:positionId/task/ticket/:ticketId'
          role={UserRoles.REVIEWER}
        >
          <Ticket />
        </PrivateRoute>
        <PrivateRoute
          exact
          path='/position/:positionId/candidate/:candidateId/code/:sourceCommit?/:destinationCommit?'
        >
          {userRole === UserRoles.REVIEWER && (
            <CandidateContainer>
              <FilesChanged />
            </CandidateContainer>
          )}
          {userRole === UserRoles.RECRUITER && (
            <CandidateContainer>
              <Code />
            </CandidateContainer>
          )}
        </PrivateRoute>
        <PrivateRoute
          exact
          path='/position/:positionId/candidate/:candidateId'
          role={UserRoles.RECRUITER}
        >
          <CandidateContainer>
            <CandidateProfile />
          </CandidateContainer>
        </PrivateRoute>

        <PrivateRoute exact path='/position/:positionId/task_sent'>
          <TaskSent />
        </PrivateRoute>
        <PrivateRoute exact path='/position/:positionId/processed'>
          <CandidatesContainer>
            <ProcessedCandidates />
          </CandidatesContainer>
        </PrivateRoute>
        <PrivateRoute
          exact
          path='/position/:positionId/in_progress/:candidatesType'
        >
          <CandidatesContainer>
            <InProgressCandidates />
          </CandidatesContainer>
        </PrivateRoute>
        <PrivateRoute
          exact
          path='/position/:positionId/archived'
          role={UserRoles.RECRUITER}
        >
          <CandidatesContainer>
            <Archived />
          </CandidatesContainer>
        </PrivateRoute>
        <PrivateRoute exact path='/position/:positionId/:stageType'>
          {userRole === UserRoles.RECRUITER && (
            <CandidatesContainer>
              <Submitted />
            </CandidatesContainer>
          )}
          {userRole === UserRoles.REVIEWER && (
            <CandidatesContainer>
              <SubmittedReviewed />
            </CandidatesContainer>
          )}
        </PrivateRoute>
        <PrivateRoute
          exact
          path='/position/:positionId/:stageType/:candidatesType'
        >
          <CandidatesContainer>
            {userRole === UserRoles.RECRUITER && <CandidatesToInform />}
          </CandidatesContainer>
        </PrivateRoute>
        <Route path='/cookie'>
          <PoliciesContainer>
            <CookiePolicy />
          </PoliciesContainer>
        </Route>
        <Route path='/terms-of-service'>
          <PoliciesContainer>
            <LegalDocument document='Terms Of Service' />
          </PoliciesContainer>
        </Route>
        <Route path='/privacy-policy'>
          <PoliciesContainer>
            <LegalDocument document='Privacy Policy' />
          </PoliciesContainer>
        </Route>
        <Route path='/data-protection'>
          <PoliciesContainer>
            <LegalDocument document='Data Protection Agreement' />
          </PoliciesContainer>
        </Route>
        <Route exact path='/500'>
          <Page500 />
        </Route>
        <Route path='*'>
          <Page404 />
        </Route>
      </Switch>
      <ScrollTop />
      <ModalComponent />
      <AlertComponent />
    </BrowserRouter>
  );
};
