import {
  SET_TASK,
  PENDING_TASK,
  ERROR_TASK,
  CLEAR_TASK,
  // UPDATE_TASK,
  TaskActionsTypes,
  TaskState,
} from '../actions/types';

const initialState: TaskState = {
  task: undefined,
  pendingTask: false,
  errorTask: '',
};

export const taskReducer = (state = initialState, action: TaskActionsTypes) => {
  switch (action.type) {
    case SET_TASK:
      return {
        ...state,
        task: action.payload,
        pendingTask: false,
        errorTask: '',
      };
    case CLEAR_TASK:
      return {
        ...state,
        task: undefined,
        pendingTask: false,
        errorTask: '',
      };
    case ERROR_TASK:
      return {
        ...state,
        pendingTask: false,
        errorTask: action.payload,
      };
    case PENDING_TASK:
      return {
        ...state,
        pendingTask: true,
      };
    default:
      return state;
  }
};
