import { UserActionsTypesConstants, UserActionTypes } from './types';
import { Action, Dispatch } from 'redux';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { IConsent } from '../typings/interfaces';
import { apiFetch } from '../util/apiFetch';
import { UserRoles } from '../typings/enums';
import { clearPositions } from './positionsActions';
import { postConsent } from './policiesActions';
import { saveTokensCookies } from '../util/saveTokensCookies';
import { setCookie } from '../util/setCookie';
import { errorText } from '../util/fetchWithToken';
import { fetchWithToken } from '../util/fetchWithToken';
import { getEnv } from '../util/getEnv';
import { deleteTokens } from '../util/deleteTokens';
import { GuestFormTypes } from '../typings/interfaces/GuestFormTypes';

const jwtDecode = require('jwt-decode');

const allowedRoles = [
  UserRoles.REVIEWER,
  UserRoles.RECRUITER,
  UserRoles.CANDIDATE,
];

export const signin = (
  userData: {
    username: string;
    password: string;
    invite?: string;
  },
  keepSigned: boolean,
  cb: () => void
): ThunkAction<void, RootState, UserActionTypes, Action<string>> => async (
  dispatch
) => {
  try {
    const env = getEnv();
    dispatch({
      type: UserActionsTypesConstants.PENDING_FETCH_USER,
    });

    const response = await apiFetch('auth', '/signin/', 'POST', userData);

    if (response.error) {
      throw new Error(response.status === 401 ? response.error : errorText);
    }

    if (keepSigned) {
      const maxAge = 60 * 60 * 24 * 30;
      setCookie(`keep_signin_${env}`, 'true', maxAge);
    }

    dispatch(saveTokens(response.data));
    saveTokensCookies(response.data);

    !userData.invite && cb();
    dispatch(saveUser());
    userData.invite && cb();
  } catch (err) {
    cb();
    dispatch({
      type: UserActionsTypesConstants.ERROR_FETCH_USER,
      payload: err.message,
    });
  }
};

export const verifyToken = (): ThunkAction<
  void,
  RootState,
  UserActionTypes,
  Action<string>
> => async (dispatch, getState) => {
  const { accessToken } = getState().userReducer;
  let response;
  try {
    dispatch({
      type: UserActionsTypesConstants.PENDING_FETCH_USER,
    });
    if (accessToken) {
      response = await apiFetch('auth', '/verify/', 'GET');

      if (response.error) {
        throw new Error(response.status === 401 ? response.error : errorText);
      }

      const role = response.data.user_claims.roles[0];

      if (!allowedRoles.includes(role)) {
        throw new Error(errorText);
      }
    }
    dispatch(saveUser());
  } catch (err) {
    if (response?.status === 401) {
      dispatch(refreshAccessToken());
    } else {
      dispatch(signout());
    }
  }
};

const saveUser = (): ThunkAction<
  void,
  RootState,
  UserActionTypes,
  Action<string>
> => async (dispatch, getState) => {
  const { accessToken } = getState().userReducer;
  const userData = accessToken ? jwtDecode(accessToken) : null;

  dispatch({
    type: UserActionsTypesConstants.SIGNIN_USER,
    payload: userData,
  });
};

export const signout = (): ThunkAction<
  void,
  RootState,
  UserActionTypes,
  Action<string>
> => async (dispatch) => {
  try {
    deleteTokens();
    dispatch(clearPositions());
    dispatch({
      type: UserActionsTypesConstants.SIGNOUT_USER,
    });
  } catch (err) {
    console.log(err);
  }
};

export const refreshAccessToken = (): ThunkAction<
  void,
  RootState,
  UserActionTypes,
  Action<string>
> => async (dispatch) => {
  try {
    const response = await fetchWithToken('auth', '/refresh/', 'POST');

    if (response.error) throw new Error(response.error);

    saveTokensCookies(response.data);
    dispatch(saveTokens(response.data));
    dispatch(saveUser());
  } catch (err) {
    dispatch(signout());
  }
};

export const identifyUser = () => ({
  type: UserActionsTypesConstants.IDENTIFY_USER,
});

export const getUserInfo = (): ThunkAction<
  void,
  RootState,
  UserActionTypes,
  Action<string>
> => async (dispatch) => {
  try {
    dispatch({
      type: UserActionsTypesConstants.PENDING_USER_INFO,
    });
    const response = await apiFetch('auth', '/me/');
    if (response.error) throw new Error(response.error);
    dispatch({
      type: UserActionsTypesConstants.GET_USER_INFO,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UserActionsTypesConstants.ERROR_USER_INFO,
      payload: error.message,
    });
  }
};

export const signup = (
  userObj: any,
  inviteId: string,
  cb: (response: any) => void,
  consentsData?: IConsent[]
): ThunkAction<void, RootState, UserActionTypes, Action<string>> => async (
  dispatch
) => {
  let response;
  try {
    response = await apiFetch('auth', '/signup/', 'POST', {
      ...userObj,
      invite: inviteId,
    });

    if (response.error) throw new Error(response.error);

    saveTokensCookies(response.data);
    dispatch(saveTokens(response.data));

    if (consentsData) {
      const consentPromises = [];
      for (let consentData of consentsData) {
        consentPromises.push(postConsent(consentData));
      }

      await Promise.all(consentPromises);
    }

    dispatch(saveUser());
  } catch (err) {
    dispatch({
      type: UserActionsTypesConstants.ERROR_SIGN_UP,
      payload: err.message,
    });
  } finally {
    cb(response);
  }
};

export const clearSignUpError = () => ({
  type: UserActionsTypesConstants.CLEAR_ERROR_SIGN_UP,
});

export const saveTokens = (tokens: {
  access_token: string;
  refresh_token: string;
}) => ({
  type: UserActionsTypesConstants.SAVE_TOKENS,
  payload: {
    accessToken: tokens.access_token,
    refreshToken: tokens.refresh_token,
  },
});

export const guestSession = (guestCompanyData: GuestFormTypes) => async (
  dispatch: Dispatch<any>
) => {
  const sendDataRegData = {
    company: guestCompanyData.company,
    email: guestCompanyData.email,
    first_name: guestCompanyData.firstName,
    last_name: guestCompanyData.lastName,
    password: guestCompanyData.password,
    role: 'reviewer',
  };

  const req = await apiFetch(
    'auth',
    '/signup/company/',
    'POST',
    sendDataRegData
  );
  if (req.error) {
    throw new Error(req.status === 400 ? req.error : errorText);
  }

  console.log(guestCompanyData, 'comp data', req.data);
  await saveTokensCookies(req.data);
  dispatch(saveTokens(req.data));
  dispatch(saveUser());
};

// export const guestCompanyData = () => async (dispatch: Dispatch<any>) => {
//   const fakeToken = localStorage.getItem('guesttokendata');
//   const tempSession = localStorage.getItem('guestuserdata');
//   if (fakeToken && tempSession) {
//     dispatch({
//       type: UserActionsTypesConstants.SIGNIN_USER,
//       payload: JSON.parse(fakeToken),
//     });
//     dispatch({
//       type: UserActionsTypesConstants.GET_USER_INFO,
//       payload: JSON.parse(tempSession),
//     });
//   }
// };
