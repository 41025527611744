import React from 'react';
import { CalendlyButton } from '../CalendlyButton';
import avatar from '../../../assets/avatar_dmytro.png';

interface Props {}

export const ScheduleBlock = (props: Props) => {
  return (
    <div className='flex-grow-1 d-flex flex-column align-items-center'>
      <div style={{ width: '80px', height: '80px' }} className='mb-4'>
        <img src={avatar} alt='' className='d-block w-100 rounded-circle' />
      </div>
      <div className='text-xl font-weight-bold mb-2'>
        Or schedule a call with Dmitry
      </div>
      <CalendlyButton
        url='https://calendly.com/dmitry-11/30min'
        text='Schedule call'
      />
    </div>
  );
};
