import { isExpired, isExpiring } from './filterFunctions';
import { Stages, UserRoles } from '../typings/enums';
import { ISolution } from '../typings/interfaces';

export const getSolutionStage = (
  solution: ISolution,
  userRole: string | undefined
) => {
  if (
    solution.is_archived &&
    solution.feedback?.is_positive === true &&
    userRole === UserRoles.REVIEWER
  ) {
    return Stages.ACCEPTED;
  }

  if (
    solution.is_archived &&
    solution.feedback?.is_positive === false &&
    userRole === UserRoles.REVIEWER
  ) {
    return Stages.REJECTED;
  }

  if (solution.is_archived) {
    return Stages.ARCHIVED;
  }

  if (solution.feedback?.is_sent && userRole === UserRoles.RECRUITER) {
    return Stages.PROCESSED;
  }

  if (solution.feedback?.is_positive === true) {
    return Stages.ACCEPTED;
  }

  if (solution.feedback?.is_positive === false) {
    return Stages.REJECTED;
  }

  if (solution.is_ready) {
    return Stages.SUBMITTED;
  }

  if (isExpired(solution)) {
    return Stages.EXPIRED;
  }

  if (isExpiring(solution)) {
    return Stages.EXPIRING;
  }

  return Stages.STARTED;
};
