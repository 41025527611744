export const TicketPersentColorFilter = (
  persent: number,
  candidateAcceptStatus: string
): string => {
  if (persent <= 10 && candidateAcceptStatus === 'SUCCESS') {
    return '#27AE60';
  }
  if (persent >= 90 && candidateAcceptStatus === 'FAIL') {
    return '#D20B0B';
  }
  return 'black';
};
