import React from 'react';
import { ReactComponent as LikeIcon } from '../../../assets/like.svg';
import { ILike } from '../../../typings/interfaces';
import './LikeBtn.scss';

interface Props {
  like: ILike;
}

export const Like: React.FC<Props> = ({ like }) => {
  return (
    <div className='p-0 focus:outline-none like-btn bg-transparent active'>
      <LikeIcon
        className='rounded-circle border-white border icon--33'
        style={{ transform: `${!like.negate ? '' : 'rotate(180deg)'}` }}
      />
    </div>
  );
};
