import React from 'react';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { Feedback } from '../../../recruiter/components/Feedback';
import { Notes } from './Notes';
import { RecruiterNote } from '../../components/RecruiterNote';

export const NotesFeedback: React.FC = () => {
  const currentCandidate = useShallowEqualSelector(
    (state) => state.candidatesReducer.currentCandidate
  );

  if (!currentCandidate) {
    return null;
  }

  return (
    <>
      <div className='text-2xl font-weight-bold mb-2'>Notes & Feedback</div>
      <div className='container d-flex flex-column flex-lg-row mx-0 px-0 justify-content-lg-between'>
        <div className='lg:w-6/12'>
          <Notes />
        </div>
        <div className='lg:w-5/12 mb-3'>
          <div className='font-weight-bold text-lg mb-1'>Feedback</div>
          <Feedback currentCandidate={currentCandidate} withAuthor />
          <RecruiterNote />
        </div>
      </div>
    </>
  );
};
