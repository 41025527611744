import React from 'react';
import './TicketInfo.scss';

interface Props {
  body: any;
}

export const TicketInfo: React.FC<Props> = ({ body }) => {
  return (
    <div
      className='ticket-info mb-3'
      dangerouslySetInnerHTML={{
        __html: body,
      }}
    />
  );
};
