import { RootState } from '../reducers';
import { createSelector } from 'reselect';

export const positionsSelector = (state: RootState) =>
  state.positionsReducer.positions;
export const positionsPendingSelector = (state: RootState) =>
  state.positionsReducer.pending;
export const positionsErrorSelector = (state: RootState) =>
  state.positionsReducer.error;

export const pendingPositionSelector = (state: RootState) =>
  state.positionsReducer.pendingPosition;
export const positionSelector = (state: RootState) =>
  state.positionsReducer.position;

export const isActiveAddPositionSelector = (state: RootState) =>
  state.positionsReducer.isActiveAddPosition;
export const curPositionSelector = (state: RootState) =>
  state.positionsReducer.curPosition;

export const postionsIdsSelector = createSelector(
  positionsSelector,
  (positions) => {
    return positions?.reduce((positionsIds: string[], position) => {
      positionsIds.push(position.uuid);
      return positionsIds;
    }, []);
  }
);
