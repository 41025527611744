import React, { useState, useRef } from 'react';
import { Tooltip, Overlay } from 'react-bootstrap';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow_down.svg';
import { UserRoles } from '../../../typings/enums';
import { capitalize } from '../../../util/capitalize';
import { RoleBtn } from './RoleBtn';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

interface Props {
  role: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const RolesDropdown: React.FC<Props> = ({ role, setFieldValue }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const menu = useRef(null);

  const onClickOutside = () => {
    setShow(false);
  };

  useOnClickOutside(menu, onClickOutside);

  return (
    <>
      <button
        ref={target}
        onClick={(e) => {
          e.preventDefault();
          setShow(!show);
        }}
        className='position-absolute bg-transparent border-0 d-flex align-items-center text-xs leading-none px-0 h-100'
        style={{
          top: '50%',
          right: '10px',
          transform: 'translate3d(0, -50%, 0)',
        }}
      >
        <span>{capitalize(role)}</span>
        <ArrowIcon
          className='icon--8 ml-1'
          style={{
            transform: 'rotate(-90deg)',
          }}
        />
      </button>
      <Overlay target={target.current} show={show} placement='bottom'>
        {(props) => (
          <Tooltip id='overlay-example' {...props} className='text-sm'>
            <div className='p-1' ref={menu}>
              <RoleBtn
                setShow={setShow}
                curRole={UserRoles.REVIEWER as string}
                activeRole={role}
                setFieldValue={setFieldValue}
              />
              <RoleBtn
                setShow={setShow}
                curRole={UserRoles.RECRUITER as string}
                activeRole={role}
                setFieldValue={setFieldValue}
              />
            </div>
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};
