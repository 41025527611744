import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface Props {
  className?: string;
}

export const TryButton: React.FC<Props> = ({ className }) => {
  return (
    <Link
      className={cn(
        'btn rounded-lg bg-transparent lg:hover:bg-yellow-700 w-100',
        [className]
      )}
      style={{ maxWidth: '228px' }}
      to='/try'
    >
      Try Challenge
    </Link>
  );
};
