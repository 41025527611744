import { getCookie } from './getCookie';
import { getEnv } from './getEnv';

export const getTokens = () => {
  const env = getEnv();

  return {
    access_token: getCookie(`access_token_${env}`),
    refresh_token: getCookie(`refresh_token_${env}`),
  };
};
