import React from 'react';
import imgPath from '../../../assets/hiring_team/code.png';

interface Props {}

export const CandidateExperience = (props: Props) => {
  return (
    <div
      style={{ background: '#E9EBEF', minHeight: '280px' }}
      className='pt-4 pb-lg-0 pr-lg-0 px-4 d-flex flex-column flex-lg-row justify-content-lg-between rounded'
    >
      <div className='lg:w-6/12 mb-6 mb-lg-0'>
        <div className='text-2-5xl font-weight-bold mb-2 leading-thin'>
          Great candidate’s experience
        </div>
        <div className='mb-2'>
          There is no room for stress. Candidates work on challenges as they do
          on real projects.
        </div>
        <div>
          They work in a familiar environment, well-described tickets, and their
          own repository.{' '}
        </div>
      </div>
      <div className='lg:w-5/12 d-flex'>
        <img
          src={imgPath}
          alt='Great candidate’s experience'
          className='w-100 align-self-end'
        />
      </div>
    </div>
  );
};
