import React, { useMemo } from 'react';
import { Quantity } from './Quantity';
import { Stages } from '../../../typings/enums';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { Pipeline } from '../../../typings/interfaces';
import { Loader } from '../../../shared/components/Loader';
import {
  pendingCandidatesSelector,
  selectRecruiterPositionPipeline
} from '../../../selectors';

interface Props {
  type: Stages;
  handleClick: ({ title, type }: { title: string; type: string }) => void;
  modalTitle: string;
  title: string;
  positionId: string;
}

export const CandidatesQty: React.FC<Props> = ({
  type,
  modalTitle,
  handleClick,
  title,
  positionId
}) => {
  const pendingCandidates = useShallowEqualSelector(pendingCandidatesSelector);
  const pipeline = useShallowEqualSelector((state) =>
    selectRecruiterPositionPipeline(state, positionId)
  );

  const qty = useMemo(() => {
    if (pipeline) {
      if (type === Stages.WORKING) {
        return pipeline?.started + pipeline?.expiring + pipeline?.expired;
      } else {
        return pipeline?.[type as keyof Pipeline];
      }
    } else {
      return 0;
    }
  }, [pipeline, type]);

  return (
    <div className='flex-grow-1 position-absolute inset-0'>
      {!pendingCandidates.includes(positionId) && type && pipeline ? (
        <Quantity
          type={type}
          length={qty}
          expiring={pipeline?.expiring}
          expired={pipeline?.expired}
          handleClick={handleClick}
          modalTitle={modalTitle}
          title={title}
        />
      ) : (
        <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
          <Loader />
        </div>
      )}
    </div>
  );
};
