import React from 'react';
import { Link } from 'react-router-dom';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import cn from 'classnames';

interface Props {
  handleLinkClick?: (
    e: React.MouseEvent<Element, MouseEvent>
  ) => void | undefined;
  links: anchor[];
  darkText?: boolean;
}

type anchor = {
  titleDesktop: string;
  titleMob: string;
  href: string;
};

export const Links: React.FC<Props> = ({
  handleLinkClick,
  links,
  darkText,
}) => {
  const [isMatchMediaQuery] = useMatchMedia(992);
  const classes =
    'mb-1 mb-lg-0 mr-lg-8 bg-transparent border-0 text-left mr-4 flex-shrink-0 px-0';

  return (
    <>
      {links.map((link: anchor) => {
        if (
          (link.href === 'pricing' || link.href === 'candidates') &&
          handleLinkClick
        ) {
          return (
            <button
              className={cn(['focus:outline-none', classes], {
                'lg:text-white text-blue-300 hover:opacity-50': !darkText,
                'text-black': darkText,
              })}
              data-scrollto={link.href}
              key={link.titleDesktop}
              onClick={handleLinkClick}
            >
              {link.titleDesktop}
            </button>
          );
        }
        return (
          <Link
            className={cn(classes, {
              'lg:text-white text-blue-300 hover:text-white hover:opacity-50': !darkText,
              'text-black': darkText,
            })}
            data-scrollto={link.href}
            key={link.titleDesktop}
            to={link.href}
          >
            {isMatchMediaQuery ? link.titleDesktop : link.titleMob}
          </Link>
        );
      })}
    </>
  );
};
