import React from 'react';
import { SubscribeError } from '../../../typings/interfaces';
import { Subscribes } from '../../../typings/enums';

interface Props {
  errorSubscribes: SubscribeError[];
  type: Subscribes;
}

export const SubscribeApiError: React.FC<Props> = ({
  errorSubscribes,
  type,
}) => {
  const error = errorSubscribes.find((subscribe) => subscribe.type === type);

  return (
    <>
      {error && (
        <div className='text-red-400 mb-3 text-center' id='login_form_error'>
          {error.message.split('\n').map((str: string, i: number) => (
            <div key={`str-${i}`}>{str}</div>
          ))}
        </div>
      )}
    </>
  );
};
