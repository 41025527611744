import React from 'react';
import { Badge, BadgeProps, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  fit: string | undefined;
  candidateId: string;
  className?: string;
}

enum fitTypes {
  GOOD = 'GOOD',
  BAD = 'BAD',
}
interface BadgeOptionsProps extends BadgeProps {
  text: string;
}

export const PredictionBadge: React.FC<Props> = ({
  fit,
  candidateId,
  className,
}) => {
  if (!fit) {
    return null;
  }

  const classes = `align-items-end d-flex-inline leading-none font-weight-normal py-1 ${className}`;

  const badgeOptions: BadgeOptionsProps = {
    variant: fit === fitTypes.GOOD ? 'success' : 'danger',
    text: fit === fitTypes.GOOD ? 'Good Fit' : 'Bad Fit',
  };

  const BadgeComponent: JSX.Element | null = (
    <Badge variant={badgeOptions.variant} className={classes}>
      {badgeOptions.text}
    </Badge>
  );

  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip id={`tooltip-badge-${candidateId}`}>
          Based on our <br />
          AI based assessment
        </Tooltip>
      }
    >
      {BadgeComponent}
    </OverlayTrigger>
  );
};
