import React, { FC, useState } from 'react';

interface ITrace {
  trace: string;
  screenshot: string;
}

export const Trace: FC<ITrace> = ({ trace, screenshot }) => {
  const [hideTrace, setHideTrace] = useState(false);

  if (!hideTrace) {
    return (
      <span className='trace-show-btn' onClick={() => setHideTrace(true)}>
        Traceback
      </span>
    );
  }

  return (
    <div className='trace-flow'>
      <span>{trace.trim()}</span>
      <img src={screenshot} alt='' />
      <span className='trace-hide-btn' onClick={() => setHideTrace(false)}>
        Hide Traceback
      </span>
    </div>
  );
};
