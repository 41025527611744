import React from 'react';
import { ReactComponent as LogoLight } from '../../../assets/logo_light.svg';
import { ReactComponent as LogoDark } from '../../../assets/logo_dark.svg';
import { ReactComponent as LogoStageLight } from '../../../assets/logo_light_staging.svg';
import { ReactComponent as LogoStageDark } from '../../../assets/logo_dark_staging.svg';
import { ReactComponent as LogoDevLight } from '../../../assets/logo_light_dev.svg';
import { ReactComponent as LogoDevDark } from '../../../assets/logo_dark_dev.svg';

export interface LogoProps {
  type?: string;
  height?: number;
  withEnv?: boolean;
}

export const Logo: React.FC<LogoProps> = ({
  type = 'light',
  height = 24,
  withEnv,
}) => {
  let EnvLogo;
  if (window.OPTIONS.API_URL.includes('api-dev') && withEnv) {
    EnvLogo = type === 'dark' ? LogoDevDark : LogoDevLight;
  } else if (window.OPTIONS.API_URL.includes('api-staging') && withEnv) {
    EnvLogo = type === 'dark' ? LogoStageDark : LogoStageLight;
  } else {
    EnvLogo = type === 'dark' ? LogoDark : LogoLight;
  }

  return <EnvLogo style={{ height: `${height}px`, width: 'auto' }} />;
};
