import {
  ResponseFromPostSocialData,
  SAVEVIEWCOUNT,
  socialActionType,
  SHARESOLUTIONTOGGLE,
  SHAREDCANDIDATEDATA,
  SHAREOFFREDIRECT,
} from '../actions/types/socialActionTypes';

interface initialStateType {
  savedDataViewCounter: ResponseFromPostSocialData | {};
  shareSolutionData: any;
  shared: boolean;
  redirect: boolean;
}

const initialState: initialStateType = {
  savedDataViewCounter: {},
  shareSolutionData: {},
  shared: false,
  redirect: false,
};

export const socialReducer = (
  state = initialState,
  action: socialActionType
) => {
  switch (action.type) {
    case SAVEVIEWCOUNT:
      return {
        ...state,
        savedDataViewCounter: action.payload,
      };
    case SHAREDCANDIDATEDATA:
      return {
        ...state,
        shareSolutionData: action.payload,
      };
    case SHARESOLUTIONTOGGLE:
      return {
        ...state,
        shared: action.payload,
      };
    case SHAREOFFREDIRECT:
      return {
        ...state,
        redirect: action.payload,
      };
    default:
      return state;
  }
};
