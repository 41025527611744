import React from 'react';
import { useHistory } from 'react-router-dom';
import { NavBar } from '../../components/NavBar';

interface Props {
  title: string;
  text: string;
  children: React.ReactChild;
}

const LINKS = [
  {
    titleDesktop: 'Hiring teams',
    titleMob: 'Teams',
    href: '/hiring_teams',
  },
  {
    titleDesktop: 'Recruiters',
    titleMob: 'Recruiters',
    href: '/recruiters',
  },

  {
    titleDesktop: 'Pricing',
    titleMob: 'Pricing',
    href: '/#pricing',
  },
  {
    titleDesktop: 'Contact',
    titleMob: 'Contact',
    href: '/contact',
  },
];

export const ErrorPage: React.SFC<Props> = ({ children, title, text }) => {
  let history = useHistory();

  return (
    <div
      className='bg-blue-900 text-white d-flex flex-column w-100 px-4'
      style={{ minHeight: '100vh' }}
    >
      <div className='container px-md-0 flex-grow-1 py-3 d-flex flex-column'>
        <div>
          <NavBar links={LINKS} />
        </div>
        <div className='flex-grow-1 d-flex flex-column align-items-center justify-content-center w-100'>
          <div style={{ maxWidth: '693px' }}>
            {children}
            <div
              className='text-4-6xl font-weight-bold text-center mb-8'
              style={{ lineHeight: '48px' }}
            >
              {title}
            </div>
            <div className='text-center text-2-5xl mb-8'>{text}</div>
            <div className='d-flex flex-column align-items-center w-100'>
              <a
                className='text-warning text-xl mb-6'
                href='https://sfeeda.com/'
              >
                Go to Homepage
              </a>
              <button
                className='bg-transparent border-0 p-0 text-warning text-xl link-back'
                onClick={() => {
                  history.goBack();
                }}
              >
                Return to Previous Page
              </button>
            </div>
          </div>
        </div>
      </div>
      <footer
        className='text-center text-white p-4'
        style={{ background: '#0A1A2F' }}
      >
        © sfeeda.com, 2020
      </footer>
    </div>
  );
};
