interface Author {
  first_name?: string;
  last_name?: string;
  [key: string]: any;
}

export const getAuthorCharacters = (fullName: Author) => {
  if (!fullName?.first_name || !fullName?.last_name) return null;
  return (
    fullName.first_name[0].toUpperCase() + fullName.last_name[0].toUpperCase()
  );
};
