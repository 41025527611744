export enum Urls {
  CALENDLY_SCRIPT_SOURCE = 'https://assets.calendly.com/assets/external/widget.js',
  CALENDLY_STYLESHEET_SOURCE = 'https://assets.calendly.com/assets/external/widget.css',
}

export const loadScript = (onLoad?: () => void) => {
  const script = document.createElement('script');
  script.src = Urls.CALENDLY_SCRIPT_SOURCE;
  script.onload = onLoad || (() => null);

  document.body.appendChild(script);
};

export const loadStyleSheet = () => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = Urls.CALENDLY_STYLESHEET_SOURCE;
  document.body.appendChild(link);
};

const Calendly = window ? window.Calendly : {};

export default Calendly as ICalendly;
