export default {
  'code[class*="language-"]': {
    color: '#005cc5',
    fontFamily:
      '"Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace',
    direction: 'ltr',
    textAlign: 'left',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    fontSize: '0.95em',
    lineHeight: '1.2em',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    whiteSpace: 'pre-wrap'
  },
  'pre[class*="language-"]': {
    color: '#005cc5',
    fontFamily:
      '"Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace',
    direction: 'ltr',
    textAlign: 'left',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    fontSize: '0.95em',
    lineHeight: '1.2em',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    padding: '1em',
    margin: '.5em 0',
    overflow: 'auto',
    border: '1px solid #dddddd',
    backgroundColor: 'white',
    whiteSpace: 'pre-wrap'
  },
  'pre[class*="language-"]::-moz-selection': {
    background: '#b3d4fc'
  },
  'pre[class*="language-"] ::-moz-selection': {
    background: '#b3d4fc'
  },
  'code[class*="language-"]::-moz-selection': {
    background: '#b3d4fc'
  },
  'code[class*="language-"] ::-moz-selection': {
    background: '#b3d4fc'
  },
  'pre[class*="language-"]::selection': {
    background: '#b3d4fc'
  },
  'pre[class*="language-"] ::selection': {
    background: '#b3d4fc'
  },
  'code[class*="language-"]::selection': {
    background: '#b3d4fc'
  },
  'code[class*="language-"] ::selection': {
    background: '#b3d4fc'
  },
  ':not(pre) > code[class*="language-"]': {
    padding: '.2em',
    paddingTop: '1px',
    paddingBottom: '1px',
    background: '#f8f8f8',
    border: '1px solid #dddddd'
  },
  comment: {
    color: '#999988',
    fontStyle: 'italic'
  },
  prolog: {
    color: '#999988',
    fontStyle: 'italic'
  },
  doctype: {
    color: '#999988',
    fontStyle: 'italic'
  },
  cdata: {
    color: '#999988',
    fontStyle: 'italic'
  },
  namespace: {
    Opacity: '1'
  },
  string: {
    color: 'black'
  },
  'attr-value': {
    color: '#e3116c'
  },
  punctuation: {
    color: '#005cc5'
  },
  operator: {
    color: '#005cc5'
  },
  entity: {
    color: '#24292e'
  },
  url: {
    color: '#24292e'
  },
  symbol: {
    color: '#24292e'
  },
  number: {
    color: '#24292e'
  },
  boolean: {
    color: '#24292e'
  },
  variable: {
    color: '#24292e'
  },
  constant: {
    color: '#24292e'
  },
  property: {
    color: '#24292e'
  },
  regex: {
    color: '#24292e'
  },
  inserted: {
    color: '#24292e'
  },
  atrule: {
    color: '#d73a49'
  },
  keyword: {
    color: '#d73a49'
  },
  'attr-name': {
    color: '#d73a49'
  },
  '.language-autohotkey .token.selector': {
    color: '#d73a49'
  },
  function: {
    color: '#9a050f',
    fontWeight: 'bold'
  },
  deleted: {
    color: '#9a050f'
  },
  '.language-autohotkey .token.tag': {
    color: '#9a050f'
  },
  tag: {
    color: '#00009f'
  },
  selector: {
    color: '#00009f'
  },
  '.language-autohotkey .token.keyword': {
    color: '#00009f'
  },
  important: {
    fontWeight: 'bold'
  },
  bold: {
    fontWeight: 'bold'
  },
  italic: {
    fontStyle: 'italic'
  }
};
