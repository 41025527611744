import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { useEffect } from 'react';
import { ICheck } from '../../../typings/interfaces';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { ReactComponent as CompletedIcon } from '../../../assets/completed-blue.svg';
import { useHistory } from 'react-router-dom';
import { CommitsTypes } from '../../../typings/enums';
import { useParams } from 'react-router-dom';
import './CommitsDropdown.scss';

interface Props {
  className?: string;
  commitsArray: ICheck[] | undefined;
  value: ICheck | undefined;
  opositeValue: ICheck | undefined;
  type: CommitsTypes;
}

const CommitsDropdown: React.FC<Props> = ({
  className,
  commitsArray,
  value,
  opositeValue,
  type,
}) => {
  const { positionId, candidateId } = useParams();
  let history = useHistory();
  const [isOpened, setIsOpened] = useState(false);
  const dropdown = useRef(null);

  useEffect(() => {
    const closeDropdown = (e: MouseEvent) => {
      if (e.target !== dropdown.current) {
        setIsOpened(false);
      }
    };
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);

  if (!commitsArray) {
    return null;
  }

  return (
    <div style={{ minWidth: '240px' }} className={className}>
      <div className='position-relative'>
        <button
          type='button'
          className={cn(
            'd-flex',
            'justify-content-between',
            'align-items-center',
            'w-100',
            'border-gray-600',
            'border',
            'text-left',
            'custom-dropdown-toggle',
            'rounded-top',
            'py-2',
            'px-3',
            'text-sm',
            'bg-white',
            {
              active: isOpened,
              'rounded-bottom': !isOpened,
              'text-gray-600': !value,
              'opacity-50':
                type === CommitsTypes.destinationCommit && !opositeValue,
            }
          )}
          ref={dropdown}
          onClick={() => {
            setIsOpened(!isOpened);
          }}
          disabled={type === CommitsTypes.destinationCommit && !opositeValue}
        >
          <div className='text-truncate'>
            Commit:{' '}
            {value ? (
              <span className='font-weight-bold'>
                {moment
                  .utc(value.date_created)
                  .local()
                  .format('DD MMMM, HH:mm')}
              </span>
            ) : null}
          </div>
        </button>
        {isOpened ? (
          <div className='position-absolute d-block w-100 border rounded-bottom border-top-0 custom-dropdown-menu bg-white'>
            {commitsArray.map((dropdownItem) => {
              return (
                <button
                  type='button'
                  data-testid={`commits-drop-${dropdownItem.commit.slice(
                    0,
                    7
                  )}`}
                  className='d-flex align-items-center w-100 bg-transparent border-0 text-left py-2 px-3 text-sm text-truncate'
                  key={uuid()}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    let url = '';
                    if (
                      type === CommitsTypes.destinationCommit &&
                      opositeValue
                    ) {
                      url = `/${opositeValue.commit}/${dropdownItem.commit}`;
                    } else {
                      url = `/${dropdownItem.commit}${
                        opositeValue?.commit ? `/${opositeValue?.commit}` : ''
                      }`;
                    }
                    history.push(
                      `/position/${positionId}/candidate/${candidateId}/code${url}`
                    );
                  }}
                  disabled={
                    (value && value.commit === dropdownItem.commit) ||
                    (opositeValue &&
                      opositeValue.commit === dropdownItem.commit)
                  }
                >
                  <div>
                    #{dropdownItem.commit.slice(0, 7)} &#9679;{' '}
                    {moment
                      .utc(dropdownItem.date_created)
                      .local()
                      .format('DD MMMM, HH:mm')}{' '}
                  </div>
                  {value && value.commit === dropdownItem.commit ? (
                    <div className='ml-2 d-flex align-items-center text-center'>
                      <CompletedIcon className='icon--16' />
                    </div>
                  ) : null}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CommitsDropdown;
