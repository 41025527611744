import React from 'react';
import { Avatar } from '../../shared/components/Avatar';
import cn from 'classnames';
import moment from 'moment';
import { IFeedback } from '../../typings/interfaces';

interface Props {
  feedback: IFeedback;
  className?: string;
}

export const FeedbackAuthor: React.FC<Props> = ({ feedback, className }) => {
  return (
    <div className={cn('d-flex align-items-center', [className])}>
      <Avatar data={feedback.created_by} className='mr-2' />
      <div className='mr-1'>
        {feedback.created_by.first_name} {feedback.created_by.last_name}
      </div>
      {feedback.date_created && (
        <div className='mr-1'>
          |{' '}
          {feedback.is_sent &&
            `${feedback.resolution[0]}${feedback.resolution
              .slice(1)
              .toLowerCase()}`}{' '}
          {moment.utc(feedback.date_created).local().format('DD MMM, HH:mm')}
        </div>
      )}
    </div>
  );
};
