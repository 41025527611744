import React from 'react';
import { getColor } from '../../../util/getColor';
import cn from 'classnames';
import { getAuthorCharacters } from '../../../util/getAuthorCharacters';

interface Props {
  data:
    | {
        first_name: string;
        last_name: string;
        [key: string]: any;
      }
    | undefined;
  className?: string;
}

export const Avatar: React.FC<Props> = ({ data, className }) => {
  if (!data) {
    return null;
  }

  const symbols = getAuthorCharacters(data);

  return (
    <div
      className={cn(
        'rounded-circle bg-blue-300 text-white d-flex align-items-center justify-content-center leading-none border border-white',
        [className],
        {
          'icon--33': !className?.includes('icon--72'),
        }
      )}
      style={{
        background: `#${getColor(symbols?.[1])}`,
        fontSize: !className?.includes('text-3xl') ? '13px' : undefined,
      }}
    >
      {symbols}
    </div>
  );
};
