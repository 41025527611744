import React from 'react';
import { Links } from '../../components/Header/Links';
import { CompanyData } from '../../../typings/enums';

const LINKS = [
  {
    titleDesktop: 'Imprint',
    titleMob: 'Imprint',
    href: '/imprint',
  },
  {
    titleDesktop: 'Privacy Policy',
    titleMob: 'Privacy Policy',
    href: '/privacy-policy',
  },
  {
    titleDesktop: 'Contact',
    titleMob: 'Contact',
    href: '/contact',
  },
];

export const Footer = () => {
  return (
    <div className='pb-3 pb-4 pt-8'>
      <div className='d-lg-flex justify-content-lg-between w-100 mb-lg-6'>
        <div className='d-flex flex-column justify-content-between d-lg-block mb-2 flex-shrink-0'>
          <Links links={LINKS} />
        </div>
        <div className='d-none d-lg-flex justify-content-center flex-shrink-0'>
          <a
            href={`mailto:${CompanyData.email}`}
            className='d-block text-white mr-6 font-serif'
          >
            {CompanyData.email}
          </a>
          <a
            href={`tel:${CompanyData.phone.replace(' ', '')}`}
            className='d-block text-white font-serif'
          >
            {CompanyData.phone}
          </a>
        </div>
      </div>
      <div className='text-xxs text-left text-lg-center text-gray-600'>
        ©2020 {CompanyData.companyName}. All Rights Reserved.
      </div>
    </div>
  );
};
