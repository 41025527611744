import React from 'react';
import { FormikErrors, Field, FormikTouched } from 'formik';
import cn from 'classnames';
import { useMatchMedia } from '../../../hooks/useMatchMedia';

export type Props = {
  isError:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  name: string;
  placeholder?: string;
  type?: string;
  style?: React.CSSProperties | undefined;
  id?: string;
  onKeyDown?: any;
  // height?: number;
  Btn?: JSX.Element;
  className?: string;
  size?: 'sm' | 'lg' | 'md';
  autoComplete?: string;
  readOnly?: boolean;
  tabIndex?: number;
};
export const TextField: React.FC<Props> = ({
  isError,
  isTouched,
  name,
  placeholder,
  type = 'text',
  style,
  id,
  tabIndex,
  Btn,
  className,
  onKeyDown,
  size = 'lg',
  autoComplete,
  readOnly,
}) => {
  const [isMatchMediaQuery] = useMatchMedia(769);

  return (
    <>
      <div
        className={cn({
          'position-relative': Btn,
        })}
      >
        <Field
          id={id}
          name={name}
          type={type}
          autoComplete={autoComplete}
          readOnly={readOnly}
          className={cn(
            'rounded border w-100 py-1 pl-4 d-block',
            {
              'border-gray-400 border-red-500 focus:outline-none':
                isError && isTouched && isError,
              'focus:border-gray-400 focus:border-2 hover:border-gray-400  border-purple-200 focus:outline-none':
                (isError && isTouched && !isError) || !isTouched || !isError,
              'text-xl': isMatchMediaQuery && size === 'lg',
              'pr-4': !style?.paddingRight,
              'h-10': size === 'lg' || size === 'md',
              'md:h-16': size === 'lg',
              'h-8': size === 'sm',
              'text-gray-500': readOnly,
            },
            [className]
          )}
          placeholder={placeholder}
          style={{
            backgroundColor: '#fcfcfc',
            ...style,
          }}
          onKeyDown={onKeyDown}
          tabIndex={tabIndex}
        />
        {Btn}
      </div>
      <div
        className={cn({
          'text-danger my-1': isError && isTouched && isError,
          'mb-6':
            size === 'lg' &&
            ((isError && isTouched && !isError) || !isTouched || !isError),
          'mb-4':
            size !== 'lg' &&
            ((isError && isTouched && !isError) || !isTouched || !isError),
        })}
      >
        {isError && isTouched && isError}
      </div>
    </>
  );
};
