import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCookie } from '../util/getCookie';
import { agreeCookies } from '../actions/policiesActions';
import { showAlert } from '../actions/alertActions';
import { AlertType } from '../typings/enums';
import ReactGA from 'react-ga';
import { getEnv } from '../util/getEnv';
import { capitalize } from '../util/capitalize';

export const useReactGA = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const env = capitalize(getEnv());
    if (
      getCookie(`cookiePolicyConsent${env}`) &&
      getCookie(`cookiePolicyConsentTimestamp${env}`)
    ) {
      dispatch(
        agreeCookies(parseInt(getCookie(`cookiePolicyConsentTimestamp${env}`)))
      );
      ReactGA.initialize('UA-156554747-1');
    } else {
      dispatch(
        showAlert(
          {
            type: AlertType.cookies,
            message: '',
          },
          undefined,
          false
        )
      );
    }
  }, [dispatch]);
};
