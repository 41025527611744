export default `<pre class='terms'>
<h1>INTRODUCTION</h1>
<div>
Welcome to the Sfeeda UG's privacy notice.
Sfeeda UG respects your privacy and is committed to protecting your personal data. This privacy notice will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you. This privacy notice is provided in a layered format so you can click through to the specific areas set out below. [Alternatively you can download a pdf version of the policy here <a rel='noopener noreferrer' target='_blank' href='https://sfeeda.com/privacy-policy'>https://sfeeda.com/privacy-policy</a>]. Please also use the Glossary to understand the meaning of some of the terms used in this privacy notice.</div><div>
<div>1. [IMPORTANT INFORMATION AND WHO WE ARE]</div>
<div>2. [THE DATA WE COLLECT ABOUT YOU]</div>
<div>3. [HOW IS YOUR PERSONAL DATA COLLECTED]</div>
<div>4. [HOW WE USE YOUR PERSONAL DATA]</div>
<div>5. [DISCLOSURES OF YOUR PERSONAL DATA]</div>
<div>6. [INTERNATIONAL TRANSFERS]</div>
<div>7. [DATA SECURITY]</div>
<div>8. [DATA RETENTION]</div>
<div>9. [YOUR LEGAL RIGHTS]</div>
<div>10. [GLOSSARY]</div>
</div>
<h2>1 Important information and who we are</h2>
<div class='d-flex pl-6'><span class='mr-5'>1.1 </span><span>Purpose of this privacy notice This privacy notice aims to give you information on how Sfeeda UG collects and processes your personal data through your use of this website, including any data you may provide through this website when you use Company's Services (<a rel='noopener noreferrer' target='_blank' href='https://sfeeda.com/terms-of-service'>https://sfeeda.com/terms-of-service</a>).This website is not intended for children and we do not knowingly collect data relating to children. It is important that you read this privacy notice together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy notice supplements the other notices and is not intended to override them.</span></div>
<div>
<h2>2 Controller</h2>
Sfeeda UG is the controller and responsible for your personal data (collectively referred to as ["COMPANY"], "we", "us" or "our" in this privacy notice).
We have appointed a data privacy manager who is responsible for overseeing questions in relation to this privacy notice. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.
<div>
<div class='d-flex pl-6'><span class='mr-5'>2.1 Contact details </span><span>Our full details are:
Full name of legal entity: Sfeeda UG,
Dmytro Malyshenko
Email address: dmytro@sfeeda.com
Postal address: Bastianstr. 22, Berlin,
Germany, 13357, c/o Malyshenko
You have the right to make a complaint at any time to the Information Commissioner's Office
(ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would,
however, appreciate the chance to deal with your concerns before you approach the ICO so
please contact us in the first instance.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>2.2 </span><span>Changes to the privacy notice and your duty to inform us of changes [This version was last updated on June 22th, 2020. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>2.3 </span><span>Third-party links This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.</span></div>
</div>
<div>
<h2>3 The data we collect about you</h2>
<div class='d-flex pl-6'><span class='mr-5'>3.1 </span><span>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.2 </span><span>We may collect, use, store, and transfer different kinds of personal data about you which we have grouped together follows:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>3.2.1 </span><span>Identity Data includes [first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender].</span></div>
<div class='d-flex pl-14'><span class='mr-5'>3.2.2 </span><span>Contact Data includes [billing address, delivery address, email address and telephone numbers].</span></div>
<div class='d-flex pl-14'><span class='mr-5'>3.2.3 </span><span>Technical Data includes [internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website].</span></div>
<div class='d-flex pl-14'><span class='mr-5'>3.2.4 </span><span>Profile Data includes [your username and password, purchases, or orders made by you, your interests, preferences, feedback, and survey responses].</span></div>
<div class='d-flex pl-14'><span class='mr-5'>3.2.5 </span><span>Usage Data includes [information about how you use our website, products and services].</span></div>
<div class='d-flex pl-14'><span class='mr-5'>3.2.6 </span><span>Marketing and Communications Data includes [your preferences in receiving marketing from us and our third parties and your communication preferences]. We also collect, use, and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice. We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offenses.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.3 </span><span>If you fail to provide personal data Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</span></div>
</div>
<div>
<h2>4 How is your personal data collected?</h2>
We use different methods to collect data from and about you including through:
<div class='d-flex pl-6'><span class='mr-5'>4.1 </span><span>Direct interactions - You may give us your Identity and Contact Data by filling in forms or by corresponding with us by post, phone, email, or otherwise. This includes personal data you provide when you:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>4.1.1 </span><span>apply for our products or services;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>4.1.2 </span><span>create an account on our website;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>4.1.3 </span><span>subscribe to our service or publications;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>4.1.4 </span><span>request marketing to be sent to you;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>4.1.5 </span><span>enter a competition, promotion or survey; or</span></div>
<div class='d-flex pl-14'><span class='mr-5'>4.1.6 </span><span>give us some feedback.</span></div>
</div>
<div>
<h2>5 Automated technologies or interactions.</h2>
As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions, and patterns. We collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies. Please see our cookie policy <a rel='noopener noreferrer' target='_blank' href='https://sfeeda.com/cookie'>https://sfeeda.com/cookie</a> for further details.
<div class='d-flex pl-6'><span class='mr-5'>5.1 </span><span>Technical Data from the following parties:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>5.1.1 </span><span>analytics providers such as Google based inside or outside the EU;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>5.1.2 </span><span>social media platforms such as Linkedin, Twitter and Facebook based inside or outside the EU;</span></div>
<div class='d-flex pl-6'><span class='mr-5'>5.2 </span><span>Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as Stripe based inside OR outside the EU.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>5.3 </span><span>Identity and Contact Data from data brokers or aggregators such as Google based inside OR outside the EU.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>5.4 </span><span>Identity and Contact Data from publicly available sources such as Hubspot based inside the EU. [</span></div>
</div>
<div>
<h2>6 How we use your personal data.</h2>
<div class='d-flex pl-6'><span class='mr-5'>6.1 </span><span>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>6.1.1 </span><span>Where we need to perform the contract we are about to enter into or have entered into with you.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>6.1.2 </span><span>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>6.1.3 </span><span>Where we need to comply with a legal or regulatory obligation.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>6.2 </span><div>Purposes for which we will use your personal data. We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate. Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below. </div>
</div>
</pre>
<div class="table-responsive">
<table class='table table-bordered'>
<thead>
<tr >
<th class='align-top'>Purpose/Activity</th>
<th class='align-top'>Type of data</th>
<th class='align-top'>Lawful basis for processing including basis of legitimate interest</th>
</tr>
</thead>
<tbody>
<tr>
<td class='align-top'>
To register you as a new customer
</td>
<td class='align-top'>
(a) Identity
(b) Contact
</td>
<td class='align-top'>
Performance of a contract with you
</td>
</tr>
<tr>
<td class='align-top'>
<div>To process and deliver
your order including:</div>
<div>(a) Manage payments,
fees and charges</div>
<div>(b) Collect and recover
money owed to us</div>
</td>
<td class='align-top'>
<div>(a) Identity</div>
<div>(b) Contact</div>
<div>(c) Financial</div>
<div>(d) Transaction</div>
<div>(e) Marketing and Communications</div>
</td>
<td class='align-top'>
<div>(a)	Performance of a contract with you </div>
<div>(b)	Necessary for our legitimate interests (to recover debts due to us)</div>
</td>
</tr>
<tr>
<td class='align-top'>
<div>To manage our relationship with you which will include:</div>
<div>(a)	Notifying you about changes to our terms or privacy policy </div>
<div>(b)	Asking you to leave a review or take a survey </div>
</td>
<td class='align-top'>
<div>(a)	Identity </div>
<div>(b)	Contact </div>
<div>(c)	Profile </div>
<div>(d)	Marketing and Communications</div>
</td>
<td class='align-top'>
<div>(a)	Performance of a contract with you </div>
<div>(b)	Necessary to comply with a legal obligation </div>
<div>(c)	Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</div>
</td>
</tr>
<tr>
<td class='align-top'>
To enable you to partake in a prize draw, competition or complete a survey
</td>
<td class='align-top'>
<div>(a)	Identity </div>
<div>(b)	Contact </div>
<div>(c)	Profile </div>
<div>(d)	Usage </div>
<div>(e)	Marketing and Communications</div>
</td>
<td class='align-top'>
<div>(a)	Performance of a contract with you </div>
<div>(b)	Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)</div>
</td>
</tr>
<tr>
<td class='align-top'>
To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and ho+6sting of data)
</td>
<td class='align-top'>
<div>(a)	Identity </div>
<div>(b)	Contact </div>
<div>(c)	Technical</div>
</td>
<td class='align-top'>
<div>(a)	Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise) </div>
<div>(b)	Necessary to comply with a legal obligation</div>
</td>
</tr>
<tr>
<td class='align-top'>
To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you
</td>
<td class='align-top'>
<div>(a)	Identity </div>
<div>(b)	Contact </div>
<div>(c)	Profile </div>
<div>(d)	Usage </div>
<div>(e)	Marketing and Communications </div>
<div>(f)	Technical </div>
</td>
<td class='align-top'>
Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)
</td>
</tr>
<tr>
<td class='align-top'>
To use data analytics to improve our website, products/services, marketing, customer relationships and experiences
</td>
<td class='align-top'>
<div>(a)	Technical </div>
<div>(b)	Usage</div>
</td>
<td class='align-top'>
Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)
</td>
</tr>
<tr>
<td class='align-top'>
To make suggestions and recommendations to you about goods or services that may be of interest to you
</td>
<td class='align-top'>
<div>(a) Identity </div>
<div>(b) Contact </div>
<div>(c) Technical</div>
<div>(d) Usage (e) Profile </div>
</td>
<td class='align-top'>
Necessary for our legitimate interests (to develop our products/services and grow our business)
</td>
</tr>
</tbody>
</table>
</div>
</div>
<pre class='terms'>
<div>
<div class='d-flex pl-6'><span class='mr-5'>6.3 </span><div><div>Marketing</div>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. You can always unsubscribe easily or manage your preferences at the bottom of any marketing email.</div></div>
</div>
<div>
<h2>7 Promotional offers from us</h2>
We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing). You will receive marketing communications from us if you have requested information from us or purchased services from us or if you provided us with your details when you entered a competition or registered for a promotion and, in each case, you have not opted out of receiving that marketing.
</div>
<div>
<h2>8 Third-party marketing</h2>
We will get your express opt-in consent before we share your personal data with any company outside the Sfeeda UG group of companies for marketing purposes.
</div>
<div>
<h2>9 Opting out</h2>
You can ask us or third parties to stop sending you marketing messages at any time by unsubscribing or managing your preferences at the bottom of any marketing email, or contacting us. Where you opt out of receiving these marketing messages, this will not apply to personal data provided to
us as a result of a product or service purchase.
<div class='d-flex pl-6'><span class='mr-5'>9.1 </span><div><div>Cookies</div>[You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see <a rel='noopener noreferrer' target='_blank' href='https://sfeeda.com/cookie'>https://sfeeda.com/cookie</a></div></div>
<div class='d-flex pl-6'><span class='mr-5'>9.2 </span><div><div>Change of purpose </div>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so. Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</div></div>
<div>
<h2>10 Disclosures of your personal data</h2>
<div class='d-flex pl-6'><span class='mr-5'>10.1 </span><span>We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>10.1.1 </span><span>Internal Third Parties such as employees and contractors of Sfeeda UG, as well as companies in the Sfeeda UG Group, acting from inside or outside the European Economic Area (EEA) as joint controllers or processors.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>10.1.2 </span><span>External Third Parties for marketing and sales purposes (such as Hubspot, LinkedIn); analytics purposes (such as Google Analytics); technical purposes (such as Amazon Web Services); professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based in the Germany, who provide consultancy, banking, legal, insurance, and accounting services.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>10.1.3 </span><span>Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice. </span></div>
<div class='d-flex pl-14'><span class='mr-5'>10.2 </span><span>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</span></div>
</div>
<div>
<div class='d-flex pl-6'><span class='mr-5'>11 </span><span>International transfers</span></div>
<div class='d-flex pl-14'><span class='mr-5'>11.1 </span><span>We may share your data across our servers within the Sfeeda Group. This may involve transferring your data outside the European Economic Area (EEA).</span></div>
<div class='d-flex pl-14'><span class='mr-5'>11.2 </span><span>We endeavour to keep your personal data within the European Economic Area (EEA), but in some cases, their processing of your personal data may involve a transfer of data outside the EEA.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>11.3 </span><span>Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:</span></div>
<div class='d-flex pl-20 ml-10'><span class='mr-5'>11.3.1 </span><span>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. For further details, see European Commission: Adequacy of the protection of personal data in non-EU countries.</span></div>
<div class='d-flex pl-20 ml-10'><span class='mr-5'>11.3.2 </span><span>Where we use certain service providers, we may use specific contracts approved by the European Commission which give personal data the same protection it has in Europe. For further details, see European Commission: Model contracts for the transfer of personal data to third countries.</span></div>
<div class='d-flex pl-20 ml-10'><span class='mr-5'>11.3.3 </span><span>Where we use providers based in the US, we may transfer data to them if they are part of the Privacy Shield which requires them to provide similar protection to personal data shared between Europe and the US. For further details, see European Commission: EU-US Privacy Shield. Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.</span></div>
</div>
<div>
<h2>12 Data security</h2>
<div class='d-flex pl-6'><span class='mr-5'>12.1 </span><span>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>12.2 </span><span>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</span></div>
</div>
<div>
<h2>13 Data retention</h2>
<div class='d-flex pl-6'><span class='mr-5'>13.1 </span><div><div>How long will you use my personal data for?</div>
<div>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</div>
<div>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</div>
<div>By law we have to keep basic information about our customers (including Contact, Identity,</div>
<div>Financial and Transaction Data) for six years after they cease being customers for tax purposes.</div>
<div>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for product, research or statistical purposes in which case we may use this information indefinitely without further notice to you.</div>
</div></div>
</div>
<div>
<h2>14 Your legal rights</h2>
<div class='d-flex pl-6'><span class='mr-5'>14.1 </span><span>Under certain circumstances, you have rights under data protection laws in relation to your personal data. Please click on the links below to find out more about these rights:</span></div>
<div class='d-flex pl-10'><span class='mr-5'>14.1.1 </span><span>[Request access to your personal data].</span></div>
<div class='d-flex pl-10'><span class='mr-5'>14.1.2 </span><span>[Request correction of your personal data].</span></div>
<div class='d-flex pl-10'><span class='mr-5'>14.1.3 </span><span>[Request erasure of your personal data].</span></div>
<div class='d-flex pl-10'><span class='mr-5'>14.1.4 </span><span>[Object to processing of your personal data].</span></div>
<div class='d-flex pl-10'><span class='mr-5'>14.1.5 </span><span>[Request restriction of processing your personal data].</span></div>
<div class='d-flex pl-10'><span class='mr-5'>14.1.6 </span><span>[Request transfer of your personal data].</span></div>
<div class='d-flex pl-10'><span class='mr-5'>14.1.7 </span><div><div>[Right to withdraw consent].</div><div>If you wish to exercise any of the rights set out above, please contact us at sfeeda@sfeeda.com</div></div></div>
<div class='d-flex pl-6'><span class='mr-5'>14.2 </span><span>No fee usually required You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>14.3 </span><span>What we may need from you We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>14.4 </span><span>Time limit to respond We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</span></div>
</div>
<div>
<h2>15 Glossary</h2>
<div class='d-flex pl-6'><span class='mr-5'>15.1 </span><span>Lawful Basis</span></div>
<div class='d-flex pl-14'><span class='mr-5'>15.1.1 </span><span>Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us </span></div>
<div class='d-flex pl-14'><span class='mr-5'>15.1.2 </span><span>Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>15.1.3 </span><span>Comply with a legal or regulatory obligation means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>15.2 </span><span>Your Legal Rights You have the right to:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>15.2.1 </span><span>Request access to your personal data (commonly known as a ‘data subject access request’). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>15.2.2 </span><span>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>15.2.3 </span><span>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>15.2.4 </span><span>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>15.2.5 </span><span>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</span></div>
<div class='d-flex pl-14'><span class='mr-5'>15.2.6 </span><span>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you. 15.2.7 Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</span></div>
</div>
</pre>`;
