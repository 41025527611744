import { useEffect } from 'react';
import { useShallowEqualSelector } from './useShallowEqualSelector';
import { useDispatch } from 'react-redux';
import { refreshAccessToken } from '../actions/userActions';
import { accessTokenSelector } from '../selectors';
const jwtDecode = require('jwt-decode');

export const useRefreshToken = () => {
  const dispatch = useDispatch();

  const accessToken = useShallowEqualSelector(accessTokenSelector);
  const exp = accessToken && jwtDecode(accessToken).exp * 1000;

  useEffect(() => {
    let id: number;
    if (exp) {
      const timeout = exp - Date.now() - 1000 * 60;
      id = window.setTimeout(() => {
        dispatch(refreshAccessToken());
      }, timeout);
    }
    return () => {
      id && window.clearTimeout(id);
    };
  }, [exp, dispatch]);
};
