import React from 'react';
import { BlockTitle } from '../../components/BlockTitle';
import { Tip } from './Tip';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';

interface Props {
  category?: string;
  title: string;
  text: string;
  tips: Array<string>;
  additionalContent?: JSX.Element;
  imagePath: string;
  className?: string;
}

export const BlockImgLeft: React.FC<Props> = ({
  category = 'hiring team',
  title,
  additionalContent,
  imagePath,
  text,
  tips,
  className,
}) => {
  return (
    <div className={cn('pb-2', [className])}>
      <div className='d-flex flex-column flex-grow-1 flex-lg-row justify-content-lg-between'>
        <div className='lg:w-6/12 pl-lg-12 order-lg-2 mb-4 mb-md-8'>
          <BlockTitle title={category} color='orange-500' className='mb-2' />
          <div className='text-2-5xl lh-32 md:text-4-6xl font-weight-bold mb-2'>
            {title.split('\n').map((str: string) => (
              <div className='md:leading-thin' key={uuid()}>
                {str}
              </div>
            ))}
          </div>
          <div className='md:text-xl md:lh-32 mb-4'>{text}</div>
          <div className='mb-4'>
            {tips.map((tipText) => (
              <Tip text={tipText} key={uuid()} />
            ))}
          </div>
          {additionalContent}
        </div>
        <div className='lg:w-6/12'>
          <img
            src={imagePath}
            alt={title}
            className='d-block w-100 d-block rounded-lg'
            // style={{ boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.05)' }}
          />
        </div>
      </div>
    </div>
  );
};
