import { useEffect, useRef } from 'react';
import { getLikes, clearLikes } from '../actions/likesActions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import { LikesContentTypeEnum } from '../typings/enums';
import { useShallowEqualSelector } from './useShallowEqualSelector';
import { getSolutionsIds } from '../util/getSolutionsIds';
import { ISolution } from '../typings/interfaces';
import {
  likesByCandidatePositionSelector,
  pendingLikesSelector,
} from '../selectors';

export const useLikes = (objectId: string | ISolution[] | undefined) => {
  const mounted = useRef(false);
  const dispatch = useDispatch();

  const likesByCandidatePosition = useShallowEqualSelector(
    likesByCandidatePositionSelector
  );
  const pendingLikes = useShallowEqualSelector(pendingLikesSelector);

  const { showLikeDeslikeFeature } = useFlags();
  useEffect(() => {
    if (showLikeDeslikeFeature && objectId && mounted.current === false) {
      dispatch(
        getLikes(
          LikesContentTypeEnum.CANDIDATE_POSITION,
          objectId instanceof Array
            ? getSolutionsIds(objectId).join(',')
            : objectId
        )
      );
      mounted.current = true;
    }
  }, [dispatch, showLikeDeslikeFeature, objectId]);

  useEffect(() => {
    return () => {
      dispatch(clearLikes(LikesContentTypeEnum.CANDIDATE_POSITION));
    };
  }, [dispatch]);

  return { likesByCandidatePosition, pendingLikes };
};
