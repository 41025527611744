export enum PoliciesActionsTypesConstants {
  AGREE_COOKIES = 'AGREE_COOKIES',
  REFUSE_COOKIES = 'REFUSE_COOKIES',
}

export interface PoliciesState {
  isCookiesAgreed: boolean;
  cookiesConsentTimestamp: number | undefined;
}

interface AgreeCookiesAction {
  type: typeof PoliciesActionsTypesConstants.AGREE_COOKIES;
  payload: number;
}

interface RefuseCookiesAction {
  type: typeof PoliciesActionsTypesConstants.REFUSE_COOKIES;
}

export type PoliciesActionTypes = AgreeCookiesAction | RefuseCookiesAction;
