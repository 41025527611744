import React from 'react';
import { Logo } from '../../components/Logo';
import { BookADemoBtn } from '../../components/BookADemoBtn';
import { SignInBtn } from '../../components/SignInBtn';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { ReactComponent as SqrLogo } from '../../../assets/logo_sqr_dark.svg';
import { CompanyData } from '../../../typings/enums';
import { Footer } from '../../components/Footer';
import { Link } from 'react-router-dom';
import { userSelector } from '../../../selectors';

const LIST = [
  {
    title: 'Name of operator',
    content: (
      <>
        {CompanyData.companyName}, {CompanyData.address}
      </>
    ),
  },
  {
    title: 'Contact',
    content: (
      <>
        <div>
          Email: <a href={`mailto:${CompanyData.email}`}>{CompanyData.email}</a>
        </div>
        <div>
          Tel: <a href={`tel:${CompanyData.phone}`}>{CompanyData.phone}</a>
        </div>
      </>
    ),
  },
  {
    title: 'Trade register number',
    content: <>{CompanyData.TRN}</>,
  },
  {
    title: 'VAT ID',
    content: <>{CompanyData.vatId}</>,
  },
  {
    title: 'Tax number',
    content: <>{CompanyData.TaxNumber}</>,
  },
  {
    title: 'Managing Director',
    content: <>{CompanyData.director}</>,
  },
];

export const ImPrint = () => {
  const user = useShallowEqualSelector(userSelector);

  return (
    <div className='d-flex flex-column' style={{ minHeight: '100vh' }}>
      <div className='bg-blue-900 py-4'>
        <div className='container'>
          <div className='d-flex flex-column flex-md-row justify-content-md-between'>
            <div className='text-center text-md-left mb-4'>
              <Link to='/'>
                <Logo />
              </Link>
            </div>
            <div className='d-flex align-items-center justify-content-between justify-md-start'>
              <div className='mr-lg-8'>
                <SignInBtn
                  user={user}
                  className='border-0 hover:text-purple px-0'
                />
              </div>
              <div>
                <BookADemoBtn />
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-center w-100 mt-6'>
            <SqrLogo className='d-lg-block d-none mr-6' />
            <div className='text-4-5xl md:text-6xl font-weight-bold text-white'>
              Imprint
            </div>
          </div>
        </div>
      </div>
      <div
        className='px-4 mx-auto mt-lg-16 my-8 flex-grow-1'
        style={{ maxWidth: '682px' }}
      >
        {LIST.map((listItem) => (
          <div
            className='grid-cols-1 md:grid-cols-2 grid mb-md-10 mb-5 text-xl lh-24'
            key={listItem.title}
          >
            <div className='text-gray-500 md:text-black'>
              <div className='ml-md-auto' style={{ maxWidth: '230px' }}>
                {listItem.title}
              </div>
            </div>
            <div>{listItem.content}</div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};
