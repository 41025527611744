import React, { useRef } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import cn from 'classnames';

interface Props {
  isNoteEditable: boolean;
  setIsNoteEdiatble: React.Dispatch<React.SetStateAction<boolean>>;
  setText: React.Dispatch<React.SetStateAction<string>>;
  noteText: string;
  error: boolean;
}

export const RecruiterNote: React.FC<Props> = ({
  noteText,
  isNoteEditable,
  setIsNoteEdiatble,
  setText,
  error,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const pendingNoteUpdate = useShallowEqualSelector(
    (state) => state.notesReducer.pendingNoteUpdate
  );
  return (
    <div className='mb-3'>
      <div className='font-weight-bold text-lg mb-1'>Note for recruiter</div>
      <TextareaAutosize
        value={noteText}
        className={cn('w-100 focus:outline-none', {
          'border-0': !isNoteEditable && noteText,
          'border bg-gray-500 py-2 px-4 rounded': isNoteEditable || !noteText,
          'vorder-red-500': error,
        })}
        ref={textAreaRef}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setText(e.target.value)
        }
        onFocus={() => setIsNoteEdiatble(true)}
        readOnly={pendingNoteUpdate}
        data-testid='recruiter-note'
      />
    </div>
  );
};
