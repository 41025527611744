import { RootState } from '../reducers';

export const userSelector = (state: RootState) => state.userReducer.user;
export const meSelector = (state: RootState) => state.userReducer.me;
export const pendingMeSelector = (state: RootState) =>
  state.userReducer.pendingMe;
export const accessTokenSelector = (state: RootState) =>
  state.userReducer.accessToken;
export const isIdentifiedSelector = (state: RootState) =>
  state.userReducer.isIdentified;
export const intialUserLoadedSelector = (state: RootState) =>
  state.userReducer.intialUserLoaded;
export const userRoleSelector = (state: RootState) =>
  state.userReducer.user?.user_claims.roles[0];

export const errorSignUpSelector = (state: RootState) =>
  state.userReducer.errorSignUp;
export const userFetchErrorSelector = (state: RootState) =>
  state.userReducer.userFetchError;
