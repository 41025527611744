import React, { useState } from 'react';
import { FormContainer } from '../../components/SubscribeForms/FormContainer';
import { FreeTrialForm } from './FreeTrialForm';
import { ThankYouBlock } from '../../components/SubscribeForms/ThankYouBlock';

export const FreeTrial: React.SFC = () => {
  const [subscriber, setSubscriber] = useState<string | undefined>(undefined);

  return (
    <FormContainer>
      <div className='d-flex flex-column align-items-center w-100'>
        {subscriber ? (
          <ThankYouBlock subscriber={subscriber} />
        ) : (
          <FreeTrialForm withTitle fixedWidth setSubscriber={setSubscriber} />
        )}
      </div>
    </FormContainer>
  );
};
