import React from 'react';
import { Likes } from '../Likes';
import { ILike } from '../../../typings/interfaces';
import { LikesRenderType } from '../../../typings/enums';
import cn from 'classnames';

interface Props {
  votes: ILike[];
  type?: LikesRenderType;
  className?: string;
}

export const LikesBlock: React.FC<Props> = ({ votes, type, className }) => {
  const likes = votes.filter((like) => !like.negate);
  const dislikes = votes.filter((like) => like.negate);
  return (
    <div className={cn('d-flex align-items-center', [className])}>
      <Likes
        votes={likes}
        type={type}
        className={dislikes.length ? 'mr-3' : ''}
      />
      <Likes votes={dislikes} type={type} />
    </div>
  );
};
