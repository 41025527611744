import React from 'react';
import { Field, FormikValues } from 'formik';
import { RadioBtn } from './RadioBtn';
interface Props {
  values: FormikValues;
  valueName: string;
}

export const ToggleYesNo: React.FC<Props> = ({ values, valueName }) => {
  return (
    <div className='btn-group btn-group-toggle' data-toggle='buttons'>
      <RadioBtn
        condition={{
          'border-blue-700': values[valueName] === 'yes',
          'border-2': values[valueName] === 'yes',
        }}
      >
        <Field
          type='radio'
          name={valueName}
          checked={values[valueName] === 'yes'}
          id={`${valueName}-yes`}
          value='yes'
        />
        Yes
      </RadioBtn>
      <RadioBtn
        condition={{
          'border-blue-700': values[valueName] === 'no',
          'border-2': values[valueName] === 'no',
        }}
      >
        <Field
          type='radio'
          name={valueName}
          checked={values[valueName] === 'no'}
          id={`${valueName}-no`}
          value='no'
        />{' '}
        No
      </RadioBtn>
    </div>
  );
};
