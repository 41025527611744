import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactComponent as TipIcon } from '../../../assets/tip.svg';
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';
import { IAcceptance } from '../../../typings/interfaces';
import { Trace } from './Trace';
import { RootState } from '../../../reducers';
import './tickets.scss';
import './acceptanceItemDetails.scss';
import { TicketPersentColorFilter } from '../../../util/TicketPersentColorFilter';

interface Props {
  acceptance: IAcceptance[];
  title: string;
  desc?: string;
}

export const AcceptanceItemDetails: React.FC<Props> = ({
  acceptance,
  title,
  desc,
}): JSX.Element => {
  const { ticketPassByCandidate } = useFlags();

  const userRole = useSelector(
    (state: RootState) => state.userReducer.me?.roles[0].name
  );
  return (
    <div>
      <div className='d-flex align-items-center justify-content-between mb-2 pb-1'>
        <div className='acceptance-static-title-flex'>
          <span>{title}</span>
          {ticketPassByCandidate ? (
            <span>Passed by other candidates</span>
          ) : null}
        </div>
        {desc && <div className='text-xs'>{desc}</div>}
      </div>
      {acceptance.map((acceptanceItem: IAcceptance) => {
        const { success, title, trace, screenshot } = acceptanceItem;
        return (
          <div key={uuid()}>
            <div>
              <div>
                <div className='acceptance-line'>
                  <div>
                    <span>
                      {success ? (
                        <TipIcon className='mr-2 mb-1' />
                      ) : (
                        <CrossIcon className='mr-2 mb-1' />
                      )}
                    </span>
                    <span>
                      {title}{' '}
                      {trace === null || userRole === 'recruiter' ? null : (
                        <Trace trace={trace} screenshot={screenshot} />
                      )}
                    </span>
                  </div>
                  {ticketPassByCandidate ? (
                    <div
                      className='acceptance-records'
                      style={{
                        color:
                          acceptanceItem.status === 'SUCCESS'
                            ? TicketPersentColorFilter(
                                acceptanceItem.persents.persentSuccess,
                                acceptanceItem.status
                              )
                            : TicketPersentColorFilter(
                                acceptanceItem.persents.persentFail,
                                acceptanceItem.status
                              ),
                      }}
                    >
                      {acceptanceItem.status === 'SUCCESS'
                        ? `${acceptanceItem.persents.persentSuccess} %`
                        : `${acceptanceItem.persents.persentFail} %`}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
