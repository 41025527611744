import React from 'react';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { IPosition } from '../../../typings/interfaces';
import { RecruiterPosition } from '../../components/RecruiterPosition';
import { CreateFirstPosition } from '../../components/CreateFirstPosition';
import { CreatePosition } from '../../components/CreatePosition';
import { Summary } from '../../components/Summary';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PositionsContainer } from '../../../containers/PositionsContainer';
import {
  positionsSelector,
  isActiveAddPositionSelector,
  positionsErrorSelector,
} from '../../../selectors';

export const RecruiterDashboard: React.SFC = () => {
  const { summaryPositionsFlag } = useFlags();

  const positions = useShallowEqualSelector(positionsSelector);

  const isActiveAddPosition = useShallowEqualSelector(
    isActiveAddPositionSelector
  );

  const positionsError = useShallowEqualSelector(positionsErrorSelector);

  if (!positions) return null;

  return (
    <div data-testid='recruiter'>
      {positions.length > 0 && summaryPositionsFlag && <Summary />}
      {isActiveAddPosition && <CreatePosition />}
      {positions.length === 0 && <CreateFirstPosition />}
      {positionsError && <div>{positionsError}</div>}
      <PositionsContainer>{renderPositions(positions)}</PositionsContainer>
    </div>
  );
};

const renderPositions = (positions: IPosition[]) => {
  if (!positions) {
    return null;
  }
  return positions.map((position: IPosition) => {
    return <RecruiterPosition position={position} key={position.uuid} />;
  });
};
