import { ITask } from '../../typings/interfaces';

// TASK
export const SET_TASK = 'SET_TASK';
export const PENDING_TASK = 'PENDING_TASK';
export const ERROR_TASK = 'ERROR_TASK';
export const CLEAR_TASK = 'CLEAR_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';

interface SetTaskAction {
  type: typeof SET_TASK;
  payload: ITask;
}

interface PendingTaslAction {
  type: typeof PENDING_TASK;
}

interface ErrorTaskaction {
  type: typeof ERROR_TASK;
  payload: string;
}
interface ClearTaskaction {
  type: typeof CLEAR_TASK;
}

export interface TaskState {
  task: undefined | ITask;
  pendingTask: boolean;
  errorTask: string;
}
export type TaskActionsTypes =
  | PendingTaslAction
  | ErrorTaskaction
  | ClearTaskaction
  | SetTaskAction;
