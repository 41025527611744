import React from 'react';
import CommitsDropdown from './CommitsDropdown';
import { CommitsTypes } from '../../../typings/enums';
import { ICheck } from '../../../typings/interfaces';

interface Props {
  commitsArray: any[] | undefined;
  beforeCommit: ICheck | undefined;
  afterCommit: ICheck | undefined;
}

export const CommitDropdowns: React.FC<Props> = ({
  commitsArray,
  beforeCommit,
  afterCommit,
}) => {
  return (
    <>
      <div className='flex-grow-1 d-flex align-items-center mb-2 mb-lg-0'>
        <div className='mr-3'>
          <CommitsDropdown
            commitsArray={commitsArray}
            value={beforeCommit}
            opositeValue={afterCommit}
            type={CommitsTypes.sourceCommit}
          />
        </div>
        {beforeCommit && (
          <div>
            #{beforeCommit.commit.slice(0, 7)}{' '}
            {beforeCommit.message ? <>&#9679; {beforeCommit.message}</> : null}
          </div>
        )}
      </div>
      <div className='flex-grow-1 d-flex align-items-center mb-2 mb-lg-0'>
        <div className='mr-3'>
          <CommitsDropdown
            commitsArray={commitsArray}
            value={afterCommit}
            opositeValue={beforeCommit}
            type={CommitsTypes.destinationCommit}
          />
        </div>
        {afterCommit && (
          <div>
            #{afterCommit.commit.slice(0, 7)}{' '}
            {afterCommit.message ? <>&#9679; {afterCommit.message}</> : null}
          </div>
        )}
      </div>
    </>
  );
};
