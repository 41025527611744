export const formatKindStr = (kind: string) => {
  if (kind === 'REFACTOR') {
    return 'Refactoring';
  } else {
    const lowerCaseKind = kind.toLowerCase();
    return lowerCaseKind.charAt(0).toUpperCase() + lowerCaseKind.slice(1);
  }
};

export const formatComplexity = (key: number) => {
  switch (key) {
    case 1:
      return 'EASY';
    case 2:
      return 'MEDIUM';
    case 3:
      return 'HARD';
    default:
      return '';
  }
};
