import React, { useEffect } from 'react';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getPositions } from '../../../actions/positionsActions';
import { Header } from '../../../containers/CandidatesContainer/Header';
import { Loader } from '../../components/Loader';
import { getInvites } from '../../../actions/invitesActions';
import { StageTitle } from '../../components/StageTitle';
import {
  invitesSelector,
  positionsSelector,
  pendingInvitesSelector,
} from '../../../selectors';
import { InvitesTable } from './InvitesTable';

export const TaskSent: React.FC = () => {
  const dispatch = useDispatch();

  const { positionId } = useParams();

  const positions = useShallowEqualSelector(positionsSelector);

  const curPosition =
    positions && positions.find((position) => position.uuid === positionId);

  const invites = useShallowEqualSelector(invitesSelector);
  const pendingInvites = useShallowEqualSelector(pendingInvitesSelector);

  useEffect(() => {
    dispatch(getPositions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInvites());
  }, [dispatch]);

  if (pendingInvites) {
    return <Loader />;
  }

  if (!invites || !curPosition) {
    return null;
  }

  const curInvites = [...invites].sort(
    (a, b) => Date.parse(b.date_created) - Date.parse(a.date_created)
  );

  return (
    <div className='bg-white px-4 px-md-5 py-3'>
      <Header />
      <div>
        <StageTitle title='Invites Generated' qty={curInvites.length} />
        <InvitesTable invitesData={curInvites} />
      </div>
    </div>
  );
};
