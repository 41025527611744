import React, { useState, useMemo } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { nGram } from '../../../util/nGram';
import { Stages, UserRoles } from '../../../typings/enums';
import { getSolutionStage } from '../../../util/getSolutionStage';
import { ISolution } from '../../../typings/interfaces';
import { ResultRow } from './ResultRow';

import './Search.scss';
import { userRoleSelector, candidatesSelector } from '../../../selectors';

export const DashboardSearch = () => {
  const [value, setValue] = useState('');

  const candidates = useShallowEqualSelector(candidatesSelector);

  const userRole = useShallowEqualSelector(userRoleSelector);

  const allCandidates: ISolution[] = useMemo(() => {
    let result: ISolution[] = [];
    if (candidates) {
      for (let position in candidates) {
        result = [...result, ...candidates[position]];
      }
    }
    return result;
  }, [candidates]);

  const handleClear = () => setValue('');

  const filteredCandidates = filterCandidates(allCandidates, value, userRole!);

  return (
    <div className='flex-grow-1 text-center search-block'>
      <div
        className='position-relative mx-auto postiton-relative w-100'
        style={{ maxWidth: '500px' }}
      >
        <input
          id='search-input'
          type='search'
          placeholder='Candidate name'
          className='rounded hover:border-gray-400 border w-100 px-4 py-2 text-sm border-purple-200 focus:outline-none bg-gray-700 rounded-lg focus:border-blue-600 hover:border-gray-400 focus:bg-gray-700 h-8'
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
          }}
          autoComplete='off'
          data-testid='search-input'
        />

        {!value && (
          <button
            className='bg-transparent border-0 px-0 position-absolute'
            style={{ top: '3px', right: '15px' }}
          >
            <FontAwesomeIcon icon={faSearch} color='#A3A3A3' size='lg' />
          </button>
        )}
        {value.length > 2 && filteredCandidates.length > 0 ? (
          <div
            className='position-absolute w-100 bg-white rounded-lg shadow text-left text-sm'
            style={{
              marginTop: '10px',
              left: 0,
              maxHeight: '232px',
              overflow: 'auto',
            }}
          >
            {filteredCandidates.map((candidate) => {
              return (
                <ResultRow
                  key={candidate.candidate.uuid}
                  userRole={userRole}
                  candidate={candidate}
                  handleClear={handleClear}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const filterCandidates = (
  allCandidates: ISolution[],
  value: string,
  userRole: string | undefined
) => {
  let sortedCandidates = allCandidates.sort((a, b) => {
    if (b?.candidate?.first_name > a?.candidate?.first_name) return -1;
    return 1;
  });

  if (userRole === UserRoles.REVIEWER)
    sortedCandidates = sortedCandidates.filter((candidate) => {
      const stage = getSolutionStage(candidate, userRole);
      return stage !== Stages.ARCHIVED && stage !== Stages.PROCESSED;
    });

  if (value.length < 3) return sortedCandidates;

  const ngramsArr = nGram(3)(value?.toLowerCase() || '');

  return sortedCandidates.reduce((acc: ISolution[], candidate) => {
    const nameNgrams = nGram(3)(
      candidate?.candidate?.first_name?.toLowerCase() || ''
    );
    const surNameNgrams = nGram(3)(
      candidate?.candidate?.last_name?.toLowerCase() || ''
    );

    for (const ngram of ngramsArr) {
      if (nameNgrams?.includes(ngram) || surNameNgrams?.includes(ngram)) {
        acc.push(candidate);
        break;
      }
    }

    return acc.sort((a, b) => {
      if (
        b?.candidate?.first_name?.toLowerCase().startsWith(ngramsArr[0]) ||
        (!a?.candidate?.first_name?.toLowerCase().startsWith(ngramsArr[0]) &&
          b?.candidate?.last_name?.toLowerCase().startsWith(ngramsArr[0]))
      )
        return 1;
      return -1;
    });
  }, []);
};
