export default `<pre class='terms'>
<h1>Sfeeda Terms</h1>
<h2>1 About our Terms</h2>
<div class='d-flex pl-6'><span class='mr-5'>1.1 </span><span>These Terms explain how you may use this website (the “Site”) which is provided by us free of charge.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>1.2 </span><span>References in these Terms to the Site includes the following website: <a rel='noopener noreferrer' target='_blank' href='https://sfeeda.com'>https://sfeeda.com</a>, and all associated web pages.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>1.3 </span><span>You should read these Terms carefully before using the Site.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>1.4 </span><span>By accessing or using the Site or otherwise indicating your consent, you agree to be bound by these Terms and the documents referred to in them.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>1.5 </span><span>If you do not agree with or accept any of these Terms, you should stop using the Site immediately.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>1.6 </span><span>If you have any questions about the Site, please contact us by:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>1.6.1 </span><span>e-mail <a href="mailto:sfeeda@sfeeda.com">sfeeda@sfeeda.com</a> (Monday to Friday: 9 am to 6 pm), or</span></div><div class='d-flex pl-14'><span class='mr-5'>1.6.2 </span><span>telephone +49 162 584-14-16 (Monday to Friday: 9 am to 6 pm).</span></div>
<div class='pl-6'>1.7 Definitions</div>
<div><div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>
“Account”</div></div>
<div>
means (as applicable): 
a) where you are a Company, the Company Account; and
b) where you are a Candidate, the Candidate Account;  
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>
“Associated”, or “Association”, as applicable
</div><div>
means where a Candidate: 
a) was Introduced by the Company to the Site; and
b) the Introduction did not result in a Candidate Job Placement.
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>
“Candidate”</div><div>
means any person that completes Candidate Registration including without limitation any person  the Company forwards to the Site to evaluate their Code with one or more Challenges to assist in the selection process for filling one or more Positions; and “Candidates” means more than one Candidate;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>
“Candidate Account”</div><div>
means the account of the Candidate set up after Candidate Registration is completed by the Candidate;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>
“Candidate Registration”</div><div>
means the Candidate’s registration for an Account and to receive the Candidate Services, which is approved by Sfeeda, the process which is accessible on the landing page of the Site;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>
“Candidate Services”</div><div>
means the services available on the Site for a Candidate that is not Introduced by a Company; or that is no longer Associated with a Company pursuant to a Candidate Job Placement failure with respect to that Candidate,  including without limitation the Code Evaluation services, and Company Introduction Services, it being understood that the Candidate Services are provided in exchange for and consideration of the Direct License;</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>
“Company”</div><div>
means the person that completes the Company Registration; 
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Company Account”</div><div>means the account of the Company set up after Company Registration is completed by the Company;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Company Introduction Services”
</div><div>means where the Candidate is introduced to another third party company (each a “Third Party Company”) with the purpose being to evaluate the potential of a business or work relationship between the Candidate and such Third Party Company; 
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Company Registration”
</div><div>means the Company's registration for an Account and to receive the Company Services, which is approved by Sfeeda, the process which is accessible on the landing page of the Site;</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Company Services”
</div><div>means the services available on the Site for any Company, including without limitation the Code Evaluation services, provided in exchange for and consideration of the Fees;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Candidate Job Placement”
</div><div>means the successful filling of the Position with its Candidate; 
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Challenge”
</div><div>means any task, test, or other challenge a Candidate has to perform by developing certain Code which is Evaluated; and “Challenges” means more than one Challenge; 
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Code”
</div><div>means each and collectively (as applicable) any source code developed by a Candidate pursuant to a Challenge and Evaluated as part of the Company Services or Candidate Services; 
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Content”
</div><div>means each and collectively (as applicable) any Code, text, images, video, audio or other multimedia content, software or other information or material submitted to or on the Site;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Effective Date”
</div><div>means the date you agree to the Terms during and complete the Registration;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Evaluated”; or “Evaluation” (as applicable)  
</div><div>means any testing, checking and/or evaluation (as applicable) conducted by Sfeeda on any Code supplied by a Candidate, directly or indirectly, including without limitation using any automatic, machine learning and/or manual method to conduct such Evaluation; 
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Fees”
</div><div>means the amount payable by the Company to us in consideration for the Company Services and detailed on the Site on the following page: <a rel='noopener noreferrer' target='_blank' href='https://sfeeda.com/#pricing'>https://sfeeda.com/#pricing</a>, and in the Company Registration, in accordance with section 12;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Introduction“, “Introduced”, or “Introduce”, as applicable 
</div><div>means where the Company introduces a Candidate to Sfeeda resulting in the Candidate Registration of the Candidate; 
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Party”
</div><div>means each Sfeeda, the Company, and the Candidate (as applicable); and collectively the “Parties”;  
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Position”
</div><div>means any open role with a Prospective Employer for which the Company is searching for one or more potential workers and pursuant to which the Company shall use the Services; and “Positions” shall mean more than one Position; 
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Prospective Employer”
</div><div>means any company that desires to fill a Position at the company with a Candidate, including without limitation where the Company is the Prospective Employer, and where the Prospective Employer is a client of the Company; 
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Registration”
</div><div>means each the Company Registration; or the Candidate Registration, as applicable;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Registration”
</div><div>means each the Company Services; or the Candidate Services, as applicable; 
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Site”
</div><div>has the meaning given to it in section 1.1;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Submission”
</div><div>shall take the meaning given in section 8;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Terms”
</div><div>means these terms and conditions of use as updated from time to time under section 17;
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“Use“
</div><div>means each and collectively as applicable, Sfeeda’s use, reproduction, distribution, transmission and/or public display of any Code, including without limitation: 
<div>a) use of the Code by Sfeeda to provide Company Introduction Services; </div>
<div>b) generation of a report by Sfeeda on any Code to by shared with any Third Party Company; </div>
<div>c)  analysis by Sfeeda on any Code; and</div>
<div>d) sharing of the Code with the Company and any Third Party Company;</div>
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“we”, “We”, “our”, “us” or “Sfeeda”, as applicable
</div><div>means Sfeeda UG, a company organised under the laws of Berlin with the following principal address: Bastianstr. 22, Berlin, Germany, 13357; and
</div></div>
<div class='d-lg-flex mb-2'><div class='mr-6 lg:w-4/12 flex-shrink-0'>“you”, “You”, “Your”, “your”, as applicable
</div><div>means the person that is reading these Terms, and (as applicable):  
<div>a) agrees to the Terms by completing the Company Registration and is a Company; or</div>
<div>b) agrees to the Terms by completing the Candidate Registration and is a Candidate.</div>
</div></div>
<div><h2>2 Using the Site</h2>
<div class='d-flex pl-6'><span class='mr-5'>2.1 </span><span>The Site is for your use only.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>2.2 </span><span>You agree that you are solely responsible for:</span></div><div class='d-flex pl-14'><span class='mr-5'>2.2.1 </span><span>all costs and expenses you may incur in relation to your use of the Site; and</span></div><div class='d-flex pl-14'><span class='mr-5'>2.2.2 </span><span>keeping your password and other Account details confidential.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>2.3 </span><span>We seek to make the Site as accessible as possible. If you have any difficulties using the Site, please contact us at <a href="mailto:sfeeda@sfeeda.com">sfeeda@sfeeda.com</a> and/or use the website accessibility tools available at <a rel='noopener noreferrer' target='_blank' href='https://sfeeda.com/contact'>https://sfeeda.com/contact</a>.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>2.4 </span><span>As a condition of your use of the Site, you agree to comply with the Site’s privacy policy and cookie policy.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>2.5</span><span> We may prevent or suspend your access to the Site if you do not comply with any part of these Terms, any terms or policies to which they refer or any applicable law.</span></div>
</div>
<div><h2>3 The Services. Your use of the Services is subject to the following explicit requirements, that:</h2>
<div class='d-flex pl-6'><span class='mr-5'>3.1 </span><span>you shall accept the conclusion of every Evaluation with respect to any Code;</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.2 </span><span>you shall accept the nature, substance and content of any Challenge that is utilized from time to time;</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.3 </span><span>if you are a Company, you agree that with respect to any Candidate that does not lead to Candidate Job Placement, Sfeeda is entitled to offer to such Candidate any other job, task and/or project opportunity;</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.4 </span><span>if you are a Company, you are only entitled to introduce Candidates according to the provisions contained herein and any guidelines provided by Sfeeda to the Company in writing, including email, it being understood for the avoidance of doubt, that Sfeeda will not be responsible in the event a Candidate misunderstands the terms of any Evaluation;</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.5 </span><span>you shall follow (and ensure that every Candidate follows if you are a Company) the procedure communicated by Sfeeda to the Company from time to time in writing including email, which Sfeeda is entitled to update in its absolute discretion, on the understanding that Sfeeda will provide the you with notice of any change as soon as reasonably practicable after the change, and that the current Procedure is accessible on the landing page of the Site (each and collectively the “Procedure”, as applicable);</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.6 </span><span>you agree that you entitled Sfeeda to disclose and publicize that the Company and the Candidate is a user of the Services; </span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.7 </span><span>you will ensure you always have the legal right to receive the Services;</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.8 </span><span>you will at all times during the receipt of the Services comply with any regulations, rules, laws and conventions that apply to it in the receipt of the Services, including without limitation financial services, anti-money laundering, employment and data privacy and security regulations (each and collectively “Compliance”, as applicable), on the understanding that it is your duty to determine where you are not in Compliance and to ensure Compliance;</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.9 </span><span>if you are a Company, you will ensure that you conduct due diligence checks on prospective Candidates you intend to Introduce, where that is necessary for your Compliance; and</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.10 </span><span>you are not entitled to make any part of any Challenge public;</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.11 </span><span>in the event you are offered a free trial to use the Services (each a “Free Trial”, and collectively “Free Trials”, as applicable):</span></div>
<div class='d-flex pl-14'><span class='mr-5'>3.11.1 </span><span>the Terms apply to you nonetheless (for the avoidance of doubt);</span></div>
<div class='d-flex pl-14'><span class='mr-5'>3.11.2 </span><span>any rules communicated to you on the Site when you take part in the Free Trial shall become part of these Terms by reference (each and collectively the “Rules”, as applicable); and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>3.11.3 </span><span>your Free Trial will last for the period detailed in the Rules; and </span></div>
<div class='d-flex pl-6'><span class='mr-5'>13.12 </span><span>in the event the person that completes the Registration is doing so on behalf of a legal entity (each the “Representative”), that Representative warrants, represents and undertakes to Sfeeda that the Representative is entitled to enter into these Terms on behalf of the Company or Candidate (as applicable) and has full power and authority to grant the rights expressed to be granted under these Terms on behalf of the Company or Candidate (as applicable) and the Representative is exclusively entitled to give all assurances, confirmations, waivers and agreements set out in these Terms on behalf of the Company or Candidate (as applicable) to enable the Company or Candidate (as applicable) to execute these Terms and for Sfeeda provide the Services as described in these Terms.</span></div>
</div>
<div><h2>4 Your privacy and personal information</h2>
<div class='d-flex pl-6'><span class='mr-5'>4.1 </span><span>Your privacy and personal information are important to us. Any personal information that you provide to us will be dealt with in line with our privacy policy, which explains what personal information we collect from you, how and why we collect, store, use and share such information, your rights in relation to your personal information and how to contact us and supervisory authorities in the event you have a query or complaint about the use of your personal information.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>4.2 </span><span>Our privacy policy is available at <a rel='noopener noreferrer' target='_blank' href='https://sfeeda.com/privacy-policy'>https://sfeeda.com/privacy-policy</a>.</span></div>
</div>
<div><h2>5 Ownership, use and intellectual property rights</h2>
<div class='d-flex pl-6'><span class='mr-5'>5.1 </span><span>If you are a Company, then you agree to ensure that every Candidate agrees to grant to
Sfeeda a perpetual, royalty-free, worldwide, non-exclusive licence to Use the Code supplied by the Candidate for the purposes of the Company Services.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>5.2 </span><span>If you are Candidate, you agree to grant to Sfeeda a perpetual, royalty-free, worldwide,
non-exclusive licence to Use the Code supplied by the Candidate for the purposes of the
Company Services and the Candidate Services (each the “Direct License”).</span></div>
<div class='d-flex pl-6'><span class='mr-5'>5.3 </span><span>The Site and all intellectual property rights in it including but not limited to any Content are owned by us, our licensors or both (as applicable). Intellectual property rights means rights such as: copyright, trade marks, domain names, design rights, database rights, patents and all other intellectual property rights of any kind whether or not they are registered or unregistered (anywhere in the world). We and our licensors reserve all of our and their rights in any intellectual property in connection with these Terms. This means, for example, that we and they remain owners of them and free to use them as we and they see fit.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>5.4 </span><span>Nothing in these Terms grants you any legal rights in the Site other than as necessary to enable you to access the Site. You agree not to adjust to try to circumvent or delete any notices contained on the Site (including any intellectual property notices) and in particular in any digital rights or other security technology embedded or contained within the Site.</span></div>
</div>
<div>
<h2>6 Submitting information including Code to the Site</h2>
While we try to make sure that the Site is secure, we cannot guarantee the security of any information including without limitation Code submitted to the Site and therefore we cannot guarantee that it will be kept confidential.
</div>
<div><h2>7 Unacceptable use</h2>
<div class='d-flex pl-6'><span class='mr-5'>7.1 </span><span>As a condition of your use of the Site, you agree not to use the Site:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>7.1.1 </span><span>for any purpose that is unlawful under any applicable law or prohibited by these Terms;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>7.1.2 </span><span>to commit any act of fraud;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>7.1.3 </span><span>to distribute viruses or malware or other similar harmful software code;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>7.1.4 </span><span>for purposes of promoting unsolicited advertising or sending spam;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>7.1.5 </span><span>to simulate communications from us or another service or entity in order to collect identity information, authentication credentials, or other information (‘phishing’);</span></div>
<div class='d-flex pl-14'><span class='mr-5'>7.1.6 </span><span>in any manner that disrupts the operation of our Site or business or the website or business of any other entity;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>7.1.7 </span><span>in any manner that harms minors;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>7.1.8 </span><span>to promote any unlawful activity;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>7.1.9 </span><span>to represent or suggest that we endorse any other business, product or service unless we have separately agreed to do so in writing;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>7.1.10 </span><span>to gain unauthorised access to or use of computers, data, systems, accounts or networks; or</span></div>
<div class='d-flex pl-14'><span class='mr-5'>7.1.11 </span><span>to attempt to circumvent password or user authentication methods.</span></div>
</div>
<div><h2>8 Submission standards</h2>
<div class='d-flex pl-6'><span class='mr-5'>8.1 </span><span>Any Code or other submission to the Site (each and collectively “Submission” as applicable) must conform to standards of accuracy, decency and lawfulness, which shall be applied in our discretion, acting reasonably.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>8.2 </span><span>In particular, any Submission or communication by you (as applicable) must be:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>8.2.1 </span><span>your own original work and lawfully submitted;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>8.2.2 </span><span>factually accurate or your own genuinely held belief;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>8.2.3 </span><span>provided with the necessary consent of any third party;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>8.2.4 </span><span>not defamatory or likely to give rise to an allegation of defamation;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>8.2.5 </span><span>not offensive, obscene, sexually explicit, discriminatory or deceptive; and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>8.2.6 </span><span>unlikely to cause offence, embarrassment or annoyance to others.</span></div>
</div>
<div><h2>9 Linking and framing</h2>
<div class='d-flex pl-6'><span class='mr-5'>9.1 </span><span>You may create a link to our Site from another website without our prior written consent provided no such link:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>9.1.1 </span><span>creates a frame or any other browser or border environment around the content of our Site;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>9.1.2 </span><span>implies that we endorse your products or services or any of the products or services of, or available through, the website on which you place a link to our Site;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>9.1.3 </span><span>displays any of the trade marks or logos used on our Site without our permission or that of the owner of such trade marks or logos; or</span></div>
<div class='d-flex pl-14'><span class='mr-5'>9.1.4 </span><span>is placed on a website that itself breaches these Terms.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>9.2 </span><span>We reserve the right to require you to immediately remove any link to the Site at any time, and you shall immediately comply with any request by us to remove any such link</span></div>
</div>
<div><h2>10 Accuracy of information and availability of the Site</h2>
<div class='d-flex pl-6'><span class='mr-5'>10.1 </span><span>While we try to make sure that the Site is accurate, up-to-date and free from bugs, we cannot promise that it will be. Furthermore, we cannot promise that the Site will be fit or suitable for any purpose. Any reliance that you may place on the information on the Site is at your own risk.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>10.2 </span><span>We may suspend or terminate operation of the Site at any time as we see fit.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>10.3 </span><span>Content is provided for your general information purposes only and to inform you about us and our products and news, features, services and other websites that may be of interest. It does not constitute technical, financial or legal advice or any other type of advice and should not be relied on for any purposes.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>10.4 </span><span>While we try to make sure that the Site is available for your use, we do not promise that the Site is available at all times nor do we promise the uninterrupted use by you of the Site.</span></div>
</div>
<div>
<h2>11 Hyperlinks and third party sites</h2>
<div>The Site may contain hyperlinks or references to third party websites other than the Site. Any such hyperlinks or references are provided for your convenience only. We have no control over third party websites and accept no legal responsibility for any content, material or information contained in them. The display of any hyperlink and reference to any third party website does not mean that we endorse that third party’s website, products or services. Your use of a third party site may be governed by the terms and conditions of that third party site.
</div>
</div>
<div>
<h2>12 Price and Payment</h2>
<div class='d-flex pl-6'><span class='mr-5'>12.1 </span><span>The Company is responsible for paying all relevant Fees. Fees are due and payable as detailed in the Registration and on the Site.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>12.2 </span><span>All Fees are exclusive of applicable sales tax, VAT and any other applicable taxes, unless otherwise stated and, unless otherwise agreed in writing, we may vary the Fees upon giving you 30 days’ notice. The Company may be charged, and is solely responsible for, all applicable taxes.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>12.3 </span><span>Payment processing of the Fees for the Company Services is provided by such third party payment processor as we may utilize from time to time (each a “Payment Processor”). Sfeeda does not collect or store the Company’s credit card information. In the Company’s provision of a credit card or other payment method accepted by Sfeeda, the Company represents and warrants that it is authorized to use the designated payment method and that it authorizes our Payment Processor to charge the Company’s payment method for the total amount of the transaction and any applicable fees (including any applicable taxes and other charges). If the payment method the Company provides cannot be verified, is invalid or is otherwise not acceptable, your Company Account may be suspended or cancelled. The Company must resolve any problem we or our Payment Processor encounters in order to proceed with the Company’s use of the Services.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>12.4 </span><span>The Company acknowledges and agrees that the payment instrument provided by the Company will be automatically charged the Fees incurred in connection with the Company’s use of the Services, and represents and warrants that the Company has all necessary rights relating to such payment instrument to authorize Sfeeda to make such charges. The Company’s use of the Services may be suspended if we are unable to charge such payment instrument for any reason or if the Company’s Account is otherwise past due. The Fees applicable to your Account may be subject to modification from time to time pursuant to notice (which may be given via e-mail) provided by us at least 30 days in advance of the payment date for which the modification would be effective. The Company may at any time cancel its Company Account as set forth below if it does not agree to any modified Fees. All Fees must be paid in the currency(ies) which may be accepted by Sfeeda from time to time, as indicated at the time of payment) and are non-refundable.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>12.5 </span><span>If you are a Company, then for any paid subscription service Account type:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.5.1 </span><span>you will be automatically enrolled in (and charged for) the subscription plan you selected following the end of any applicable free trial period (if any);</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.5.2 </span><span>if you are offered a free trial, then you must cancel prior to the end of your free trial in the manner specified in the Registration to avoid being charged;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.5.3 </span><span>you may cancel your subscription at any time by contacting us at <a href="mailto:sfeeda@sfeeda.com">sfeeda@sfeeda.com</a>;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.5.4 </span><span>if you cancel your subscription, you may still use your subscription until the end of your then-current subscription month; and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.5.5 </span><span>to not be charged for your subscription for the following subscription month, you must cancel your subscription at least 30 days prior to that month, or you will otherwise be charged for that month’s subscription.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>12.6 </span><span>All cancellation requests received less than thirty (30) days before the following subscription month will apply to the following cycle.</span></div>
</div>
<div>
<h2>13 Term and Termination</h2>
<div class='d-flex pl-6'><span class='mr-5'>13.1 </span><span>These Terms commence on the Effective Date and shall continue until either Party terminates these Terms in accordance with this section 13 or any other provisions contained herein (the “Term”) .</span></div>
<div class='d-flex pl-6'><span class='mr-5'>13.2 </span><span>Either Party may terminate these Terms (without prejudice to their other rights and remedies) with immediate effect by written notice to the other Party if the other Party commits a material breach of this Agreement and, if capable of remedy, fails to remedy such breach within 5 Business Days.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>13.3 </span><span>Sfeeda is entitled to terminate these Terms immediately without any notice to you, in the event you decide not to carry on using the Services after a Free Trial has come to an end.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>13.4 </span><span>Either Party is entitled to terminate these Terms for convenience at any time upon 30 Business Days’ written notice to the other Party.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>13.5 </span><span>Upon termination or expiry of these Terms, the Parties shall have no further obligations or rights under these Terms, without prejudice to any obligations or rights which have accrued to either Party at the date of termination or expiry, save that those sections which by their nature are intended to survive shall do so.</span></div>
</div>
<div>
<h2>14 Limitation on our liability</h2>
<div class='d-flex pl-6'><span class='mr-5'>14.1 </span><span>Except for any legal responsibility that we cannot exclude in law (such as for death or personal injury) or arising under applicable laws relating to the protection of your personal information, we are not legally responsible for any:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>14.1.1 </span><span>losses that:</span></div>
<div class='d-flex pl-20 ml-10'><span class='mr-5'>(a) </span><span>were not foreseeable to you and us when these Terms were formed; or</span></div>
<div class='d-flex pl-20 ml-10'><span class='mr-5'>(b) </span><span>that were not caused by any breach on our part;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>14.1.2 </span><span>business losses; and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>14.1.3 </span><span>losses to non-consumers.</span></div>
</div>
<div>
<h2>15 Events beyond our control</h2>
<div>We shall have no liability to you for any breach of these Terms caused by any event or circumstance beyond our reasonable control including, but not limited to, strikes, lock-outs or other industrial disputes; breakdown of systems or network access; or flood, fire, explosion or accident.
</div>
</div>
<div>
<h2>16 Rights of third parties</h2>
No one other than a party to these Terms has any right to enforce any of these Terms.
</div>
<div>
<h2>17 Variation</h2>
<div>
These Terms are dated 22th of June. No changes to these Terms are valid or have any effect unless agreed by us in writing or made in accordance with this section 17. We reserve the right to vary these Terms from time to time. Our updated Terms will be displayed on the Site and by continuing to use and access the Site following such changes, you agree to be bound by any variation made by us. It is your responsibility to check these Terms from time to time to verify such variations.
</div>
</div>
<div>
<h2>18 Disputes</h2>
<div class='d-flex pl-6'><span class='mr-5'>18.1 </span><span>We will try to resolve any disputes with you quickly and efficiently.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>18.2 </span><span>If you are unhappy with us please contact us as soon as possible.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>18.3 </span><span>If you and we cannot resolve a dispute using our complaint handling procedure, we will:   </span></div>
<div class='d-flex pl-14'><span class='mr-5'>18.3.1 </span><span>let you know that we cannot settle the dispute with you; and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>18.3.2 </span><span>give you certain information about our alternative dispute resolution provider. You may also use the online dispute resolution (ODR) platform to resolve the dispute with us. For more details, please visit the website on the ‘Your Europe’ portal: <a style='word-break: break-all;' rel='noopener noreferrer' target='_blank' href='https://ec.europa.eu/consumers/odr/main/?event=main.home2.show'>https://ec.europa.eu/consumers/odr/main/?event=main.home2.show</a></span></div>
<div class='d-flex pl-6'><span class='mr-5'>18.4 </span><span>If you want to take court proceedings, the relevant courts of Germany will have exclusive jurisdiction in relation to these Terms.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>18.5 </span><span>Relevant United Kingdom law will apply to these Terms.</span></div>
</div>
</pre>`;
