const generatedColors: string[] = [];

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

const colors = [
  '67b6ff',
  'BB6BD9',
  '2A2F57',
  '650B3C',
  '184A32',
  '103B90',
  '8B1887',
  '0D6751',
  '250073',
  '462C7C',
  '3F9D86'
];

let n = 0;
alphabet.forEach(i => {
  n++;
  if (n >= colors.length - 1) {
    n = 0;
  }
  generatedColors.push(colors[n]);
});

export const getColor = (letter: string | undefined) => {
  const symbol = letter?.toLowerCase();
  return generatedColors[symbol ? alphabet.indexOf(symbol) : 0];
};
