import { SHOW_MODAL, HIDE_MODAL, ModalsActionTypes } from './types';
import { Dispatch } from 'redux';
import { InviteWithoutEmailModal, INVITEWITHOUTEMAILMODAL } from './types/modalsActionsTypes';

export const showModal = ({
  type,
  title,
}: {
  type: string;
  title: string;
}): ModalsActionTypes => ({
  type: SHOW_MODAL,
  payload: { type, title },
});
export const hideModal = (): ModalsActionTypes => ({
  type: HIDE_MODAL,
});

export const modalInviteWithoutEmail = (payload: boolean) => async (dispatch: Dispatch<InviteWithoutEmailModal>) => {
  dispatch({
    type: INVITEWITHOUTEMAILMODAL,
    payload,
  })
}