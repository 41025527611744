import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { getChallenge } from '../../../actions/challengesActions';
import { ISolution, IPosition, IUser } from '../../../typings/interfaces';
import { SummaryRow } from './SummaryRow';
import { UserRoles, Stages } from '../../../typings/enums';
import {
  selectConsumedInvitesForPosition,
  pendingInvitesSelector,
} from '../../../selectors';
import { getInvites } from '../../../actions/invitesActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useParams } from 'react-router-dom';
import { Loader } from '../Loader';
import { filterSolutionByStage } from '../../../util/filterSolutionByStage';

interface Props {
  candidate: ISolution;
  position: IPosition;
  user?: IUser | null;
}

export const SummaryInfo: React.FC<Props> = ({ candidate, position, user }) => {
  const { positionId } = useParams();
  const dispatch = useDispatch();
  const pendingInvites = useShallowEqualSelector(pendingInvitesSelector);
  const candidateInvite = useShallowEqualSelector((state) =>
    selectConsumedInvitesForPosition(state, positionId)?.find(
      (invite) => invite.consumed_by.uuid === candidate?.candidate.user?.uuid
    )
  );

  // useEffect(() => {
  //   if (position && position.task.tickets) {
  //     dispatch(getChallenge(position.task.tickets[0].challenge.id));
  //   }
  // }, [dispatch, position]);

  useEffect(() => {
    dispatch(getInvites());
  }, [dispatch]);

  let duration;

  if (candidate.date_ready && candidate.date_created) {
    duration =
      Date.parse(candidate.date_ready) - Date.parse(candidate.date_created);
  }

  if (pendingInvites) return <Loader />;

  return (
    <table className='mb-4 mr-auto' data-testid='candidate-summary-info'>
      <tbody>
        {candidate.recruiter ? (
          <tr>
            <td className='text-gray-600 pr-8'>Recruiter</td>
            <td>
              {candidate.recruiter.first_name} {candidate.recruiter.last_name}
            </td>
          </tr>
        ) : null}
        <SummaryRow
          date={candidateInvite?.date_created}
          title='Task Sent'
          testId='sent'
        />
        <SummaryRow
          date={candidate.date_created}
          title='Task Started'
          testId='started'
        />
        {candidate.is_ready && (
          <SummaryRow
            date={candidate.date_ready}
            title='Completed'
            testId='completed'
          />
        )}
        {duration && (
          <SummaryRow date={duration} title='Time Spent' testId='time-spent' />
        )}
        {user?.user_claims.roles[0] === UserRoles.RECRUITER &&
        (filterSolutionByStage(
          candidate,
          user?.user_claims.roles[0],
          Stages.ACCEPTED
        ) ||
          filterSolutionByStage(
            candidate,
            user?.user_claims.roles[0],
            Stages.REJECTED
          )) ? (
          <SummaryRow
            date={candidate.feedback?.date_created}
            title='Reviewed'
            testId='reviewed'
          />
        ) : null}
      </tbody>
    </table>
  );
};
