export default `<pre class='terms'>
<h1>DATA PROCESSING AGREEMENT</h1>
<div>This Data Processing Agreement (this "Agreement"), is effective as of the Effective Date and is entered into by and between: you and Sfeeda UG, a company organised under
the laws of Berlin with the following principal address: Bastianstr. 22, Berlin, Germany, 13357 (c/o Malyshenko) (the "Supplier"), on the date you read and execute this Agreement by applying a tick in the appropriate tick box during Registration to accept the Website Terms and this Agreement (the “Effective Date”).

The any terms found in this Agreement that have been defined in the Website Terms
and not herein, shall take the meaning given to them in the Website Terms.</div>
<h2>1. DEFINITIONS</h2><div><b>"Applicable Law"</b> means (each and collectively, as applicable) any law, statute, regulation, byelaw or subordinate legislation in force from time to time to which a Party is subject and/or in any jurisdiction that the Services are provided to or in respect of:
<div class='d-flex pl-6'><span class='mr-5'>a.</span><span>the common law and laws of equity as applicable to the Parties from time to time;</span></div><div class='d-flex pl-6'><span class='mr-5'>a.</span><span>tany binding court order, judgment or decree;</span></div><div class='d-flex pl-6'><span class='mr-5'>a.</span><span>any applicable industry code, policy or standard; or</span></div><div class='d-flex pl-6'><span class='mr-5'>a.</span><span>any applicable direction, policy, rule or order that is binding on a party and that is made or given by any regulatory body having jurisdiction over a Party or any of that Party’s assets, resources or business.</span></div>
<b>"Business Day"</b> means a day other than a Saturday, Sunday or public holiday in.

<b>"Confidential Information"</b> means:
<div class='d-flex pl-6'><span class='mr-5'>a.</span><span>subject to sub-clause b of this definition, information disclosed by the Discloser to the Recipient under or in connection with this Agreement which is marked as confidential, or which the Discloser has otherwise indicated to the Recipient is confidential, or which would be regarded as confidential by a reasonable business person, which shall in each case be the Confidential Information of the Discloser; and</span></div>       <div class='d-flex pl-6'><span class='mr-5'>a.</span><span>all Protected Data, which shall in all cases be Confidential Information of the person to which the Protected Data relates (not Confidential Information of the Discloser).</span></div>
       
<b>"Controller"</b> has the meaning given to the term in the GDPR, it being understood that the Customer is the Controller with respect to the Protected Data, as further detailed herein.

<b>"Data Protection Complaint"</b> means a complaint or request relating to either Party’s obligations under Data Protection Laws relevant to this Agreement or the Principal Agreement, including any compensation claim from a Data Subject or any notice, investigation or other action from a Supervisory Authority.

<b>"Data Protection Impact Assessment"</b> has the meaning given in the GDPR.

<b>"Data Protection Laws"</b> means any Applicable Law relating to the Processing, privacy, and/or use of Personal Data, as applicable to the Customer, the Supplier and/or the Services, including:
<div class='d-flex pl-6'><span class='mr-5'>a.</span><span>in member states of the European Union ("EU") and/or European Economic Area ("EEA"): the GDPR and the ePrivacy Directive and all relevant EU and EEA member state laws or regulations giving effect to or corresponding with any of them;</span></div>
<div class='d-flex pl-6'><span class='mr-5'>a.</span><span>in any jurisdiction outside the EEA: the rules, laws, conventions and/or regulations that have the same or a materially similar function as the Applicable Law referenced above in the applicable jurisdiction; and</span></div>
<div class='d-flex pl-6'><span class='mr-5'>a.</span><span>any judicial or administrative interpretation of any of the above, any guidance, guidelines, codes of practice, approved codes of conduct or approved certification mechanisms issued by any relevant Supervisory Authority.</span></div>

<b>"Data Protection Losses"</b> means all liabilities and other amounts, including without limitation all:
<div class='d-flex pl-6'><span class='mr-5'>a.</span><span>costs (including legal costs), claims, demands, actions, settlements, interest, charges, procedures, expenses, losses and damages (including relating to material or non-material damage);</span></div>
<div class='d-flex pl-6'><span class='mr-5'>a.</span><span>loss or damage to reputation, brand or goodwill;</span></div>
<div class='d-flex pl-6'><span class='mr-5'>a.</span><span>to the extent permitted by Applicable Laws:</span></div><div class='d-flex pl-14'><span class='mr-5'>i.</span><span>administrative fines, penalties, sanctions, liabilities or other remedies imposed by a Supervisory Authority;</span></div><div class='d-flex pl-14'><span class='mr-5'>i.</span><span>compensation paid to a Data Subject (including compensation to protect goodwill and ex gratia payments); and</span></div><div class='d-flex pl-14'><span class='mr-5'>i.</span><span>costs of compliance with investigations by a Supervisory Authority;</span></div>
<div class='d-flex pl-6'><span class='mr-5'>a.</span><span>the costs of loading Protected Data and replacement of the Customer's materials and equipment, to the extent the same are lost, damaged or destroyed, and any loss or corruption of Protected Data (including the costs of rectification or restoration of Protected Data).</span></div>

<b>"Data Protection Officer"</b> has the meaning given in the GDPR.

<b>"Data Subject"</b> has the meaning given in the GDPR.

<b>"Data Subject Request"</b> means a request made by a Data Subject to exercise any
rights of Data Subjects under Data Protection Laws.

<b>"Discloser"</b> means a person that discloses Confidential Information in support of the Purpose, which in this Agreement includes (as applicable) the Supplier and the Customer, and any person that discloses Confidential Information on the Discloser's behalf. "GDPR" means the General Data Protection Regulation 2016/679.

<b>"International Organisation"</b> means an organisation and its subordinate bodies
governed by public international law, or any other body which is set up by, or on
the basis of, an agreement between two or more countries.
"International Recipient" means a country outside the EEA including the UK, or an
International Organisation.

<b>"Intellectual Property Rights"</b> means intellectual and industrial property rights, including copyright (including moral rights), patents, know-how, trade secrets, trademarks, service marks, trade names, design rights, registered designs, get-up, database rights chip topography rights, the right to sue in passing off, mask works, utility models, domain names, rights in trade and business names and all similar rights and, in each case whether registered or not, wherever existing, including without limitation:
<div class='d-flex pl-6'><span class='mr-5'>a.</span><span>any applications to protect or register such rights;</span></div><div class='d-flex pl-6'><span class='mr-5'>a.</span><span>all renewals and extensions of such rights or applications;</span></div><div class='d-flex pl-6'><span class='mr-5'>a.</span><span>whether vested, contingent or future; and</span></div><div class='d-flex pl-6'><span class='mr-5'>a.</span><span>rights in the nature of unfair competition rights and rights to sue in passing off.</span></div>

<b>"Member State"</b> means a Member State of the EU or a country that is a member of the EEA.

<b>"Personal Data"</b> has the meaning given to that term in Data Protection Laws.

<b>"Personal Data Breach"</b> means any breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, any Protected Data.

<b>"Principal Agreement"</b>, or <b>“Website Terms”</b>, as applicable: means the terms and conditions of the Website accessible via the following url: <a rel='noopener noreferrer' target='_blank' href='https://sfeeda.com/terms-of-service'>https://sfeeda.com/terms-of-service</a>, and executed on the Effective Date, between the Customer and the Supplier, pursuant to which the Supplier agreed to provide the Services to the Customer.

<b>"Processing"</b> has the meaning given in Data Protection Laws (and related terms such as "process" and "processed" have corresponding meanings).

<b>"Processing Instructions"</b> has the meaning given in clause 3.1.1.

<b>"Processor"</b> has the meaning given in the Data Protection Laws, it being understood that the Supplier is the Processor with respect to the Protected Data, as further detailed herein.

<b>"Protected Data"</b> means Personal Data received by the Supplier from or on behalf of the Customer, or otherwise obtained in connection with the performance of the Supplier’s obligations under this Agreement and/or under the Principal Agreement (as applicable).

<b>"Purpose"</b> means the provision of the Services from the Supplier to the Customer all in accordance with the Principal Agreement. 

<b>"Recipient"</b> means a person that receives Confidential Information in support of the Purpose, which in this Agreement includes (as applicable) each the Supplier and the Customer, and any person that receives Confidential Information on the Recipient's behalf.

<b>“Registration”</b> means where you register on the Website use the Services via the following url: <a rel='noopener noreferrer' target='_blank' href='https://sfeeda.com/register'>https://sfeeda.com/register</a>.

<b>"Services"</b> means the cloud based code analysis services flowing from the Supplier to the Customer in support of which the Supplier will process the Protected Data on behalf of the Customer, as further detailed herein and in the Principal Agreement (as applicable).

<b>"Sub-Processor"</b> means any Processor engaged by the Supplier (or by any other Sub-Processor) for carrying out any processing activities in respect of the Protected Data.

<b>"Supervisory Authority"</b> means any local, national or multinational agency, department, official, parliament, public or statutory person or any government or professional body, regulatory or supervisory authority, board or other body responsible for administering the Data Protection Laws.
"Supplier Personnel" means all employees, officers, staff, other workers, agents and consultants of the Processor and of any Sub-Processor who are engaged in the performance of the Services based on the Principal Agreement or the Processing of any Protected Data from time to time. 

<b>“Website”</b> means www.sfeeda.com.
</div>
<div><h2>2. PROCESSOR AND CONTROLLER</h2>
<div class='d-flex pl-6'><span class='mr-5'>2.1. </span><span>The Parties agree that, for the Protected Data, the Customer shall be the Controller and the Supplier shall be the Processor Nothing in this Agreement relieves the Customer of any responsibilities or liabilities under any Data Protection Laws.</span></div><div class='d-flex pl-6'>
<span class='mr-5'>2.2. </span><span>The Supplier shall process Protected Data in compliance with:</span></div><div class='d-flex pl-14'><span class='mr-5'>2.2.1. </span><span>the obligations of Processors under Data Protection Laws in respect of the performance of its obligations under this Agreement; and</span></div><div class='d-flex pl-14'><span class='mr-5'>2.2.2. </span><span>the terms of this Agreement.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>2.3. </span><span>The Customer shall comply with:</span></div><div class='d-flex pl-14'><span class='mr-5'>2.3.1. </span><span>all Data Protection Laws in connection with the processing of Protected Data, the Services and the exercise and performance of its respective rights and obligations under this Agreement, including maintaining all relevant regulatory registrations and notifications as required under Data Protection Laws, and the Principal Agreement Data Protection Laws; and</span></div><div class='d-flex pl-14'><span class='mr-5'>2.3.2. </span><span>the terms of this Agreement.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>2.4. </span><span>The Customer warrants, represents and undertakes, that:</span></div><div class='d-flex pl-14'><span class='mr-5'>2.4.1. </span><span>all data sourced by the Customer for use in connection with the Services, prior to such data being provided to or accessed by the Supplier for the performance of the Services under this Agreement, shall comply with all in all respects, including in terms of its collection, storage and processing (which shall include the Customer providing all of the required fair processing information to, and obtaining all necessary consents from, Data Subjects), with all Data Protection Laws;</span></div><div class='d-flex pl-14'><span class='mr-5'>2.4.2. </span><span>all instructions given by it to the Supplier in respect of Personal Data shall at all times be in accordance with Data Protection Laws; and</span></div><div class='d-flex pl-14'><span class='mr-5'>2.4.3. </span><span>it has undertaken due diligence in relation to the Supplier’s processing operations, and it is satisfied that:</span></div><div class='d-flex pl-20 ml-10'><span class='mr-5'>a. </span><span>the Supplier’s processing operations are suitable for the purposes for which the Customer proposes to use the Services and engage the Supplier to process the Protected Data; and</span></div>
<div class='d-flex pl-20 ml-10'><span class='mr-5'>b. </span><span>the Supplier has sufficient expertise, reliability and resources to implement technical and organisational measures that meet the requirements of Data Protection Laws.</span></div><div class='d-flex pl-6'><span class='mr-5'>2.5. </span><span>The Customer shall not unreasonably withhold, delay or condition its agreement to any change requested by the Supplier in order to ensure the Services and the Supplier (and each Sub-Processor) can comply with Data Protection Laws.</span></div>
</div>
<div>
<h2>3. PROCESSOR INSTRUCTIONS</h2>
<div class='d-flex pl-6'><span class='mr-5'>3.1. </span><span>Insofar as the Supplier processes Protected Data on behalf of the Customer, the Supplier:</span></div><div class='d-flex pl-14'><span class='mr-5'>3.1.1. </span><span>unless required to do otherwise by Applicable Law, shall (and shall take steps to ensure each person acting under its authority shall) process the Protected Data only on and in accordance with the Customer’s documented instructions as set out in this Agreement including the schedule (Data Processing Details) and the Principal Agreement, as updated from time to time by the written agreement of the Parties ("Processing Instructions");</span></div><div class='d-flex pl-14'><span class='mr-5'>3.1.2. </span><span>if Applicable Law requires it to process Protected Data other than in accordance with the Processing Instructions, shall notify the Customer of any such requirement before Processing the Protected Data (unless Applicable Laws prohibit such information on important grounds of public interest); and </span></div>
<div class='d-flex pl-14'><span class='mr-5'>3.1.3. </span><span>shall promptly inform the Customer if the Supplier becomes aware of a Processing Instruction that, in the Supplier’s opinion, infringes Data Protection Laws provided that:</span></div>
<div class='d-flex pl-6 pl-20 ml-10'><span class='mr-5'>a. </span><span>this shall be without prejudice to clauses 2.3 and 2.4; and</span></div>
<div class='d-flex pl-6 pl-20 ml-10'><span class='mr-5'>b. </span><span>to the maximum extent permitted by mandatory law, the Supplier shall have no liability howsoever arising (whether in contract, tort (including negligence) or otherwise) for any losses, costs, expenses or
liabilities (including any Data Protection Losses) arising from or in connection with any Processing in accordance with the Processing
Instructions following the Customer’s receipt of that information.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>3.2. </span><span>The Processing of Protected Data to be carried out by the Supplier under this</span></div>
Agreement shall comprise the Processing set out in the schedule (Data Processing Details), and such other Processing as agreed by the parties in writing from time to time.</span></div>
</div>
<div>
<h2>4. TECHNICAL AND ORGANISATIONAL MEASURES</h2>
<div class='d-flex pl-6'><span class='mr-5'>4.1. </span><span>The Supplier shall implement and maintain at its cost and expense the technical and organisational measures:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>4.1.1. </span><span>in relation to the Processing of Protected Data by the Supplier, as set out in the schedule 'Technical and Organisational Measures'; and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>4.1.2. </span><span>taking into account the nature of the Processing, to assist the Customer insofar as is possible in the fulfilment of the Customer’s obligations to respond to Data Subject Requests relating to Protected Data.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>4.2. </span><span>Any additional technical and organisational measures shall be at the Customer’s cost and expense.</span></div>
</div>
<div>
<h2>5. USING STAFF AND OTHER PROCESSORS</h2>
<div class='d-flex pl-6'><span class='mr-5'>5.1. </span><span>The Supplier shall not engage any Sub-Processor (or any replacement) for carrying out any Processing activities in respect of the Protected Data without the Customer’s written authorisation of that specific Sub-Processor (such authorisation
not to be unreasonably withheld, conditioned or delayed) provided that the Customers herewith authorises the appointment of any of the Sub-Processors listed in the Schedule 'Data Processing Details', as applicable.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>5.2. </span><span>The Supplier shall:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>5.2.1. </span><span>prior to the relevant Sub-Processor carrying out any processing activities in respect of the Protected Data, appoint each Sub-Processor under a written contract containing materially the same obligations relating to Processing and Personal Data as under this Agreement that is enforceable by the Supplier;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>5.2.2. </span><span>ensure each such Sub-Processor complies with all such obligations; and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>5.2.3. </span><span>remain fully liable for all the acts and omissions of each Sub-Processor as if they were its own.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>5.3. </span><span>The Supplier shall ensure that the Supplier Personnel and all persons authorised by it or by any Sub-Processor to process Protected Data are subject to a binding written contractual obligation to keep the Protected Data confidential (except
where disclosure is required in accordance with Applicable Law, in which case the Supplier shall, where practicable and not prohibited by Applicable Law, notify the Customer of any such requirement before such disclosure).</span></div>
</div>
<div>
<h2>6. ASSISTANCE AND DATA SUBJECT RIGHTS</h2>
<div class='d-flex pl-6'><span class='mr-5'>6.1. </span><span>The Supplier shall refer all Data Subject Requests it receives to the Customer within three (3) Business Days of receipt of the request, provided that if the number of
Data Subject Requests exceeds 10 per calendar month, the Customer shall pay Supplier for the Data Subject Requests in accordance with this clause.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>6.2. </span><span>The Supplier shall provide such reasonable assistance as the Customer reasonably requires (taking into account the nature of Processing and the information available
to the Supplier) to the Customer in ensuring compliance with the Customer’s obligations under Data Protection Laws with respect to:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>6.2.1. </span><span>security of Processing;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>6.2.2. </span><span>Data Protection Impact Assessments;</span></div><div class='d-flex pl-14'><span class='mr-5'>6.2.3. </span><span>prior consultation with a Supervisory Authority regarding high risk Processing; and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>6.2.4. </span><span>notifications to the Supervisory Authority and/or communications to Data Subjects by the Customer in response to any Personal Data Breach,
provided the Customer shall pay the Supplier’s charges for providing the assistance in this clause, such charges to be calculated at the Supplier’s rates communicated to
the Customer from time to time in writing.</span></div>
</div>
<div>
<h2>7. INTERNATIONAL DATA TRANSFERS</h2>The Customer agrees that the Supplier may transfer Protected Data in support of the Purpose to an International Recipient, provided all transfers by the Supplier of
<div>Protected Data to an International Recipient (and any onward transfer) shall (to the extent required under Data Protection Laws) be effected by way of Appropriate
Safeguards and in accordance with Data Protection Laws. The provisions of this Agreement shall constitute the Customer’s instructions with respect to transfers in
accordance with clause 3.1.
</div>
<div>
<h2>8. TERMINATION</h2>
Without prejudice to the confidentiality obligations under this Agreement, the term of this Agreement commences on the Effective Date and shall expire or terminate
upon (the "Term"): the completion of the Purpose.
</div>
<div>
<h2>9. RECORDS INFOMRATION AND AUDIT</h2>
<div class='d-flex pl-6'><span class='mr-5'>9.1. </span><span>The Supplier shall maintain (in accordance with Data Protection Laws binding on the Supplier) written records of all categories of Processing activities carried out on behalf of the Customer.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>9.2. </span><span>The Supplier shall, in accordance with Data Protection Laws, make available to the Customer such information as is reasonably necessary to demonstrate the
Supplier’s compliance with its obligations under Article 28 of the GDPR (and under any Data Protection Laws equivalent to Article 28), and allow for and contribute to
audits, including inspections by the Customer or another auditor mandated by the Customer for this purpose, subject to the Customer:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>9.2.1. </span><span>giving the Supplier reasonable prior notice of such information request, audit and/or inspection being required by the Customer;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>9.2.2. </span><span>ensuring that all information obtained or generated by the Customer or its auditor(s) in connection with such information requests, inspections and
audits is kept strictly confidential (save for disclosure to the Supervisory Authority or as otherwise required by Applicable Law);</span></div>
<div class='d-flex pl-14'><span class='mr-5'>9.2.3. </span><span>ensuring that such audit or inspection is undertaken during normal business hours, with minimal disruption to the Supplier’s business, the
Sub-Processors’ business and the business of other customers of the Supplier; and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>9.2.4. </span><span>paying the Supplier’s reasonable costs for assisting with the provision of information and allowing for and contributing to inspections and audits.</span></div>
</div>
<div>
<h2>10. DATA BREACH NOTIFICATION. </h2>
In respect of any Personal Data Breach (involving Protected Data) the Supplier shall (without undue delay):
<div class='d-flex pl-6'><span class='mr-5'>10.1. </span><span>notify the Customer of the Personal Data Breach; and </span></div><div class='d-flex pl-6'><span class='mr-5'>10.2. </span><span>provide the Customer with details of the Personal Data Breach.</span></div>
</div>
<div>
<h2>11. DELETION OR RETURN OF PROTECTED DATA.</h2>
The Supplier shall and all Supplier Personnel at the Customer’s written request, either delete, destroy or return all the Protected Data to the Customer in such form as the Customer reasonably requests within a
reasonable time after the earlier of:
<div class='d-flex pl-6'><span class='mr-5'>11.1. </span><span>the end of the provision of the Services; or </span></div><div class='d-flex pl-6'><span class='mr-5'>11.2. </span><span>once Processing by the Supplier of any Protected Data is no longer required for the purpose of the Supplier’s performance of its relevant obligations under this
Agreement and/or the Principal Agreement, and delete/destroy existing copies (unless storage of any data is required by Applicable Laws and, if so, the Supplier shall inform the Customer of any such
requirement).</span></div>
</div>
<div>
<h2>12. DATA PROTECTION LOSSES</h2>
<div class='d-flex pl-6'><span class='mr-5'>12.1. </span><span>The Customer shall indemnify and keep indemnified the Supplier in respect of all Data Protection Losses suffered or incurred by, awarded against or agreed to be
paid by, the Supplier and any Sub-Processor arising from or in connection with any:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.1.1. </span><span>non-compliance by the Customer with the Data Protection Laws;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.1.2. </span><span>Processing carried out by the Supplier or any Sub-Processor pursuant to any Processing Instruction that infringes any Data Protection Laws; or</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.1.3. </span><span>breach by the Customer of any of its obligations under this Agreement, except to the extent the Supplier is liable under clause 12.2 the Supplier.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>12.2. </span><span>The Supplier shall be liable for Data Protection Losses (howsoever arising, whether in contract, tort (including negligence) or otherwise) under or in connection with this Agreement:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.2.1. </span><span>only to the extent caused by the Processing of Protected Data under this Agreement and directly resulting from the Supplier’s breach of this Agreement; and </span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.2.2. </span><span>in no circumstances to the extent that any Data Protection Losses (or the circumstances giving rise to them) are contributed to or caused by any breach of this Agreement by the Customer.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>12.3. </span><span>If a Party receives a compensation claim from a person relating to Processing of Protected Data, it shall promptly provide the other Party with notice and full details of such claim. The Party involved in the action shall:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.3.1. </span><span>make no admission of liability nor agree to any settlement or compromise of the relevant claim without the prior written consent of the other Party (which shall not be unreasonably withheld or delayed); and </span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.3.2. </span><span>consult fully with the other Party in relation to any such action, but the terms of any settlement or compromise of the claim will be exclusively the decision of the Party that is responsible under this Agreement for paying the compensation.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>12.4. </span><span>The Parties agree that the Customer shall not be entitled to claim back from the Supplier any part of any compensation paid by the Customer in respect of such damage to the extent that the Customer is liable to indemnify the Supplier in accordance with clause 12.1.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>12.5. </span><span>This clause is intended to apply to the allocation of liability for Data Protection Losses as between the Parties, including with respect to compensation to Data Subjects, notwithstanding any provisions under Data Protection Laws to the contrary, except:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.5.1. </span><span>to the extent not permitted by Applicable Law (including Data Protection Laws); and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>12.5.2. </span><span>that it does not affect the liability of either Party to any Data Subject.</span></div>
</div>
<div>
<h2>13. CONFIDENTIAL INFORMATION</h2>
<div class='d-flex pl-6'><span class='mr-5'>13.1. </span><span>Recipient Obligations. The Recipient shall:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.1.1. </span><span>protect and safeguard the confidentiality of all such Confidential Information with at least the same degree of care as the Recipient would protect its own Confidential Information, but in no event with less than a commercially reasonable degree of care;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.1.2. </span><span>protect and safeguard the confidentiality of any part of the Confidential Information that is Protected Data in accordance with the Processing provisions contained herein;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.1.3. </span><span>not use the Discloser's Confidential Information, or permit it to be accessed or used, for any purpose other than the Purpose or any related transactions between the Parties, or otherwise in any manner to the Discloser's detriment, including without limitation, to reverse engineer, disassemble, decompile or design around the other Party's proprietary services, products and/or confidential intellectual property; and </span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.1.4. </span><span>not disclose any such Confidential Information to any person or entity except to the contractors consultants, directors, officers and/or employees of the Recipient, as applicable (each and collectively, the "Authorised Persons"), that:</span></div><div class='d-flex pl-20 ml-10'><span class='mr-5'>a. </span><span>need to know the Confidential Information to assist the Recipient, or act on its behalf, in relation to the Purpose or to exercise its rights under this Agreement;</span></div>
<div class='d-flex pl-20 ml-10'><span class='mr-5'>b. </span><span>are informed by the Recipient of the confidential nature of the Confidential Information; c. are subject to confidentiality duties or obligations to the Recipient that are no less restrictive than the terms and conditions of this Agreement; and</span></div><div class='d-flex pl-20 ml-10'><span class='mr-5'>d. </span><span>are secondarily liable to the Recipient, it being understood that the Parties agree the Recipient is primarily liable and responsible for any breach of this Agreement caused by any Authorised Persons of the Recipient.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>13.2. </span><span>Exclusions. Information is not Confidential Information if any of the following applies (except where such information is Protected Data, in which case the Protected Data will still be Confidential Information and provisions of this Agreement will still apply, for the avoidance of doubt):</span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.2.1. </span><span>it is, or becomes, generally available to the public other than as a direct or indirect result of the information being disclosed by the Recipient in breach of this Agreement (except that any compilation of otherwise public information in a form not publicly known shall still be treated as Confidential Information);</span></div>

<div class='d-flex pl-14'><span class='mr-5'>13.2.2. </span><span>it was, is, or becomes available to the Recipient on a non-confidential basis from a person who, to the Recipient's knowledge, is not under any confidentiality obligation in respect of that information;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.2.3. </span><span>it was lawfully in the possession of the Recipient before the information was disclosed by the Discloser;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.2.4. </span><span>it is developed by or for the Recipient independently of the information disclosed by the Discloser; </span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.2.5. </span><span>it was publicly available to the Recipient on a non-confidential basis prior to disclosure by the Discloser; or </span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.2.6. </span><span>the Parties agree in writing that the information is not confidential.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>13.3. </span><span>Additional Disclosure Limitations relating to Confidential Information. Except as otherwise required in this Agreement or mutually agreed to in writing by the Parties, the Discloser shall not disclose to any person any terms, conditions or other arrangements that are being discussed or negotiated in relation to the Confidential Information or the Purpose.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>13.4. </span><span>Reservation of Rights and No Warranties. The Parties agree:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.4.1. </span><span>Each Party hereby retains its entire right, title and interest, including all Intellectual Property rights, in and to all of its Confidential Information. Any disclosure of such Confidential Information hereunder shall not be construed as an assignment, grant, option, license or other transfer of any such right, title or interest whatsoever to the Recipient; and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.4.2. </span><span>Except as expressly stated in this Agreement, the Discloser does not make any express or implied warranty or representation concerning the Discloser's Confidential Information, including but not limited to the accuracy or completeness of the Confidential Information.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>13.5. </span><span>Return and Destruction of Confidential Information. On termination of this Agreement each Recipient shall within five (5) Business Days of that termination at the Discloser's option:</span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.5.1. </span><span>return, or procure the return, to the Discloser of all documents and materials (including any copies and whether in electronic or other form) containing, reflecting, incorporating or based on the Discloser’s Confidential Information in the Recipient's possession or control;</span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.5.2. </span><span>destroy or erase, or procure the destruction or erasure of, all documents and materials (including any copies and whether in electronic or other form) containing, reflecting, incorporating or based on the Discloser’s Confidential Information in the Recipient's possession or control; and</span></div>
<div class='d-flex pl-14'><span class='mr-5'>13.5.3. </span><span>certify in writing to the other party that it has complied with clauses 13.5.1 and 13.5.2.</span></div>
</div>
<div>
<h2>14. REMEDIES</h2>
Without prejudice to any other rights or remedies that each Party may have, each Party acknowledges and agrees that damages alone would not be an adequate
remedy for any breach of the terms of this Agreement by the other Party. Accordingly, each Party shall be entitled to the remedies of injunctions, specific
performance or other equitable relief for any threatened or actual breach of this Agreement.
</div>
<div>
<h2>15. NOTICES</h2>
<div class='d-flex pl-6'><span class='mr-5'>15.1. </span><span>Any notice or other communication given by a Party under this Agreement shall be via email and sent to he email addresses of the Parties detailed at the bottom of this Agreement, unless either Party communicates in writing to the other Party an email address to be used going forward, in which case that will be the email address to be used for notices.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>15.2. </span><span>Notices hereunder are deemed received on receipt of the email by the receiving Party at the correct address.</span></div>
</div>
<div>
<h2>16. ENTIRE AGREEMENT</h2>
Each of the Parties agrees that the Principal Agreement, this Agreement and any agreement entered into pursuant to it constitutes the entire agreement between the Parties and supersedes all previous agreements, understandings and arrangements between them, whether in writing or oral in respect of its subject matter; and each of the Parties acknowledges that it has not entered into this Agreement or any documents entered into pursuant to it in reliance on, and shall have no remedies in respect of, any representation or warranty that is not expressly set out in the Principal Agreement or this Agreement.
</div>
<div>
<h2>17. GOVERNING LAW AND JURISDICTION</h2>
<div class='d-flex pl-6'><span class='mr-5'>17.1. </span><span>This Agreement and any dispute or claim arising out of, or in connection with it, its subject matter or its formation (including non-contractual disputes or claims) shall be governed by, and construed in accordance with, the laws of England and Wales.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>17.2. </span><span>The Parties irrevocably agree that the courts of Germany shall have exclusive jurisdiction to settle any dispute or claim arising out of, or in connection with, this Agreement, its subject matter or its formation (including non-contractual disputes or claims).</span></div>
</div>
<div>
<h2>18. VARIATION</h2>
No variation of this Agreement shall be valid or effective unless it is in writing, refers to this Agreement and is duly signed or executed by, or on behalf of, each Party.
</div>
<div>
<h2>19. SEVERANCE</h2>
<div class='d-flex pl-6'><span class='mr-5'>19.1. </span><span>If any provision of this Agreement (or part of any provision) is or becomes illegal, invalid or unenforceable, the legality, validity and enforceability of any other provision of this Agreement shall not be affected; and</span></div>
<div class='d-flex pl-6'><span class='mr-5'>19.2. </span><span>the provision or part-provision in question shall apply with such deletions or modifications as may be necessary to make the provision legal, valid and enforceable. In the event of such deletion or modification, the Parties shall
negotiate in good faith in order to agree the terms of a mutually acceptable alternative provision.</span></div>
</div>
<div>
<h2>20. WAIVER</h2>
<div class='d-flex pl-6'><span class='mr-5'>20.1. </span><span>No failure, delay or omission by any Party in exercising any right, power or remedy provided by law or under this Agreement shall operate as a waiver of that right, power or remedy, nor shall it preclude or restrict any future exercise of that or any other right, power or remedy.</span></div>
<div class='d-flex pl-6'><span class='mr-5'>20.2. </span><span>No single or partial exercise of any right, power or remedy provided by law or under
this Agreement shall prevent any future exercise of it or the exercise of any other right, power or remedy.</span></div>
</div>
<div>
<h2>21. COUNTERPARTS</h2>
This Agreement may be signed in any number of separate counterparts, each of which when signed and dated shall be an original, and such counterparts taken together shall constitute one and the same agreement.
</div>
<div>
<h2>22. THIRD PARTY RIGHTS</h2>
Except as expressly provided for in this Agreement a person who is not a Party to this Agreement shall not have rights to enforce any of the provisions of this Agreement, where the source of such rights are any law or rule that gives third parties mentioned in an agreement such rights, including with respect to the UK, the Contracts (Rights of Third Parties) Act 1999.

<b>SCHEDULE - DATA PROCESSING DETAILS</b>
Subject-matter of processing: is the Candidate’s Personal Data.
Duration of the processing: Until the completion of the Services with respect to the candidate to which the personal data relates.
Nature and purpose of the processing: perform the obligations of the Supplier under the Website Terms, <a rel='noopener noreferrer' target='_blank' href='https://sfeeda.com/terms-of-service'>https://sfeeda.com/terms-of-service</a>.
Type of Personal Data: Name; and email address. Categories of Data Subjects: recruitment candidates, of the Customer.
Processing Instructions: process name and address of the candidate to enable analysis of code of the candidate and communication with the candidate which is presented with name and address for identification purposes.

<b>SCHEDULE - TECHNICAL AND ORGANISATIONAL MEASURES</b>
In accordance with the Data Protection Laws, taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of the processing of the Protected Data to be carried out under or in connection with this Agreement, as well as the risks of varying likelihood and severity for the rights and freedoms of natural persons and the risks that are presented by the processing, especially from accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to the Protected Data transmitted, stored or otherwise processed, the Supplier shall implement appropriate technical and organisational security measures appropriate to the risk, including as appropriate those matters mentioned in Articles 32(1)(a) to 32(1)(d) (inclusive) of the GDPR.
</div>
</pre>`;
