import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { StepKeys } from '../../../typings/enums';

interface Props {
  timestamp: string | undefined | null;
  title: string;
  stepKey: StepKeys;
  storyPoints?: number;
  candidateId: string;
}

export const Step: React.FC<Props> = ({
  title,
  timestamp,
  stepKey,
  storyPoints,
  candidateId
}) => {
  return (
    <li
      className={cn('step-progressbar__item', {
        'step-progressbar__item--complete':
          stepKey === StepKeys.sent || timestamp
      })}
      data-testid={`${stepKey}-${candidateId}`}
    >
      <div className='text-sm'>{title}</div>
      {timestamp && stepKey !== StepKeys.tickets ? (
        <div className='text-xs text-gray-700'>
          {moment.utc(timestamp).fromNow()}
        </div>
      ) : null}
      {timestamp && stepKey === StepKeys.tickets ? (
        <div className='text-gray-700 text-xs'>
          <span>{moment.utc(timestamp).fromNow()} &#9679; </span>
          <span>{storyPoints}</span> SP
        </div>
      ) : null}
    </li>
  );
};
