import React from 'react';
import { LikesBlock } from '../../shared/components/LikesBlock';
import { CommentsCount } from '../../shared/components/CommentsCount';
import { FeedbackAuthor } from './FeedbackAuthor';
import { ISolution, ILike, INote } from '../../typings/interfaces';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface Props {
  candidate: ISolution;
  votes: ILike[] | undefined;
  curCandidateComments: INote[] | undefined;
}

export const CandidateCardFooter: React.FC<Props> = ({
  candidate,
  votes,
  curCandidateComments,
}) => {
  return (
    <div className='d-flex align-items-center mr-4'>
      {candidate?.feedback && (
        <FeedbackAuthor
          feedback={candidate.feedback}
          className={!candidate.feedback.is_sent ? 'mr-4' : ''}
        />
      )}{' '}
      {candidate.feedback?.is_sent && (
        <div className='mr-4'>
          | Informed {moment.utc(candidate.feedback.date_sent).fromNow()}
        </div>
      )}
      {curCandidateComments && curCandidateComments.length > 0 && (
        <Link
          to={`/position/${candidate.position.uuid}/candidate/${candidate.candidate.uuid}/code`}
        >
          <CommentsCount
            qty={curCandidateComments.length}
            size='sm'
            className='mr-4'
          />
        </Link>
      )}
      {votes && <LikesBlock votes={votes} />}
    </div>
  );
};
