import React from 'react';
import moment from 'moment';
import { deleteCookie } from '../../../util/deleteCookie';
import { Button } from 'react-bootstrap';
import { refuseCookies } from '../../../actions/policiesActions';
import { showAlert } from '../../../actions/alertActions';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { AlertType } from '../../../typings/enums';
import { destroyGA } from '../../../util/destroyGA';
import {
  isCookiesAgreedSelector,
  cookiesConsentTimestampSelector,
} from '../../../selectors';
import { capitalize } from '../../../util/capitalize';
import { getEnv } from '../../../util/getEnv';

export const CookiePolicy = () => {
  const dispatch = useDispatch();
  const isCookiesAgreed = useShallowEqualSelector(isCookiesAgreedSelector);
  const cookiesConsentTimestamp = useShallowEqualSelector(
    cookiesConsentTimestampSelector
  );

  return (
    <div className='bg-white w-100 pb-10'>
      <div className='px-md-0 d-flex flex-column '>
        <div className='px-4 px-lg-0 w-100' style={{ maxWidth: '650px' }}>
          <h1 className='font-weight-bold text-2xl'>Cookie Policy</h1>
          {isCookiesAgreed && cookiesConsentTimestamp && (
            <div className='mb-1'>
              <span className='text-gray-600'>Accepted on</span>{' '}
              <span>
                {moment(new Date(cookiesConsentTimestamp)).format(
                  'DD MMMM YYYY, HH:mm'
                )}
              </span>
            </div>
          )}

          <div className='mb-4'>
            This is the Cookie Policy for Sfeeda, accessible from {SfeedaLink}
          </div>
          {BLOCKS.map((block) => (
            <div key={block.title} className='mb-4'>
              <h2 className='text-lg mb-0 font-weight-bold mb-1'>
                {block.title}
              </h2>
              {block.content}
            </div>
          ))}
          {isCookiesAgreed && (
            <Button
              variant='outline-dark'
              onClick={() => {
                const env = capitalize(getEnv());
                deleteCookie(`cookiePolicyConsent${env}`);
                deleteCookie(`cookiePolicyConsentTimestamp${env}`);
                dispatch(refuseCookies());
                dispatch(
                  showAlert(
                    {
                      type: AlertType.cookies,
                      message: '',
                    },
                    undefined,
                    false
                  )
                );

                destroyGA(window, document);
              }}
            >
              Refuse Policy
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const SfeedaLink = (
  <a href={'/'} target='_blank' rel='noopener noreferrer'>
    {window.location.origin + '/'}
  </a>
);

const BLOCKS = [
  {
    title: 'What Are Cookies',
    content: (
      <>
        <div className='mb-2'>
          As is common practice with almost all professional websites this site
          uses cookies, which are tiny files that are downloaded to your
          computer, to improve your experience. This page describes what
          information they gather, how we use it and why we sometimes need to
          store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or 'break'
          certain elements of the sites functionality.
        </div>
        <div>
          For more general information on cookies, please read{' '}
          <a
            href='https://en.wikipedia.org/wiki/HTTP_cookie'
            target='_blank'
            rel='noopener noreferrer'
          >
            "What Are Cookies"
          </a>
          .
        </div>
      </>
    ),
  },
  {
    title: 'How We Use Cookies',
    content: (
      <div>
        We use cookies for a variety of reasons detailed below. Unfortunately in
        most cases there are no industry standard options for disabling cookies
        without completely disabling the functionality and features they add to
        this site. It is recommended that you leave on all cookies if you are
        not sure whether you need them or not in case they are used to provide a
        service that you use.
      </div>
    ),
  },
  {
    title: 'Disabling Cookies',
    content: (
      <div>
        You can prevent the setting of cookies by adjusting the settings on your
        browser (see your browser Help for how to do this). Be aware that
        disabling cookies will affect the functionality of this and many other
        websites that you visit. Disabling cookies will usually result in also
        disabling certain functionality and features of the this site. Therefore
        it is recommended that you do not disable cookies.
      </div>
    ),
  },
  {
    title: 'The Cookies We Set',
    content: (
      <div>
        Login related cookies
        <br />
        We use cookies when you are logged in so that we can remember this fact.
        This prevents you from having to log in every single time you visit a
        new page. These cookies are typically removed or cleared when you log
        out to ensure that you can only access restricted features and areas
        when logged in.
      </div>
    ),
  },
  {
    title: 'Third Party Cookies',
    content: (
      <>
        <div className='mb-2'>
          In some special cases we also use cookies provided by trusted third
          parties. The following section details which third party cookies you
          might encounter through this site.
        </div>
        <div className='mb-2'>
          This site uses Google Analytics which is one of the most widespread
          and trusted analytics solution on the web for helping us to understand
          how you use the site and ways that we can improve your experience.
          These cookies may track things such as how long you spend on the site
          and the pages that you visit so we can continue to produce engaging
          content.
        </div>
        <div>
          For more information on Google Analytics cookies, see the official
          Google Analytics page.
        </div>
      </>
    ),
  },
  {
    title: 'More Information',
    content: (
      <>
        <div className='mb-2'>
          Hopefully that has clarified things for you and as was previously
          mentioned if there is something that you aren't sure whether you need
          or not it's usually safer to leave cookies enabled in case it does
          interact with one of the features you use on our site. This Cookies
          Policy was created with the help of the{' '}
          <a
            href='https://www.cookiepolicygenerator.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Cookies Policy Template Generator
          </a>{' '}
          and the{' '}
          <a
            href='https://www.cookiepolicygenerator.com/privacy-policy-generator/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy Template Generator
          </a>
          .
        </div>
        <div>
          However if you are still looking for more information then you can
          contact us through {SfeedaLink}
        </div>
      </>
    ),
  },
];
