import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { TableRows } from './TableRows';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { getTicketsByChallengeId } from '../../../actions/ticketsActions';
import { Loader } from '../../../shared/components/Loader';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  challengeId: number | undefined;
  selectedTickets: number[];
  ticketsMandatory: number[];
}

export const TaskTable: React.FC<Props> = ({
  selectedTickets,
  challengeId,
  ticketsMandatory,
}) => {
  const { mandatoryTickets } = useFlags();
  const dispatch = useDispatch();

  const tickets = useShallowEqualSelector(
    (state) => state.ticketsReducer.tickets
  );
  const errorTickets = useShallowEqualSelector(
    (state) => state.ticketsReducer.errorTickets
  );
  const pendingTickets = useShallowEqualSelector(
    (state) => state.ticketsReducer.pendingTickets
  );

  useEffect(() => {
    if (challengeId) {
      dispatch(getTicketsByChallengeId(challengeId));
    }
  }, [dispatch, challengeId]);

  if (errorTickets) {
    return <div>{errorTickets}</div>;
  }

  if (pendingTickets) {
    return <Loader />;
  }

  if (!tickets) {
    return null;
  }

  return (
    <>
      <div className='mx-0 p-6 border rounded-lg border-gray-600 mb-4'>
        <Table responsive>
          <thead>
            <tr>
              <th></th>
              <th>Ticket</th>
              <th>Title</th>
              <th>Type</th>
              <th className='pl-7'>Skills</th>
              <th className='text-right'>Story Points</th>
              {mandatoryTickets ? (
                <th className='text-right'>Mandatory</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            <TableRows tickets={tickets} selectedTickets={selectedTickets} />
          </tbody>
        </Table>
      </div>
    </>
  );
};
