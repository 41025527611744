import { prepareError } from './prepareError';
import { ApiType } from '../typings/types';
import { store } from '../store';
export const errorText = 'Sorry, something went wrong. Please try again.';

export const fetchWithToken = async <B>(
  api: ApiType,
  url: string,
  method = 'get',
  body: B | undefined = undefined,
  headers = {}
): Promise<any> => {
  const { accessToken, refreshToken } = store.getState().userReducer;
  let token;
  if (url === '/refresh/') {
    token = refreshToken;
  } else {
    token = accessToken;
  }

  let res;
  try {
    let headersWithAuth = {};
    if (token) {
      headersWithAuth = {
        Authorization: `Bearer ${token}`,
      };
    }
    res = await fetch(`${window.OPTIONS.API_URL}/${api + url}`, {
      method: method.toUpperCase(),
      body: typeof body === 'object' ? JSON.stringify(body) : undefined,
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        ...headers,
        ...headersWithAuth,
      },
    });
    if (!res.ok) {
      const err = await res.json();
      return {
        data: undefined,
        error: prepareError(err),
        status: res.status,
      };
    }
    let data = {};
    try {
      data = await res.json();
    } catch (err) {}
    return { data, error: undefined, status: res.status };
  } catch (err) {
    return { data: undefined, error: errorText, status: res?.status };
  }
};
