import React, { useCallback } from 'react';
import { isExpired, isExpiring } from '../../../util/filterFunctions';
import { ISolution } from '../../../typings/interfaces';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setCurCandidate } from '../../../actions/candidatesActions';
import { Actions, UserRoles } from '../../../typings/enums';
import { showModal } from '../../../actions/modalActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { userRoleSelector } from '../../../selectors';

interface Props {
  candidate: ISolution;
}

export const Btns: React.FC<Props> = ({ candidate }) => {
  const dispatch = useDispatch();
  const userRole = useShallowEqualSelector(userRoleSelector);

  const handleProlong = useCallback(
    (candidate: ISolution) => {
      dispatch(setCurCandidate(candidate));
      dispatch(showModal({ type: Actions.PROLONG, title: '' }));
    },
    [dispatch]
  );

  const handleArchive = useCallback(
    (candidate: ISolution) => {
      dispatch(showModal({ type: Actions.ARCHIVE, title: '' }));
      dispatch(setCurCandidate(candidate));
    },
    [dispatch]
  );

  const handleReject = useCallback(
    async (candidate: ISolution) => {
      dispatch(showModal({ type: Actions.rejectExpired, title: '' }));
      dispatch(setCurCandidate(candidate));
    },
    [dispatch]
  );

  return (
    <div className='d-flex justify-content-start'>
      {userRole === UserRoles.REVIEWER ? (
        <>
          {isExpired(candidate) && (
            <Button
              variant='outline-dark'
              className='mr-2 rounded-lg px-4'
              onClick={() => handleReject(candidate)}
              data-testid={`btn-reject-${candidate.candidate.uuid}`}
            >
              Reject
            </Button>
          )}
        </>
      ) : (
        <>
          {isExpired(candidate) && (
            <Button
              variant='outline-dark'
              className='mr-2 rounded-lg py-1'
              onClick={() => handleArchive(candidate)}
              data-testid={`btn-archive-${candidate.candidate.uuid}`}
            >
              Archive
            </Button>
          )}
        </>
      )}
      {(isExpired(candidate) || isExpiring(candidate)) && (
        <Button
          variant='outline-dark'
          onClick={() => handleProlong(candidate)}
          className='rounded-lg py-1'
          data-testid={`btn-prolong-${candidate.candidate.uuid}`}
        >
          Prolong for 7d
        </Button>
      )}
    </div>
  );
};
