import {
  GitActionsConstants,
  gitActionsTypes,
  GitContentState,
} from '../actions/types';

const initialState: GitContentState = {
  filesDiff: undefined,
  pendingFilesDiff: false,
  errorFilesDiff: '',
  filesChangedLoaded: false,
  repoInfo: undefined,
  pendingRepoInfo: false,
  errorRepoInfo: '',
  gitContent: null,
  gitFile: undefined,
  gitBranches: [],
  pendingGitBranches: false,
};

export const gitReducer = (
  state = initialState,
  action: gitActionsTypes
): GitContentState => {
  switch (action.type) {
    case GitActionsConstants.PENDING_GIT_BRANCHES:
      return {
        ...state,
        pendingGitBranches: true,
      };
    case GitActionsConstants.CLEAR_GIT_BRANCHES:
      return {
        ...state,
        gitBranches: [],
        pendingGitBranches: false,
      };
    case GitActionsConstants.CLEAR_GIT_CONTENT:
      return {
        ...state,
        gitContent: null,
      };
    case GitActionsConstants.CLEAR_GIT_FILE:
      return {
        ...state,
        gitFile: null,
      };
    case GitActionsConstants.GET_GIT_CONTENT:
      return {
        ...state,
        gitContent: action.payload,
      };
    case GitActionsConstants.GET_GIT_BRANCHES:
      return {
        ...state,
        gitBranches: action.payload,
        pendingGitBranches: action.pendingGitBranches,
      };
    case GitActionsConstants.GET_GIT_FILE:
      return {
        ...state,
        gitFile: action.payload,
      };
    case GitActionsConstants.GET_FILES_DIFF:
      return {
        ...state,
        filesDiff: action.payload,
        errorFilesDiff: '',
        pendingFilesDiff: false,
      };
    case GitActionsConstants.PENDING_FILES_DIFF:
      return {
        ...state,
        pendingFilesDiff: true,
      };
    case GitActionsConstants.ERROR_FILES_DIFF:
      return {
        ...state,
        errorFilesDiff: action.payload,
        pendingFilesDiff: true,
      };
    case GitActionsConstants.GET_REPO_INFO:
      return {
        ...state,
        repoInfo: action.payload,
        errorRepoInfo: '',
        pendingRepoInfo: false,
      };
    case GitActionsConstants.PENDING_REPO_INFO:
      return {
        ...state,
        pendingRepoInfo: true,
      };
    case GitActionsConstants.ERROR_REPO_INFO:
      return {
        ...state,
        errorRepoInfo: action.payload,
        pendingRepoInfo: true,
      };
    default:
      return state;
  }
};
