import React from 'react';
import cn from 'classnames';

export type Props = {
  condition?: any;
  children: React.ReactNode;
  classes?: string[];
};
export const StageContainer: React.FC<Props> = ({
  condition = {},
  children,
  classes,
}) => {
  return (
    <div
      className={cn(
        'flex-grow-1',
        'rounded',
        'bg-content',
        'mb-4',
        'px-4',
        'pt-1',
        'border',
        'border-top-4',
        'border-bottom-0',
        'border-left-0',
        'border-right-0',
        'border-4',
        classes,
        { ...condition }
      )}
      style={{ minHeight: '304px', height: '100%' }}
    >
      {children}
    </div>
  );
};
