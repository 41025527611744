import React from 'react';
import { Stage } from '../Stage/Stage';
import { IPosition } from '../../../typings/interfaces';
import { Container, Row, Col } from 'react-bootstrap';
import cn from 'classnames';

interface Props {
  position: IPosition;
}

export const PositionStages: React.FC<Props> = ({ position }) => {
  return (
    <Container className='px-0 mx-0'>
      <Row noGutters className='flex-column flex-xl-row'>
        {STAGES.map((stage: any) => (
          <Col
            className={cn({ 'mr-lg-3': stage.type !== 'rejected' })}
            key={stage.type}
          >
            <Stage
              position={position}
              title={stage.title}
              type={stage.type}
              modalTitle={stage.modalTitle || ''}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const STAGES = [
  {
    title: 'Invites',
    type: 'task_sent',
    modalTitle: 'Candidates working on task',
  },
  {
    title: 'Candidates working\n on the task',
    type: 'working',
    modalTitle: 'Candidates working on task',
  },
  {
    title: 'To review',
    type: 'submitted',
  },
  {
    title: 'Accepted',
    type: 'accepted',
  },
  {
    title: 'Rejected',
    type: 'rejected',
  },
];
