import React, { useEffect, useCallback, useState } from 'react';
import { CardComponent } from '../CardComponent';
import {
  getNotifications,
  clearNotifications,
} from '../../../actions/notificationsActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useDispatch } from 'react-redux';
import { Loader } from '../Loader';
import { errorText } from '../../../util/fetchWithToken';
import { KeyActivityList } from './KeyActivityList';
import { Link } from 'react-router-dom';
import { clearCandidates } from '../../../actions/candidatesActions';
import {
  candidatesSelector,
  pendingCandidatesSelector,
  postionsIdsSelector,
  userRoleSelector,
  errorCandidatesSelector,
  notificationsSelector,
  pendingNotificationsSelector,
  errorNotificationsSelector,
} from '../../../selectors';

export const KeyActivitiesWidget = () => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState<boolean>(false);

  const candidates = useShallowEqualSelector(candidatesSelector);

  const positionsIds = useShallowEqualSelector((state) =>
    postionsIdsSelector(state)
  );

  const pendingCandidates = useShallowEqualSelector(pendingCandidatesSelector);
  const errorCandidates = useShallowEqualSelector(errorCandidatesSelector);

  const notifications = useShallowEqualSelector(notificationsSelector);
  const pendingNotifications = useShallowEqualSelector(
    pendingNotificationsSelector
  );
  const errorNotifications = useShallowEqualSelector(
    errorNotificationsSelector
  );

  const userRole = useShallowEqualSelector(userRoleSelector);

  useEffect(() => {
    positionsIds &&
      dispatch(
        getNotifications(
          {
            rel_position_pk: positionsIds,
          },
          userRole!
        )
      );
  }, [dispatch, positionsIds, userRole]);

  useEffect(() => {
    return () => {
      dispatch(clearNotifications());
      dispatch(clearCandidates());
    };
  }, [dispatch]);

  const loadMoreNotifications = useCallback(async () => {
    setIsPending(true);
    await dispatch(
      getNotifications(
        {
          rel_position_pk: positionsIds!,
          page: notifications!.page + 1,
        },
        userRole!
      )
    );
    setIsPending(false);
  }, [notifications, positionsIds, userRole, dispatch]);

  return (
    <CardComponent className='px-0'>
      <div
        className='d-flex flex-column w-100'
        style={{
          minHeight: '364px',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <div className='d-flex align-items-center justify-content-between text-sm w-100 px-3'>
          <div>
            <span className='font-weight-bold'>Key activities</span>{' '}
            {!pendingNotifications && pendingCandidates.length === 0 && (
              <span className='text-gray-600 text-xs'>
                {notifications?.total}
              </span>
            )}
          </div>
          {notifications && notifications.total > 20 && (
            <Link to='/key_activities'>Expand</Link>
          )}
        </div>
        <div
          className='d-flex flex-grow-1 flex-column'
          style={{
            overflowY: 'auto',
          }}
        >
          {(pendingNotifications || pendingCandidates.length > 0) && (
            <div className='m-auto text-center'>
              <Loader />
            </div>
          )}
          {(errorNotifications || errorCandidates.length > 0) && (
            <div className='text-center mb-2'>{errorText}</div>
          )}
          {pendingCandidates.length === 0 && (
            <KeyActivityList
              notifications={notifications}
              candidates={candidates}
              loadMoreNotifications={loadMoreNotifications}
              isPending={isPending}
            />
          )}
        </div>
      </div>
    </CardComponent>
  );
};
