import React from 'react';
import cn from 'classnames';

interface Props {
  children: React.ReactNode;
  condition?: any;
}

export const RadioBtn: React.FC<Props> = ({ children, condition }) => {
  return (
    <label
      className={cn(
        'btn',
        'btn-outline-dark',
        'px-7',
        'border-gray-600',
        'leading-none',
        {
          ...condition,
        }
      )}
    >
      {children}
    </label>
  );
};
