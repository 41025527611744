import React from 'react';
import './CustomSwitch.scss';

interface Props {
  isChecked: boolean;
  onChange: () => void;
  name: string;
  label?: string;
  className?: string;
}

export const CustomSwitch: React.FC<Props> = ({
  isChecked,
  onChange,
  name,
  label,
}) => {
  return (
    <div className='custom-switch-wrap'>
      <label className='el-switch'>
        <input
          type='checkbox'
          name={name}
          checked={isChecked}
          onChange={onChange}
        />
        <span className='el-switch-style' />
      </label>
      {label && <div>{label}</div>}
    </div>
  );
};
