import {
  RecruitersActionsConstants,
  RecruitersActionTypes,
  RecruitersState
} from '../actions/types';

const initialState: RecruitersState = {
  recruiters: undefined,
  pendingRecruiters: undefined,
  errorRecruiters: ''
};

export const recruitersReducer = (
  state = initialState,
  action: RecruitersActionTypes
): RecruitersState => {
  switch (action.type) {
    case RecruitersActionsConstants.GET_RECRUITERS:
      return {
        ...state,
        recruiters: {
          ...state.recruiters,
          [action.payload.positionId]: action.payload.recruiters
        },
        pendingRecruiters: {
          ...state.pendingRecruiters,
          [action.payload.positionId]: false
        },
        errorRecruiters: ''
      };
    case RecruitersActionsConstants.PENDING_RECRUITERS:
      return {
        ...state,
        pendingRecruiters: {
          ...state.pendingRecruiters,
          [action.payload.positionId]: true
        }
      };
    case RecruitersActionsConstants.ERROR_RECRUITERS:
      return {
        ...state,
        pendingRecruiters: undefined,
        errorRecruiters: action.payload
      };
    case RecruitersActionsConstants.DELETE_RECRUITER_BY_POSITION:
      return {
        ...state,
        recruiters: {
          ...state.recruiters,
          [action.payload.positionId]:
            state.recruiters?.[action.payload.positionId]?.filter(
              recruiter =>
                recruiter.recruiter.uuid !== action.payload.recruiterId
            ) || []
        }
      };

    default:
      return state;
  }
};
