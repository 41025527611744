import React, { useEffect, FC } from 'react';
import { Card } from 'react-bootstrap';
import { ReactComponent as BookIcon } from '../../../../assets/bookIcon.svg';
import { getGitFile, clearGitFile } from '../../../../actions/gitActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
const ReactMarkdown = require('react-markdown');

export const ReadMe: FC<any> = ({ gitContent, positionId }) => {
  const dispatch = useDispatch();

  const isReadMeFile =
    gitContent &&
    gitContent.files.find((file: any) => {
      return file.relativePath.match(/readme.md/gi);
    });

  const readMeFile = useSelector(
    (state: RootState) => state.gitReducer.gitFile
  );

  useEffect(() => {
    if (isReadMeFile) {
      dispatch(getGitFile(isReadMeFile.absolutePath, positionId));
    }
    return () => {
      dispatch(clearGitFile());
    };
  }, [isReadMeFile, dispatch, positionId]);

  return (
    <>
      {readMeFile && (
        <Card>
          <Card.Header className='py-2 px-4'>
            <div className='d-flex align-items-center'>
              <BookIcon className='mr-3' />
              {readMeFile.filePath}
            </div>
          </Card.Header>
          <Card.Body className='px-4'>
            <ReactMarkdown source={readMeFile.fileContent} />
          </Card.Body>
        </Card>
      )}
    </>
  );
};
