import React from 'react';
import { Logo } from '../../components/Logo';
import { SignInBtn } from '../../components/SignInBtn';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import './background.css';
import { Link } from 'react-router-dom';
import { GreenBadge } from './GreenBadge';
import { Button } from 'react-bootstrap';
import { userSelector } from '../../../selectors';

interface Props {
  handleLinkClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export const Header: React.FC<Props> = ({ handleLinkClick }) => {
  const user = useShallowEqualSelector(userSelector);

  return (
    <div className='pt-6 pb-16 pb-md-8 bg-blue-900 min-vh-50'>
      <div className='container'>
        <div className='hero'>
          <div className='d-flex justify-content-between mb-lg-10'>
            <Link to='/'>
              <Logo height={32} />
            </Link>
            <SignInBtn
              className='border hover:border-blue-500 px-4'
              user={user}
            />
          </div>
        </div>
        <div className='text-gray-100 text-center text-md-left hero-text-container'>
          <div className='pt-md-16 hero-text'>
            <GreenBadge />
            <div className='text-4xl md:text-4-9xl leading-thin my-4'>
              Shortlist software developers for tech interviews
            </div>
            <div className='mb-4 lg:text-lg'>
              Screen a wider range of candidates based on real-life coding
              skills and not resumes
            </div>
            <div className='d-lg-flex align-items-lg-center'>
              <Button
                variant='warning'
                className='d-inline-flex align-items-center font-weight-bold mb-4 mb-lg-0 mr-lg-4 btn btn-warning h-12'
                style={{ color: '#424242' }}
                onClick={handleLinkClick}
                data-scrollto='free-trial-form'
              >
                Request a Free 2 Months Trial
              </Button>
              <div className='lg:text-xl'>and get 10% life-time discount</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
