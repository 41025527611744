import React, { useState } from 'react';
import { TextField } from '../TextField';
import { ReactComponent as EyeIcon } from '../../../assets/eye.svg';
import { ReactComponent as HideEyeIcon } from '../../../assets/hide.svg';
import { FormikErrors, FormikTouched } from 'formik';
import './PasswordField.scss';

interface Props {
  isError:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  name: string;
  placeholder?: string;
  type?: string;
  style?: React.CSSProperties | undefined;
  id?: string;
  size?: 'lg' | 'sm' | 'md';
  tabIndex?: number;
}

export const PasswordField: React.FC<Props> = ({
  isError,
  isTouched,
  placeholder,
  id,
  size,
  tabIndex,
  name,
}) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  return (
    <TextField
      name={name}
      autoComplete='current-password'
      type={isPasswordShown ? 'text' : 'password'}
      isError={isError}
      isTouched={isTouched}
      id={id}
      placeholder={placeholder}
      size={size}
      style={{ backgroundColor: '#F8FBFF' }}
      tabIndex={tabIndex}
      Btn={
        <div
          className='position-absolute bg-transparent border-0'
          style={{
            top: '50%',
            right: '5px',
            transform: 'translate3d(0, -50%, 0)',
          }}
          onClick={() => {
            setIsPasswordShown(!isPasswordShown);
          }}
        >
          {isPasswordShown ? (
            <HideEyeIcon
              style={{ height: '32px', width: '32px' }}
              className='signin-hide-eye'
            />
          ) : (
            <EyeIcon
              style={{ height: '32px', width: '32px' }}
              className='signin-eye'
            />
          )}
        </div>
      }
    />
  );
};
