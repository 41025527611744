import {
  UserActionsTypesConstants,
  AuthState,
  UserActionTypes,
} from '../actions/types';

const initialState: AuthState = {
  accessToken: undefined,
  refreshToken: undefined,
  user: null,
  intialUserLoaded: false,
  pendingUserFetch: false,
  userFetchError: '',
  isIdentified: false,
  me: undefined,
  errorSignUp: '',
  pendingMe: false,
  errorMe: '',
};

export const userReducer = (
  state = initialState,
  action: UserActionTypes
): AuthState => {
  switch (action.type) {
    case UserActionsTypesConstants.SAVE_TOKENS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case UserActionsTypesConstants.SIGNIN_USER:
      return {
        ...state,
        user: action.payload,
        intialUserLoaded: true,
        pendingUserFetch: false,
        userFetchError: '',
      };
    case UserActionsTypesConstants.SIGNOUT_USER:
      return {
        ...state,
        accessToken: undefined,
        refreshToken: undefined,
        user: null,
        isIdentified: false,
        me: undefined,
      };
    case UserActionsTypesConstants.PENDING_FETCH_USER:
      return {
        ...state,
        pendingUserFetch: true,
      };
    case UserActionsTypesConstants.ERROR_FETCH_USER:
      return {
        ...state,
        pendingUserFetch: false,
        userFetchError: action.payload,
      };
    case UserActionsTypesConstants.IDENTIFY_USER:
      return { ...state, isIdentified: true };
    case UserActionsTypesConstants.GET_USER_INFO:
      return { ...state, me: action.payload, pendingMe: false, errorMe: '' };
    case UserActionsTypesConstants.ERROR_USER_INFO:
      return {
        ...state,
        pendingMe: false,
        errorMe: action.payload,
      };
    case UserActionsTypesConstants.PENDING_USER_INFO:
      return {
        ...state,
        pendingMe: true,
      };
    case UserActionsTypesConstants.ERROR_SIGN_UP:
      return { ...state, errorSignUp: action.payload };
    case UserActionsTypesConstants.CLEAR_ERROR_SIGN_UP:
      return { ...state, errorSignUp: '' };
    default:
      return state;
  }
};
