import React, { useState } from 'react';
import { Modal, Button, Form as BootstrapForm } from 'react-bootstrap';
import { Formik, Field, FormikErrors, FormikValues, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { hideModal } from '../../../actions/modalActions';
import { sendInvite, clearInviteError } from '../../../actions/invitesActions';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import { errorText } from '../../../util/fetchWithToken';
import { generateInviteLink } from '../../../util/invitesHelpers';
import cn from 'classnames';
import { UserRoles } from '../../../typings/enums';
import { NewCopyLinkModal } from './NewCopyLinkModal';

const initialValues: FormValues = {
  email: '',
};

interface FormValues {
  email: string;
}

export const InviteCandidate = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [invitationLink, setInvitationLink] = useState('');
  const [isInvitationSent, setInvitationSent] = useState(false);

  const curPosition = useShallowEqualSelector(
    (state) => state.positionsReducer.curPosition
  );

  const errorInvite = useShallowEqualSelector(
    (state) => state.invitesReducer.errorSendInvite
  );

  return (
    <>
      <Modal.Header
        className={cn(
          'border-bottom-0 pt-2 pb-0 d-flex justify-content-between align-items-center'
        )}
      >
        {!isInvitationSent ? (
          <Modal.Title
            id={'invite-candidate-modal'}
            className='text-sm leading-tight font-weight-bold'
          >
            Enter email to generate a personal link
          </Modal.Title>
        ) : (
          <Modal.Title className='text-sm leading-tight font-weight-bold'>
            Copy the link and send to a candidate
          </Modal.Title>
        )}
        <button
          className={cn('bg-transparent border-0 p-0 d-block')}
          onClick={() => {
            dispatch(clearInviteError());
            dispatch(hideModal());
          }}
        >
          <CloseIcon className='icon--16' />
        </button>
      </Modal.Header>
      <Modal.Body className='px-0'>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(
              sendInvite(
                {
                  email: values.email,
                  role: 'candidate',
                  data: {
                    position_id: curPosition?.uuid,
                  },
                  duration: curPosition?.task.duration,
                },
                (response) => {
                  setSubmitting(false);
                  if (response.data) {
                    setInvitationLink(
                      generateInviteLink(
                        response.data.uuid,
                        UserRoles.CANDIDATE
                      )
                    );
                    setInvitationSent(true);
                  }
                  setEmail(values.email);
                }
              )
            );
          }}
          validate={(values) => {
            const errors: FormikErrors<FormikValues> = {};

            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }

            return errors;
          }}
        >
          {({ errors, touched, values, isSubmitting }) => (
            <Modal.Body>
              {!isInvitationSent ? (
                <Form className='items-center flex flex-col'>
                  {errorInvite ? (
                    <div className='text-danger text-sm'>
                      {typeof errorInvite === 'string'
                        ? errorInvite
                        : errorText}
                    </div>
                  ) : null}
                  <BootstrapForm.Label className='text-sm mb-1'>
                    Email:
                  </BootstrapForm.Label>
                  <div className='d-flex'>
                    <div className='flex-grow-1 mr-3'>
                      <Field
                        name='email'
                        type='text'
                        className={
                          errors.email && touched.email && errors.email
                            ? 'form-control border-danger bg-gray-500'
                            : 'form-control bg-gray-500'
                        }
                        id='invite_email'
                        data-testid='invite_email'
                      />
                      <div
                        className={
                          errors.email && touched.email && errors.email
                            ? 'text-danger text-xs my-0'
                            : ''
                        }
                      >
                        {errors.email && touched.email && errors.email}
                      </div>
                    </div>
                    <div>
                      <Button
                        variant='warning'
                        disabled={isSubmitting || !values.email}
                        className='text-sm'
                        type='submit'
                        id='invitation-submit'
                        style={{ minHeight: '38px' }}
                      >
                        {isSubmitting ? 'Sending...' : 'Generate'}
                      </Button>
                    </div>
                  </div>
                </Form>
              ) : (
                <NewCopyLinkModal
                  invitationLink={invitationLink}
                  email={email}
                />
              )}
            </Modal.Body>
          )}
        </Formik>
      </Modal.Body>
    </>
  );
};
