import React from 'react';
import { ISolution } from '../../../typings/interfaces';
import { ReactComponent as Clip } from '../../../assets/clip.svg';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { InformCandidateBtn } from './InformCandidateBtn';
import { attachedFeedbacksSelector } from '../../../selectors';

interface Props {
  candidate: ISolution;
}

export const InformCandidateBlock: React.FC<Props> = ({ candidate }) => {
  const attachedFeedbacks = useShallowEqualSelector(attachedFeedbacksSelector);

  const isFeedbackAttached = attachedFeedbacks.includes(
    candidate.candidate.uuid
  );

  return (
    <div className='flex-shrink-0'>
      <InformCandidateBtn candidate={candidate} />
      {candidate.feedback && candidate.feedback.body && isFeedbackAttached ? (
        <div className='mb-0 text-gray-600 d-flex align-items-center justify-content-center'>
          <div
            className='d-flex align-items-center justify-content-center bg-gray-100 rounded-circle px-1 mr-2'
            style={{ width: '16px', height: '16px' }}
          >
            <Clip />
          </div>
          Feedback
        </div>
      ) : (
        <div className='mb-0 text-xs text-center text-gray-600'>
          No feedback attached
        </div>
      )}
    </div>
  );
};
