import React from 'react';
import { PipelineItem } from './PipelineItem';
import { StageContainer } from './StageContainer';
import { Loader } from '../../shared/components/Loader';
import { Stages } from '../../typings/enums';
import { Pipeline } from '../../typings/interfaces';

interface Props {
  pipeline: Pipeline | undefined;
  positionId: string;
  pendingCandidates: string[];
}

export const Completed: React.FC<Props> = ({
  pipeline,
  positionId,
  pendingCandidates,
}) => {
  return (
    <StageContainer
      condition={{
        'opacity-50': !pipeline?.processed && !pipeline?.archived,
      }}
      classes={['border-green-600']}
    >
      {pendingCandidates ? (
        <>
          <div className='text-sm font-weight-bold mb-4'>Completed</div>

          {!pendingCandidates.includes(positionId) && (
            <>
              <div className='max-w-173'>
                <PipelineItem
                  type={Stages.PROCESSED}
                  title='Processed to the next step'
                  positionId={positionId}
                  length={pipeline?.processed}
                />
              </div>
              <div className='max-w-173'>
                <PipelineItem
                  type={Stages.ARCHIVED}
                  title={
                    Stages.ARCHIVED[0].toUpperCase() + Stages.ARCHIVED.slice(1)
                  }
                  positionId={positionId}
                  length={pipeline?.archived}
                />
              </div>
            </>
          )}
          {pendingCandidates.includes(positionId) ? <Loader /> : null}
        </>
      ) : null}
    </StageContainer>
  );
};
