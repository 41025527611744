import React from 'react';
import { Field } from 'formik';

interface Props {
  title: string;
  name: string;
  id: string;
  tabIndex: number;
}

export const CustomCheckbox: React.FC<Props> = ({
  title,
  name,
  id,
  tabIndex,
}) => {
  return (
    <div className='w-100 h-9 relative mb-1'>
      <div className='custom-control custom-checkbox'>
        <Field
          type='checkbox'
          className='custom-control-input'
          id={id}
          name={name}
          tabIndex={tabIndex}
        />
        <label
          className='custom-control-label text-black leading-tight'
          htmlFor={id}
          id={`${id}-label`}
        >
          {title}
        </label>
      </div>
    </div>
  );
};
