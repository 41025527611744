import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '../../../containers/CandidatesContainer/Header';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { getPositions } from '../../../actions/positionsActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTicketsByChallengeId,
  ticketsFromChallange,
  setInstanceSetup,
  putUpdatedTickets,
} from '../../../actions/ticketsActions';
import { Loader } from '../../../shared/components/Loader';
import { Table } from 'react-bootstrap';
import { formatKindStr } from '../../../util/ticketHelpers';
import { RunSolution } from '../../components/RunSolution';
import { setActiveTickets } from '../../../actions/ticketsActions';
import { TicketInfo } from '../../../shared/components/TicketInfo';
import {
  positionsSelector,
  ticketsSelector,
  activeTicketsSelector,
  errorTicketsSelector,
  pendingTicketsSelector,
} from '../../../selectors';
import './index.scss';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState } from '../../../reducers';

export const Ticket = () => {
  const dispatch = useDispatch();
  const { ticketId, positionId } = useParams();
  const { mandatoryTickets } = useFlags();

  const positions = useShallowEqualSelector(positionsSelector);

  const curPosition =
    positions && positions.find((position) => position.uuid === positionId);

  const tickets = useShallowEqualSelector(ticketsSelector);
  const activeTickets = useShallowEqualSelector(activeTicketsSelector);
  const errorTickets = useShallowEqualSelector(errorTicketsSelector);
  const pendingTickets = useShallowEqualSelector(pendingTicketsSelector);
  const { instanceTicket } = useSelector(
    (state: RootState) => state.ticketsReducer
  );

  const curTicket =
    tickets && tickets.find((ticket) => ticket.uid === ticketId);

  const [selectedTicket, setSelectedTicket] = useState(false);
  const [mandatoryTicket, setMandatoryTicket] = useState(false);

  useEffect(() => {
    if (curTicket !== undefined) {
      setSelectedTicket(curTicket.selectedTicket);
      setMandatoryTicket(curTicket.mandatoryTicket);
    }
  }, [curTicket]);

  useEffect(() => {
    dispatch(getPositions());
  }, [dispatch]);

  useEffect(() => {
    if (curPosition) {
      dispatch(ticketsFromChallange(positionId));
    }
  }, [dispatch, curPosition]);

  const setInstanceTicketFromChallengeSetup = (
    setTickets?: number[],
    setMandatory?: number[]
  ) => {
    if (instanceTicket === undefined) {
      return null;
    }
    const payload = {
      duration: instanceTicket.duration,
      estimate: instanceTicket.estimate,
      story_points: instanceTicket.story_points,
      tickets_mandatory: setMandatory || instanceTicket.tickets_mandatory,
      tickets: setTickets || instanceTicket.tickets,
    };
    dispatch(setInstanceSetup(payload));
    dispatch(putUpdatedTickets(instanceTicket, positionId));
  };

  const handleSelectedTicketChange = (id: number) => {
    if (instanceTicket === undefined) {
      return null;
    }

    if (selectedTicket) {
      const index = instanceTicket.tickets.indexOf(id);
      if (index > -1) {
        instanceTicket?.tickets.splice(index, 1);
      }
      setInstanceTicketFromChallengeSetup(instanceTicket.tickets);
      setSelectedTicket(false);
    } else {
      instanceTicket.tickets.push(id);
      setInstanceTicketFromChallengeSetup(instanceTicket.tickets);
      setSelectedTicket(true);
    }
  };

  const handleMandatoryTicketChange = (id: number) => {
    if (instanceTicket === undefined) {
      return null;
    }

    if (mandatoryTicket) {
      const index = instanceTicket.tickets_mandatory.indexOf(id);
      if (index > -1) {
        instanceTicket.tickets_mandatory.splice(index, 1);
      }
      setInstanceTicketFromChallengeSetup(
        undefined,
        instanceTicket.tickets_mandatory
      );
      setMandatoryTicket(false);
    } else {
      instanceTicket.tickets_mandatory.push(id);
      setInstanceTicketFromChallengeSetup(
        undefined,
        instanceTicket.tickets_mandatory
      );
      setMandatoryTicket(true);
    }
  };

  if (errorTickets) {
    return <div>{errorTickets}</div>;
  }

  if (pendingTickets) {
    return <Loader />;
  }

  if (!tickets) {
    return null;
  }

  if (!curTicket) {
    return <div>no ticket</div>;
  }

  const ROWS = [
    {
      title: 'Type',
      value: formatKindStr(curTicket.kind),
    },
    {
      title: 'Skills',
      value: curTicket.skills_meta.map((skill, i: number) =>
        i !== curTicket.skills_meta.length - 1
          ? `${skill.title}, `
          : skill.title
      ),
    },
    {
      title: 'Story Points',
      value: curTicket.story_points,
    },
  ];

  const activeTicketsHandleChange = () => {
    if (activeTickets?.includes(curTicket.id)) {
      dispatch(
        setActiveTickets(activeTickets.filter((id) => id !== curTicket.id))
      );
    } else {
      dispatch(setActiveTickets([...activeTickets, curTicket.id]));
    }
  };

  return (
    <div className='bg-white px-4 px-md-5 py-3'>
      <Header />
      <div className='ticket-info-wrap'>
        {mandatoryTickets ? (
          <div className='selected-mandatory-tickets'>
            <div className='selected'>
              <input
                type='checkbox'
                name='selected-ticket'
                id='switch-selected'
                checked={selectedTicket}
                onChange={() => handleSelectedTicketChange(curTicket.id)}
              />
              <label htmlFor='selected-ticket'>Selected</label>
            </div>
            <div className='mandatory'>
              <p>Mandatory</p>
              <input
                type='checkbox'
                className='checkbox'
                id={curTicket.id.toString()}
                checked={mandatoryTicket}
                onChange={() => handleMandatoryTicketChange(curTicket.id)}
              />
              <label
                htmlFor={curTicket.id.toString()}
                className='switch'
                id={curTicket.id.toString()}
              />
            </div>
          </div>
        ) : null}
        {activeTickets && (
          <div className='custom-control custom-checkbox'>
            <input
              type='checkbox'
              className='custom-control-input'
              id={curTicket.id.toString()}
              checked={activeTickets.includes(curTicket.id)}
              onChange={activeTicketsHandleChange}
            />
            <label
              className={'custom-control-label text-black leading-tight'}
              htmlFor={curTicket.id.toString()}
            >
              Selected
            </label>
          </div>
        )}

        <div className='text-2xl font-weight-bold'>
          {curTicket.uid} {curTicket.title}
        </div>
        <Table responsive className='mb-3'>
          <tbody>
            {ROWS.map((row) => (
              <tr key={row.title}>
                <td className='border-0 text-gray-600 py-1 pl-0'>
                  {row.title}
                </td>
                <td className='border-0 py-1 pl-0'>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {curTicket.sections.map((section) => (
          <div key={section.id}>
            <div className='font-weight-bold mb-1'>{section.title}</div>
            <TicketInfo body={section.body} />
          </div>
        ))}
        <div className='font-weight-bold mb-3'>Check how it looks like</div>
        <RunSolution
          className='border-blue-700'
          iconColor='#0c74ef'
          text='Open Application'
        />
      </div>
    </div>
  );
};
