import React from 'react';
import terms from '../../../docs/terms';
import dataProtection from '../../../docs/dataProtection';
import privacyPolicy from '../../../docs/privacyPolicy';
import './LegalDocument.scss';
interface Props {
  document: 'Terms Of Service' | 'Data Protection Agreement' | 'Privacy Policy';
}

export const LegalDocument: React.FC<Props> = ({ document }) => {
  const renderDocumnet = () => {
    switch (document) {
      case 'Terms Of Service':
        return terms;
      case 'Data Protection Agreement':
        return dataProtection;
      case 'Privacy Policy':
        return privacyPolicy;

      default:
        return '';
    }
  };
  return (
    <div className='bg-white w-100 pb-10'>
      <div
        style={{ maxWidth: '650px' }}
        dangerouslySetInnerHTML={{ __html: renderDocumnet() }}
      ></div>
    </div>
  );
};
