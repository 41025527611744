import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showModal, modalInviteWithoutEmail } from '../../actions/modalActions';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { PipelineItems } from './PipelineItems';
import { Loader } from '../../shared/components/Loader';
import { setCurrentPosition } from '../../actions/positionsActions';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import cn from 'classnames';
import { Actions } from '../../typings/enums';

import { Pipeline, IPosition } from '../../typings/interfaces';
import { selectCandidtesNotConsumedInvites } from '../../selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  pipeline: Pipeline | undefined;
  position: IPosition;
  pendingCandidates: string[];
}

export const InProgress: React.FC<Props> = ({
  pipeline,
  position,
  pendingCandidates,
}) => {
  const inviteCandidateEmailWithWithout = false;
  const dispatch = useDispatch();

  const positionNotConsumedInvites = useShallowEqualSelector((state) =>
    selectCandidtesNotConsumedInvites(state, position.uuid)
  );

  const initialInProgressState = useMemo(() => {
    return (
      (!positionNotConsumedInvites ||
        positionNotConsumedInvites.length === 0) &&
      pipeline?.started === 0 &&
      pipeline?.expiring === 0 &&
      pipeline?.expired === 0
    );
  }, [positionNotConsumedInvites, pipeline]);

  const openModalWithEmail = () => {
    dispatch(
      showModal({
        type: Actions.inviteCandidate,
        title: 'Invite new candidate',
      })
    );
    dispatch(setCurrentPosition(position));
  };

  const openModalWithoutEmail = () => {
    // recruiter interface
    dispatch(modalInviteWithoutEmail(true));
    dispatch(setCurrentPosition(position));
  };

  return (
    <div
      className='flex-grow-1 d-flex flex-column rounded bg-content mb-4 mr-lg-3 border border-top-4 border-bottom-0 border-left-0 border-right-0 border-yellow-700 border-4 justify-content-between'
      style={{ minHeight: '304px', minWidth: '180px', height: '100%' }}
    >
      <div
        className={cn('px-4', 'pt-1', {
          'd-flex': initialInProgressState,
          'flex-column': initialInProgressState,
          'h-100': initialInProgressState,
        })}
      >
        <div className='text-sm font-weight-bold mb-4'>In Progress</div>
        {!pendingCandidates.includes(position.uuid) ? (
          <PipelineItems
            pipeline={pipeline}
            position={position}
            initialInProgressState={initialInProgressState}
            positionNotConsumedInvites={positionNotConsumedInvites}
          />
        ) : null}
        {pendingCandidates.includes(position.uuid) ? <Loader /> : null}
      </div>
      {!initialInProgressState ? (
        <button
          id={`send-invite-${position.uuid}`}
          className='border-0 bg-gray-600 rounded-bottom py-2 d-flex align-items-center justify-content-center'
          onClick={() =>
            inviteCandidateEmailWithWithout
              ? openModalWithEmail()
              : openModalWithoutEmail()
          }
          data-testid='invite-btn'
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            size='lg'
            color='#C1C1C1'
            className='mr-2'
          />
          <div className='text-sm'>Invite</div>
        </button>
      ) : null}
    </div>
  );
};
