import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import closepic from '../../../assets/close.svg';
import { modalInviteWithoutEmail } from '../../../actions/modalActions';
import { RootState } from '../../../reducers';
import { sendInvite } from '../../../actions/invitesActions';
import './index.scss';

interface InviteWithoutEmailProps {}

export const InviteWithoutEmail: FC<InviteWithoutEmailProps> = () => {
  const dispatch = useDispatch();
  const positions = useSelector(
    (state: RootState) => state.positionsReducer.curPosition
  );
  const { invites } = useSelector((state: RootState) => state.invitesReducer);

  const [link, setLink] = useState('Loading...');
  const [linkCopy, setLinkCopy] = useState(false);
  const [comment, setComment] = useState('');
  const [genLink, setGenLink] = useState(false);

  useEffect(() => {
    if (Object.keys(invites).length > 0) {
      setLink(
        `${window.OPTIONS.CANDIDATE_APP_URL}/invitation/${
          invites[invites.length - 1].uuid
        }`
      );
    }
  }, [invites]);
  

  const generateLink = () => {
    dispatch(
      sendInvite(
        {
          role: 'candidate',
          data: {
            position_id: positions?.task.position_id,
          },
          note: comment,
        },
        () => null
      )
    );
    setGenLink(true);
  };
  const linkCopyed = () => {
    setLinkCopy(true);
    setTimeout(() => {
      setLinkCopy(false);
    }, 3000);
  };

  return (
    <div className='invite-modal-wrap'>
      <div
        className='shadow'
        onClick={() => dispatch(modalInviteWithoutEmail(false))}
      />
      <div className='invite-link-candidate'>
        <div className='invite-tools-wrap'>
          <div className='left-block'>
            Generate and copy unique invite to send it to the candidate
          </div>
          {genLink ? (
            <>
              <div className='middle-block'>
                <input
                  type='text'
                  name='commentstatic'
                  value={comment}
                  disabled
                />
                <input name='link' type='text' disabled value={link} id='invitation-link'/>
              </div>
              <div className='right-block'>
                <div className='copy-link'>
                  <CopyToClipboard text={link}>
                    <p
                      onClick={() =>
                        link === 'Loading...' ? null : linkCopyed()
                      }
                    id='copied-link'>
                      {linkCopy ? 'Link Copied' : 'Copy Link'}
                    </p>
                  </CopyToClipboard>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='middle-block'>
                <input
                  type='text'
                  name='comment'
                  value={comment}
                  onChange={(e) => setComment(e.currentTarget.value)}
                  placeholder='Comment i.e. candidate name'
                />
              </div>
              <div className='right-block'>
                <div className='copy-link'>
                  <p onClick={() => generateLink()} id='invitation-submit'>Generate</p>
                </div>
              </div>
            </>
          )}

          <img
            onClick={() => dispatch(modalInviteWithoutEmail(false))}
            src={closepic}
            alt=''
          />
        </div>
      </div>
    </div>
  );
};
