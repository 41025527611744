import React from 'react';
import cn from 'classnames';
import { PipelineItem } from './PipelineItem';
import { SendInviteBtn } from '../../shared/components/SendInviteBtn';
import { Invite, Pipeline } from '../../typings/interfaces';
import { Stages } from '../../typings/enums';

interface Props {
  position: any;
  pipeline: Pipeline | undefined;
  initialInProgressState: boolean | undefined;
  positionNotConsumedInvites: Invite[] | undefined;
}

export const PipelineItems: React.FC<Props> = ({
  position,
  pipeline,
  initialInProgressState,
  positionNotConsumedInvites,
}) => {
  return (
    <>
      <div
        className={cn('d-flex', 'flex-wrap', {
          'justify-content-center': initialInProgressState,
          'align-items-center': initialInProgressState,
          'h-100': initialInProgressState,
        })}
      >
        {!initialInProgressState ? (
          <div className='w-100 max-w-146 mr-2'>
            <PipelineItem
              type={Stages.SENT}
              title='Invites'
              positionId={position.uuid}
              stageType={Stages.SENT}
              length={positionNotConsumedInvites?.length || 0}
            />
          </div>
        ) : null}

        {initialInProgressState ? (
          <div>
            <SendInviteBtn
              initialInProgressState={initialInProgressState}
              taskSent={positionNotConsumedInvites?.length || 0}
              position={position}
            />
          </div>
        ) : null}

        {!initialInProgressState ? (
          <div className='w-100 max-w-146'>
            <PipelineItem
              type='started'
              title='Task started'
              positionId={position.uuid}
              stageType={Stages.IN_PROGRESS}
              length={pipeline?.started || 0}
            />
          </div>
        ) : null}
      </div>

      {!initialInProgressState ? (
        <div className='d-flex flex-wrap'>
          <div className='w-100 max-w-146 mr-2'>
            <PipelineItem
              type='expired'
              title='Task expired'
              positionId={position.uuid}
              stageType={Stages.IN_PROGRESS}
              length={pipeline?.expired || 0}
            />
          </div>
          <div className='w-100 max-w-146'>
            <PipelineItem
              type='expiring'
              title='Task expiring'
              positionId={position.uuid}
              stageType={Stages.IN_PROGRESS}
              length={pipeline?.expiring || 0}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
