import React from 'react';
import {
  ISolution,
  INotifications,
  PositionsCandidates,
} from '../../../typings/interfaces';
import { KeyActivity } from '../KeyActivity';
import { Button } from 'react-bootstrap';
import { Loader } from '../Loader';

interface Props {
  notifications: INotifications | undefined;
  candidates: PositionsCandidates | undefined;
  loadMoreNotifications: () => void;
  isPending: boolean;
}

export const KeyActivityList: React.FC<Props> = ({
  notifications,
  candidates,
  loadMoreNotifications,
  isPending,
}) => {
  if (!notifications?.items || !candidates) return null;
  if (notifications.items.length === 0) {
    return (
      <div className='m-auto text-center'>
        <div className='font-weight-bold text-lg mb-2'>
          Nothing Happened Yet
        </div>
        <div style={{ maxWidth: '220px' }}>
          All the most important activities on all positions will be avaiable
          here
        </div>
      </div>
    );
  }
  return (
    <div>
      {notifications?.items.map((notification) => {
        const positionCandidates = candidates[notification.rel_position_pk];
        if (!positionCandidates) return null;
        const candidate = positionCandidates.find(
          (candidate: ISolution) =>
            candidate.candidate.user.uuid === notification.rel_user_pk
        );

        return (
          <KeyActivity
            notification={notification}
            key={notification.uuid}
            candidate={candidate}
          />
        );
      })}
      {isPending && <Loader />}
      {notifications.items.length < notifications.total && !isPending && (
        <div className='mt-2 text-center px-3'>
          <Button
            variant='outline-dark'
            className='text-xs px-2 w-100'
            onClick={loadMoreNotifications}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};
