import React from 'react';
import './PricingContent.scss';

interface Props {}

export const PricingContent: React.FC<Props> = () => {
  const styles = {
    price: {
      fontSize: '6.25rem',
    },
  };

  return (
    <div className='pt-4 text-white container mb-6 mb-lg-0'>
      <h2 className='text-4-5xl font-weight-bold text-center mx-auto leading-thin mb-8'>
        Pricing
      </h2>
      <div className='d-flex flex-column flex-lg-row justify-content-between pb-16 align-items-stretch w-100'>
        <div className='lg:skew-15 lg:w-4/12 border border-left-0 border-r-0 border-top-0 lg:border-b-0 lg:border-r pb-5 pb-lg-0 border-gray-opacity-200'>
          <div className='text-center lg:skew-back-15 d-flex flex-column justify-content-between h-100'>
            <div className='text-2xl font-weight-bold mb-2 font-weight-bold'>
              Plan A
            </div>
            <div style={styles.price} className='font-weight-bold'>
              €99
            </div>
            <div className='mb-6 mb-lg-0 text-xl'>
              10 assessments/month <br />3 positions
            </div>
          </div>
        </div>
        <div className='lg:skew-15 lg:w-4/12 border border-left-0 border-r-0 border-top-0 lg:border-b-0 lg:border-r py-5 py-lg-0 border-gray-opacity-200'>
          <div className='text-center d-flex flex-column justify-content-between lg:skew-back-15 h-100 pr-lg-2'>
            <div className='text-2xl font-weight-bold mb-2 font-weight-bold'>
              Plan B
            </div>
            <div style={styles.price} className='font-weight-bold'>
              €299
            </div>
            <div className='mb-6 mb-lg-0 text-xl'>
              40 assessments/month
              <br />
              10 positions
            </div>
          </div>
        </div>

        <div className='text-center lg:w-4/12 pt-5 pt-lg-0 d-flex flex-column justify-content-between h-100 border-gray-opacity-200'>
          <div className='text-2xl font-weight-bold mb-2 font-weight-bold'>
            Plan C
          </div>
          <div style={{ ...styles.price }} className='font-weight-bold'>
            €999
          </div>
          <div className='text-xl position-relative'>
            250 assessments
            <br />
            Unlimited number of positions
            <div
              className='position-absolute text-center'
              style={{ left: 0, right: 0 }}
            >
              + Dedicated Account Manager
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
