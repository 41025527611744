import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Formik, Field, FormikErrors, FormikValues, Form } from 'formik';
import { Button, Form as BootstrapForm } from 'react-bootstrap';
import { createPosition } from '../../../actions/positionsActions';
import { CreatePositionForm } from '../CreateFirstPosition/CreatePositionForm';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { ITeam, IChallengeCategory } from '../../../typings/interfaces';
import { SelectField } from '../../../shared/components/SelectField';
import { getTeams } from '../../../actions/teamsActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { showAddPostion } from '../../../actions/positionsActions';
import { CardComponent } from '../../../shared/components/CardComponent';
import { AddTeamForm } from './AddTeamForm';

import { v4 as uuid } from 'uuid';
import { teamsPendingSelector, teamsSelector } from '../../../selectors';

export interface FormValues {
  name: string;
  level: string;
  categoyId: string;
  team: string;
}

const initialValues: FormValues = {
  name: '',
  level: '',
  categoyId: '',
  team: ''
};

export const CreatePosition: React.SFC = () => {
  const dispatch = useDispatch();
  const [addTeamStep, setAddTeamStep] = useState<boolean>(false);

  const teams = useShallowEqualSelector(teamsSelector);
  const teamsPending = useShallowEqualSelector(teamsPendingSelector);
  const categories = useShallowEqualSelector(
    (state): IChallengeCategory[] => state.challengesReducer.categories
  );

  useEffect(() => {
    !teams.length && dispatch(getTeams());
  }, [dispatch, teams]);

  return (
    <CardComponent>
      {!addTeamStep ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            const team = teams.find((teamsItem: ITeam) => {
              return teamsItem.uuid === values.team;
            });
            const category = categories.find((categoryItem: any) => {
              return categoryItem.id === values.categoyId;
            });
            if (category && team) {
              dispatch(
                createPosition({
                  title: `${values.name} ${category.title} ${values.level}`,
                  level: values.level,
                  uuid: uuid(),
                  team,
                  category,
                  company: team.company,
                  task: '',
                  pipeline: {}
                })
              );
            }

            dispatch(showAddPostion(false));
          }}
          validate={(values) => {
            const errors: FormikErrors<FormikValues> = {};

            if (!values.name) {
              errors.name = 'Required';
            }
            if (!values.level) {
              errors.level = 'Choose level for position';
            }
            if (!values.categoyId) {
              errors.categoyId = 'Required';
            }

            if (!values.level) {
              errors.level = 'Required';
            }
            if (!values.team) {
              errors.team = 'Required';
            }

            return errors;
          }}
        >
          {({ handleSubmit, errors, touched, values, setFieldTouched }) => (
            <>
              <Form>
                <>
                  <div className='h2'>Create new position</div>
                  <div className='d-flex align-items-stretch'>
                    <div className='flex-grow-1 w-100 pr-2'>
                      <CreatePositionForm
                        errors={errors}
                        touched={touched}
                        values={values}
                        setFieldTouched={setFieldTouched}
                      />
                      <div>
                        <Button
                          variant='warning'
                          className='rounded-lg custom-btn mr-2'
                          style={{ minWidth: '160px' }}
                          type='submit'
                        >
                          Create Position
                        </Button>
                        <button
                          className='btn btn-outline-dark border-gray-300 px-4 py-2'
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            dispatch(showAddPostion(false));
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className='flex-grow-1 w-100 pl-4'>
                      {teams.length > 0 && !teamsPending && (
                        <>
                          <BootstrapForm.Group className='mb-3'>
                            <BootstrapForm.Label className='text-sm mb-1'>
                              Team:
                            </BootstrapForm.Label>
                            <Field
                              name='team'
                              component={SelectField}
                              setFieldTouched={setFieldTouched}
                              selectOptions={teams}
                              selectValueField='uuid'
                              selectLabelField='title'
                              selectPlaceholder='choose team'
                              className='h-10 md:h-16 text-xl'
                            />
                          </BootstrapForm.Group>
                          <button
                            className='bg-transparent border-0 px-0 mb-4'
                            style={{ color: '#3D5470' }}
                            onClick={(
                              e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                              setAddTeamStep(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              size='lg'
                              color='#3D5470'
                              className='mr-3'
                            />
                            Add Team
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              </Form>
            </>
          )}
        </Formik>
      ) : (
        <AddTeamForm setAddTeamStep={setAddTeamStep} />
      )}
    </CardComponent>
  );
};
