import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { stringify } from 'query-string';
import { RootState } from '../reducers';
import { ISolution, FeedbackData } from '../typings/interfaces';
import { showAlert } from './alertActions';
import { Actions, AlertType } from '../typings/enums';
import {
  candidatesActionTypesConstants,
  CandidatesActionsTypes,
} from './types';
import { apiFetch } from '../util/apiFetch';

export const getPositionCandidates = (
  positionId: string
): ThunkAction<
  void,
  RootState,
  CandidatesActionsTypes,
  Action<string>
> => async (dispatch) => {
  try {
    dispatch({
      type: candidatesActionTypesConstants.PENDING_CANDIDATES,
      payload: positionId,
    });

    const response = await apiFetch('company', `/v1/candidate/${positionId}/`);
    const trackerList = await apiFetch('ticket', '/tracker/');
    const ticketList = await apiFetch('ticket', '/ticket/');


    trackerList.data.map((item: any) => {
      item.ticket = {};
      ticketList.data.map((point: any) => {
        if (item.ticket_id === point.id) {
          return Object.assign(item.ticket, point);
        }
      });
    });

    response.data.map((item: any) => {
      item.candidate.trackers = [];
    });

    response.data.map((item: any) => {
      trackerList.data.map((point: any) => {
        if (item.candidate.user.uuid === point.user_id) {
          return item.candidate.trackers.push(point);
        }
      });
    });

    if (response.error) throw new Error(response.error);

    dispatch({
      type: candidatesActionTypesConstants.GET_POSITION_CANDIDATES,
      payload: {
        positionId,
        candidates: response.data,
      },
    });
  } catch (err) {
    dispatch({
      type: candidatesActionTypesConstants.ERROR_CANDIDATES,
      payload: positionId,
    });
  }
};

export const clearCandidates = (): CandidatesActionsTypes => {
  return {
    type: candidatesActionTypesConstants.CLEAR_CANDIDATES,
  };
};
export const clearCandidate = (): CandidatesActionsTypes => {
  return {
    type: candidatesActionTypesConstants.CLEAR_CANDIDATE,
  };
};

export const sendCandidateToArchive = (): ThunkAction<
  void,
  RootState,
  CandidatesActionsTypes,
  Action<string>
> => async (dispatch, getState) => {
  const candidateId = getState().candidatesReducer.currentCandidate?.candidate
    .uuid;
  const positionId = getState().candidatesReducer.currentCandidate?.position
    .uuid;
  const positionCandidates =
    (positionId && getState().candidatesReducer.candidates?.[positionId]) || [];
  try {
    const response = await apiFetch<{ action: string }>(
      'company',
      `/v1/candidate/${positionId}/${candidateId}/`,
      'patch',
      { action: Actions.ARCHIVE }
    );

    if (response.error) throw new Error(response.error);
    dispatch({
      type: candidatesActionTypesConstants.GET_POSITION_CANDIDATES,
      payload: {
        positionId: positionId,
        candidates: positionCandidates.map((candidate) => {
          if (candidate.candidate.uuid === candidateId) return response.data;
          return candidate;
        }),
      },
    });
  } catch (err) {
    dispatch(showAlert({ message: err.message, type: AlertType.fail }));
    throw new Error(err.message);
  }
};
export const prolongCandidate = (): ThunkAction<
  void,
  RootState,
  CandidatesActionsTypes,
  Action<string>
> => async (dispatch, getState) => {
  const candidateId = getState().candidatesReducer.currentCandidate?.candidate
    .uuid;
  const positionId = getState().candidatesReducer.currentCandidate?.position
    .uuid;
  const positionCandidates =
    (positionId && getState().candidatesReducer.candidates?.[positionId]) || [];
  try {
    const response = await apiFetch<{ action: string }>(
      'company',
      `/v1/candidate/${positionId}/${candidateId}/`,
      'patch',
      { action: Actions.PROLONG }
    );

    if (response.error) throw new Error(response.error);
    dispatch({
      type: candidatesActionTypesConstants.GET_POSITION_CANDIDATES,
      payload: {
        positionId: positionId,
        candidates: positionCandidates.map((candidate) => {
          if (candidate.candidate.uuid === candidateId) return response.data;
          return candidate;
        }),
      },
    });
  } catch (err) {
    dispatch(showAlert({ message: err.message, type: AlertType.fail }));
    throw new Error(err.message);
  }
};

export const setCurCandidate = (
  candidate: ISolution
): CandidatesActionsTypes => {
  return {
    type: candidatesActionTypesConstants.SET_CURRENT_CANDIDATE,
    payload: candidate,
  };
};

export const sendFeedback = (
  curCandidate: ISolution,
  feedbackData: FeedbackData
): ThunkAction<
  void,
  RootState,
  CandidatesActionsTypes,
  Action<string>
> => async (dispatch, getState) => {
  const positionCandidates =
    getState().candidatesReducer.candidates?.[curCandidate.position.uuid] || [];
  try {
    const response = await apiFetch(
      'company',
      `/v1/feedback/${curCandidate.position.uuid}/${curCandidate.candidate.uuid}/`,
      'POST',
      feedbackData
    );
    if (response.error) throw new Error(response.error);

    const updatedFeedback = response.data;
    const updatedCandidate = { ...curCandidate, feedback: updatedFeedback };

    dispatch({
      type: candidatesActionTypesConstants.SET_CURRENT_CANDIDATE,
      payload: updatedCandidate,
    });

    dispatch(clearSendFeedbackError(curCandidate.candidate.uuid));

    dispatch({
      type: candidatesActionTypesConstants.GET_POSITION_CANDIDATES,
      payload: {
        positionId: curCandidate.position.uuid,
        candidates: positionCandidates.map((candidate) => {
          if (candidate.candidate.uuid === updatedCandidate.candidate.uuid)
            return updatedCandidate;
          return candidate;
        }),
      },
    });
  } catch (err) {
    dispatch({
      type: candidatesActionTypesConstants.ERROR_SEND_FEEDBACK,
      payload: curCandidate.candidate.uuid,
    });
    dispatch(showAlert({ message: err.message, type: AlertType.fail }));
    throw new Error(err.message);
  }
};

export const informCandidate = (
  curCandidate: ISolution
): ThunkAction<
  void,
  RootState,
  CandidatesActionsTypes,
  Action<string>
> => async (dispatch, getState) => {
  const updatedFeedback = {
    body: curCandidate.feedback?.body || null,
    is_positive:
      typeof curCandidate.feedback?.is_positive === 'boolean'
        ? curCandidate.feedback?.is_positive
        : null,
    is_sent: true,
    is_attached: getState().candidatesReducer.attachedFeedbacks.includes(
      curCandidate.candidate.uuid
    ),
  };
  try {
    await dispatch(sendFeedback(curCandidate, updatedFeedback));
  } catch (error) {
    throw new Error(error.message);
  }
};

export const clearSendFeedbackError = (
  candidateId: string
): CandidatesActionsTypes => ({
  type: candidatesActionTypesConstants.CLEAR_ERROR_SEND_FEEDBACK,
  payload: candidateId,
});

export const getCandidate = (
  candidateId: string,
  positionId: string
): ThunkAction<
  void,
  RootState,
  CandidatesActionsTypes,
  Action<string>
> => async (dispatch) => {
  try {
    dispatch({
      type: candidatesActionTypesConstants.PENDING_CANDIDATE,
    });
    const response = await apiFetch(
      'company',
      `/v1/candidate/${positionId}/${candidateId}/`
    );
console.log('err', candidateId, positionId, response);

    const trackerList = await apiFetch('ticket', '/tracker/');
    const ticketList = await apiFetch('ticket', '/ticket/');

    trackerList.data.map((item: any) => {
      item.ticket = {};
      ticketList.data.map((point: any) => {
        if (item.ticket_id === point.id) {
          return Object.assign(item.ticket, point);
        }
      });
    });

    response.data.candidate.trackers = [];

    trackerList.data.map((point: any) => {
      if (response.data.candidate.user.uuid === point.user_id) {
        return response.data.candidate.trackers.push(point);
      }
    });

    if (response.error) throw new Error(response.error);

    dispatch({
      type: candidatesActionTypesConstants.SET_CURRENT_CANDIDATE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: candidatesActionTypesConstants.ERROR_CANDIDATE,
      payload: err.message,
    });
  }
};

export const getCandidateNote = (
  positionId: string,
  userId: string
): ThunkAction<
  void,
  RootState,
  CandidatesActionsTypes,
  Action<string>
> => async (dispatch) => {
  try {
    dispatch({
      type: candidatesActionTypesConstants.PENDING_CANDIDATE_NOTE,
    });

    const response = await apiFetch(
      'company',
      `/v1/note/?position_id=${positionId}&created_by_id=${userId}&user_id=${userId}`
    );
    if (response.error) throw new Error(response.error);

    dispatch({
      type: candidatesActionTypesConstants.GET_CANDIDATE_NOTE,
      payload: response.data.length ? response.data[0] : { body: null },
    });
  } catch (err) {
    dispatch({
      type: candidatesActionTypesConstants.ERROR_CANDIDATE_NOTE,
      payload: err.message,
    });
  }
};

export const clearCandidateNote = (): CandidatesActionsTypes => ({
  type: candidatesActionTypesConstants.CLEAR_CANDIDATE_NOTE,
});

export const attachFeedback = (uuid: string) => {
  return {
    type: candidatesActionTypesConstants.ATTACH_FEEDBACK,
    payload: uuid,
  };
};
export const dettachFeedback = (uuid: string, feedbacks: string[]) => {
  return {
    type: candidatesActionTypesConstants.DETACH_FEEDBACK,
    payload: feedbacks.filter((feedback: string) => {
      return feedback !== uuid;
    }),
  };
};
