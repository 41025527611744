import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Table } from 'react-bootstrap';
import { compareValues } from '../../../util/compareValues';
import { CandidateRow } from './CandidateRow';
import { ISolution, ILike } from '../../../typings/interfaces';
import { TableHeader } from './TableHeader';
import { Stages } from '../../../typings/enums';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LikesContentTypeEnum } from '../../../typings/enums';
import { useLikes } from '../../../hooks/useLikes';
import { StageTitle } from '../../../shared/components/StageTitle';
import { Loader } from '../../../shared/components/Loader';
import {
  pendingCandidatesSelector,
  selectFilteredCandidates,
} from '../../../selectors';

export const SubmittedReviewed: React.FC = () => {
  const { showLikeDeslikeFeature } = useFlags();

  let history = useHistory();

  const { positionId, stageType } = useParams();
  const [order, setOrder] = useState(true);
  const [propertyKey, setPropertyKey] = useState('Candidate');

  const pendingCandidates = useShallowEqualSelector(pendingCandidatesSelector);

  const filteredCandidates = useShallowEqualSelector((state) =>
    selectFilteredCandidates(state, positionId, stageType)
  );

  useEffect(() => {
    if (positionId && filteredCandidates && filteredCandidates.length === 0) {
      history.push(`/dashboard#position-${positionId}`);
    }
  }, [filteredCandidates, history, positionId]);

  const { likesByCandidatePosition, pendingLikes } = useLikes(
    filteredCandidates
  );

  const renderTitle = () => {
    if (!stageType) {
      return null;
    }
    if (stageType === Stages.SUBMITTED) {
      return 'To Review';
    }
    return stageType[0].toUpperCase() + stageType.slice(1);
  };

  let isLoading = pendingCandidates.includes(positionId) || pendingLikes;

  if (isLoading) {
    return <Loader />;
  }

  if (!filteredCandidates || pendingLikes) {
    return null;
  }

  return (
    <div data-testid={`candidates-${stageType}-${positionId}`}>
      <StageTitle title={renderTitle()} qty={filteredCandidates.length} />
      <div
        className='border rounded px-4 py-2'
        style={{
          maxWidth: '1000px',
        }}
      >
        <Table responsive size='sm' hover>
          <TableHeader
            setOrder={setOrder}
            setPropertyKey={setPropertyKey}
            order={order}
            propertyKey={propertyKey}
            stageType={stageType}
          />
          <tbody>
            {filteredCandidates
              .sort(compareValues(propertyKey, order))
              .map((candidate: ISolution) => {
                let currentCandidateLikes: ILike[] | undefined;
                if (showLikeDeslikeFeature && likesByCandidatePosition) {
                  currentCandidateLikes = likesByCandidatePosition.filter(
                    (like) =>
                      like.content_type_id ===
                        LikesContentTypeEnum.CANDIDATE_POSITION &&
                      like.object_id === String(candidate.id)
                  );
                }

                return (
                  <CandidateRow
                    candidate={candidate}
                    stageType={stageType}
                    key={candidate.candidate.uuid}
                    currentCandidateLikes={currentCandidateLikes}
                  />
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
