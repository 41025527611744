import React, { useMemo } from 'react';
import { PipelineItem } from './PipelineItem';
import { ReactComponent as ExclamationIcon } from '../../assets/exclamation.svg';
import { StageContainer } from './StageContainer';
import { Loader } from '../../shared/components/Loader';
import { Stages } from '../../typings/enums';
import { Pipeline } from '../../typings/interfaces';

interface Props {
  pipeline: Pipeline | undefined;
  positionId: string;
  pendingCandidates: string[];
}

export const ToInform: React.FC<Props> = ({
  pipeline,
  positionId,
  pendingCandidates,
}) => {
  const needsRecruiterReview = useMemo(() => {
    return pipeline && pipeline?.rejected > 9;
  }, [pipeline]);

  return (
    <StageContainer
      condition={{
        'opacity-50': !pipeline?.rejected && !pipeline?.accepted,
        'bg-content': !needsRecruiterReview,
        'border-red-500': !needsRecruiterReview,
        'bg-orange-200': needsRecruiterReview,
        'border-orange-300': needsRecruiterReview,
      }}
      classes={['mr-lg-3']}
    >
      <div className='text-sm font-weight-bold mb-4'>To Inform</div>
      {!pendingCandidates.includes(positionId) ? (
        <div className='d-flex flex-column justify-content-between pb-3'>
          <div>
            <div className='max-w-173'>
              <PipelineItem
                type={Stages.ACCEPTED}
                title={
                  Stages.ACCEPTED[0].toUpperCase() + Stages.ACCEPTED.slice(1)
                }
                positionId={positionId}
                stageType='reviewed'
                needsRecruiterReview={needsRecruiterReview}
                length={pipeline?.accepted}
              />
            </div>
            <div className='max-w-173'>
              <PipelineItem
                type={Stages.REJECTED}
                title={
                  Stages.REJECTED[0].toUpperCase() + Stages.REJECTED.slice(1)
                }
                positionId={positionId}
                stageType='reviewed'
                length={pipeline?.rejected}
              />
            </div>
          </div>
          {needsRecruiterReview ? (
            <div className='d-flex'>
              <div
                style={{ width: '16px', height: '16px' }}
                className='mr-2 d-flex to-inform-alert'
              >
                <ExclamationIcon className='w-100' />
              </div>
              <div
                className='small text-red-500 text-xs'
                style={{ wordBreak: 'normal' }}
              >
                You have {pipeline?.rejected} canditates to be <br /> informed.
                Please send feedback <br />
                to get more results
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {pendingCandidates.includes(positionId) ? <Loader /> : null}
    </StageContainer>
  );
};
