import React, { useEffect } from 'react';
import { Sidebar } from '../../shared/components/Sidebar';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { SlideMenu } from '../../shared/components/SlideMenu';
import { useIdentify } from '../../hooks/useIdentify';
import { Header } from './Header';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { getPositions } from '../../actions/positionsActions';
import { notificationsSocket } from '../../App';
import { postionsIdsSelector, positionsPendingSelector } from '../../selectors';
import * as chat from '../../CollectChat';
import {
  getPositionCandidates,
  clearCandidates,
} from '../../actions/candidatesActions';
import { getInvites } from '../../actions/invitesActions';
import { Loader } from '../../shared/components/Loader';
import {
  userSelector,
  meSelector,
  isIdentifiedSelector,
  positionsSelector,
  isConnectedSelector,
} from '../../selectors';

interface IProps {
  children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }): React.ReactElement => {
  const [isMatchMediaQuery] = useMatchMedia(769);

  const dispatch = useDispatch();
  const user = useShallowEqualSelector(userSelector);
  const me = useShallowEqualSelector(meSelector);

  const positions = useShallowEqualSelector(positionsSelector);

  const positionsIds = useShallowEqualSelector((state) =>
    postionsIdsSelector(state)
  );

  const isIdentified = useShallowEqualSelector(isIdentifiedSelector);

  const isNotificationsSocketConnected = useShallowEqualSelector(
    isConnectedSelector
  );

  const positionsPending = useShallowEqualSelector(positionsPendingSelector);
  useIdentify(user, isIdentified, dispatch);

  useEffect(() => {
    dispatch(getPositions());
  }, [dispatch]);
  
  useEffect(() => {
    dispatch(getInvites());
  }, [dispatch]);
  
  useEffect(() => {
    if (positions) {
      positions.forEach((position) => {
        dispatch(getPositionCandidates(position.uuid));
      });
    }
  }, [positions, dispatch]);

  useEffect(() => {
    me && chat.setEmail(window, me.email);
  }, [me]);

  useEffect(() => {
    if (me && me.uuid && positionsIds && !isNotificationsSocketConnected) {
      notificationsSocket.init(positionsIds);
    }
  }, [isNotificationsSocketConnected, me, positionsIds]);

  useEffect(() => {
    return () => {
      dispatch(clearCandidates());
    };
  }, [dispatch]);

  return (
    <>
      {!isMatchMediaQuery ? (
        <SlideMenu>
          <Sidebar />
        </SlideMenu>
      ) : null}
      <div
        className='d-flex align-items-stretch container-fluid px-0'
        style={{ minHeight: '100vh' }}
      >
        {isMatchMediaQuery ? (
          <div
            className='flex-shrink-0 bg-sidebar w-100'
            style={{ maxWidth: '216px' }}
          >
            <Sidebar />
          </div>
        ) : null}
        <div className='flex-grow-1 bg-content w-100'>
          <Header withSearch />
          <div className='py-4'>{positionsPending ? <Loader /> : children}</div>
        </div>
      </div>
    </>
  );
};
