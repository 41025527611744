import React, { useState } from 'react';
import { HeaderCell } from '../HeaderCell';
import { FormatedNotification } from '../../../typings/interfaces';
import { compareValues } from '../../../util/compareValues';
import { KeyActivityRow } from './KeyActivityRow';
import '../../components/KeyActivity.scss';

interface Props {
  notifications: FormatedNotification[] | undefined;
}

export const KeyActivitiesGrid = ({ notifications }: Props) => {
  const [order, setOrder] = useState(true);
  const [propertyKey, setPropertyKey] = useState('Candidate');

  if (!notifications) return null;
  if (notifications.length === 0)
    return (
      <div className='m-auto text-center'>
        <div className='font-weight-bold text-lg mb-2'>
          Nothing Happened Yet
        </div>
        <div style={{ maxWidth: '220px' }} className='m-auto'>
          All the most important activities on all positions will be avaiable
          here
        </div>
      </div>
    );

  return (
    <>
      <div className='grid-cols-4 grid text-gray-600 px-2'>
        {TABLE_HEADER.map((cell) => (
          <HeaderCell
            key={cell.title}
            order={order}
            setOrder={setOrder}
            propertyKey={propertyKey}
            setPropertyKey={setPropertyKey}
            property={cell.property}
            title={cell.title}
            gridCell
          />
        ))}
      </div>
      {notifications
        ?.sort(compareValues(propertyKey, order))
        .map((notification: FormatedNotification) => (
          <KeyActivityRow notification={notification} key={notification.uuid} />
        ))}
    </>
  );
};

const TABLE_HEADER = [
  {
    title: 'Candidate',
    property: 'candidate.candidate.first_name',
  },
  {
    title: 'Event',
    property: 'event.id',
  },
  {
    title: 'Position',
    property: 'position.title',
  },
  {
    title: 'When',
    property: 'date_created',
  },
];
