import {
  SHOW_ALERT,
  HIDE_ALERT,
  AcceptanceActionsTypes,
  CandidatesActionsTypes,
  ChallengesActionTypes,
  RecruitersActionTypes,
  TaskActionsTypes,
  TeamsActionTypes,
  LikesActionsTypes,
  NotesActionsTypes
} from './types';
import { AlertType } from '../typings/enums';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';
import { Action } from 'redux';

export const showAlert = (
  alertData: {
    message: string;
    type: AlertType;
  },
  timeout = 3000,
  autoHide = true
): ThunkAction<
  void,
  RootState,
  | AcceptanceActionsTypes
  | CandidatesActionsTypes
  | ChallengesActionTypes
  | RecruitersActionTypes
  | TaskActionsTypes
  | TeamsActionTypes
  | LikesActionsTypes
  | NotesActionsTypes,
  Action<string>
> => async (dispatch) => {
  try {
    dispatch({
      type: SHOW_ALERT,
      payload: alertData
    });
    if (autoHide) {
      setTimeout(() => {
        dispatch(hideAlert());
      }, timeout);
    }
  } catch (err) {
    dispatch({
      type: SHOW_ALERT,
      payload: {
        message: 'Something went wrong',
        type: 'fail'
      }
    })
  }
};

export const hideAlert = () => ({
  type: HIDE_ALERT
});
