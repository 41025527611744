import React, { useCallback } from 'react';
import { ISolution } from '../../typings/interfaces';
import {
  attachFeedback,
  dettachFeedback,
} from '../../actions/candidatesActions';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import cn from 'classnames';
import { ReactComponent as ClipIcon } from '../../assets/clip2.svg';
import './AttachFeedbackCheckbox.scss';
import { attachedFeedbacksSelector } from '../../selectors';

interface Props {
  candidate: ISolution;
  id: string;
}

export const AttachFeedbackCheckbox: React.FC<Props> = ({ candidate, id }) => {
  const dispatch = useDispatch();

  const attachedFeedbacks = useShallowEqualSelector(attachedFeedbacksSelector);

  const isFeedbackAttached = attachedFeedbacks.includes(
    candidate.candidate.uuid
  );

  const handleChange = useCallback(() => {
    if (isFeedbackAttached) {
      dispatch(dettachFeedback(candidate.candidate.uuid, attachedFeedbacks));
    } else {
      dispatch(attachFeedback(candidate.candidate.uuid));
    }
  }, [candidate, attachedFeedbacks, dispatch, isFeedbackAttached]);

  if (candidate.feedback) {
    return (
      <div>
        <div className='custom-checkbox attach-feedback'>
          <input
            type='checkbox'
            className='custom-control-input'
            onChange={handleChange}
            id={id}
            checked={isFeedbackAttached}
            data-testid={id}
          />
          <label
            className={cn(
              'btn btn-outline-dark d-inline-flex align-items-center mb-0 text-sm leading-none',
              {
                'text-success': isFeedbackAttached,
              }
            )}
            style={{ height: '34px' }}
            htmlFor={id}
          >
            <ClipIcon className={cn('mr-2', { active: isFeedbackAttached })} />
            {isFeedbackAttached ? 'Feedback Attached' : 'Attach feedback'}
          </label>
        </div>
      </div>
    );
  }
  return null;
};
