import React, { useEffect } from 'react';
import { ISolution } from '../../../typings/interfaces';
import { RunSolution } from '../../../reviewer/components/RunSolution';
import { countStoryPoints } from '../../../util/countStoryPoints';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Ticket } from './Ticket';
import { ShareTicket } from './ShareTicket';
import { useDispatch, useSelector } from 'react-redux';
import { getAcceptance } from '../../../actions/acceptanceActions';
import { Loader } from '../Loader';
import { getRepoInfo } from '../../../actions/gitActions';
import { RootState } from '../../../reducers';

interface Props {
  candidate: ISolution;
  positionId: string;
  share?: boolean;
}

export const Tickets: React.FC<Props> = ({ candidate, share, positionId }) => {
  const { acceptanceVisibleFlag } = useFlags();
  const dispatch = useDispatch();
  const userId = candidate.candidate.user.uuid;

  const pendingCandidateAcceptance = useSelector(
    (state: RootState) => state.acceptanceReducer.pendingCandidateAcceptance
  );

  const candidateAcceptance = useSelector(
    (state: RootState) => state.acceptanceReducer.candidateAcceptance
  );

  const completedTrackers = candidate.candidate.trackers.filter(
    (tracker: any) => tracker.status === 'COMPLETED'
  );

  useEffect(() => {
    dispatch(getRepoInfo({ user_id: userId }));
    !share && userId && dispatch(getAcceptance(positionId, userId));
  }, [positionId, userId, dispatch, share]);

  const renderCompletedTrackers = () => {
    if (share) {
      return completedTrackers.map((tracker: any) => {
        if (pendingCandidateAcceptance) {
          return null;
        }
        return <ShareTicket key={tracker.ticket.id} tracker={tracker} />;
      });
    }
    return completedTrackers.map((tracker: any) => (
      <Ticket
        key={tracker.ticket.id}
        tracker={tracker}
        withVisibility={acceptanceVisibleFlag}
        acceptance={
          candidateAcceptance
            ? candidateAcceptance.filter(
                (acceptance) => acceptance.ticket_id === tracker.ticket.id
              )
            : []
        }
      />
    ));
  };

  return (
    <div data-testid={`candidate-tickets-${candidate.candidate.uuid}`}>
      <div className='d-flex align-items-center mb-3 justify-content-between'>
        <div
          className='d-flex align-items-center'
          data-testid='tickets-heading'
        >
          <div className='text-2xl font-weight-bold mr-2'>
            Tickets Implemented
          </div>
          <div className='text-2xl'>
            {countStoryPoints(completedTrackers)}SP/
            <span className='opacity-50'>
              {candidate.position.task.story_points}SP
            </span>
          </div>
        </div>
        <RunSolution
          className='border-blue-700'
          iconColor='#0c74ef'
          text='Check implementation'
        />
      </div>
      <div data-testid='candidate-tickets-list'>
        {pendingCandidateAcceptance ? <Loader /> : renderCompletedTrackers()}
      </div>
    </div>
  );
};
