import React, { useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGitFile, clearGitFile } from '../../../../actions/gitActions';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import ReactMarkdown from 'react-markdown';
import CustomTheme from './customTheme';
import { Path } from './Path';
import { Card } from 'react-bootstrap';
import { Loader } from '../../../../shared/components/Loader';
import { RootState } from '../../../../reducers';
import { detectLang } from '../../../../util/detectLang';

interface BlobProps {
  path: string;
  positionId: string;
  id?: string;
}

export const Blob: FC<BlobProps> = ({ path, positionId }) => {
  const dispatch = useDispatch();

  const gitFile = useSelector((state: RootState) => state.gitReducer.gitFile);

  useEffect(() => {
    dispatch(getGitFile(path, positionId));
    return () => {
      dispatch(clearGitFile());
    };
  }, [dispatch, path, positionId]);

  let returnPath;
  if (path) {
    returnPath = path.split('/').slice(0, -1).join('/');
  }

  const renderFile = () => {
    if (gitFile.filePath) {
      if (gitFile.filePath.match(/.txt/g)) {
        return (
          <Card>
            <Card.Body className='p-4'>{gitFile.fileContent}</Card.Body>
          </Card>
        );
      } else if (gitFile.filePath.match(/.md/g)) {
        return (
          <Card>
            <Card.Body className='p-3'>
              <ReactMarkdown source={gitFile.fileContent} />
            </Card.Body>
          </Card>
        );
      } else {
        return (
          <div style={{ paddingTop: 0, display: 'flex' }}>
            <div
              style={{
                flex: 1,
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <SyntaxHighlighter
                style={CustomTheme}
                wrapLines={true}
                language={detectLang(gitFile.filePath)}
                showLineNumbers={true}
                className='pt-4 px-5 pb-2 d-flex'
              >
                {gitFile.fileContent}
              </SyntaxHighlighter>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <>
      {gitFile ? (
        <>
          {path && <Path path={path} returnPath={returnPath} />}
          {renderFile()}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
