import React from 'react';
import cn from 'classnames';

interface Props {
  firstName: string;
  lastName: string;
  uuid: string;
}

export const NameWithShortId: React.FC<Props> = ({
  firstName,
  lastName,
  uuid,
}) => {
  return (
    <span>
      {firstName} {lastName}
      <span
        className={cn({
          'd-none': !firstName,
        })}
      >
        {' '}
      </span>
      <span
        className={cn('font-weight-normal', {
          'text-gray-900': firstName,
        })}
      >
        {uuid.slice(0, 6).toUpperCase()}
      </span>
    </span>
  );
};
