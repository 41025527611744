import { FieldProps } from 'formik';
import React from 'react';
import Select from 'react-dropdown-select';
import cn from 'classnames';
import './SelectField.scss';

interface Props {
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  selectOptions: any[];
  selectValueField: string;
  selectLabelField: string;
  selectPlaceholder: string;
  className?: string;
  initialValue?: Array<any>;
}

export const SelectField: React.SFC<FieldProps & Props> = ({
  field,
  form,
  setFieldTouched,
  selectOptions,
  selectValueField,
  selectLabelField,
  selectPlaceholder,
  className,
  initialValue,
}) => (
  <Select
    options={selectOptions}
    values={initialValue ? initialValue : []}
    searchable={false}
    valueField={selectValueField}
    labelField={selectLabelField}
    onChange={(selectValues: any) =>
      form.setFieldValue(field.name, selectValues[0][selectValueField], true)
    }
    dropdownGap={0}
    color='#F3F3F3'
    placeholder={selectPlaceholder}
    onDropdownOpen={() => {
      setFieldTouched(field.name, true, true);
    }}
    className={cn(
      'bg-gray-500 border-purple-200 rounded focus:border-2 hover:border-gray-400',
      [className]
    )}
  />
);
