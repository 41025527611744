import React from 'react';
import { v4 as uuid } from 'uuid';

export const Describe = () => {
  return (
    <div className='pb-md-12 pb-8'>
      <div className='md:border-t d-md-flex justify-content-between pt-4'>
        {LIST.map(({ title, text }) => (
          <div className='md:w-6/12 mb-4' key={uuid()}>
            <div className='text-warning text-xs md:text-sm lh-24'>
              {title.toUpperCase()}
            </div>
            <div
              className='text-blue-100 pr-2 md:text-2xl md:lh-32'
              style={{ maxWidth: '550px' }}
            >
              {text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const LIST = [
  {
    title: 'what’s wrong with tech recruitment',
    text:
      'Recruiters often struggle to identify the right tech talent. As a result, hiring teams conduct many unnecessary interviews and can miss out on the most promising candidates.',
  },
  {
    title: 'what sfeeda does',
    text:
      'Sfeeda helps evaluate real-life coding skills and assess more candidates in a fraction of the time. This means shortlisting and interviewing only the most relevant candidates',
  },
];
