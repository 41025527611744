import React from 'react';
import { CompanyData } from '../../../typings/enums';
import { Logo } from '../Logo';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Link } from 'react-router-dom';

interface Props {}

const LINKS = [
  {
    title: 'Imprint',
    path: '/imprint',
  },
  {
    title: 'Cookie Policy',
    path: '/cookie',
  },
  {
    title: 'Terms Of Service',
    path: '/terms-of-service',
  },
  {
    title: 'Data Protection Agreement',
    path: '/data-protection',
  },
  {
    title: 'Privacy Policy',
    path: '/privacy-policy',
  },
];

export const Footer = (props: Props) => {
  return (
    <div className='bg-gray-400'>
      <div className='container-fluid xl:px-0 py-4'>
        <div className='d-flex flex-column flex-lg-row justify-content-between mb-lg-6 mb-2'>
          <div className='order-2 order-lg-1 text-center text-lg-left'>
            <Link to='/'>
              <Logo type='dark' />
            </Link>
          </div>
          <div className='d-flex flex-column flex-lg-row order-1 order-lg-2 mb-6 mb-lg-0'>
            <div className='d-lg-flex justify-content-center pr-lg-6 mb-4 mb-lg-0 mx-auto'>
              {LINKS.map((link, i: number) => (
                <NavLink
                  key={link.path}
                  to={link.path}
                  exact
                  className={cn('text-black text-center d-block mb-1 mb-lg-0', {
                    'mr-lg-4': i !== LINKS.length - 1,
                  })}
                >
                  {link.title}
                </NavLink>
              ))}
            </div>
            <div className='ml-lg-3'>
              <a
                href={`mailto:${CompanyData.email}`}
                className='text-lg-right text-center d-block text-black'
              >
                {CompanyData.email}
              </a>
              <a
                href={`tel:${CompanyData.phone.replace(' ', '')}`}
                className='text-lg-right text-center d-block text-black'
              >
                {CompanyData.phone}
              </a>
            </div>
          </div>
        </div>
        <div className='text-sm text-center text-lg-left pl-1'>
          ©2020 {CompanyData.companyName}. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};
