import React from 'react';
import { ImgRightSlider } from './ImgRightSlider';
import { v4 as uuid } from 'uuid';
import './ExperiencedBlock.scss';

const BLOCKS = [
  {
    title: 'Low Drop-off',
    btns: [
      {
        title: 'Highly-relevant task',
        text:
          'Real web application with different task types and tickets to work on. 2—4 hours to complete within strict deadline',
      },
      {
        title: 'Commonly used tools',
        text: 'Git interface, tickets, own repository and code checks',
      },
    ],
    carousel: 'candidates',
    className: 'mb-8 mb-lg-20',
    category: 'CANDIDATES’ EXPERIENCE',
  },
  {
    title: 'Fast Review',
    btns: [
      {
        title: 'Prelimiary Results',
        text:
          'Engineers see what is completed and what doesn’t work in advance',
      },
      {
        title: 'Efficient environment',
        text: 'Code review without editors',
      },
      {
        title: 'Collaborative Tools',
        text: 'Code comments, likes and notes',
      },
    ],
    carousel: 'engineers',
    category: 'Engineers Experience',
  },
];

export const ExperienceBlock = () => {
  return (
    <div className='bg-gray-200 pb-14 pt-8 py-md-20' id='exp-block'>
      <div className='container'>
        {BLOCKS.map((props) => (
          <ImgRightSlider {...props} key={uuid()} />
        ))}
      </div>
    </div>
  );
};
