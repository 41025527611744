import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import { clearSendFeedbackError } from '../../../actions/candidatesActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import { hideModal } from '../../../actions/modalActions';
import { useFeedback } from '../../../hooks/useFeedback';
import { useNote } from '../../../hooks/useNote';
import { NoteType, Actions } from '../../../typings/enums';
import { RecruiterNote } from './RecruiterNote';
import { Feedback } from './Feedback';
import { SendWithoutFeedbackCheckbox } from './SendWithoutFeedbackCheckbox';
import { currentCandidateSelector, notesSelector } from '../../../selectors';

interface Props {
  resolution: string;
}

export const ReviewForm: React.FC<Props> = ({ resolution }) => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState<boolean>(false);

  const [sendWithoutFeedback, setSendWithoutFeedback] = useState<boolean>(
    false
  );

  const currentCandidate = useShallowEqualSelector(currentCandidateSelector);

  const { feedbackValue, setFeedbackValue, updateFeedback } = useFeedback(
    currentCandidate
  );

  const notes = useShallowEqualSelector(notesSelector);

  let noteForRecruiter;
  if (notes && currentCandidate) {
    noteForRecruiter = notes[currentCandidate.position.uuid]?.find(
      (note) =>
        note.body.type === NoteType.to_recruiter &&
        note.user_id === currentCandidate.candidate.user.uuid
    );
  }

  const title = resolution[0].toUpperCase() + resolution.slice(1);

  useEffect(() => {
    return () => {
      currentCandidate &&
        dispatch(clearSendFeedbackError(currentCandidate.candidate.uuid));
    };
  }, [dispatch, currentCandidate]);

  const {
    handleSave: handleSaveNote,
    setText,
    isEditable: isNoteEditable,
    setIsEditable: setIsNoteEdiatble,
    text: noteText,
    error,
  } = useNote(noteForRecruiter, currentCandidate, NoteType.to_recruiter);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const feedbackData = {
      is_positive: resolution === Actions.accept,
      is_sent: false,
      body: sendWithoutFeedback ? null : feedbackValue,
    };
    setIsPending(true);
    try {
      await updateFeedback(feedbackData);
      await handleSaveNote();
      dispatch(hideModal());
    } catch (error) {
      console.error(error);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <>
      <Modal.Header className='border-bottom-0 pb-0'>
        <Modal.Title id={'candidates_progress_modal'}>
          {title} Solution
        </Modal.Title>
        <div className='d-flex justify-content-end mb-4'>
          <button
            className='bg-transparent border-0 p-0 d-block'
            onClick={() => dispatch(hideModal())}
          >
            <CloseIcon />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className='pt-2'>
        <Form onSubmit={handleSubmit}>
          <Feedback
            feedbackValue={feedbackValue}
            setFeedbackValue={setFeedbackValue}
            sendWithoutFeedback={sendWithoutFeedback}
          />
          {resolution === Actions.reject && (
            <SendWithoutFeedbackCheckbox
              sendWithoutFeedback={sendWithoutFeedback}
              setSendWithoutFeedback={setSendWithoutFeedback}
            />
          )}
          <RecruiterNote
            noteText={noteText}
            isNoteEditable={isNoteEditable}
            setIsNoteEdiatble={setIsNoteEdiatble}
            setText={setText}
            error={error}
          />
          <Button
            variant='warning'
            className='w-100 position-abolute rounded-0 py-2'
            style={{ bottom: 0, left: 0 }}
            data-testid='submit'
            disabled={
              (!feedbackValue &&
                !sendWithoutFeedback &&
                resolution === Actions.reject) ||
              isPending
            }
            type='submit'
          >
            {title}
          </Button>
        </Form>
      </Modal.Body>
    </>
  );
};
