import React, { useEffect } from 'react';
import { loadScript, loadStyleSheet } from './calendly';
import calendlyLogo from '../../../assets/calendly.png';
import { Urls } from './calendly';

interface Props {
  url: string;
  text: string;
}

const createClickHandler = (url: string) => (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => {
  e.preventDefault();
  return window.Calendly.initPopupWidget({
    url,
  });
};

export const CalendlyButton: React.FC<Props> = ({ url, text }) => {
  useEffect(() => {
    if (
      !document.querySelector(`script[src="${Urls.CALENDLY_SCRIPT_SOURCE}"]`)
    ) {
      loadScript();
    }
    if (
      !document.querySelector(`link[href="${Urls.CALENDLY_STYLESHEET_SOURCE}"]`)
    ) {
      loadStyleSheet();
    }

    return () => {
      window.Calendly.closePopupWidget();
    };
  }, []);

  return (
    <button
      className='d-flex py-2 px-4 rounded-lg btn btn-outline-dark border-gray-300'
      onClick={createClickHandler(url)}
    >
      <img src={calendlyLogo} alt='' className='mr-3' />
      {text}
    </button>
  );
};
