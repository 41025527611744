import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}

export const GreenBadge: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={cn(
        'rounded-pill px-3 py-1 bg-success text-sm md:text-base d-inline-block mt-10',
        [className]
      )}
    >
      Till July 30
    </div>
  );
};
