import React from 'react';
// import { useDispatch } from 'react-redux';
// import { getPositionsV2 } from '../actions/positionActionsV2';

export const DashboardInit = () => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getPositionsV2());
  // }, []);

  return <div />;
};
