import React from 'react';
import {
  INotification,
  ISolution,
  KeyActivityEvent,
} from '../typings/interfaces';
import { NotificationContentTypes } from '../typings/enums';
import cn from 'classnames';

enum verbs {
  created = 'created',
  completed = 'completed',
  updated = 'updated',
}

export const generateKeyActivityEvent = (
  { verb, content_type_pk, meta }: INotification,
  candidate: ISolution | undefined,
  type: 'widget' | 'page' = 'widget'
): KeyActivityEvent | null => {
  if (
    verb === verbs.created &&
    content_type_pk === NotificationContentTypes.user
  ) {
    return { content: 'started solution', id: 1 };
  }
  if (
    verb === verbs.created &&
    content_type_pk === NotificationContentTypes.note
  ) {
    const parsedMeta = JSON.parse(meta);
    const body = parsedMeta.body && JSON.parse(parsedMeta.body);
    if (!body || (body && !body.lineId)) return null;
    return {
      content: 'new comment created',
      link: `/position/${candidate?.position.uuid}/candidate/${
        candidate?.candidate.uuid
      }/code/${body.specifier.replace('-', '/')}#${body.lineId}`,
      id: 4,
    };
  }
  if (
    (verb === verbs.created || verb === verbs.updated) &&
    content_type_pk === NotificationContentTypes.feedback
  ) {
    const feedback = JSON.parse(meta);
    return {
      content: (
        <span>
          {type === 'widget' && 'is'}
          <span>
            {' '}
            <span
              className={cn({
                'green-pill text-success': feedback.is_positive,
                'red-pill text-red-800': !feedback.is_positive,
                'rounded-pill text-xs px-2 py-1': type === 'widget',
              })}
            >
              {feedback.is_positive ? 'Accepted' : 'Rejected'}
            </span>{' '}
          </span>
          by {candidate?.feedback?.created_by?.full_name}
        </span>
      ),
      id: 2,
    };
  }
  if (
    verb === verbs.completed &&
    content_type_pk === NotificationContentTypes.position
  ) {
    return {
      content: 'posted solution',
      id: 3,
    };
  }
  return null;
};
