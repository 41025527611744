import React from 'react';
import cn from 'classnames';

interface Props {
  title: string;
  color: string;
  className?: string;
}

export const BlockTitle: React.FC<Props> = ({ title, color, className }) => {
  return (
    <div
      className={cn(`font-memo text-${color}`, [className])}
      style={{ letterSpacing: '0.04em' }}
    >
      {title.toUpperCase()}
    </div>
  );
};
