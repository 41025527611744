import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { getSummaryPipeline } from '../../../actions/positionsActions';

import { SummaryItem } from './SummaryItem';
import { CardComponent } from '../../../shared/components/CardComponent';
interface Props {}

export const Summary: React.SFC<Props> = (props) => {
  const dispatch = useDispatch();

  const summaryPipeline = useShallowEqualSelector(
    (state) => state.positionsReducer.summaryPipeline
  );

  useEffect(() => {
    dispatch(getSummaryPipeline());
  }, [dispatch]);

  return (
    <CardComponent>
      <div className='h2 font-weight-bold mb-4'>Summary</div>
      <div className='d-flex'>
        {summaryPipeline &&
          Object.entries(summaryPipeline).map(([type, value]: any) => {
            return <SummaryItem key={type} qty={value.total} type={type} />;
          })}
      </div>
    </CardComponent>
  );
};
