import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InProgressCandidate } from './InProgressCandidate';
import { useParams, useHistory } from 'react-router-dom';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { ISolution } from '../../../typings/interfaces';
import { Stages } from '../../../typings/enums';
import { renderInProgressStageTitle } from '../../../util/renderInProgressStageTitle';
import { getChecks, clearChecks } from '../../../actions/checksActions';
import { Loader } from '../../components/Loader';
import { getInvites } from '../../../actions/invitesActions';
import { StageTitle } from '../../components/StageTitle';
import {
  checksSelector,
  userSelector,
  pendingCandidatesSelector,
  selectConsumedInvitesForPosition,
  pendingChecksSelector,
  pendingInvitesSelector,
  selectInProgressCandidates,
} from '../../../selectors';
import '../../../styles/step.css';

export interface Ticket {
  type: 'task';
  timestamp: any;
}

export interface Commit {
  type: 'commit';
  timestamp: any;
}

export const InProgressCandidates: React.FC = () => {
  const { positionId, candidatesType } = useParams();
  const dispatch = useDispatch();
  let history = useHistory();

  const user = useShallowEqualSelector(userSelector);
  const checks = useShallowEqualSelector(checksSelector);
  const pendingChecks = useShallowEqualSelector(pendingChecksSelector);
  const pendingCandidates = useShallowEqualSelector(pendingCandidatesSelector);
  const filteredSolutions = useShallowEqualSelector((state) =>
    selectInProgressCandidates(state, positionId, candidatesType)
  );
  const positionConsumedInvites = useShallowEqualSelector((state) =>
    selectConsumedInvitesForPosition(state, positionId)
  );

  const pendingInvites = useShallowEqualSelector(pendingInvitesSelector);

  useEffect(() => {
    if (positionId && filteredSolutions && filteredSolutions.length === 0) {
      history.push(`/dashboard#position-${positionId}`);
    }
  }, [filteredSolutions, history, positionId]);

  useEffect(() => {
    dispatch(getInvites());
  }, [dispatch]);

  useEffect(() => {
    if (positionId) {
      dispatch(getChecks(positionId, false));
    }
  }, [dispatch, positionId]);
  useEffect(() => {
    return () => {
      dispatch(clearChecks());
    };
  }, [dispatch]);


  let isLoading =
    pendingCandidates.includes(positionId) || pendingChecks || pendingInvites;

  if (isLoading) {
    return <Loader />;
  }

  if (!filteredSolutions || pendingChecks || pendingInvites) {
    return null;
  }

  return (
    <div data-testid={candidatesType}>
      <StageTitle
        title={renderInProgressStageTitle(candidatesType)}
        qty={filteredSolutions.length}
      />
      {
        !(
          <div className='d-flex'>
            <span className='h2 mr-2'>{renderTitle(candidatesType)}</span>
            <span className='text-gray-600'>{filteredSolutions.length}</span>
          </div>
        )
      }
      {checks &&
        filteredSolutions.map((candidate: ISolution) => {
          const candidateChecks = checks
            .filter(
              (check) => check.user_id === candidate.candidate?.user?.uuid
            )
            .sort(
              (a, b) => Date.parse(b.date_created) - Date.parse(a.date_created)
            );

          const candidateInvite = positionConsumedInvites?.find(
            (invite) =>
              invite.consumed_by.uuid === candidate?.candidate.user?.uuid
          );

          return (
            <InProgressCandidate
              candidate={candidate}
              key={candidate.candidate.uuid}
              user={user}
              checks={candidateChecks}
              date_sent={candidateInvite?.date_created}
            />
          );
        })}
    </div>
  );
};

const renderTitle = (candidatesType: string | undefined) => {
  switch (candidatesType) {
    case Stages.SENT:
      return 'Task sent';
    case Stages.STARTED:
      return 'Task started';
    case Stages.EXPIRED:
      return 'Task expired';
    case Stages.EXPIRING:
      return 'Task expiring';
    case Stages.WORKING:
      return 'Candidates working on task';
    default:
      return '';
  }
};
