import React from 'react';
import { Point } from './Point';

interface Props {
  text: string | JSX.Element;
  mobileImg: string;
  isMatchMediaQuery: boolean;
  desktopImg: string;
  isSfeedaStep?: boolean | undefined;
  stepName: string;
}

export const VerticalStep: React.FC<Props> = ({
  text,
  mobileImg,
  isMatchMediaQuery,
  desktopImg,
  isSfeedaStep,
  stepName,
}) => {
  return (
    <li className='w-100'>
      <Point isSfeedaStep={isSfeedaStep} />
      <div className='item-container pb-6'>
        <div
          className='item'
          style={{ marginTop: !isSfeedaStep ? '-5px' : undefined }}
        >
          {text}
          <img
            src={!isMatchMediaQuery ? mobileImg : desktopImg}
            alt={stepName}
            className='img-fluid'
          />
        </div>
      </div>
    </li>
  );
};
