import React, { useEffect, ReactNode, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { getChecks, clearChecks } from '../../actions/checksActions';
import { getSolutionStage } from '../../util/getSolutionStage';
import { NoteWidget } from '../../reviewer/components/NoteWidget';
import { Header } from '../CandidatesContainer/Header';
import { Loader } from '../../shared/components/Loader';
import { Tabs } from './Tabs';
import { ShareTabs } from './ShareTabs';
import { CandidateInfo } from './CandidateInfo';
import { getNotes, clearNotes } from '../../actions/notesActions';
import { clearCandidate } from '../../actions/candidatesActions';
import { getRepoInfo } from '../../actions/gitActions';
import { ShareSignup } from '../../shared/components/ShareSignup';
import { getCandidate } from '../../actions/candidatesActions';
import { UserRoles, Stages } from '../../typings/enums';
import { userRoleSelector } from '../../selectors';
import {
  currentCandidateSelector,
  pendingCandidateSelector,
  errorCandidateSelector,
  checksSelector,
} from '../../selectors';
import { ShareTop } from '../../shared/pages/Share/ShareTop';
import { RootState } from '../../reducers';
import {
  saveSocialViews,
  checkSharedSolutionCandidate,
} from '../../actions/socialActions';
import { RequestToPostSocialData } from '../../actions/types/socialActionTypes';
import { v4 as uuid } from 'uuid';

interface Props {
  children: ReactNode;
}

export const CandidateContainer: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const { shareuuid, candidateId, positionId } = useParams();
  const stageRef = useRef<Stages | undefined>();
  const share = useSelector((state: RootState) => state.socialReducer.shared);
  const { redirect } = useSelector((state: RootState) => state.socialReducer);
  const userRole = useShallowEqualSelector(userRoleSelector);
  const currentCandidate = useShallowEqualSelector(currentCandidateSelector);
  const pendingCandidate = useShallowEqualSelector(pendingCandidateSelector);
  const errorCandidate = useShallowEqualSelector(errorCandidateSelector);
  const userId = useSelector(
    (state: RootState) =>
      state.candidatesReducer.currentCandidate?.candidate.user.uuid
  );

  useEffect(() => {
    dispatch(checkSharedSolutionCandidate(shareuuid));
  }, [shareuuid]);

  useEffect(() => {
    const socialPayload: RequestToPostSocialData = {
      content_type_id: 'CANDIDATE_POSITION',
      created_by_id:  shareuuid,
      kind: 'VIEW',
      object_id: candidateId || uuid(),
    };
    if(localStorage.getItem('shareviews')) {
      return () => undefined;
    }
    
    dispatch(saveSocialViews(socialPayload));
    localStorage.setItem('shareviews', 'true');
  }, []);

  const checks = useShallowEqualSelector(checksSelector);

  const commitsArr = checks
    ? checks.sort(
        (a, b) => Date.parse(b.date_created) - Date.parse(a.date_created)
      )
    : [];

  useEffect(() => {
    if (!shareuuid) {
      dispatch(getCandidate(candidateId, positionId));
    }
  }, []);

  useEffect(() => {
    if (typeof userId !== 'undefined') {
      dispatch(
        getChecks(positionId, userRole === UserRoles.REVIEWER || share, userId)
      );
    }

    return () => {
      dispatch(clearChecks());
    };
  }, [userId]);

  useEffect(() => {
    if (currentCandidate && !share) {
      dispatch(
        getNotes(
          currentCandidate.position.uuid,
          currentCandidate.candidate.user?.uuid
        )
      );
    }
    return () => {
      !share && dispatch(clearNotes());
    };
  }, [dispatch, currentCandidate, share]);

  useEffect(() => {
    if (!share && positionId && currentCandidate)
      dispatch(
        getRepoInfo({
          position_id: positionId,
          user_id: currentCandidate.candidate.user.uuid,
        })
      );
  }, [dispatch, positionId, currentCandidate, share]);

  useEffect(() => {
    return () => {
      dispatch(clearCandidate());
    };
  }, [dispatch]);

  let codeLink = share
    ? `/share/${shareuuid}/${positionId}/${candidateId}/code`
    : `/position/${positionId}/candidate/${candidateId}/code`;
  if (commitsArr && commitsArr.length) {
    if (commitsArr.length > 1) {
      codeLink = `${codeLink}/${commitsArr[commitsArr.length - 1].commit}/${
        commitsArr[0].commit
      }`;
    } else {
      codeLink = `${codeLink}/${commitsArr[0].commit}`;
    }
  }

  if (!stageRef.current) {
    stageRef.current =
      currentCandidate && getSolutionStage(currentCandidate, userRole);
  }

  if (pendingCandidate) {
    return <Loader />;
  }

  if (!currentCandidate) {
    return null;
  }

  // if (redirect) {
  //   return <Redirect to='/' />;
  // }

  return (
    <div
      className='bg-white'
      data-testid={`candidate-info-${currentCandidate?.candidate.uuid}`}
    >
      {share && (
        <ShareTop height={34} type={'dark'} gap={40} shareNavsStyle={'dark'} />
      )}
      <div className='px-4 px-md-5 py-3 w-100'>
        {!share && (
          <>
            <Header stage={stageRef.current} />
            <CandidateInfo
              candidate={currentCandidate}
              className='mb-6'
              positionId={positionId}
            />
          </>
        )}
        <div className='mb-6 border-bottom'>
          {!share ? (
            <Tabs
              userRole={userRole}
              codeLink={codeLink}
              candidate={currentCandidate}
            />
          ) : (
            <ShareTabs userRole={userRole} codeLink={codeLink} />
          )}
        </div>
        <div className='px-0 mx-0'>
          {errorCandidate ? errorCandidate : null}
          {share && !userRole ? (
            <div className='d-flex'>
              <div className='flex-grow-1'>{children}</div>
              <div className='flex-shrink-0'>
                <ShareSignup />
              </div>
            </div>
          ) : (
            <>{children}</>
          )}
          {userRole === UserRoles.REVIEWER && !share && (
            <NoteWidget candidate={currentCandidate} />
          )}
        </div>
      </div>
    </div>
  );
};
