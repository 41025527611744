import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

interface Props {
  iconSize?: SizeProp;
  className?: string;
  id?: string;
  text?: string;
  candidate?: any;
  iconColor?: string;
}

export const RunSolution: React.FC<Props> = ({
  iconSize,
  iconColor,
  className,
  id,
  text = 'Open Air Pollution App'
}) => {
  
  const repoInfo = useSelector((state: RootState) => state.gitReducer.repoInfo);
  const url = repoInfo?.[0]?.demo_url ? repoInfo[0].demo_url : undefined;

  if (!url) return null;

  return (
    <a
      href={url}
      className={`btn rounded d-inline-flex align-items-center hover:text-purple ${className}`}
      target='_blank'
      rel='noopener noreferrer'
      id={id ? id : undefined}
    >
      <FontAwesomeIcon
        icon={faPlayCircle}
        className='mr-2'
        size={iconSize ? iconSize : 'lg'}
        color={iconColor ? iconColor : undefined}
      />
      {text}
    </a>
  );
};
