export const sortByDateAndIdDesc = (a: any, b: any) => {
  const dateA = Date.parse(a.date_due);
  const dateB = Date.parse(b.date_due);
  const idA = a.id;
  const idB = b.id;
  if (dateA < dateB) return -1;
  if (dateA > dateB) return 1;
  if (idB > idA) return 1;
  if (idB < idA) return -1;
  return 0;
};
