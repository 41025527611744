import React, { useState, useRef, useCallback } from 'react';
import { UserDropdown } from './UserDropdown';
import { NotificationDropdown } from './NotificationDropdown';
import { DashboardSearch } from '../../shared/components/DashboardSearch';
import cn from 'classnames';
import { Logo } from '../../shared/components/Logo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import './Header.scss';

interface Props {
  withSearch?: boolean;
  withLogo?: boolean;
}

export const Header: React.FC<Props> = ({ withSearch, withLogo }) => {
  const { hideNotificationsBellIcon } = useFlags();

  const notificationsButtonRef = useRef<any>();
  const userProfileButtonRef = useRef<any>();

  const [isDropdownOpen, setIsDropdownOpen] = useState({
    isOpen: false,
    type: '',
  });

  const clickOutsideHandler = useCallback(
    (event: MouseEvent | TouchEvent) => {
      let condition = !userProfileButtonRef.current.contains(event.target);
      if (!hideNotificationsBellIcon) {
        condition =
          notificationsButtonRef.current &&
          !notificationsButtonRef.current.contains(event.target) &&
          !userProfileButtonRef.current.contains(event.target);
      }
      if (isDropdownOpen.isOpen && isDropdownOpen.type && condition)
        setIsDropdownOpen({
          isOpen: false,
          type: '',
        });
    },
    [isDropdownOpen.isOpen, isDropdownOpen.type, hideNotificationsBellIcon]
  );

  return (
    <div
      className='position-sticky'
      style={{
        top: 0,
        zIndex: 1000,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <nav
        className='navbar navbar-light bg-white w-100 d-flex header-container'
        style={{ zIndex: 1002 }}
      >
        {withLogo && (
          <Link to='/' data-testid='logo'>
            <Logo type='dark' />
          </Link>
        )}
        {withSearch && <DashboardSearch />}
        <div
          className={cn('nav-right d-flex pl-4', {
            'ml-auto': !withSearch,
          })}
        >
          <NotificationDropdown
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            onClickOutside={clickOutsideHandler}
            curRef={notificationsButtonRef}
          />
          <UserDropdown
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            onClickOutside={clickOutsideHandler}
            curRef={userProfileButtonRef}
          />
        </div>
      </nav>
    </div>
  );
};
