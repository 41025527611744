import React from 'react';
import { Quantity } from './Quantity';
import { Stages } from '../../../typings/enums';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { Loader } from '../../../shared/components/Loader';
import { isExpiring } from '../../../util/invitesHelpers';
import { selectCandidtesNotConsumedInvites } from '../../../selectors';

interface Props {
  type: Stages;
  handleClick: ({ title, type }: { title: string; type: string }) => void;
  modalTitle: string;
  title: string;
  positionId: string;
}

export const InvitesQty: React.FC<Props> = ({
  type,
  modalTitle,
  handleClick,
  title,
  positionId
}) => {
  const pendingInvites = useShallowEqualSelector(
    (state) => state.invitesReducer.pendingInvites
  );

  const positionNotConsumedInvites = useShallowEqualSelector((state) =>
    selectCandidtesNotConsumedInvites(state, positionId)
  );

  const expiredInvites = positionNotConsumedInvites?.filter((invite) =>
    isExpiring(invite.date_created, invite.duration)
  );

  return (
    <div className='flex-grow-1 position-absolute inset-0'>
      {type && !pendingInvites ? (
        <Quantity
          type={type}
          length={positionNotConsumedInvites?.length || 0}
          expiring={expiredInvites?.length || 0}
          handleClick={handleClick}
          modalTitle={modalTitle}
          title={title}
        />
      ) : (
        <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
          <Loader />
        </div>
      )}
    </div>
  );
};
