import { PositionsActionsTypesConstants, PositionsActionTypes } from './types';
import { Action } from 'redux';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { IPosition } from '../typings/interfaces';
import { apiFetch } from '../util/apiFetch';
import { sleep } from '../util/sleep';
export const getSummaryPipeline = (): ThunkAction<
  void,
  RootState,
  PositionsActionTypes,
  Action<string>
> => async (dispatch) => {
  dispatch({
    type: PositionsActionsTypesConstants.PENDING_SUMMARY_PIPELINE,
  });
  try {
    const response = await fetch('/api/positions/summary/pipeline?.json');

    dispatch({
      type: PositionsActionsTypesConstants.GET_SUMMARY_PIPELINE,
      payload: await response.json(),
    });
  } catch (err) {
    dispatch({
      type: PositionsActionsTypesConstants.GET_SUMMARY_PIPELINE,
      payload: [],
    });
  }
};

let i = 0;
export const getPositions = (): ThunkAction<
  void,
  RootState,
  PositionsActionTypes,
  Action<string>
> => async (dispatch) => {
  dispatch({
    type: PositionsActionsTypesConstants.PENDING_POSITIONS,
  });
  try {
    const response = await apiFetch('company', '/position/');
    if (response.error) {
      if (i < 10) {
        await sleep(500);
        dispatch(getPositions());
        i++;
      } else {
        throw new Error(response.error);
      }
    } else {
      dispatch({
        type: PositionsActionsTypesConstants.GET_POSITIONS,
        payload: response.data,
      });
      i = 0;
    }
  } catch (err) {
    dispatch({
      type: PositionsActionsTypesConstants.ERROR_POSITIONS,
      payload: err.message,
    });
  }
};

export const getPosition = (
  id: string
): ThunkAction<void, RootState, PositionsActionTypes, Action<string>> => async (
  dispatch
) => {
  try {
    dispatch({
      type: PositionsActionsTypesConstants.PENDING_POSITION,
    });

    const response = await apiFetch('candidate', `/v1/position/${id}/`);

    if (response.data) {
      dispatch({
        type: PositionsActionsTypesConstants.GET_POSITION,
        payload: response.data,
      });
    } else {
      dispatch({
        type: PositionsActionsTypesConstants.ERROR_POSITION,
        payload: response.status,
      });
    }
  } catch (err) {
    return err;
  }
};

export const clearPositions = (): PositionsActionTypes => ({
  type: PositionsActionsTypesConstants.CLEAR_POSITIONS,
});

export const createPosition = (position: IPosition): PositionsActionTypes => ({
  type: PositionsActionsTypesConstants.CREATE_POSITION,
  payload: position,
});

export const setCurrentPosition = (
  positionObj: IPosition
): PositionsActionTypes => {
  return {
    type: PositionsActionsTypesConstants.SET_CURRENT_POSITION,
    payload: positionObj,
  };
};

export const updatePositions = (newPositions: IPosition[]) => ({
  type: PositionsActionsTypesConstants.UPDATE_POSITIONS,
  payload: newPositions,
});

export const showAddPostion = (value: boolean) => ({
  type: PositionsActionsTypesConstants.TOGGLE_ADD_POSITION,
  payload: value,
});
