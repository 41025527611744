import React from 'react';

interface Props {
  isAccepted: boolean;
}

export const Hint: React.FC<Props> = ({ isAccepted }) => {
  return (
    <div style={{ maxWidth: 200, top: 20 }} className='position-sticky'>
      <div className='mb-2'>
        <span role='img' aria-label='right'>
          👉
        </span>{' '}
        Candidates will be informed on Sfeeda that their solutions have been{' '}
        {isAccepted ? 'accepted' : 'rejected'}
      </div>
      {!isAccepted && (
        <div>
          <span role='img' aria-label='right'>
            📎
          </span>{' '}
          Don't forget to attach the feedback to provide a better candidate
          experience
        </div>
      )}
    </div>
  );
};
