import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title?: string;
}

export const FreeTrialBtn = ({ title = 'Free Trial' }) => {
  return (
    <Link
      className={
        'lg:text-white hover:text-white text-yellow-400 lg:hover:text-black lg:border hover:border-0 btn rounded-lg text-2xl bg-transparent py-2 lg:hover:bg-yellow-700 w-100'
      }
      style={{ maxWidth: '228px' }}
      to='/free-trial'
    >
      {title}
    </Link>
  );
};
