import { IChallenge, IChallengeCategory } from '../../typings/interfaces';
export const CLEAR_CHALLENGE = 'CLEAR_CHALLENGE';
export const GET_CHALLENGE = 'GET_CHALLENGE';
export const GET_CHALLENGES_CATEGORIES = 'GET_CHALLENGES_CATEGORIES';

interface GetChallengeAction {
  type: typeof GET_CHALLENGE;
  payload: IChallenge;
}

interface GetChallengesCategoriesAction {
  type: typeof GET_CHALLENGES_CATEGORIES;
  payload: IChallengeCategory[];
}

export interface ChallengesState {
  categories: IChallengeCategory[];
  challenge: null | IChallenge;
}

export type ChallengesActionTypes =
  | GetChallengeAction
  | GetChallengesCategoriesAction;
