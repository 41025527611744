import React, { useCallback } from 'react';
import { showAlert } from '../../../actions/alertActions';
import { AlertType } from '../../../typings/enums';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';

interface Props {
  link: string;
}

export const CopyLinkBtn: React.FC<Props> = ({ link }) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(
    () =>
      dispatch(
        showAlert({
          type: AlertType.success,
          message: 'Link is copied to clipboard',
        })
      ),
    [dispatch]
  );

  return (
    <CopyToClipboard text={link}>
      <button
        className='rounded-lg bg-transparent border border-transparent hover:border-blue-500 text-primary px-3 focus:outline-none'
        onClick={handleClick}
      >
        Copy link
      </button>
    </CopyToClipboard>
  );
};
