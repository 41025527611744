import React, { useRef } from 'react';
import { NoteBtns } from '../../../shared/components/NoteBtns';
import { ISolution, INote } from '../../../typings/interfaces';
import TextareaAutosize from 'react-autosize-textarea';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useNote } from '../../../hooks/useNote';
import cn from 'classnames';
import {
  errorCreateNoteSelector,
  pendingNoteUpdateSelector,
} from '../../../selectors';

interface Props {
  currentCandidate: ISolution;
  note?: INote;
  placeholder?: string;
  type?: string;
}

export const NoteTextarea: React.FC<Props> = ({
  currentCandidate,
  note,
  type,
  placeholder = 'Your thoughts',
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const errorCreateNote = useShallowEqualSelector(errorCreateNoteSelector);
  const pendingNoteUpdate = useShallowEqualSelector(pendingNoteUpdateSelector);

  const {
    handleSave,
    handleCancel,
    setText,
    isEditable,
    setIsEditable,
    text,
    error,
  } = useNote(note, currentCandidate, type);

  return (
    <>
      <TextareaAutosize
        value={text}
        className={cn('w-100 focus:outline-none', {
          'border py-2 px-4 rounded bg-gray-800': !text || isEditable,
          'border-red-500': error,
          'border-0': text && !isEditable && !errorCreateNote,
        })}
        ref={textAreaRef}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setText(e.target.value)
        }
        onFocus={() => setIsEditable(true)}
        placeholder={placeholder}
        readOnly={pendingNoteUpdate}
        rows={!text ? 5 : undefined}
      />
      {isEditable ? (
        <NoteBtns handleSave={handleSave} handleCancel={handleCancel} />
      ) : null}
    </>
  );
};
