import React from 'react';
import { ReactComponent as CompletedIcon } from '../../../assets/completed.svg';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { ReactComponent as RejectIcon } from '../../../assets/rejected.svg';
import { CookiesAlert } from './CookiesAlert';
import { AlertType } from '../../../typings/enums';
import './AlertComponent.scss';

export const AlertComponent = () => {
  const type = useShallowEqualSelector((state) => state.alertReducer.type);
  const message = useShallowEqualSelector(
    (state) => state.alertReducer.message
  );
  const isShown = useShallowEqualSelector(
    (state) => state.alertReducer.isShown
  );

  if (!isShown) {
    return null;
  } else {
    return (
      <div
        className='w-100 position-fixed mx-auto d-flex justify-content-center align-items-center form-alert rounded-lg text-white px-4'
        style={{
          bottom: '15px',
          zIndex: 2000,
        }}
      >
        <div
          className='d-flex p-3 rounded-lg align-items-center'
          style={{
            background: 'rgba(0,0,0,.75)',
          }}
        >
          {type === AlertType.success && <CompletedIcon className='mr-3' />}
          {type === AlertType.fail && <RejectIcon className='mr-3' />}
          {type !== AlertType.cookies ? message : <CookiesAlert />}
        </div>
      </div>
    );
  }
};
