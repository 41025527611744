import React, { useState } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { sendSubscribe } from '../../../actions/subscribeActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { AgreeCheckbox } from '../../components/SubscribeForms/AgreeCheckbox';
import { ThankYouBlock } from '../../components/SubscribeForms/ThankYouBlock';
import { SubmitBtn } from '../../components/SubscribeForms/SubmitBtn';
import { FormContainer } from '../../components/SubscribeForms/FormContainer';
import { FormFields } from '../../components/FormFields';
import { IFields } from '../../../typings/interfaces';
import { Subscribes } from '../../../typings/enums';
import { SubscribeApiError } from '../../components/SubscribeForms/SubscribeApiError';

import * as Yup from 'yup';
import { errorSubscribesSelector } from '../../../selectors';

const initialValues: FormValues = {
  name: '',
  email: '',
  agree: false,
};

interface FormValues {
  name: string;
  email: string;
  agree: boolean;
}

export const TryChallenge: React.FC = () => {
  const dispatch = useDispatch();

  const [subscriber, setSubscriber] = useState<string | undefined>(undefined);

  const errorSubscribes = useShallowEqualSelector(errorSubscribesSelector);

  const fields: IFields = {
    name: {
      label: 'Name',
      id: 'name',
    },
    email: {
      label: 'Email',
      id: 'email',
    },
  };

  return (
    <FormContainer>
      <div className='d-flex flex-column align-items-center w-100'>
        {!subscriber ? (
          <div className='mb-6 mb-lg-0'>
            <h1
              className='text-4-6xl font-weight-bold mx-auto leading-thin mb-4 text-center'
              style={{ maxWidth: '551px' }}
            >
              Try Challenge
            </h1>
            <div
              className='text-2xl mb-6 text-center'
              style={{ lineHeight: '32px', maxWidth: '565px' }}
            >
              Get and invite to see what the platform looks like and the example
              of task candidates work on
            </div>
            <SubscribeApiError
              type={Subscribes.TRY}
              errorSubscribes={errorSubscribes}
            />
            <Formik
              initialValues={initialValues}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(
                  sendSubscribe(
                    {
                      name: `${values.name.trim()}`,
                      email: values.email,
                    },
                    Subscribes.TRY,
                    (response) => {
                      setSubmitting(false);
                      if (response.data) {
                        setSubscriber(values.name);
                      }
                    }
                  )
                );
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .required('Required')
                  .min(2, 'Must be 2 characters or more'),
                email: Yup.string()
                  .email('Invalid email address')
                  .required('Required'),
                agree: Yup.boolean().oneOf([true], 'Required'),
              })}
            >
              {({
                handleSubmit,
                errors,
                handleBlur,
                touched,
                handleChange,
                isSubmitting,
              }) => (
                <div style={{ maxWidth: '430px' }} className='mx-auto'>
                  <form
                    className='items-center d-flex flex-column w-100'
                    onSubmit={handleSubmit}
                  >
                    <FormFields
                      fields={fields}
                      errors={errors}
                      touched={touched}
                    />
                    <AgreeCheckbox
                      isError={errors.agree}
                      isTouched={touched.agree}
                    />
                    <SubmitBtn
                      btnTitle='Try Challenge'
                      isSubmitting={isSubmitting}
                      id='subscribe_submit'
                    />
                  </form>
                </div>
              )}
            </Formik>
          </div>
        ) : (
          <ThankYouBlock subscriber={subscriber} />
        )}
      </div>
    </FormContainer>
  );
};
