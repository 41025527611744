import React from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { LoaderMini } from '../components/LoaderMini';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { useHistory } from 'react-router-dom';
import { FormContainer } from '../components/SubscribeForms/FormContainer';
import * as Yup from 'yup';
import { IFields } from '../../typings/interfaces';
import { FormFields } from '../components/FormFields';
import { Button } from 'react-bootstrap';
import { useQuery } from '../../hooks/useQuery';
import { errorText } from '../../util/fetchWithToken';
import { AlertType } from '../../typings/enums';
import { showAlert } from '../../actions/alertActions';
import { userFetchErrorSelector } from '../../selectors';

interface FormValues {
  email: string;
  newPassword: string;
}

export const ResetPassword: React.SFC = () => {
  let query = useQuery();
  let history = useHistory();
  const dispatch = useDispatch();
  const token = query.get('token');
  const email = query.get('email');

  if (!token || !email) {
    history.push('/404');
  }

  const initialValues: FormValues = {
    email: email!,
    newPassword: '',
  };

  const userFetchError = useShallowEqualSelector(userFetchErrorSelector);

  const fields: IFields = {
    email: {
      label: 'Email',
      id: 'field_username',
      autoComplete: 'username',
      readOnly: true,
    },
    newPassword: {
      label: 'New Password',
      id: 'field_new_password',
    },
  };

  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            const res = await fetch(
              `${window.OPTIONS.API_URL}/auth/password/force/`,
              {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  token: token!,
                  new_password: values.newPassword,
                  email: values.email,
                }),
              }
            );

            if (!res.ok) {
              throw new Error(errorText);
            }
            dispatch(
              showAlert({
                message: 'Password has been changed',
                type: AlertType.success,
              })
            );
            history.push('/signin');
          } catch (error) {
            dispatch(
              showAlert({ message: error.message, type: AlertType.fail })
            );
          }
        }}
        validationSchema={Yup.object({
          newPassword: Yup.string()
            .required('Required')
            .min(2, 'Must be 2 characters or more'),
        })}
      >
        {({ errors, touched, isSubmitting }) => (
          <div style={{ maxWidth: '430px' }} className='mx-auto'>
            <h1 className='text-4-5xl text-center font-weight-bold'>
              Create New Password
            </h1>
            {userFetchError && (
              <div className='text-danger mb-3' id='login_form_error'>
                {userFetchError.split('\n').map((str: string, i: number) => (
                  <div key={`str-${i}`}>{str}</div>
                ))}
              </div>
            )}
            <Form className='items-center d-flex flex-column w-100'>
              <FormFields fields={fields} errors={errors} touched={touched} />
              <Button
                variant='warning'
                disabled={isSubmitting}
                id='sign_in'
                type='submit'
                className='md:text-xl mb-6 rounded-lg d-flex align-items-center justify-content-center h-10 md:h-16 font-weight-bold'
              >
                {isSubmitting ? <LoaderMini /> : 'Create'}
              </Button>
            </Form>
          </div>
        )}
      </Formik>
    </FormContainer>
  );
};
