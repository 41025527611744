import { RootState } from '../reducers';
import { createSelector } from 'reselect';
import { getSolutionStage } from '../util/getSolutionStage';
import { userRoleSelector } from './user';
import { Pipeline, ISolution } from '../typings/interfaces';
import { Stages } from '../typings/enums';
import { filterSolutionByStage } from '../util/filterSolutionByStage';
import { sortByDateAndIdAsc } from '../util/sortByDateAndIdAsc';
import { sortByDateAndIdDesc } from '../util/sortByDateAndIdDesc';

export const candidatesSelector = (state: RootState) =>
  state.candidatesReducer.candidates;
export const pendingCandidatesSelector = (state: RootState) =>
  state.candidatesReducer.pendingCandidates;
export const currentCandidateSelector = (state: RootState) =>
  state.candidatesReducer.currentCandidate;
export const pendingCandidateSelector = (state: RootState) =>
  state.candidatesReducer.pendingCandidate;
export const errorCandidateSelector = (state: RootState) =>
  state.candidatesReducer.errorCandidate;
export const attachedFeedbacksSelector = (state: RootState) =>
  state.candidatesReducer.attachedFeedbacks;
export const errorFeedbackSelector = (state: RootState) =>
  state.candidatesReducer.errorFeedback;
export const candidateNoteSelector = (state: RootState) =>
  state.candidatesReducer.candidateNote;
export const pendingCandidateNoteSelector = (state: RootState) =>
  state.candidatesReducer.pendingCandidateNote;
export const errorCandidateNoteSelector = (state: RootState) =>
  state.candidatesReducer.errorCandidateNote;
export const errorCandidatesSelector = (state: RootState) =>
  state.candidatesReducer.errorCandidates;

export const selectRecruiterPositionPipeline = createSelector(
  [
    candidatesSelector,
    userRoleSelector,
    (_: any, positionId: string) => positionId,
  ],
  (candidates, userRole, positionId): Pipeline | undefined => {
    return candidates?.[positionId]?.reduce(
      (pipeline: Pipeline, solution: ISolution) => {
        const stage = getSolutionStage(solution, userRole);
        pipeline[stage as keyof Pipeline] =
          pipeline?.[stage as keyof Pipeline] + 1;

        return pipeline;
      },
      {
        started: 0,
        expiring: 0,
        expired: 0,
        submitted: 0,
        accepted: 0,
        rejected: 0,
        archived: 0,
        processed: 0,
      }
    );
  }
);

export const selectInProgressCandidates = createSelector(
  [
    candidatesSelector,
    userRoleSelector,
    (_: any, positionId: string, __: any) => positionId,
    (_: any, __: any, candidatesType: string) => candidatesType,
  ],
  (candidates, userRole, positionId, candidatesType) => {
    if (!candidates) return undefined;
    const curPositionCandidates = candidates[positionId];
    if (!curPositionCandidates) return undefined;
    if (candidatesType === 'working') {
      return [
        ...curPositionCandidates
          .filter((solution) =>
            filterSolutionByStage(solution, userRole, Stages.EXPIRED)
          )
          .sort(sortByDateAndIdDesc),
        ...curPositionCandidates
          .filter((solution) =>
            filterSolutionByStage(solution, userRole, Stages.EXPIRING)
          )
          .sort(sortByDateAndIdDesc),
        ...curPositionCandidates
          .filter((solution) =>
            filterSolutionByStage(solution, userRole, Stages.STARTED)
          )
          .sort(sortByDateAndIdAsc),
      ];
    }
    return curPositionCandidates.filter((solution) =>
      filterSolutionByStage(solution, userRole, candidatesType)
    );
  }
);

export const selectFilteredCandidates = createSelector(
  candidatesSelector,
  userRoleSelector,
  (_: any, positionId: string, __: any) => positionId,
  (_: any, __: any, stage: string) => stage,
  (candidates, userRole, positionId, stage) => {
    return candidates?.[positionId]?.filter((candidate) =>
      filterSolutionByStage(candidate, userRole, stage)
    );
  }
);
