import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { hideModal } from '../../../actions/modalActions';
import { Actions, AlertType } from '../../../typings/enums';
import {
  informCandidate,
  sendCandidateToArchive,
  prolongCandidate,
  sendFeedback,
} from '../../../actions/candidatesActions';
import { showAlert } from '../../../actions/alertActions';
import { Content } from './Content';

export const ConfirmModalContent: React.FC = () => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState<boolean>(false);

  const modalType = useShallowEqualSelector(
    (state) => state.modalReducer.modalType
  );

  const currentCandidate = useShallowEqualSelector(
    (state) => state.candidatesReducer.currentCandidate
  );

  const handleAccept = useCallback(async () => {
    if (!currentCandidate) return;
    try {
      let message = '';
      setPending(true);
      if (modalType === Actions.PROLONG) {
        await dispatch(prolongCandidate());
        message += 'Candidate has another 7 days to complete the challenge';
      }
      if (modalType === Actions.ARCHIVE) {
        await dispatch(sendCandidateToArchive());
        message = 'Candidate has been added to archive';
      }
      if (modalType === Actions.rejectExpired) {
        await dispatch(
          sendFeedback(currentCandidate, {
            is_positive: false,
            is_sent: false,
            body: null,
            is_attached: false,
          })
        );
        message = 'Candidate has been rejected';
      }
      if (modalType === Actions.inform) {
        message = 'Candidate has been informed';
        await dispatch(informCandidate(currentCandidate));
        if (currentCandidate.feedback?.is_positive === false) {
          message = 'Candidate has been informed and added to archived';
          await dispatch(sendCandidateToArchive());
        }
      }

      dispatch(
        showAlert({
          type: AlertType.success,
          message,
        })
      );
      dispatch(hideModal());
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  }, [currentCandidate, dispatch, modalType]);

  if (!currentCandidate) {
    return null;
  }

  return (
    <>
      <>
        <Form>
          <Modal.Body>
            <Content modalType={modalType} candidate={currentCandidate} />
          </Modal.Body>
          <Modal.Footer className='border-top-0'>
            <Button
              variant='warning'
              className='rounded-lg text-lg px-4'
              onClick={handleAccept}
              disabled={pending}
            >
              Yes
            </Button>
            <Button
              className='rounded-lg text-lg px-4'
              variant='outline-dark'
              onClick={() => dispatch(hideModal())}
            >
              No
            </Button>
          </Modal.Footer>
        </Form>
      </>
    </>
  );
};
