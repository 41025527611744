import { act } from 'react-dom/test-utils';
import {
  TicketsTypesConstants,
  TicketsState,
  TicketsActionsTypes,
} from '../actions/types';

const initialState: TicketsState = {
  tickets: undefined,
  pendingTickets: false,
  errorTickets: '',
  activeTickets: undefined,
  instanceTicket: undefined
};

export const ticketsReducer = (
  state = initialState,
  action: TicketsActionsTypes
): TicketsState => {
  switch (action.type) {
    case TicketsTypesConstants.GET_TICKETS_BY_CHALLENGE:
      return {
        ...state,
        tickets: action.payload,
        pendingTickets: false,
        errorTickets: '',
      };
    case TicketsTypesConstants.PENDING_TICKETS_BY_CHALLENGE:
      return {
        ...state,
        pendingTickets: true,
      };
    case TicketsTypesConstants.ERROR_TICKETS_BY_CHALLENGE:
      return {
        ...state,
        pendingTickets: false,
        errorTickets: action.payload,
      };
    case TicketsTypesConstants.SET_ACTIVE_TICKETS:
      return {
        ...state,
        activeTickets: action.payload,
      };
    case TicketsTypesConstants.CLEAR_ACTIVE_TICKETS:
      return {
        ...state,
        activeTickets: undefined,
      };
      case TicketsTypesConstants.SET_INSTANCE_TICKETS:
        return {
          ...state,
          instanceTicket: action.payload
        }
    default:
      return state;
  }
};
