import { IUser, IMe } from '../../typings/interfaces';

export enum UserActionsTypesConstants {
  SAVE_TOKENS = 'SAVE_TOKENS',
  SIGNOUT_USER = 'SIGNOUT_USER',
  SIGNIN_USER = 'SIGNIN_USER',
  PENDING_FETCH_USER = 'PENDING_FETCH_USER',
  FINISH_FETCH_USER = 'FINISH_FETCH_USER',
  ERROR_FETCH_USER = 'ERROR_FETCH_USER',
  IDENTIFY_USER = 'IDENTIFY_USER',
  GET_USER_INFO = 'GET_USER_INFO',
  ERROR_SIGN_UP = 'ERROR_SIGN_UP',
  CLEAR_ERROR_SIGN_UP = 'CLEAR_ERROR_SIGN_UP',
  PENDING_USER_INFO = 'PENDING_USER_INFO',
  ERROR_USER_INFO = 'ERROR_USER_INFO',
}

interface pendingUserInfoAction {
  type: typeof UserActionsTypesConstants.PENDING_USER_INFO;
}
interface LogoutActionType {
  type: typeof UserActionsTypesConstants.SIGNOUT_USER;
}
interface IdentifyActionType {
  type: typeof UserActionsTypesConstants.IDENTIFY_USER;
}

interface FinishFetchUserActionType {
  type: typeof UserActionsTypesConstants.FINISH_FETCH_USER;
}
interface PendingFetchUserActionType {
  type: typeof UserActionsTypesConstants.PENDING_FETCH_USER;
}
interface LoginActionType {
  type: typeof UserActionsTypesConstants.SIGNIN_USER;
  payload: IUser;
}
interface ErrorUserInfoActionType {
  type: typeof UserActionsTypesConstants.ERROR_USER_INFO;
  payload: string;
}
interface ErrorFetchUserActionType {
  type: typeof UserActionsTypesConstants.ERROR_FETCH_USER;
  payload: string;
}
export interface GetUserInfoAction {
  type: typeof UserActionsTypesConstants.GET_USER_INFO;
  payload: IMe;
}
export interface ErrorSignupAction {
  type: typeof UserActionsTypesConstants.ERROR_SIGN_UP;
  payload: string;
}
export interface ClearErrorSignupAction {
  type: typeof UserActionsTypesConstants.CLEAR_ERROR_SIGN_UP;
}
export interface SaveTokenAction {
  type: typeof UserActionsTypesConstants.SAVE_TOKENS;
  payload: { accessToken: undefined; refreshToken: undefined };
}

export interface AuthState {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  user: null | IUser;
  intialUserLoaded: boolean;
  pendingUserFetch: boolean;
  userFetchError: string;
  isIdentified: boolean;
  me: IMe | undefined;
  errorSignUp: string;
  pendingMe: boolean;
  errorMe: string;
}

export type UserActionTypes =
  | SaveTokenAction
  | FinishFetchUserActionType
  | LogoutActionType
  | LoginActionType
  | ErrorFetchUserActionType
  | IdentifyActionType
  | ErrorUserInfoActionType
  | GetUserInfoAction
  | ErrorSignupAction
  | ClearErrorSignupAction
  | pendingUserInfoAction
  | PendingFetchUserActionType;
