import {
  TeamActionsTypesConstants,
  TeamsState,
  TeamsActionTypes,
} from '../actions/types';

const initialState: TeamsState = {
  teams: [],
  pending: false,
  error: '',
  employees: undefined,
  pendingEmployees: undefined,
  errorEmployees: '',
};

export const teamsReducer = (
  state = initialState,
  action: TeamsActionTypes
): TeamsState => {
  switch (action.type) {
    case TeamActionsTypesConstants.GET_TEAMS:
      return { ...state, teams: action.payload, pending: false };
    case TeamActionsTypesConstants.PENDING_TEAMS:
      return { ...state, pending: true };
    case TeamActionsTypesConstants.ERROR_TEAMS:
      return { ...state, pending: false, error: action.payload };
    case TeamActionsTypesConstants.CLEAR_TEAMS:
      return { ...state, teams: [] };
    case TeamActionsTypesConstants.ADD_TEAM:
      return { ...state, teams: [...state.teams, action.payload] };
    case TeamActionsTypesConstants.GET_TEAM_EMPLOYEES:
      return {
        ...state,
        employees: {
          ...state.employees,
          [action.payload.teamId]: action.payload.employees,
        },
        pendingEmployees: {
          ...state.pendingEmployees,
          [action.payload.teamId]: false,
        },
        errorEmployees: '',
      };
    case TeamActionsTypesConstants.PENDING_TEAM_EMPLOYEES:
      return {
        ...state,
        pendingEmployees: {
          ...state.pendingEmployees,
          [action.payload.teamId]: true,
        },
      };
    case TeamActionsTypesConstants.ERROR_TEAM_EMPLOYEES:
      return {
        ...state,
        pendingEmployees: undefined,
        errorEmployees: action.payload,
      };
    case TeamActionsTypesConstants.REMOVE_EMPLOYEE:
      return {
        ...state,
        employees: {
          ...state.employees,
          [action.payload.teamId]:
            state.employees?.[action.payload.teamId]?.filter(
              (employee) => employee.employee.uuid !== action.payload.employeeId
            ) || [],
        },
      };
    default:
      return state;
  }
};
