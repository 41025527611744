import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from 'react-bootstrap';
import { ReactComponent as CompletedIcon } from '../../../assets/completed.svg';

interface Props {
  invitationLink: string;
  onProceed: () => void;
  text: string | JSX.Element;
}

export const InvitationLink: React.FC<Props> = ({
  invitationLink,
  onProceed,
  text,
}) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const linkElement = (
    <div
      id='invitation-link'
      className='text-primary mb-4 text-sm mx-auto text-center'
      style={{ wordBreak: 'break-all' }}
    >
      {invitationLink}
    </div>
  );

  return (
    <div className='mb-5 mx-auto'>
      {!isLinkCopied ? (
        <>
          <div
            className='h4 font-weight-bold text-center mx-auto'
            style={{ maxWidth: '270px' }}
          >
            {text}
          </div>
          {linkElement}
          <div className='text-center'>
            <CopyToClipboard text={invitationLink}>
              <Button
                className='border-gray-600'
                variant='outline-dark'
                onClick={() => {
                  setIsLinkCopied(true);
                }}
                id='copy-invitation-btn'
              >
                Copy Link
              </Button>
            </CopyToClipboard>
          </div>
        </>
      ) : (
        <>
          <div className='text-center mb-1'>
            <CompletedIcon
              className='mr-2'
              style={{ width: '40px', height: '40px' }}
            />
          </div>
          <div
            className='h4 font-weight-bold text-center mx-auto'
            style={{ maxWidth: '270px' }}
          >
            Link copied
          </div>
          {linkElement}
          <div className='text-center'>
            <Button
              className='px-5 rounded-lg'
              variant='warning'
              onClick={onProceed}
            >
              Proceed
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
