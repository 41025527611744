import React, { useState } from 'react';
import { INote } from '../../../typings/interfaces';
import { Note } from '../../components/Note';
import { NoteBtns } from '../../../shared/components/NoteBtns';
import { updateNote } from '../../../actions/notesActions';
import { useDispatch } from 'react-redux';

interface Props {
  note: INote;
}
export enum NoteType {
  TeamNote = 'TeamNote'
}

export const TeamNote: React.FC<Props> = ({ note }) => {
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [text, setText] = useState<string>(
    note.body.text ? note.body.text : note.body
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleSave = async () => {
    try {
      await dispatch(
        updateNote(note.id as number, { body: { ...note.body, text } })
      );
      setIsEditable(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setText(note.body.text ? note.body.text : note.body);
    setIsEditable(false);
  };

  if (isEditable) {
    return (
      <div className='p-2'>
        <div className='form-group mb-3'>
          <textarea
            onChange={handleChange}
            value={text}
            className='form-control'
          />
        </div>
        <NoteBtns handleSave={handleSave} handleCancel={handleCancel} />
      </div>
    );
  }

  return (
    <div className='p-2 border-top'>
      <Note
        note={note}
        handleClick={() => setIsEditable(true)}
        type={NoteType.TeamNote}
      />
    </div>
  );
};
