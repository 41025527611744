export const SAVEVIEWCOUNT = 'SAVEVIEWCOUNT';
export const SHAREDCANDIDATEDATA = 'SHAREDCANDIDATEDATA';
export const SHARESOLUTIONTOGGLE = 'SHARESOLUTIONTOGGLE';
export const SHAREOFFREDIRECT = 'SHAREOFFREDIRECT';

export interface saveViewCountTypes {
  type: typeof SAVEVIEWCOUNT;
  payload: ResponseFromPostSocialData;
}

export interface RequestToPostSocialData {
  content_type_id: 'CANDIDATE_POSITION';
  created_by_id: string; // '280532a7-f746-473e-a844-bf7821425ad5', userid ?
  kind: 'VIEW';
  object_id: string; //'162' wrong data type on backend ,
}

export interface SharedCandidateDataTypes {
  type: typeof SHAREDCANDIDATEDATA;
  payload: any;
}

export interface ShareSolutionToggleTypes {
  type: typeof SHARESOLUTIONTOGGLE;
  payload: boolean;
}

export interface ShareOffRedirect {
  type: typeof SHAREOFFREDIRECT;
  payload: boolean;
}

export interface ResponseFromPostSocialData {
  content_type_id: 'CANDIDATE_POSITION';
  created_by_id: string; // '280532a7-f746-473e-a844-bf7821425ad5',
  date_created: string; //'2020-11-04T09:35:03',
  object_id: string; //'162' wrong data type on backend ,
  pk: string; //'9d3641f7-71b9-462b-8f93-afb13935f3e2',
  views: number;
}

export type socialActionType =
  | saveViewCountTypes
  | SharedCandidateDataTypes
  | ShareSolutionToggleTypes
  | ShareOffRedirect;
