import React from 'react';
import { ErrorPage } from './ErrorPage';
import { ReactComponent as Image500 } from '../../../assets/500.svg';

export const Page500: React.SFC = () => {
  return (
    <ErrorPage
      title='Server Error'
      text='We’re sorry, the server encountered an internal error was unable to complete your request'
    >
      <div className='text-center mb-10'>
        <Image500 />
      </div>
    </ErrorPage>
  );
};
