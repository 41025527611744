import {
  LikesActionsTypes,
  LikesState,
  LikesActionsTypesConstants
} from '../actions/types';

const initialState: LikesState = {
  likesByCandidate: undefined,
  likesByCandidatePosition: undefined,
  likesByNote: undefined,
  likesByFeedback: undefined,
  pendingLikes: false,
  errorLikes: ''
};

export const likesReducer = (
  state = initialState,
  action: LikesActionsTypes
): LikesState => {
  switch (action.type) {
    case LikesActionsTypesConstants.GET_LIKES_BY_CANDIDATE:
      return {
        ...state,
        likesByCandidate: action.payload,
        pendingLikes: false,
        errorLikes: ''
      };
    case LikesActionsTypesConstants.GET_LIKES_BY_CANDIDATE_POSITION:
      return {
        ...state,
        likesByCandidatePosition: action.payload,
        pendingLikes: false,
        errorLikes: ''
      };
    case LikesActionsTypesConstants.GET_LIKES_BY_FEEDBACK:
      return {
        ...state,
        likesByFeedback: action.payload,
        pendingLikes: false,
        errorLikes: ''
      };
    case LikesActionsTypesConstants.GET_LIKES_BY_NOTE:
      return {
        ...state,
        likesByNote: action.payload,
        pendingLikes: false,
        errorLikes: ''
      };
    case LikesActionsTypesConstants.CLEAR_LIKES_BY_CANDIDATE:
      return {
        ...state,
        likesByCandidate: undefined,
        pendingLikes: false,
        errorLikes: ''
      };
    case LikesActionsTypesConstants.CLEAR_LIKES_BY_CANDIDATE_POSITION:
      return {
        ...state,
        likesByCandidatePosition: undefined,
        pendingLikes: false,
        errorLikes: ''
      };
    case LikesActionsTypesConstants.CLEAR_LIKES_BY_FEEDBACK:
      return {
        ...state,
        likesByFeedback: undefined,
        pendingLikes: false,
        errorLikes: ''
      };
    case LikesActionsTypesConstants.CLEAR_LIKES_BY_NOTE:
      return {
        ...state,
        likesByNote: undefined,
        pendingLikes: false,
        errorLikes: ''
      };
    case LikesActionsTypesConstants.PENDING_LIKES:
      return {
        ...state,
        pendingLikes: true
      };
    case LikesActionsTypesConstants.ERROR_LIKES:
      return {
        ...state,
        pendingLikes: false,
        errorLikes: action.payload
      };
    default:
      return state;
  }
};
