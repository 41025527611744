import React, { useEffect } from 'react';
import { SummaryInfo } from '../../../shared/components/SummaryInfo';
import { Tickets } from '../../../shared/components/Tickets';
import { CandidateNote } from './CandidateNote';
import { Commits } from '../../components/Commits';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useParams, Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cn from 'classnames';
import {
  userSelector,
  positionsSelector,
  currentCandidateSelector,
} from '../../../selectors';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';


export const OverviewCandidate = () => {
  const { showShareViews } = useFlags();
  const { positionId } = useParams();

  const { redirect, shared } = useSelector((state: RootState) => state.socialReducer);

 
  
  const currentCandidate = useShallowEqualSelector(currentCandidateSelector);

  const positions = useShallowEqualSelector(positionsSelector);
  const user = useShallowEqualSelector(userSelector);
  

  const curPosition =
    positions && positions.find((position) => position.uuid === positionId);

  // if (redirect) {
  //   return <Redirect to='/' />;
  // }


  if (!currentCandidate) return null;


  return (
    <div>
      <div className='mb-6'>
        Air Pollution application shows data about the quality of the air from
        all
        <br /> over the world. It utilises{' '}
        <a href='http://openaq.org' rel='noopener noreferrer' target='_blank'>
          http://openaq.org
        </a>{' '}
        to obtain the data.
      </div>
      <div className='d-flex flex-column flex-lg-row w-100 flex-grow-1 mb-8 justify-content-lg-between '>
        <div className={cn('flex-grow-1', { 'pr-8 lg:w-6/12': !shared })}>
          <div style={{ maxWidth: '900px' }} className='mb-4 mb-lg-0'>
            <Tickets
              candidate={currentCandidate}
              share={shared}
              positionId={positionId}
            />
            <div className='pt-2'>
              <Commits isHidden={!shared} />
            </div>
          </div>
        </div>
        {!shared && curPosition && (
          <div className='lg:w-4/12'>
            <>
              <SummaryInfo
                candidate={currentCandidate}
                position={curPosition}
              />
              <CandidateNote
                positionId={positionId}
                candidate={currentCandidate}
              />
            </>
          </div>
        )}
      </div>
    </div>
  );
};
