import React from 'react';
import { Formik, Form } from 'formik';
import { sendSubscribe } from '../../../actions/subscribeActions';
import { AgreeCheckbox } from '../../components/SubscribeForms/AgreeCheckbox';
import { SubmitBtn } from '../../components/SubscribeForms/SubmitBtn';
import { FormFields } from '../../components/FormFields';
import { Subscribes } from '../../../typings/enums';
import { SubscribeApiError } from '../../components/SubscribeForms/SubscribeApiError';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { IFields } from '../../../typings/interfaces';
import { Skills } from './Skills';
import * as Yup from 'yup';
import { errorSubscribesSelector } from '../../../selectors';

interface Props {
  setSubscriber: React.Dispatch<React.SetStateAction<string | undefined>>;
  withTitle?: boolean;
  fixedWidth?: boolean;
}

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  agree: boolean;
  skills: string[];
}

export const FreeTrialForm: React.FC<Props> = ({
  withTitle,
  fixedWidth,
  setSubscriber,
}) => {
  const initialValues: FormValues = {
    first_name: '',
    last_name: '',
    email: '',
    agree: false,
    skills: [],
  };

  const dispatch = useDispatch();

  const errorSubscribes = useShallowEqualSelector(errorSubscribesSelector);

  const fields: IFields = {
    first_name: {
      label: 'First name',
      id: 'first_name',
    },
    last_name: {
      label: 'Last name',
      id: 'last_name',
    },
    email: {
      label: 'Email',
      id: 'email',
    },
  };

  const validationSchema = Yup.object({
    skills: Yup.string().required('Required'),
    first_name: Yup.string()
      .required('Required')
      .min(2, 'Must be 2 characters or more'),
    last_name: Yup.string()
      .required('Required')
      .min(2, 'Must be 2 characters or more'),
    email: Yup.string().email('Invalid email address').required('Required'),
    agree: Yup.boolean().oneOf([true], 'Required'),
  });

  return (
    <div className='mb-6 mb-lg-0'>
      {withTitle && (
        <>
          <h1 className='text-4-6xl font-weight-bold mx-auto leading-thin mb-4 text-center'>
            Request free trial
          </h1>
          <div
            className='text-2xl mb-6 text-center'
            style={{ lineHeight: '32px', maxWidth: '430px' }}
          >
            Use Sfeeda for technical screening for 2 months for free
          </div>
        </>
      )}
      <SubscribeApiError
        type={Subscribes.FREE_TRIAL}
        errorSubscribes={errorSubscribes}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            sendSubscribe(
              {
                name: `${values.first_name.trim()} ${values.last_name.trim()} (${
                  Subscribes.FREE_TRIAL
                } ${values.skills.filter((el) => el).join(', ')})`,
                email: values.email,
              },
              Subscribes.FREE_TRIAL,
              (response: any) => {
                setSubmitting(false);
                if (response.data) {
                  setSubscriber(`${values.first_name} ${values.last_name}`);
                }
              }
            )
          );
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          touched,
          handleChange,
          isSubmitting,
        }) => (
          <div
            style={{ maxWidth: fixedWidth ? '430px' : undefined }}
            className='mx-auto'
          >
            <Form className='items-center d-flex flex-column w-100'>
              <FormFields fields={fields} errors={errors} touched={touched} />
              <Skills
                values={values.skills}
                isError={errors.skills}
                isTouched={touched.skills}
              />
              <AgreeCheckbox isError={errors.agree} isTouched={touched.agree} />
              <SubmitBtn
                btnTitle='Request trial'
                isSubmitting={isSubmitting}
                id='subscribe_submit'
              />
              <div className='text-center mb-6 mt-2 text-sm md:text-base'>
                No credit card required
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};
