import { countStoryPoints } from './countStoryPoints';

export const compareValues = (key: string, order: boolean) => {
  return function innerSort(a: any, b: any): any {
    let varA;
    let varB;

    let propertyA = a[key];
    let propertyB = b[key];

    if (key.includes('.')) {
      const keyArr = key.split('.');
      if (keyArr.length === 2) {
        propertyA = a[keyArr[0]]?.[keyArr[1]];
        propertyB = b[keyArr[0]]?.[keyArr[1]];
      }
      if (keyArr.length === 3) {
        propertyA = a[keyArr[0]]?.[keyArr[1]]?.[keyArr[2]];
        propertyB = b[keyArr[0]]?.[keyArr[1]]?.[keyArr[2]];
      }
    }

    if (key === 'story_points') {
      const completedTrackersA = a.candidate.trackers?.filter(
        (tracker: any) => {
          return tracker.status === 'COMPLETED';
        }
      );
      const completedTrackersB = b.candidate.trackers?.filter(
        (tracker: any) => {
          return tracker.status === 'COMPLETED';
        }
      );
      varA = countStoryPoints(completedTrackersA) || 0;
      varB = countStoryPoints(completedTrackersB) || 0;
    } else {
      if (!propertyA || !propertyB) {
        return 0;
      }
      // fix if value null
      if (propertyA === null) propertyA = '';
      if (propertyB === null) propertyB = '';

      varA =
        typeof propertyA === 'string' ? propertyA.toUpperCase() : propertyA;
      varB =
        typeof propertyB === 'string' ? propertyB.toUpperCase() : propertyB;
    }

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order ? comparison * -1 : comparison;
  };
};
