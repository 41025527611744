import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { CandidateLink } from '../../../shared/components/CandidateLink';
import { compareValues } from '../../../util/compareValues';
import { PredictionBadge } from '../../../shared/components/PredictionBadge';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';
import { ISolution, ILike, INote } from '../../../typings/interfaces';
import { HeaderCell } from '../../../shared/components/HeaderCell';
import { LikesRenderType, NoteType, Stages } from '../../../typings/enums';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useLikes } from '../../../hooks/useLikes';
import { Loader } from '../../../shared/components/Loader';
import { LikesContentTypeEnum } from '../../../typings/enums';
import { LikesBlock } from '../../../shared/components/LikesBlock';
import { StageTitle } from '../../../shared/components/StageTitle';
import { CommentsCount } from '../../../shared/components/CommentsCount';
import { isComment } from '../../../typings/typeGuards';
import { getNotes, clearNotes } from '../../../actions/notesActions';
import { useDispatch } from 'react-redux';
import { ReactComponent as ExclamationIcon } from '../../../assets/exclamation.svg';
import {
  selectFilteredCandidates,
  pendingCandidatesSelector,
  notesSelector,
} from '../../../selectors';
import moment from 'moment';

const TABLE_HEADER = [
  {
    title: 'Candidate',
    property: 'first_name',
  },
  {
    title: 'Task Recieved',
    property: 'date_ready',
  },
  {
    title: 'Reviewed By',
  },
];

export const Submitted: React.FC = () => {
  const dispatch = useDispatch();
  const { shotGoodBadFit, showLikeDeslikeFeature } = useFlags();

  const { positionId } = useParams();

  const filteredCandidates = useShallowEqualSelector((state) =>
    selectFilteredCandidates(state, positionId, Stages.SUBMITTED)
  );

  const [order, setOrder] = useState(true);
  const [propertyKey, setPropertyKey] = useState('Candidate');

  const pendingCandidates = useShallowEqualSelector(pendingCandidatesSelector);
  const notes = useShallowEqualSelector(notesSelector);

  const { likesByCandidatePosition, pendingLikes } = useLikes(
    filteredCandidates
  );

  useEffect(() => {
    dispatch(getNotes(positionId));
    return () => {
      dispatch(clearNotes());
    };
  }, [dispatch, positionId]);

  const renderTableHeader = () => (
    <thead className='font-weight-normal'>
      <tr>
        {TABLE_HEADER.map((cell) => (
          <HeaderCell
            key={cell.title}
            order={order}
            setOrder={setOrder}
            propertyKey={propertyKey}
            setPropertyKey={setPropertyKey}
            property={cell.property}
            title={cell.title}
          />
        ))}
      </tr>
    </thead>
  );

  let isLoading = pendingCandidates.includes(positionId) || pendingLikes;

  if (isLoading) {
    return <Loader />;
  }

  if (!filteredCandidates || pendingLikes) {
    return null;
  }

  return (
    <>
      <StageTitle title='Tasks' qty={filteredCandidates.length} />
      <div
        className='border rounded px-4 py-2'
        style={{
          maxWidth: '762px',
        }}
      >
        <Table responsive size='sm' hover>
          {renderTableHeader()}
          <tbody>
            {filteredCandidates
              .sort(compareValues(propertyKey, order))
              .map((candidate: ISolution) => {
                let currentCandidateLikes: ILike[] | undefined;

                const dataJSON: any = candidate && JSON.parse(candidate.data);
                if (showLikeDeslikeFeature && likesByCandidatePosition) {
                  currentCandidateLikes = likesByCandidatePosition.filter(
                    (like) =>
                      like.content_type_id ===
                        LikesContentTypeEnum.CANDIDATE_POSITION &&
                      like.object_id === String(candidate.id)
                  );
                }

                const curCandidateComments = notes?.[
                  candidate.position.uuid
                ].filter(
                  (note) =>
                    isComment(note) &&
                    note.user_id === candidate.candidate.user?.uuid
                );

                let recruiterNote: INote | undefined;
                if (notes?.[candidate.position.uuid]) {
                  recruiterNote = notes?.[candidate.position.uuid]?.find(
                    (note) =>
                      note.body.type === NoteType.to_recruiter &&
                      note.user_id === candidate.candidate.user.uuid
                  );
                }

                return (
                  <tr key={candidate.candidate.uuid}>
                    <td className='border-0'>
                      <div className='d-flex align-items-center'>
                        <CandidateLink candidate={candidate} className='mr-2' />
                        {shotGoodBadFit ? (
                          <PredictionBadge
                            fit={dataJSON ? dataJSON.fit : undefined}
                            candidateId={candidate.candidate.uuid}
                            className='mr-2'
                          />
                        ) : null}
                        {recruiterNote && (
                          <ExclamationIcon
                            style={{ marginBottom: '3px' }}
                            className='orange-exclamation'
                          />
                        )}
                      </div>
                    </td>

                    <td className='border-0'>
                      {moment
                        .utc(candidate.date_ready)
                        .local()
                        .format('DD/MM/YYYY, H:mm')}
                    </td>
                    <td className='border-0'>
                      <div className='d-flex align-items0center'>
                        {currentCandidateLikes && showLikeDeslikeFeature && (
                          <LikesBlock
                            votes={currentCandidateLikes}
                            type={LikesRenderType.count}
                            className='mr-4'
                          />
                        )}
                        {curCandidateComments &&
                          curCandidateComments.length > 0 && (
                            <CommentsCount
                              qty={curCandidateComments.length}
                              size='sm'
                            />
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
};
