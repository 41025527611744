import { store } from '../store';
import {
  startConnection,
  stopConnection,
} from '../actions/notificationsActions';

export class NotificationsSocket {
  private ws: WebSocket;
  constructor(private readonly url: string) {
    this.url = url;
    this.ws = new WebSocket(url);
  }

  private setWs() {
    this.ws = new WebSocket(this.url);
  }

  init(positionsIds: string[]) {
    this.setWs();
    this.ws.onopen = () => {
      try {
        this.ws.send(
          JSON.stringify({
            action: 'init',
            user_id: store.getState().userReducer.me.uuid,
            rel_positions_pk: positionsIds,
            token: store.getState().userReducer.accessToken,
          })
        );
        store.dispatch(startConnection());
      } catch (e) {
        store.dispatch(stopConnection());
      }
    };

    this.ws.onmessage = (evt) => JSON.parse(evt.data);

    this.ws.onclose = () => {
      store.dispatch(stopConnection());
    };

    this.ws.onerror = (err) => {
      store.dispatch(stopConnection());
    };
  }
}
