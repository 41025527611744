import React from 'react';
import { Loader } from '../../components/Loader';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { useScrollToElement } from '../../../hooks/useScrollToElement';

import { Header } from './Header';
import { Describe } from './Describe';
import { HowItWorks } from './HowItWorks';
import { TestCodding } from './TestCodding';
import { Steps } from './Steps';
import { RoutineTasks } from './RoutineTasks';
import { SinglePlatform } from './SinglePlatform';
import { Pricing } from './Pricing';
import { BlueFooter } from '../../components/BlueFooter';
import { Plans } from '../../components/Plans';
import { useMatchMedia } from '../../../hooks/useMatchMedia';

import './Home.scss';
import { intialUserLoadedSelector } from '../../../selectors';

export const Home = () => {
  const [isDesktop] = useMatchMedia(992);
  const intialUserLoaded = useShallowEqualSelector(intialUserLoadedSelector);

  const { setRef, handleLinkClick } = useScrollToElement();

  if (!intialUserLoaded) return <Loader />;

  return (
    <div className='bg-blue-900 w-100 min-vh-full home'>
      <Header handleLinkClick={handleLinkClick} />
      <div className='container'>
        <Describe />
        <HowItWorks />
        <TestCodding />
        <Steps />
        <RoutineTasks />
        <SinglePlatform />
        <div
          ref={setRef}
          data-refkey='pricing'
          id='pricing'
          className='text-blue-100'
        >
          {isDesktop ? <Pricing /> : <Plans title='Pricing' />}
        </div>
      </div>
      <BlueFooter />
    </div>
  );
};
