import React from 'react';

interface Props {
  sendWithoutFeedback: boolean;
  setSendWithoutFeedback: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SendWithoutFeedbackCheckbox: React.FC<Props> = ({
  sendWithoutFeedback,
  setSendWithoutFeedback,
}) => {
  return (
    <div className='custom-control custom-checkbox'>
      <input
        type='checkbox'
        className='custom-control-input'
        id='send-feedback-checkbox'
        data-testid='send-feedback-checkbox'
        checked={sendWithoutFeedback}
        onChange={() => {
          setSendWithoutFeedback(!sendWithoutFeedback);
        }}
      />
      <label
        className={'custom-control-label text-black leading-tight'}
        htmlFor='send-feedback-checkbox'
      >
        Send without feedback
      </label>
    </div>
  );
};
