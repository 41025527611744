import React from 'react';
import { UserRoles } from '../../../typings/enums';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { FreshInvite } from './FreshInvite';
import { Invite } from '../../../typings/interfaces';

export const FreshInvites = () => {
  const invites = useShallowEqualSelector(
    (state) => state.invitesReducer.invites
  );

  const curPosition = useShallowEqualSelector(
    (state) => state.positionsReducer.curPosition
  );

  let teamInvites: Invite[] = [];
  if (invites && curPosition) {
    teamInvites = invites.filter(
      (invite) =>
        (invite.role.name === UserRoles.RECRUITER ||
          invite.role.name === UserRoles.REVIEWER) &&
        invite?.data?.position_id === curPosition.uuid &&
        !invite.is_consumed
    );
  }

  return (
    <div>
      {teamInvites
        .sort((a, b) => Date.parse(b.date_created) - Date.parse(a.date_created))
        .map((invite) => (
          <FreshInvite invite={invite} key={invite.uuid} />
        ))}
    </div>
  );
};
