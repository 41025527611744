import { ISolution } from '../typings/interfaces';

export const isExpired = (candidate: ISolution) => {
  if (typeof candidate.date_due === 'string') {
    return Date.now() > Date.parse(candidate.date_due);
  }
};
export const isExpiring = (candidate: ISolution) => {
  if (typeof candidate.date_due === 'string') {
    return (
      Date.now() < Date.parse(candidate.date_due) &&
      Date.parse(candidate.date_due) - Date.now() < 12 * 60 * 60 * 1000
    );
  }
};
