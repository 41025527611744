import React from 'react';
import { v4 as uuid } from 'uuid';
import { Feature } from '../../pages/HiringTeam/Feature';
import cn from 'classnames';

const BLOCKS = [
  {
    title: 'Test relevant skills',
    text:
      'Algorithmic tasks aren’t really about coding. \nInstead, we let candidates solve real world\n problems according to a real workflow',
  },
  {
    title: 'Reduce Bias',
    text:
      'Anonymize any personal data such as\n gender and nationality. Let reviewers\n check only the quality of the code',
  },
  {
    title: 'Great candidate experience',
    text:
      'Candidates get Git-like environment,\n well described tickets and basic test\n analysis — everything they love',
  },
];

export const Features = () => {
  return (
    <div className='py-10 d-flex flex-column flex-lg-row justify-content-lg-between'>
      {BLOCKS.map((props, i: number) => (
        <Feature
          {...props}
          className={cn({
            'mr-lg-3': i !== BLOCKS.length - 1,
          })}
          key={uuid()}
        />
      ))}
    </div>
  );
};
