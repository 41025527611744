import React from 'react';
import { Button } from 'react-bootstrap';
import { ISolution } from '../../../typings/interfaces';
import { UserRoles } from '../../../typings/enums';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../../actions/alertActions';
import { AlertType } from '../../../typings/enums';

interface Props {
  isEditable: boolean;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  currentCandidate: ISolution;
  setFeedbackValue: React.Dispatch<
    React.SetStateAction<string | number | string[] | undefined>
  >;
  updateFeedback: Function;
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  userRole: string | undefined;
}

export const FeedbackBtns: React.FC<Props> = ({
  isEditable,
  textAreaRef,
  currentCandidate,
  setFeedbackValue,
  updateFeedback,
  setIsEditable,
  userRole,
}) => {
  const dispatch = useDispatch();

  if (currentCandidate?.feedback?.is_sent) {
    return null;
  }

  const feedBackBtn = async () => {
    try {
      let message = 'Feedback successfully ';

      if (!currentCandidate?.feedback?.body) message += 'created';
      if (currentCandidate?.feedback?.body) message += 'updated';

      await updateFeedback();
      setIsEditable(false);

      dispatch(
        showAlert({
          type: AlertType.success,
          message,
        })
      );
    } catch (error) {
      dispatch(
        showAlert({
          type: AlertType.fail,
          message: 'Something went wrong',
        })
      );
    }
  };

  return (
    <>
      {!isEditable && currentCandidate?.feedback?.body ? (
        <Button
          variant='outline-dark'
          className='py-1 mr-2'
          style={{ minWidth: '74px' }}
          onClick={() => {
            if (textAreaRef.current) {
              textAreaRef.current.focus();
              moveCursorToEnd(textAreaRef.current);
            }
          }}
        >
          Edit
        </Button>
      ) : null}
      {isEditable ? (
        <div className='d-flex align-items-center'>
          <Button
            variant='warning'
            className={
              userRole === UserRoles.RECRUITER ? 'mr-2 text-sm' : 'mr-2 text-xs'
            }
            style={{
              minWidth: userRole === UserRoles.RECRUITER ? '74px' : '',
              height: userRole === UserRoles.RECRUITER ? '34px' : '',
            }}
            onClick={feedBackBtn}
          >
            Save
          </Button>
          <Button
            variant='outline-dark'
            className={
              userRole === UserRoles.RECRUITER ? 'mr-2 text-sm' : 'mr-2 text-xs'
            }
            style={{
              minWidth: userRole === UserRoles.RECRUITER ? '74px' : '',
              height: userRole === UserRoles.RECRUITER ? '34px' : '',
            }}
            onClick={() => {
              setFeedbackValue(
                currentCandidate?.feedback?.body === null
                  ? undefined
                  : currentCandidate?.feedback?.body
              );
              setIsEditable(false);
            }}
          >
            Cancel
          </Button>
        </div>
      ) : null}
    </>
  );
};

function moveCursorToEnd(el: any) {
  if (typeof el.selectionStart == 'number') {
    el.selectionStart = el.selectionEnd = el.value.length;
  } else if (typeof el.createTextRange != 'undefined') {
    el.focus();
    const range = el.createTextRange();
    range.collapse(false);
    range.select();
  }
}
