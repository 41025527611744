import React, { useState } from 'react';
import { Plans } from '../../components/Plans';
import { FreeTrialForm } from '../FreeTrial/FreeTrialForm';
import { ReactComponent as CompletedIcon } from '../../../assets/completed.svg';
interface Props {
  setRef: (node: HTMLDivElement | null) => void;
}

export const FormBlock: React.FC<Props> = ({ setRef }) => {
  const [subscriber, setSubscriber] = useState<string | undefined>(undefined);
  if (subscriber) {
    return (
      <div
        className='bg-blue-900 py-12 d-flex flex-column align-items-center justify-content-center w-100 text-gray-100 text-center'
        style={{ minHeight: '810px' }}
        data-refkey='free-trial-form'
        ref={setRef}
      >
        <CompletedIcon className='icon--56 mb-4' />
        <div
          className='text-4-5xl font-weight-bold leading-tight mb-1'
          style={{ maxWidth: '620px' }}
        >
          {subscriber}, your free trial is requested!
        </div>
        <div className='text-2-5xl'>Our team member will contact you soon</div>
      </div>
    );
  }

  return (
    <div
      className='bg-blue-900 py-10'
      data-refkey='free-trial-form'
      ref={setRef}
    >
      <div className='container text-gray-100'>
        <div className='mb-2 lg:w-6/12'>
          <div className='text-xl text-success mb-2'>Till August 9</div>
          <div className='text-27 mb-4 md:text-4-5xl lh-32 md:lh-48'>
            Request a Free 2 Months Trial and get 10% life-time discount
          </div>
        </div>
        <div className='d-flex flex-column flex-lg-row justify-content-lg-between'>
          <div className='lg:w-4/12 mb-4 order-lg-2'>
            <Plans />
          </div>
          <div className='lg:w-5/12'>
            <FreeTrialForm setSubscriber={setSubscriber} />
          </div>
        </div>
      </div>
    </div>
  );
};
