import React from 'react';
import { Features } from '../../../shared/components/Recruiters/Features';

export const FeaturesBlock = () => {
  return (
    <div className='container py-20' style={{ minHeight: '50vh' }}>
      <div style={{ maxWidth: '788px' }}>
        <div className='text-4xl font-weight-bold mb-1 leading-thin'>
          Don’t overlook great candidates because of poor CVs. We help you test
          real coding skills.
        </div>
        <div className='text-xl lh-32'>
          With our ready-made tech challenges, you can screen more applicants,
          get preliminary assessments and shortlist the most relevant candidates
        </div>
      </div>
      <Features />
    </div>
  );
};
