import {
  ITeam,
  TeamEmployess,
  IEmployee,
  PendingEmployees,
} from '../../typings/interfaces';

export enum TeamActionsTypesConstants {
  GET_TEAMS = 'GET_TEAMS',
  PENDING_TEAMS = 'PENDING_TEAMS',
  CLEAR_TEAMS = 'CLEAR_TEAMS',
  ERROR_TEAMS = 'ERROR_TEAMS',
  ADD_TEAM = 'ADD_TEAM',
  GET_TEAM_EMPLOYEES = 'GET_TEAM_EMPLOYEES',
  PENDING_TEAM_EMPLOYEES = 'PENDING_TEAM_EMPLOYEES',
  ERROR_TEAM_EMPLOYEES = 'ERROR_TEAM_EMPLOYEES',
  REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE',
  UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE',
}

interface GetTeamEmployeeAction {
  type: typeof TeamActionsTypesConstants.GET_TEAM_EMPLOYEES;
  payload: {
    employees: IEmployee[];
    teamId: string;
  };
}
interface PendingTeamEmployeeAction {
  type: typeof TeamActionsTypesConstants.PENDING_TEAM_EMPLOYEES;
  payload: {
    teamId: string;
  };
}
interface ErrorTeamEmployeeAction {
  type: typeof TeamActionsTypesConstants.ERROR_TEAM_EMPLOYEES;
  payload: string;
}

interface AddTeamAction {
  type: typeof TeamActionsTypesConstants.ADD_TEAM;
  payload: ITeam;
}

interface GetTeamsAction {
  type: typeof TeamActionsTypesConstants.GET_TEAMS;
  payload: ITeam[];
}
interface ErrorTeamsAction {
  type: typeof TeamActionsTypesConstants.ERROR_TEAMS;
  payload: string;
}
interface PendingTeamsAction {
  type: typeof TeamActionsTypesConstants.PENDING_TEAMS;
}
interface ClearTeamsAction {
  type: typeof TeamActionsTypesConstants.CLEAR_TEAMS;
}

interface DeleteEmployeeAction {
  type: typeof TeamActionsTypesConstants.REMOVE_EMPLOYEE;
  payload: {
    teamId: string;
    employeeId: string;
  };
}

interface UpdateEmployeeAction {
  type: typeof TeamActionsTypesConstants.UPDATE_EMPLOYEE;
  payload: {
    teamId: string;
    employeeId: string;
  };
}

export type TeamsActionTypes =
  | AddTeamAction
  | GetTeamsAction
  | ClearTeamsAction
  | PendingTeamsAction
  | ErrorTeamsAction
  | GetTeamEmployeeAction
  | PendingTeamEmployeeAction
  | ErrorTeamEmployeeAction
  | UpdateEmployeeAction
  | DeleteEmployeeAction;

export interface TeamsState {
  teams: ITeam[];
  pending: boolean;
  error: string;
  employees: TeamEmployess | undefined;
  pendingEmployees: PendingEmployees | undefined;
  errorEmployees: string;
}
