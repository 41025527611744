import React, { useState, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';

import { Avatar } from '../Avatar';
import { Tooltip, Overlay } from 'react-bootstrap';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { IMember, IPosition } from '../../../typings/interfaces';
import { deleteRecruiterByPosition } from '../../../actions/recruitersActions';
import { deleteReviewer } from '../../../actions/teamsActions';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow_down.svg';
import { v4 as uuid } from 'uuid';

interface Props {
  data: IMember;
  role: 'Recruiter' | 'Reviewer';
  curPosition: IPosition;
}

export const Member: React.FC<Props> = ({ data, role, curPosition }) => {
  const { teamMembersDeactivate } = useFlags();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const menu = useRef(null);
  const showTooltip = () => {
    setShow(true);
  };
  const hideTooltip = () => {
    setShow(false);
  };
  useOnClickOutside(menu, hideTooltip);

  const handleClick = () => {
    if (!teamMembersDeactivate) return;
    if (role === 'Recruiter') {
      dispatch(deleteRecruiterByPosition(curPosition.uuid, data.uuid));
    }
    if (role === 'Reviewer') {
      dispatch(deleteReviewer(curPosition.team.uuid, data.uuid));
    }
    hideTooltip();
  };

  return (
    <div className='d-flex align-items-center justify-content-between mb-2'>
      <div className='d-flex align-items-center'>
        <div className='rounded-circle icon--32 mr-2 bg-gray-400 d-flex align-items-end justify-content-center'>
          <Avatar data={data} />
        </div>
        <div>
          {data.first_name} {data.last_name}
        </div>
      </div>
      {!teamMembersDeactivate ? (
        <div>{role}</div>
      ) : (
        <button
          type='button'
          ref={target}
          onClick={showTooltip}
          className='border-0 p-0 bg-transparent d-flex align-items-center'
        >
          {role}
          <ArrowIcon
            className='icon--8 ml-1'
            style={{
              transform: show ? 'rotate(0deg)' : 'rotate(-90deg)',
            }}
          />
        </button>
      )}

      <Overlay target={target.current} show={show} placement='bottom'>
        {(props) => (
          <Tooltip {...props} className='text-sm' id={uuid()}>
            <div className='p-1' ref={menu}>
              <button
                type='button'
                className='border-0 p-0 bg-transparent text-white'
                onClick={handleClick}
              >
                Remove
              </button>
            </div>
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};
