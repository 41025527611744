import {
  PositionRecruiters,
  IRecruiter,
  PendingRecruiters,
} from '../../typings/interfaces';

export enum RecruitersActionsConstants {
  GET_RECRUITERS = 'GET_RECRUITERS',
  PENDING_RECRUITERS = 'PENDING_RECRUITERS',
  ERROR_RECRUITERS = 'ERROR_RECRUITERS',
  DELETE_RECRUITER_BY_POSITION = 'DELETE_RECRUITER_BY_POSITION',
}

interface GetRecruitersAction {
  type: typeof RecruitersActionsConstants.GET_RECRUITERS;
  payload: {
    recruiters: IRecruiter[];
    positionId: string;
  };
}
interface PendingRecruitersAction {
  type: typeof RecruitersActionsConstants.PENDING_RECRUITERS;
  payload: {
    positionId: string;
  };
}
interface ErrorRecruitersAction {
  type: typeof RecruitersActionsConstants.ERROR_RECRUITERS;
  payload: string;
}
interface DeleteRecruiterByPositionAction {
  type: typeof RecruitersActionsConstants.DELETE_RECRUITER_BY_POSITION;
  payload: {
    positionId: string;
    recruiterId: string;
  };
}

export interface RecruitersState {
  recruiters: PositionRecruiters | undefined;
  pendingRecruiters: PendingRecruiters | undefined;
  errorRecruiters: string;
}

export type RecruitersActionTypes =
  | GetRecruitersAction
  | PendingRecruitersAction
  | ErrorRecruitersAction
  | DeleteRecruiterByPositionAction;
