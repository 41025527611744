import React, { useState } from 'react';
import { Formik, Form, FormikErrors, FormikValues } from 'formik';
import { Button } from 'react-bootstrap';
import { ITask, ITicket } from '../../../typings/interfaces';
import { TaskTable } from './TaskTable';
import { StoryPointsSelect } from './StoryPointsSelect';
import { DurationInput } from './DurationInput';
import { updateTask } from '../../../actions/taskActions';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../../actions/alertActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { AlertType } from '../../../typings/enums';
import { RootState } from '../../../reducers';

interface Props {
  task: ITask;
  positionId: string;
}

export enum Duration {
  days = 'days',
  hours = 'hours',
}

const getTicketsIds = (tickets: number[]) => {
  return tickets.reduce((acc: number[], ticket: number) => {
    acc.push(ticket);
    return acc;
  }, []);
};

const countTicketsStoryPoints = (tickets: ITicket[], ticketIds: number[]) => {
  return tickets.reduce((acc: number, ticket: ITicket) => {
    if (ticketIds.includes(ticket.id)) {
      return acc + ticket.story_points;
    }
    return acc;
  }, 0);
};

interface FormValues {
  duration: number;
  estimate: number | string;
  story_points: number;
  tickets: number[];
  tickets_mandatory: number[];
}

export const TaskSettingsForm: React.FC<Props> = ({ task, positionId }) => {
  const dispatch = useDispatch();
  const [durationType, setDurationType] = useState<Duration>(Duration.hours);



  const tickets = useShallowEqualSelector(
    (state) => state.ticketsReducer.tickets
  );

  const activeTickets = useSelector(
    (state: RootState) => state.ticketsReducer.activeTickets
  );
  
  const initialValues: FormValues = {
    duration: task.duration,
    estimate: task.estimate,
    story_points: task.story_points,
    tickets: activeTickets || getTicketsIds(task.tickets),
    tickets_mandatory: task.tickets_mandatory
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        const duration =
          durationType === Duration.days
            ? values.duration * 24
            : values.duration;
        dispatch(
          updateTask(
            positionId,
            {
              ...values,
              duration,
            },
            () => {
              setSubmitting(false);
            }
          )
        );
      }}
      validate={(values) => {
        const errors: FormikErrors<FormikValues> = {};

        if (
          tickets &&
          countTicketsStoryPoints(tickets, values.tickets) < values.story_points
        ) {
          errors.tickets =
            'Sum of tickets story points can not be less than min story points parametr for task';
          dispatch(
            showAlert(
              { type: AlertType.fail, message: errors.tickets as string },
              4000
            )
          );
        }

        if (isNaN(values.duration)) {
          errors.duration = 'Duration field can not be empty';
          dispatch(
            showAlert(
              { type: AlertType.fail, message: errors.duration as string },
              4000
            )
          );
        }
        if (values.duration === 0) {
          errors.duration = 'Duration field can not be equal 0';
        }
        if (errors.duration) {
          dispatch(
            showAlert(
              { type: AlertType.fail, message: errors.duration as string },
              5000
            )
          );
        }

        return errors;
      }}
    >
      {({ setFieldTouched, setFieldValue, values, isSubmitting }) => (
        <Form>
          <div className='font-weight-bold mb-1 text-lg'>Set up challenge</div>
          <div className='d-flex align-items-center flex-wrap mb-4'>
            <StoryPointsSelect
              storyPoints={task?.story_points}
              setFieldTouched={setFieldTouched}
            />
            <DurationInput
              values={values}
              setFieldValue={setFieldValue}
              durationType={durationType}
              setDurationType={setDurationType}
            />
          </div>
          <div className='font-weight-bold mb-1 text-lg'>Select tickets</div>
          <TaskTable
            selectedTickets={values.tickets}
            challengeId={task.challenge.id}
            ticketsMandatory={values.tickets_mandatory}
          />
          <Button
            variant='warning'
            type='submit'
            className='position-fixed w-100 rounded-0'
            style={{ bottom: 0, left: 0, zIndex: 1 }}
            disabled={isSubmitting}
          >
            Update Task
          </Button>
        </Form>
      )}
    </Formik>
  );
};
