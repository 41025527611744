export default {
  diffContainer: {
    pre: {
      whiteSpace: 'pre-wrap',
      lineHeight: '1.3'
    }
  },
  line: {
    wordBreak: 'break-word'
  },
  gutter: {
    minWidth: 70
  },
  contentText: {
    paddingRight: 10
  }
};
