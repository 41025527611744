import React, { useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { ReactComponent as FolderIcon } from '../../../../assets/folderIcon.svg';
import { ReactComponent as FileIcon } from '../../../../assets/fileIcon.svg';
import { Link, useParams } from 'react-router-dom';
import { Path } from './Path';
import { ReadMe } from './ReadMe';

import {
  getGitContent,
  clearGitContent,
  getGitBranches,
  clearGitBranches,
} from '../../../../actions/gitActions';
import { v4 as uuid } from 'uuid';
import { RootState } from '../../../../reducers';
import { Loader } from '../../../../shared/components/Loader';

export const Tree: FC<any> = ({ path, positionId }) => {
  const dispatch = useDispatch();
  const { candidateId } = useParams();
  const gitContent = useSelector(
    (state: RootState) => state.gitReducer.gitContent
  );

  useEffect(() => {
    dispatch(getGitContent(path, positionId));

    return () => {
      dispatch(clearGitContent());
    };
  }, [dispatch, path, positionId]);

  useEffect(() => {
    dispatch(getGitBranches(positionId));
    return () => {
      dispatch(clearGitBranches());
    };
  }, [dispatch, positionId]);

  let returnPath;
  if (path) {
    returnPath = path.split('/').slice(0, -1).join('/');
  }

  return (
    <>
      {gitContent ? (
        <>
          {path && <Path path={path} returnPath={returnPath} />}
          <Table bordered hover responsive size='sm' className='mb-3'>
            <tbody>
              {path && (
                <tr>
                  <td className='px-4'>
                    <Link
                      className='d-block'
                      to={
                        returnPath
                          ? `/position/${positionId}/candidate/${candidateId}/repo/tree/${returnPath}`
                          : `/position/${positionId}/candidate/${candidateId}/repo`
                      }
                    >
                      ..
                    </Link>
                  </td>
                </tr>
              )}
              {gitContent.subFolders.map((subFolder: any) => {
                return (
                  <tr key={subFolder.treeId}>
                    <td className='px-4'>
                      <Link
                        className='d-block'
                        to={`/position/${positionId}/candidate/${candidateId}/repo/tree/${subFolder.absolutePath}`}
                      >
                        <FolderIcon className='mr-3' />
                        {subFolder.relativePath}
                      </Link>
                    </td>
                  </tr>
                );
              })}
              {gitContent.files.map((file: any) => {
                return (
                  <tr key={uuid()}>
                    <td className='px-4'>
                      <Link
                        className='d-block'
                        to={`/position/${positionId}/candidate/${candidateId}/repo/blob/${file.absolutePath}`}
                      >
                        <FileIcon className='mr-3' />
                        {file.relativePath}
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {!gitContent.folderPath && (
            <ReadMe gitContent={gitContent} positionId={positionId} />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
