import React from 'react';
import { IPosition } from '../../../typings/interfaces';
import { CardComponent } from '../../../shared/components/CardComponent';
import { Button } from 'react-bootstrap';

interface Props {
  position: IPosition;
}

export const PositionNoTask: React.FC<Props> = ({ position }) => {
  return (
    <CardComponent id={`position-${position.uuid}`}>
      <div className='d-flex flex-wrap justify-content-between'>
        <div className='pr-4 mb-3 mb-lg-0'>
          Recruiter{' '}
          <a href={'mailto:position.recruiter.email'}>
            {position.recruiter.email}
          </a>{' '}
          has created a new position Sr. JS Developer. <br />
          Please select a task and share it with recruiter to start testing
          candidates.
        </div>
        <div>
          <Button variant='warning'>Select Task</Button>
        </div>
      </div>
    </CardComponent>
  );
};
