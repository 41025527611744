import React from 'react';
import recruiterImgPath from '../../../assets/twoMonthsFreeTrial/recruiter_experience.png';
import recruiterImgPath2 from '../../../assets/twoMonthsFreeTrial/recruiter_experience2.png';
import recruiterImgPath3 from '../../../assets/twoMonthsFreeTrial/recruiter_experience3.png';
import { Carousel } from 'react-bootstrap';
import { ISlideBtn } from './ImgRightSlider';
import { SliderBtn } from './SliderBtn';

interface Props {
  index: number;
  slidesDesc: ISlideBtn[];
  handleSelect: (selectedIndex: number, e: object | null) => void;
  isMatchMediaQuery: boolean;
}

export const EngineersCarousel: React.FC<Props> = ({
  index,
  handleSelect,
  slidesDesc,
  isMatchMediaQuery,
}) => {
  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      controls={false}
      interval={null}
    >
      <Carousel.Item>
        <img
          className='d-block w-100'
          src={recruiterImgPath}
          alt='Prelimiary Results'
        />
        {!isMatchMediaQuery && (
          <SliderBtn
            btn={slidesDesc[0]}
            handleSelect={handleSelect}
            btnIndex={0}
            index={index}
            minHeight='78px'
          />
        )}
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src={recruiterImgPath2}
          alt='Efficient environment'
        />
        {!isMatchMediaQuery && (
          <SliderBtn
            btn={slidesDesc[1]}
            handleSelect={handleSelect}
            btnIndex={1}
            index={index}
            minHeight='78px'
          />
        )}
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100'
          src={recruiterImgPath3}
          alt='Collaborative Tools'
        />
        {!isMatchMediaQuery && (
          <SliderBtn
            btn={slidesDesc[2]}
            handleSelect={handleSelect}
            btnIndex={2}
            index={index}
            minHeight='78px'
          />
        )}
      </Carousel.Item>
    </Carousel>
  );
};
