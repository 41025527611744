import React, { useState, useCallback, useMemo } from 'react';
import ReactDiffViewer, {
  DiffMethod,
  ReactDiffViewerStylesOverride,
  CommentInfo,
} from 'react-diff-viewer';
import {
  IComment,
  INote,
  ISolution,
  IBlobChanges,
} from '../../../typings/interfaces';
import { useLocation } from 'react-router-dom';
import Prism from 'prismjs';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../../actions/alertActions';
import { CommentBlock } from '../CommentBlock';
import './gh-colors.css';
import styles from './styles';
import { PathBtn } from './PathBtn';
import { CommentsChain } from './CommentsChain';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { UserRoles, AlertType } from '../../../typings/enums';
import { useScrollToElement } from '../../../hooks/useScrollToElement';
import { Base64 } from 'js-base64';
import { constructLines } from '../../../util/constructLines';
import cn from 'classnames';
import './DiffView.scss';
import { userRoleSelector } from '../../../selectors';

interface Props {
  fileData: IBlobChanges;
  splitView: boolean;
  beforeCommit: string;
  afterCommit: string;
  comments: IComment[];
  candidate: ISolution;
  hidden?: boolean;
}

const getCommentsLineIdsArray = (comments: INote[]) => {
  return comments.reduce((acc: string[], comment: any) => {
    acc.push(comment.body.lineId);
    return acc;
  }, []);
};

const getCommentsIdsArray = (comments: INote[]) => {
  return comments.reduce((acc: number[], comment: any) => {
    acc.push(comment.id);
    return acc;
  }, []);
};

export const DiffView: React.FC<Props> = React.memo(
  ({
    fileData,
    splitView,
    beforeCommit,
    afterCommit,
    comments,
    candidate,
    hidden,
  }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [newComment, setNewComment] = useState<undefined | IComment>(
      undefined
    );
    const isReviewer =
      useShallowEqualSelector(userRoleSelector) === UserRoles.REVIEWER;

    const dispatch = useDispatch();
    let location = useLocation();
    useScrollToElement();
    const link = `${window.origin}${location.pathname}`;

    const copyLinkToClipboard = useCallback(
      (lineId: string, uniqueLineId: string) => {
        const el = document.createElement('textarea');
        el.value = link + `#${uniqueLineId}`;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        dispatch(
          showAlert({
            type: AlertType.success,
            message: 'Link to line is copied',
          })
        );
      },
      [link, dispatch]
    );

    const syntaxHighlight = useCallback((source: string, id?: string): any => {
      if (!source) return;
      const language = Prism.highlight(
        source,
        Prism.languages.javascript,
        'jsx'
      );
      return <span id={id} dangerouslySetInnerHTML={{ __html: language }} />;
    }, []);

    let commentsArray: IComment[] = [...comments];
    if (newComment) {
      commentsArray = [...comments, newComment];
    }

    const createNewComment = useCallback(
      (commentInfo: CommentInfo) => {
        setNewComment({
          body: {
            ...commentInfo,
            lineId: commentInfo.lineId,
            text: '',
          },
          position_id: candidate.position.uuid,
          user_id: candidate.candidate.user.uuid,
        });
      },
      [candidate]
    );

    const clearNewComment = () => setNewComment(undefined);

    const commentsIds = getCommentsIdsArray(comments);
    const commentsLineIds = getCommentsLineIdsArray(commentsArray);

    const commentsRenderer = useCallback(
      (commentInfo: CommentInfo) => {
        const commentsForCurLine = comments.filter(
          (comment) => comment.body.lineId === commentInfo.lineId
        );

        if (commentsForCurLine.length > 0) {
          return (
            <CommentsChain
              commentsForCurLine={commentsForCurLine}
              commentsIds={commentsIds}
              newComment={newComment}
              createNewComment={createNewComment}
              clearNewComment={clearNewComment}
              commentInfo={commentInfo}
            />
          );
        }

        return (
          <CommentBlock
            comment={newComment}
            commentsIds={commentsIds}
            clearNewComment={clearNewComment}
            commentInfo={commentInfo}
            className='rounded'
          />
        );
      },
      [comments, newComment, createNewComment, commentsIds]
    );

    const path = fileData.beforeBlob?.path || fileData.afterBlob?.path || '';

    const decodedChanges = useMemo(() => {
      const changes = fileData.beforeBlob || fileData.afterBlob;
      const lines = constructLines(Base64.decode(changes.content)).length;

      if (lines > 500) {
        return {
          isTooMuchChanges: true,
        };
      }

      let oldValue = fileData.beforeBlob?.content
        ? Base64.decode(fileData.beforeBlob.content)
        : '';
      let newValue = fileData.afterBlob?.content
        ? Base64.decode(fileData.afterBlob.content)
        : '';

      return {
        oldValue,
        newValue,
      };
    }, [fileData.afterBlob, fileData.beforeBlob]);

    let content = (
      <div className='p-4'>Too much changes, please check it locally</div>
    );
    if (!decodedChanges.isTooMuchChanges) {
      content = (
        <ReactDiffViewer
          oldValue={
            hidden
              ? decodedChanges.oldValue?.replace(/\w/g, 'X')
              : decodedChanges.oldValue
          }
          newValue={
            hidden
              ? decodedChanges.newValue?.replace(/\w/g, 'X')
              : decodedChanges.newValue
          }
          splitView={splitView}
          showDiffOnly={true}
          compareMethod={DiffMethod.LINES}
          styles={styles as ReactDiffViewerStylesOverride}
          renderContent={syntaxHighlight}
          onLineNumberClick={copyLinkToClipboard}
          beforeCommit={beforeCommit}
          afterCommit={afterCommit}
          commentLineIds={commentsLineIds}
          fileId={path}
          renderCommentBlock={commentsRenderer}
          getCommentInfo={createNewComment}
          couldComment={isReviewer}
        />
      );
    }

    return (
      <div
        className={cn('mb-6 files-diff border rounded rounded-lg', {
          'position-relative': hidden,
        })}
      >
        <PathBtn
          setIsExpanded={setIsExpanded}
          isExpanded={isExpanded}
          path={path}
        />
        {isExpanded && <div id={`collapse-${path}`}>{content}</div>}
        {hidden && (
          <div
            className={cn({
              'position-absolute inset-0 hidden d-flex rounded-lg': hidden,
            })}
          >
            <div className='m-auto text-lg text-center'>
              Available after Sign Up
            </div>
          </div>
        )}
      </div>
    );
  }
);
