import { SubscribeError } from '../../typings/interfaces';
import { Subscribes } from '../../typings/enums';

export const ERROR_FORM_SUBSCRIBE = 'ERROR_FORM_SUBSCRIBE';
export const CLEAR_ERROR_FORM_SUBSCRIBE = 'CLEAR_ERROR_FORM_SUBSCRIBE';

export interface SubscribesState {
  errorSubscribes: SubscribeError[];
}

interface ErrorFormSubscribeAction {
  type: typeof ERROR_FORM_SUBSCRIBE;
  payload: {
    type: Subscribes;
    message: string;
  };
}
interface ClearErrorFormSubscribeAction {
  type: typeof CLEAR_ERROR_FORM_SUBSCRIBE;
  payload: {
    type: Subscribes;
    message: string;
  };
}

export type SubscribesActionsTypes =
  | ClearErrorFormSubscribeAction
  | ErrorFormSubscribeAction;
