import React from 'react';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';

interface Props {
  Icon?: JSX.Element;
  title: string;
  text: string;
  className?: string;
}

export const Feature: React.FC<Props> = ({ Icon, title, text, className }) => {
  return (
    <div className={cn('lg:w-4/12 mb-8 mb-lg-0', [className])}>
      {Icon && (
        <div
          className='mb-4 d-flex align-items-center justify-content-center justify-content-lg-start'
          style={{ height: '40px' }}
        >
          {Icon}
        </div>
      )}

      <div className='text-2-5xl font-weight-bold mb-2 text-center text-lg-left'>
        {title}
      </div>
      <div>
        {text.split('\n').map((str) => (
          <div key={uuid()} className='text-center text-lg-left text-xl'>
            {str}
          </div>
        ))}
      </div>
    </div>
  );
};
