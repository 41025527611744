import React from 'react';
import { Commits } from '../../../reviewer/components/Commits';
import { FilesChanged } from '../../../reviewer/pages/FilesChanged';

export const Code: React.FC = () => {
  return (
    <div>
      <Commits />
      <FilesChanged />
    </div>
  );
};
