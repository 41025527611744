import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { HeaderCell } from '../../../shared/components/HeaderCell';
import { Stages } from '../../../typings/enums';

interface Props {
  setOrder: React.Dispatch<React.SetStateAction<boolean>>;
  setPropertyKey: React.Dispatch<React.SetStateAction<string>>;
  order: boolean;
  propertyKey: string;
  stageType: string | undefined;
}

export const TableHeader: React.FC<Props> = ({
  setOrder,
  setPropertyKey,
  order,
  propertyKey,
  stageType
}) => {
  const { recruiterDataFlag, showLikeDeslikeFeature } = useFlags();

  let tableHeader = [
    {
      title: 'Candidate',
      property: 'candidate.first_name'
    },
    {
      title: 'Task sent',
      property: 'date_created'
    },
    {
      title: 'Completed',
      property: 'date_ready'
    },
    {
      title: `${stageType && stageType[0].toUpperCase() + stageType.slice(1)}`,
      property: 'feedback.date_created'
    },
    {
      title: 'Story Points',
      property: 'story_points'
    }
  ];

  if (stageType === Stages.SUBMITTED) {
    tableHeader = tableHeader.filter(
      (cell) => cell.property !== 'feedback.date_created'
    );
  }

  return (
    <thead className='font-weight-normal'>
      <tr>
        {tableHeader.map((cell) => (
          <HeaderCell
            key={cell.title}
            order={order}
            setOrder={setOrder}
            propertyKey={propertyKey}
            setPropertyKey={setPropertyKey}
            property={cell.property}
            title={cell.title}
          />
        ))}
        {recruiterDataFlag ? (
          <HeaderCell
            order={order}
            setOrder={setOrder}
            propertyKey={propertyKey}
            setPropertyKey={setPropertyKey}
            property={'candidate.recruiter.first_name'}
            title='Recruiter'
          />
        ) : null}
        {showLikeDeslikeFeature ||
        stageType === Stages.ACCEPTED ||
        stageType === Stages.REJECTED ? (
          <th className='border-0 font-weight-normal cursor-pointer'>
            Reviewed By
          </th>
        ) : null}
      </tr>
    </thead>
  );
};
