import React, { useState } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { sendSubscribe } from '../../../actions/subscribeActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { ThankYouBlock } from '../../components/SubscribeForms/ThankYouBlock';
import { FormContainer } from '../../components/SubscribeForms/FormContainer';
import { ScheduleBlock } from '../../components/SubscribeForms/ScheduleBlock';
import { AgreeCheckbox } from '../../components/SubscribeForms/AgreeCheckbox';
import * as Yup from 'yup';
import { IFields } from '../../../typings/interfaces';
import { FormFields } from '../../components/FormFields';
import { SubmitBtn } from '../../components/SubscribeForms/SubmitBtn';
import { Subscribes } from '../../../typings/enums';
import { SubscribeApiError } from '../../components/SubscribeForms/SubscribeApiError';
import { errorSubscribesSelector } from '../../../selectors';
export const initialValues: FormValues = {
  first_name: '',
  last_name: '',
  email: '',
  agree: false,
};

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  agree: boolean;
}

export const BookADemo: React.SFC = (props) => {
  const dispatch = useDispatch();

  const [subscriber, setSubscriber] = useState<string | undefined>(undefined);

  const errorSubscribes = useShallowEqualSelector(errorSubscribesSelector);

  const fields: IFields = {
    first_name: {
      label: 'First name',
      id: 'subscribe_first_name',
    },
    last_name: {
      label: 'Last name',
      id: 'subscribe_last_name',
    },
    email: {
      label: 'Email',
      id: 'subscribe_email',
    },
  };

  return (
    <FormContainer>
      <div className='d-flex flex-column flex-lg-row w-100'>
        <div className='flex-grow-1'>
          {!subscriber ? (
            <div className='mb-6 mb-lg-0'>
              <h1 className='text-4-6xl font-weight-bold mx-auto leading-thin mb-4 text-center text-lg-left'>
                Book a demo
              </h1>
              <div
                className='text-2xl mb-6 text-center text-lg-left'
                style={{ lineHeight: '32px' }}
              >
                Fill out the form and founder
                <br />
                Dmitry Malyshenko will reach out to discuss
              </div>
              <SubscribeApiError
                type={Subscribes.BOOK}
                errorSubscribes={errorSubscribes}
              />
              <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(
                    sendSubscribe(
                      {
                        name: `${values.first_name.trim()} ${values.last_name.trim()}`,
                        email: values.email,
                      },
                      Subscribes.BOOK,
                      (response) => {
                        setSubmitting(false);
                        if (response.data) {
                          setSubscriber(values.first_name);
                        }
                      }
                    )
                  );
                }}
                validationSchema={Yup.object({
                  first_name: Yup.string()
                    .required('Required')
                    .min(2, 'Must be 2 characters or more'),
                  last_name: Yup.string()
                    .required('Required')
                    .min(2, 'Must be 2 characters or more'),
                  email: Yup.string()
                    .email('Invalid email address')
                    .required('Required'),
                  agree: Yup.boolean().oneOf([true], 'Required'),
                })}
              >
                {({
                  handleSubmit,
                  errors,
                  handleBlur,
                  touched,
                  handleChange,
                  isSubmitting,
                }) => (
                  <div
                    style={{ maxWidth: '430px' }}
                    className='mx-auto mx-lg-0'
                  >
                    <form
                      className='items-center d-flex flex-column w-100'
                      onSubmit={handleSubmit}
                    >
                      <FormFields
                        fields={fields}
                        errors={errors}
                        touched={touched}
                      />
                      <AgreeCheckbox
                        isError={errors.agree}
                        isTouched={touched.agree}
                      />
                      <SubmitBtn
                        isSubmitting={isSubmitting}
                        btnTitle='Book a demo'
                        id='subscribe_submit'
                      />
                      <div className='text-center mb-6 mt-2 text-sm md:text-base'>
                        No credit card required
                      </div>
                    </form>
                  </div>
                )}
              </Formik>
            </div>
          ) : (
            <ThankYouBlock subscriber={subscriber} />
          )}
        </div>
        <ScheduleBlock />
      </div>
    </FormContainer>
  );
};
