import React from 'react';
import feature1ImgPath from '../../../assets/recruiters/track_progress.png';
import feature2ImgPath from '../../../assets/recruiters/identify.png';
import feature3ImgPath from '../../../assets/recruiters/steps.png';
import { BlockImgRight } from './BlockImgRight';
import { v4 as uuid } from 'uuid';

const BLOCKS = [
  {
    title: 'Stay in the loop',
    text: (
      <div>
        Manage the whole process; from sending tasks to inviting candidates to
        interviews. Track candidates’ engagement. Find out who decided to drop
        out
      </div>
    ),
    imagePath: feature1ImgPath,
    className: 'mb-20',
  },
  {
    title: 'Identify relevant candidates',
    text:
      'In advance. Without engineers. We estimate solutions and highlight the top relevant candidates. They won’t be overlooked',
    imagePath: feature2ImgPath,
    className: 'mb-20',
  },
  {
    title: 'Step in only when it’s needed',
    text: (
      <>
        <div className='mb-3'>
          No more back and forth messaging between candidates and the team and
          say goodbye to ZIP files
        </div>
        <div>— Engineers get solutions right after submission.</div>
        <div>
          — End-to-end support for take-home challenges, from invite to
          feedback.
        </div>
      </>
    ),
    imagePath: feature3ImgPath,
  },
];

export const Interface: React.FC = () => {
  return (
    <div className='pb-10' data-refkey='hiringTeams'>
      {BLOCKS.map((props) => (
        <BlockImgRight {...props} key={uuid()} />
      ))}
    </div>
  );
};
