import React from 'react';
import { ILike } from '../../../typings/interfaces';
import { Avatar } from '../Avatar';
import { MultipleLike } from './MultipleLike';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { Like } from './Like';

interface Props {
  votes: ILike[];
}

export const MultipleLikeWithCounter: React.FC<Props> = ({ votes }) => {
  if (votes.length === 2) {
    return <MultipleLike votes={votes} />;
  }

  return (
    <OverlayTrigger
      placement='bottom'
      overlay={
        <Tooltip id={`tooltip-likes-${uuid()}`}>
          <div>
            {votes.map((vote) => (
              <div key={`vote-${vote.created_by.uuid}-${vote.created_by.uuid}`}>
                {vote.created_by.first_name} {vote.created_by.last_name}
              </div>
            ))}
          </div>
        </Tooltip>
      }
    >
      <div
        className='d-flex position-relative'
        style={{ width: '89px', height: '33px' }}
      >
        <div className='position-absolute' style={{ zIndex: votes.length + 1 }}>
          <Like like={votes[0]} />
        </div>
        <div className='position-absolute' style={{ zIndex: 2, left: '27px' }}>
          <Avatar data={votes[0].created_by} />
        </div>
        <div className='position-absolute' style={{ left: '56px' }}>
          <div
            className='rounded-circle bg-blue-300 text-white d-flex align-items-center justify-content-center leading-none border border-white icon--33'
            style={{
              background: '#0A1F3A',
              left: '4px',
              zIndex: 2,
              fontSize: '13px',
            }}
          >
            +{votes.length - 1}
          </div>
        </div>
      </div>
    </OverlayTrigger>
  );
};
