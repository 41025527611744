import React from 'react';
import testImg from '../../../assets/newLanding/test.png';
import { TryButton } from '../../components/TryButton';
import { Container, Col, Row } from 'react-bootstrap';

const TAGS = ['react', 'bug fixes', 'refactoring', 'feature', 'api'];

export const TestCodding = () => {
  return (
    <div className='pb-8 pb-md-12'>
      <div
        style={{ background: 'rgba(0,0,0,0.33)' }}
        className='rounded-lg px-4 pt-6 pb-8'
      >
        <Container>
          <Row>
            <Col xs={12} lg={6} className='order-lg-2'>
              <img
                src={testImg}
                alt='Test real life codding skills'
                className='img-fluid'
              />
            </Col>
            <Col
              xs={12}
              lg={6}
              className='d-flex flex-column justify-content-between'
            >
              <div>
                <div
                  className='text-2xl md:text-3xl text-blue-100 mb-2'
                  style={{ lineHeight: '40px' }}
                >
                  Test real life coding skills
                </div>
                <p className='text-silver text-xl'>
                  Get a pre-configured web application with real world tasks.
                  Check how candidates solve problems, not algorithms
                </p>
                <div className='mb-6'>
                  {TAGS.map((tag) => (
                    <span
                      key={tag}
                      className='text-silver text-13 md:text-base'
                    >
                      #{tag}{' '}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <TryButton className='border border-yellow-600 hover:bg-yellow-700 text-blue-100 font-weight-bold h-10' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
