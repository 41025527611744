import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { CompanyData } from '../../typings/enums';

const LINKS = [
  {
    title: 'Imprint',
    path: '/imprint',
  },
  {
    title: 'Privacy Policy',
    path: '/privacy-policy',
  },
  {
    title: 'Contact',
    path: '/contact',
  },
];

export const BlueFooter = () => {
  return (
    <div
      className='bg-blue-900 container-fluid py-4'
      style={{ minHeight: '80px' }}
    >
      <div>
        <div className='d-flex justify-content-between d-md-block mb-4 mb-md-0'>
          {LINKS.map((link, i: number) => (
            <NavLink
              key={link.path}
              to={link.path}
              exact
              className={cn('text-white hover:opacity-50', {
                'mr-md-4': i !== LINKS.length - 1,
              })}
            >
              {link.title}
            </NavLink>
          ))}
        </div>
        <div className='d-md-flex flex-column align-items-center mt-md-n6'>
          <div className='ml-lg-3 mb-2'>
            <a
              href={`mailto:${CompanyData.email}`}
              className='text-lg-right text-center d-block text-white hover:opacity-50'
            >
              {CompanyData.email}
            </a>
            <a
              href={`tel:${CompanyData.phone.replace(' ', '')}`}
              className='text-lg-right text-center d-block text-white hover:opacity-50'
            >
              {CompanyData.phone}
            </a>
          </div>
          <div
            className='text-sm text-center pl-1 text-gray-100'
            style={{ opacity: '0.55' }}
          >
            ©2020 {CompanyData.companyName}. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
};
