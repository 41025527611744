import {
  GitActionsConstants,
  gitActionsTypes,
  GetGitContentTypes,
  GetGitFileTypes,
  GetGitBranches,
} from './types';
import { stringify } from 'query-string';

import { Action, Dispatch } from 'redux';
import { RootState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { apiFetch } from '../util/apiFetch';

export const getFilesDiff = (
  positionId: string,
  userId: string,
  beforeCommitSpecifier: string,
  afterCommitSpecifier: string
): ThunkAction<void, RootState, gitActionsTypes, Action<string>> => async (
  dispatch
) => {
  try {
    dispatch({
      type: GitActionsConstants.PENDING_FILES_DIFF,
    });

    const response = await apiFetch(
      'git',
      `/diff/${positionId}/${afterCommitSpecifier}/${beforeCommitSpecifier}/?user_id=${userId}`
    );
    if (response.error) throw new Error(response.error);

    dispatch({
      type: GitActionsConstants.GET_FILES_DIFF,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GitActionsConstants.ERROR_FILES_DIFF,
      payload: err.message,
    });
  }
};
export const getRepoInfo = (query: {
  position_id?: string;
  user_id?: string;
}): ThunkAction<void, RootState, gitActionsTypes, Action<string>> => async (
  dispatch
) => {
  try {
    dispatch({
      type: GitActionsConstants.PENDING_REPO_INFO,
    });

    const response = await apiFetch(
      'git',
      `/repo/?${stringify(query, { skipNull: true })}`
    );

    if (response.error) throw new Error(response.error);

    dispatch({
      type: GitActionsConstants.GET_REPO_INFO,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GitActionsConstants.ERROR_REPO_INFO,
      payload: err.message,
    });
  }
};

export const getGitContent = (path: any, candidate_uuid: string) => async (
  dispatch: Dispatch<GetGitContentTypes>
) => {
  try {
    const url = setGitContentUrl('folder', path, candidate_uuid);

    const response = await apiFetch('git', url);

    dispatch({
      type: GitActionsConstants.GET_GIT_CONTENT,
      payload: response.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getGitFile = (path: string, candidate_uuid: string) => async (
  dispatch: Dispatch<GetGitFileTypes>
) => {
  try {
    const url = setGitContentUrl('file', path, candidate_uuid);
    const response = await apiFetch('git', url);

    if (response.data) {
      dispatch({
        type: GitActionsConstants.GET_GIT_FILE,
        payload: response.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getGitBranches = (positionId: string) => async (
  dispatch: Dispatch<GetGitBranches>
) => {
  try {
    const response = await apiFetch('git', `/branches/${positionId}/`);

    if (response.error) throw new Error(response.error);

    dispatch({
      type: GitActionsConstants.GET_GIT_BRANCHES,
      payload: response.data,
      pendingGitBranches: false,
    });
  } catch (err) {
    console.log(err);
  }
};

const setGitContentUrl = (gitContentType: any, path: any, positionId: any) => {
  if (path) {
    return `/${gitContentType}/${positionId}/?path=${path}`;
  } else {
    return `/${gitContentType}/${positionId}/`;
  }
};

export const clearGitBranches = () => ({
  type: GitActionsConstants.CLEAR_GIT_BRANCHES,
});

export const clearGitContent = () => ({
  type: GitActionsConstants.CLEAR_GIT_CONTENT,
});
export const clearGitFile = () => ({
  type: GitActionsConstants.CLEAR_GIT_FILE,
});
