import React from 'react';
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg';
import { ReactComponent as UserIcon } from '../../../assets/twoMonthsFreeTrial/user.svg';

const Steps = [
  {
    title: 'Invite Candidates',
    role: 'Recruiter',
    time: 'Instantly',
    text: 'Just send the link, we’ll take care of everything else',
  },
  {
    title: 'Working on task',
    role: 'Candidate',
    time: 'Up to 7 days',
    text: 'Identify who is doing their best and who decided to drop out',
  },
  {
    title: 'Team Review',
    role: 'Engineers',
    time: 'Up to 15 min',
    text: 'Your internal engineers review solutions on Sfeeda',
  },
  {
    title: 'Taking decision',
    role: 'Hiring Manager',
    time: 'Instantly',
    text: 'Based on engineers feedback',
  },
  {
    title: 'Send Feedback',
    role: 'Recruiter',
    time: 'In a single click',
    text: 'Directly on Sfeeda',
  },
];

interface StepProps {
  title: string;
  role: string;
  time: string;
  text: string;
  num: number;
}

const Step: React.FC<StepProps> = ({ title, role, time, text, num }) => {
  return (
    <div className='d-flex pt-2 pb-6 border border-top border-bottom-0 border-right-0 border-left-0 md:border-r md:border-l md:border-b  flex-md-column pb-md-8 p-md-3 md:w-2/10'>
      <div className='mr-2 mr-md-0 mb-md-8'>
        <div className='text-orange-500 text-3xl md:text-4-7xl leading-none'>
          {num}
        </div>
      </div>
      <div>
        <div className='text-xl leading-thin mb-2 mb-md-3 font-weight-bold'>
          {title}
        </div>
        <div className='d-flex align-items-center flex-md-column align-items-md-start mb-2 mb-md-4'>
          <div className='d-flex align-items-center mr-3 mr-md-0 mb-md-3'>
            <UserIcon className='mr-2' />
            <div>{role}</div>
          </div>
          <div className='d-flex align-items-center'>
            <div className='icon--24 d-flex align-items-center justify-content-center mr-2'>
              <ClockIcon />
            </div>
            <div>{time}</div>
          </div>
        </div>
        <div>{text}</div>
      </div>
    </div>
  );
};

export const HowItWorks = () => {
  return (
    <div className='container pb-md-20 pb-12'>
      <div className='text-27 mb-2 text-md-center md:text-4-5xl mb-md-6'>
        How it works
      </div>
      <div className='d-md-flex'>
        {Steps.map((step, i) => (
          <Step
            title={step.title}
            role={step.role}
            time={step.time}
            text={step.text}
            num={i + 1}
            key={step.title}
          />
        ))}
      </div>
    </div>
  );
};
