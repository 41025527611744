import React from 'react';
import { PricingContent } from './PricingContent';
import { BookADemoBtn } from '../BookADemoBtn';

interface Props {
  setRef: (node: HTMLDivElement | null) => void;
}

export const Pricing: React.FC<Props> = ({ setRef }) => {
  return (
    <div
      className='bg-blue-900 pt-12 pb-8'
      ref={setRef}
      data-refkey='pricing'
      id='pricing'
    >
      <PricingContent />
      <div className='text-center pb-20'>
        <BookADemoBtn className='py-2 text-2xl rounded-lg' />
        <div className='text-center mt-8 text-white text-sm md:text-base'>
          No credit card required
        </div>
      </div>
    </div>
  );
};
