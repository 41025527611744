import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { hideModal } from '../../../actions/modalActions';
import { ModalContent } from './ModalContent';
import { InviteWithoutEmail } from './InviteWithoutEmail'
import './index.scss';
import { RootState } from '../../../reducers';

export const ModalComponent: React.SFC = () => {
  const dispatch = useDispatch();

  const isModalShown = useShallowEqualSelector(
    (state) => state.modalReducer.isModalShown
  );

  const modalType = useShallowEqualSelector(
    (state) => state.modalReducer.modalType
  );
  const modalTitle = useShallowEqualSelector(
    (state) => state.modalReducer.modalTitle
  );

  const { modalWithoutEmail } = useSelector(
    (state: RootState) => state.modalReducer
  );

  const handleClose = () => {
    dispatch(hideModal());
  };

  return (
    <>
      <Modal
        keyboard={false}
        scrollable={true}
        show={isModalShown}
        onHide={handleClose}
        aria-labelledby={`${modalTitle}`}
      >
        <ModalContent modalType={modalType} />
      </Modal>
      { modalWithoutEmail ? <InviteWithoutEmail /> : null }
    </>
  );
};
