import { IUser } from '../typings/interfaces';
import { fetchWithToken } from './fetchWithToken';
import { store } from '../store';
import jwtDecode from 'jwt-decode';
import { refreshAccessToken } from '../actions/userActions';
import { ApiType } from '../typings/types';

export const apiFetch = async <B>(
  api: ApiType,
  url: string,
  method = 'get',
  body: B | undefined = undefined,
  headers = {}
): Promise<any> => {
  const { accessToken } = store.getState().userReducer;

  if (accessToken) {
    const userData: IUser = jwtDecode(accessToken);
    const isExpired = Date.now() - 1000 * 60 > userData.exp * 1000;
    isExpired && (await store.dispatch(refreshAccessToken()));
  }

  return await fetchWithToken<B>(api, url, method, body, headers);
};
