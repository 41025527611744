import {
  TicketsTypesConstants,
  TicketsActionsTypes,
  TaskActionsTypes,
  SET_TASK,
  InstanceTicketTypes,
} from './types';
import { stringify } from 'query-string';
import { apiFetch } from '../util/apiFetch';
import { Dispatch } from 'redux';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';
import { showAlert } from './alertActions';
import { AlertType } from '../typings/enums';

export const getTicketsByChallengeId = (
  challengeId: number
): ThunkAction<void, RootState, TicketsActionsTypes, Action<string>> => async (
  dispatch
) => {
  try {
    dispatch({
      type: TicketsTypesConstants.PENDING_TICKETS_BY_CHALLENGE,
    });

    const res = await apiFetch(
      'ticket',
      `/ticket/?${stringify({ challenge_id: challengeId }, { skipNull: true })}`
    );

    const skills = await apiFetch('meta', '/skill/');

    res.data.map((item: any) => {
      item.skills_meta.map((meta: any) => {
        skills.data.filter((title: any) => {
          if (meta.skill_id === title.id) {
            meta.title = title.title;
          }
        });
      });
    });

    if (res.error) throw new Error(res.error);

    dispatch({
      type: TicketsTypesConstants.GET_TICKETS_BY_CHALLENGE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TicketsTypesConstants.ERROR_TICKETS_BY_CHALLENGE,
      payload: err.message,
    });
  }
};

export const ticketsFromChallange = (positionId?: string) => async (
  dispatch: Dispatch<TicketsActionsTypes | TaskActionsTypes>
) => {
  try {
    dispatch({
      type: TicketsTypesConstants.PENDING_TICKETS_BY_CHALLENGE,
    });

    const taskData = await apiFetch('company', `/v1/task/${positionId}/`);

    const payload = {
      duration: taskData.data.duration,
      estimate: taskData.data.estimate,
      story_points: taskData.data.story_points,
      tickets_mandatory: taskData.data.tickets_mandatory,
      tickets: taskData.data.tickets,
    };

    dispatch({
      type: TicketsTypesConstants.SET_INSTANCE_TICKETS,
      payload,
    });
    dispatch({
      type: SET_TASK,
      payload: taskData.data,
    });

    const ticketByChallenge = await apiFetch(
      'ticket',
      `/ticket/?${stringify(
        { challenge_id: taskData.data.challenge_id },
        { skipNull: true }
      )}`
    );

    ticketByChallenge.data.map((item: any) => {
      item.selectedTicket = false;
      taskData.data.tickets.map((point: any) => {
        if (item.id === point) {
          item.selectedTicket = true;
        }
      });
      item.mandatoryTicket = false;
      taskData.data.tickets_mandatory.map((point: any) => {
        if (item.id === point) {
          item.mandatoryTicket = true;
        }
      });
    });

    const skills = await apiFetch('meta', '/skill/');

    ticketByChallenge.data.map((item: any) => {
      item.skills_meta.map((meta: any) => {
        skills.data.filter((title: any) => {
          if (meta.skill_id === title.id) {
            meta.title = title.title;
          }
        });
      });
    });

    if (ticketByChallenge.error) throw new Error(ticketByChallenge.error);

    dispatch({
      type: TicketsTypesConstants.GET_TICKETS_BY_CHALLENGE,
      payload: ticketByChallenge.data,
    });
  } catch (err) {
    dispatch({
      type: TicketsTypesConstants.ERROR_TICKETS_BY_CHALLENGE,
      payload: err.message,
    });
  }
};

export const setInstanceSetup = (payload: InstanceTicketTypes) => async (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: TicketsTypesConstants.SET_INSTANCE_TICKETS,
    payload,
  });
};

export const putUpdatedTickets = (
  payload: InstanceTicketTypes,
  positionId: string
) => async (dispatch: Dispatch<any>) => {
  const req = await apiFetch(
    'company',
    `/v1/task/${positionId}`,
    'PUT',
    payload
  );

  if (req.error) throw new Error(req.error);

  dispatch(
    showAlert({
      type: AlertType.success,
      message: 'Task updated successfully',
    })
  );
};

export const setActiveTickets = (ticketsIds: number[]) => async (
  dispatch: any
) => {
  dispatch({
    type: TicketsTypesConstants.SET_ACTIVE_TICKETS,
    payload: ticketsIds,
  });
};

export const clearActiveTickets = () => ({
  type: TicketsTypesConstants.CLEAR_ACTIVE_TICKETS,
});
