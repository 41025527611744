import React from 'react';
import { NavBar } from '../../components/NavBar';
import { Footer } from './Footer';
import { CompanyData } from '../../../typings/enums';

const LINKS = [
  {
    titleDesktop: 'Hiring teams',
    titleMob: 'Teams',
    href: '/hiring_teams',
  },
  {
    titleDesktop: 'Recruiters',
    titleMob: 'Recruiters',
    href: '/recruiters',
  },

  {
    titleDesktop: 'Pricing',
    titleMob: 'Pricing',
    href: '/#pricing',
  },
  {
    titleDesktop: 'Contact',
    titleMob: 'Contact',
    href: '/contact',
  },
];

export const Contact = () => {
  return (
    <div className='bg-blue-900'>
      <div className='container min-vh-full d-flex w-100 flex-column'>
        <div className='pt-6'>
          <NavBar links={LINKS} />
        </div>
        <div className='flex-grow-1 d-flex align-items-center justify-content-center'>
          <div className='d-lg-flex justify-content-lg-center'>
            <div className='font-weight-bold text-25 text-gray-100 mr-lg-20 lg:text-4xl mb-2'>
              Get in touch
            </div>
            <div
              className='text-white lh-32 text-22 mt-md-2 ml-lg-20'
              style={{ maxWidth: '528px' }}
            >
              <div className='mb-2'>
                Whether you’re a business, recruiter or candidate we would love
                to hear from you. You can get in contact by calling{' '}
                <div className='d-block d-lg-inline-block'>
                  <a
                    href={`tel:${CompanyData.phone.replace(' ', '')}`}
                    className='d-lg-inline-block mt-2 text-white font-serif border-bottom border-blue-600 lg:border-b-0'
                  >
                    {CompanyData.phone}
                  </a>
                </div>{' '}
                <span className='d-none d-lg-inline'>or by emailing</span>
              </div>
              <div className='d-lg-none mt-4'>or by emailing</div>
              <a
                href='mailto:sfeeda@sfeeda.com'
                className='d-inline-block text-white font-serif border-bottom border-blue-600'
              >
                sfeeda@sfeeda.com
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
