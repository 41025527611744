import {
  SHOW_MODAL,
  HIDE_MODAL,
  ModalsState,
  ModalsActionTypes,
  INVITEWITHOUTEMAILMODAL,
} from '../actions/types';

export const initialState: ModalsState = {
  isModalShown: false,
  modalType: undefined,
  modalTitle: undefined,
  modalWithoutEmail: false
};

export const modalReducer = (
  state = initialState,
  action: ModalsActionTypes
): ModalsState => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        isModalShown: true,
        modalType: action.payload.type,
        modalTitle: action.payload.title,
      };
    case HIDE_MODAL:
      return {
        ...state,
        isModalShown: false,
      };
      case INVITEWITHOUTEMAILMODAL:
        return {
          ...state,
          modalWithoutEmail: action.payload
        }
    default:
      return state;
  }
};
