import React, { ReactNode } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Container, Row, Col } from 'react-bootstrap';
import { KeyActivitiesWidget } from '../shared/components/KeyActivitiesWidget';

interface Props {
  children: ReactNode;
}

export const PositionsContainer = ({ children }: Props) => {
  const { showKeyActivities } = useFlags();
  return (
    <Container fluid className='px-4'>
      <Row noGutters>
        <Col md={showKeyActivities ? 9 : undefined}>{children}</Col>
        {showKeyActivities && (
          <Col md={3} style={{ maxHeight: '100vh' }} className='pl-md-3 '>
            <KeyActivitiesWidget />
          </Col>
        )}
      </Row>
    </Container>
  );
};
