import { useState } from 'react';
import { ISolution, INote } from '../typings/interfaces';
import { NoteType } from '../typings/enums';
import { createNote, updateNote } from '../actions/notesActions';
import { useDispatch } from 'react-redux';

export const useNote = (
  note: INote | undefined,
  currentCandidate: ISolution | undefined,
  noteType?: string | undefined
) => {
  const [text, setText] = useState<string>(note ? note.body.text : '');
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleNoteCreateUpdate = async () => {
    if (!currentCandidate) return;
    try {
      if (!note) {
        await dispatch(
          createNote({
            body:
              noteType === NoteType.to_recruiter
                ? { text: text, type: NoteType.to_recruiter }
                : { text: text },
            position_id: currentCandidate.position.uuid,
            user_id: currentCandidate.candidate.user.uuid,
          })
        );
        setText('');
        return;
      }
      await dispatch(
        updateNote(note?.id as number, {
          body: { ...note?.body, text },
        })
      );
      setText('');
      return 'updated';
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handleCancel = () => {
    setText(note ? note.body.text : '');
    setIsEditable(false);
  };

  const handleSave = async () => {
    if (text === '') {
      return handleCancel();
    }
    try {
      const action = await handleNoteCreateUpdate();
      setIsEditable(false);
      if (action === 'updated') setText(noteType ? text : '');
      setError(false);
    } catch (error) {
      setError(true);
      throw new Error(error.message);
    }
  };

  return {
    handleCancel,
    handleSave,
    setText,
    isEditable,
    setIsEditable,
    text,
    error,
  };
};
