import React from 'react';
import { Formik, Form } from 'formik';
import { UserRoles } from '../../../typings/enums';
import { TextField } from '../TextField';
import { RolesDropdown } from './RolesDropdown';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { sendInvite, clearInviteError } from '../../../actions/invitesActions';
import { useShallowEqualSelector } from '../../../hooks/useShallowEqualSelector';
import { errorText } from '../../../util/fetchWithToken';
import { generateInviteLink } from '../../../util/invitesHelpers';
import {
  curPositionSelector,
  errorSendInviteSelector,
} from '../../../selectors';

interface Props {
  membersLength: number;
  setInvitationLink: React.Dispatch<React.SetStateAction<string>>;
  setIsInvitationLinkShown: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentRole: React.Dispatch<React.SetStateAction<string>>;
}

interface FormValues {
  email: string;
  role: string;
}

export const InviteForm: React.FC<Props> = ({
  membersLength,
  setInvitationLink,
  setIsInvitationLinkShown,
  setCurrentRole,
}) => {
  const initialValues: FormValues = {
    email: '',
    role: membersLength === 1 ? UserRoles.RECRUITER : UserRoles.REVIEWER,
  };

  const dispatch = useDispatch();

  const curPosition = useShallowEqualSelector(curPositionSelector);
  const errorSendInvite = useShallowEqualSelector(errorSendInviteSelector);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(clearInviteError());
        dispatch(
          sendInvite(
            {
              email: values.email,
              role: values.role,
              data: {
                position_id: curPosition?.uuid,
              },
            },
            (response) => {
              setSubmitting(false);
              if (response.data) {
                setInvitationLink(
                  generateInviteLink(response.data.uuid, values.role)
                );
                setCurrentRole(values.role);
                setIsInvitationLinkShown(true);
              }
            }
          )
        );
      }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
      })}
    >
      {({ errors, touched, values, setFieldValue, isSubmitting }) => (
        <Form>
          {errorSendInvite ? (
            <div className='text-danger text-sm'>
              {typeof errorSendInvite === 'string'
                ? errorSendInvite
                : errorText}
            </div>
          ) : null}
          <div className='d-flex'>
            <div className='flex-grow-1 mr-2'>
              <TextField
                name='email'
                isError={errors.email}
                isTouched={touched.email}
                onKeyDown={(event: KeyboardEvent) => {
                  if (event.keyCode === 13) {
                    event.preventDefault();
                  }
                }}
                placeholder='email'
                size='sm'
                Btn={
                  <RolesDropdown
                    role={values.role}
                    setFieldValue={setFieldValue}
                  />
                }
                style={{
                  paddingRight: '75px',
                }}
              />
            </div>
            <Button
              variant='warning'
              className='py-0'
              style={{ height: '2rem', width: '106px' }}
              type='submit'
              disabled={isSubmitting}
            >
              Invite
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
