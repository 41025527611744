import { useShallowEqualSelector } from './useShallowEqualSelector';
import { IPosition, IMember } from '../typings/interfaces';

export const useMembers = (position: IPosition | undefined) => {
  const employees = useShallowEqualSelector(
    (state) => state.teamsReducer.employees
  );
  const recruiters = useShallowEqualSelector(
    (state) => state.recruitersReducer.recruiters
  );

  const curTeamEmployees: any[] = position
    ? employees?.[position.team.uuid] || []
    : [];
  const curPositionRecruiters: any[] = position
    ? recruiters?.[position.uuid] || []
    : [];

  const members = [...curTeamEmployees, ...curPositionRecruiters]
    .map((member) => {
      if (member.employee)
        return {
          ...member,
          data: member.employee as IMember,
          role: 'Reviewer',
        };
      if (member.recruiter)
        return {
          ...member,
          data: member.recruiter as IMember,
          role: 'Recruiter',
        };
      return null;
    })
    .sort((a, b) => {
      if (a.data.first_name < b.data.first_name) return -1;
      if (a.data.first_name > b.data.first_name) return 1;
      return 0;
    });

  return { members };
};
