import React from 'react';
import { TextField } from '../TextField';
import { FormikErrors, FormikValues, FormikTouched } from 'formik';
import { IFields } from '../../../typings/interfaces';
import { PasswordField } from '../PasswordField';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface Props {
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  fields: IFields;
  size?: 'lg' | 'sm' | 'md';
}

const passwordFields = ['oldPassword', 'newPassword', 'password'];

export const FormFields: React.FC<Props> = ({
  errors,
  touched,
  fields,
  size = 'lg',
}) => {
  const [isMatchMediaQuery] = useMatchMedia(769);
  const classes = cn({ 'text-xl': size === 'lg' && isMatchMediaQuery });
  const fieldsArr = Object.entries(fields as IFields);

  return (
    <>
      {fieldsArr.map(([name, values]) => {
        const { label, id, placeholder, autoComplete, readOnly } = values;
        if (!passwordFields.includes(name)) {
          return (
            <div key={name}>
              <label htmlFor={id} className={classes}>
                {label}
              </label>
              <TextField
                name={name}
                type='text'
                placeholder={placeholder}
                isError={errors[name]}
                isTouched={touched[name]}
                style={{ backgroundColor: '#F8FBFF' }}
                id={id}
                autoComplete={autoComplete}
                readOnly={readOnly}
                size={size}
                tabIndex={values.tabIndex}
              />
            </div>
          );
        }
        return (
          <div key={name}>
            <div className='d-flex align-items-center justify-content-between'>
              <label htmlFor={id} className={classes}>
                {label}
              </label>
              {name === 'password' && id !== 'new-password' && (
                <Link to='/password/forgot/' className='text-sm' tabIndex={-1}>
                  Forgot password
                </Link>
              )}
            </div>
            <PasswordField
              name={name}
              isError={errors[name]}
              isTouched={touched[name]}
              id={id}
              placeholder={placeholder}
              size={size}
              tabIndex={values.tabIndex}
            />
          </div>
        );
      })}
    </>
  );
};
